import React from 'react'

import apiClient from 'shared-components/utils/ApiClient'
import GuestAvatar from 'shared-components/avatar/GuestAvatar'
import EmojiBadge from 'shared-components/event/EmojiBadge'
import MixpanelLinkify from 'shared-components/common/MixpanelLinkify'
import moment from 'moment'
import _ from 'underscore'

import { Tooltip, Typography, withStyles } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'

const styles = theme => ({
  root: {
    height: 'auto'
  },
  container: {
    position: 'relative'
  },
  messagePaper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    borderRadius: 8
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(2)
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  messageContainer: {
    position: 'relative',
    width: '100%'
  },
  messageImageContainer: {
    width: '100%',
    paddingTop: '38%',
    overflow: 'hidden',
    height: 0,
    position: 'relative',
    borderRadius: 8,
    backgroundColor: theme.palette.primary.main
  },
  messageImage: {
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    objectFit: 'cover'
  },
  messageTopLine: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  time: {
    color: 'rgba(0, 0, 0, 0.7)'
  },
  messageBody: {
    whiteSpace: 'pre-wrap'
  },
  emojiContainer: {
    position: 'absolute',
    bottom: -theme.spacing(2),
    right: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    '& div:not(:last-child)': {
      marginRight: 8
    }
  },
  tooltip: {
    whiteSpace: 'pre-line'
  }
})

const sortByDate = messages => {
  return messages.sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt)
  })
}

const Messages = ({ classes, messages, eventCode }) => {
  const onImageClick = message => {
    window.open(message.imageUrl, '_blank')
  }

  return (
    <div className={classes.root}>
      {sortByDate(messages).map(message => (
        <div className={classes.container} key={message.id}>
          <Paper elevation={2} className={classes.messagePaper}>
            {message.imageUrl &&
              <div className={classes.messageImageContainer}>
                <img onClick={() => onImageClick(message)} src={message.imageUrl} className={classes.messageImage} />
              </div>}
            <div className={classes.contentContainer}>
              <GuestAvatar
                className={classes.avatar}
                guest={message.user}
                src={apiClient.images.userAvatar(message.user.id, eventCode)}
                size={40}
              />
              <div className={classes.messageContainer}>
                <div className={classes.messageTopLine}>
                  <Typography color='primary' variant='subtitle1'>
                    <b>{message.user.name}</b>
                  </Typography>
                  <Typography className={classes.time} variant='caption'>
                    {moment(message.createdAt).fromNow()}
                  </Typography>
                </div>
                <Typography className={classes.messageBody} variant='subtitle2'>
                  <MixpanelLinkify>
                    {message.content}
                  </MixpanelLinkify>
                </Typography>
              </div>
            </div>
          </Paper>
          {message.emojis &&
            <div className={classes.emojiContainer}>
              {_(message.emojis).uniq((emoji) => emoji.value).map(currentEmoji => {
                const counter = _(message.emojis).countBy((emoji) => emoji.value === currentEmoji.value ? 'count' : 'ignore')
                const tooltip = _(message.emojis)
                  .filter((emoji) => emoji.value === currentEmoji.value)
                  .map((emoji) => emoji.user.name)
                  .join('\n')
                return (
                  <Tooltip
                    enterTouchDelay={0}
                    classes={{ tooltip: classes.tooltip }}
                    placement='top'
                    key={currentEmoji.value}
                    title={tooltip}
                    aria-label={tooltip}
                  >
                    <div>
                      <EmojiBadge emoji={currentEmoji.value} count={counter.count} />
                    </div>
                  </Tooltip>
                )
              }
              )}
            </div>}
        </div>
      ))}
    </div>
  )
}

Messages.propTypes = {
}

export default withStyles(styles)(Messages)
