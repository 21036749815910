import React, { useState, useCallback } from 'react'
import { withTranslation } from 'react-i18next'
import { createSelector } from 'reselect'

import ShowGuestsRow from 'shared-components/event/ShowGuestsRow'
import ResponseBar from './ResponseBar'
import TitleImage from 'shared-components/event/TitleImage'

import LoadingScreen from 'shared-components/common/LoadingScreen'
import GuestDialog from './GuestDialog'
import ContentBlock from './ContentBlock'

import { withStyles, Button } from '@material-ui/core'
import { sortGuestsWithCurrent } from 'shared-components/utils/eventDisplay'
import MessageBlock from './MessageBlock'
import DetailsDescription from 'shared-components/event/DetailsDescription'

import CovidDialog from 'shared-components/event/CovidDialog'
import PageContentWrapper from 'shared-components/common/PageContentWrapper'
import OrganisationCard from 'shared-components/organisation/OrganisationCard'
import OrganiserInviteCopy from './ResponseBar/OrganiserInviteCopy'

const styles = theme => ({
  headerContainer: {
    height: 192,
    position: 'relative'
  },
  paperContainer: {
    width: '100%',
    position: 'relative',
    maxWidth: 480,
    marginLeft: 'auto',
    marginRight: 'auto',
    transition: 'all 0.3s',
    transitionTimingFunction: 'cubic-bezier(0.4, 0.0, 0.2, 1)'
  },
  footer: {
    margin: theme.spacing(3),
    marginBottom: theme.spacing(4),
    textAlign: 'center',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  logo: {
    width: '50%',
    maxWidth: 100
  },
  storeButtons: {
    maxWidth: 340,
    margin: theme.spacing(2, 'auto')
  },
  signIn: {
    marginTop: theme.spacing(2),
    alignSelf: 'center',
    width: 'fit-content'
  }
})

const sortGuestsWithOrganisers = (event, activeGuest) => {
  const activeGuestId = activeGuest ? activeGuest.id : undefined
  const codeGuestId = event.codeGuest ? event.codeGuest.id : null
  const userGuestId = event.userGuest ? event.userGuest.id : null
  const currentGuestId = activeGuestId || codeGuestId || userGuestId
  const sortedGuests = event.guests ? event.guests.sort(sortGuestsWithCurrent(currentGuestId)) : []
  if (event.organisation) return sortedGuests
  return [{
    id: event.organisers[0].id,
    status: 'GOING',
    user: event.organisers[0],
    organiser: true
  }].concat(sortedGuests)
}

/**
 * Call the memoized function and get properties from event object
 * @param {{guests: Guest[], limitNumberOfGuests: number, expectedNumberOfGuests: number}} event
 */
const getSpotsLeft = createSelector(
  [event => event.guests, event => event.limitNumberOfGuests, event => event.expectedNumberOfGuests],
  (guests, limitNumberOfGuests, expectedNumberOfGuests) => {
    if (!limitNumberOfGuests) return 10000
    const amountGoing = guests.filter(g => g.status === 'GOING').length
    return expectedNumberOfGuests - amountGoing
  }
)

export const appContext = React.createContext({})

const App = ({
  classes,
  event,
  organisation,
  isRejected,
  error,
  loading,
  isFulfilled,
  customResponseBar,
  StoreButtons = null,
  onSignIn,
  useRawImgUrl,
  useRawAvatarUrl,
  LinkComponent,
  activeGuest,
  t
}) => {
  if (organisation) event.organisation = organisation
  const [guestModalOpen, setGuestModalOpen] = useState(false)
  const [covidDialogOpen, setCovidDialogOpen] = useState(false)
  const sortedGuestsWithOrganisers = sortGuestsWithOrganisers(event, activeGuest)
  const spotsLeft = getSpotsLeft(event)
  const limitReached = event.limitNumberOfGuests && spotsLeft < 1

  const openGuestModal = useCallback(() => setGuestModalOpen(true), [setGuestModalOpen])
  const closeGuestModal = useCallback(() => setGuestModalOpen(false), [setGuestModalOpen])
  const openCovidDialog = useCallback(() => setCovidDialogOpen(true), [setCovidDialogOpen])
  const closeCovidDialog = useCallback(() => setCovidDialogOpen(false), [setCovidDialogOpen])

  const app = {
    event,
    limitReached,
    loading,
    spotsLeft,
    isPublicEvent: event.type === 'PUBLIC',
    pinningDate: event.type === 'PINNING',
    StoreButtons,
    LinkComponent,
    openCovidDialog,
    activeGuest
  }

  if (loading || !isFulfilled) return <LoadingScreen isRejected={isRejected} error={error} />
  return (
    <appContext.Provider value={app}>
      <PageContentWrapper id='app-root'>
        <div className={classes.headerContainer}>
          <TitleImage
            imageUrl={event.imageUrl}
            videoUrl={event.videoUrl}
            linkToFullImage
            key='titleImage'
            pushBack
            useRawUrl={useRawImgUrl}
          />
        </div>
        <div className={classes.paperContainer}>
          <DetailsDescription event={event} />
          <ContentBlock key='responseBar'>
            {customResponseBar ?
              React.cloneElement(customResponseBar, { openGuestModal }) :
              (
                <ResponseBar
                  showPostResponse={false}
                  activeResponse='NONE'
                  onClickResponse={() => {}}
                  shouldPulse
                  useAvatarOf={organisation}
                  inviteText={<OrganiserInviteCopy organiser={organisation} />}
                />
              )}
          </ContentBlock>
          <ContentBlock key='guests' display={event.guestsCanSeeGuests && sortedGuestsWithOrganisers.length !== 0}>
            <ShowGuestsRow
              event={event}
              spotsLeft={spotsLeft}
              key='guestsRow'
              guests={sortedGuestsWithOrganisers}
              openGuestModal={openGuestModal}
              openForUninvited={event.openForUninvited}
              useRawImgUrl={useRawAvatarUrl}
            />
          </ContentBlock>
          <ContentBlock key='organiserBlock' display={app.isPublicEvent && organisation}>
            <OrganisationCard organisation={organisation} location='Bash Event' />
          </ContentBlock>
          <MessageBlock
            key='messageContainer'
            display={event.guestsCanSeeGuests}
            eventCode={event.code}
            messages={event.messages}
          />
        </div>
        {onSignIn &&
          <Button color='primary' onClick={onSignIn} className={classes.signIn}>
            <u>{t('signIn')}</u>
          </Button>}
        {StoreButtons && <StoreButtons className={classes.storeButtons} action='RSVP_opening_page' />}
        <GuestDialog guests={sortedGuestsWithOrganisers} open={guestModalOpen} onClose={closeGuestModal} />
        <CovidDialog open={covidDialogOpen} onClose={closeCovidDialog} />
      </PageContentWrapper>
    </appContext.Provider>
  )
}

export default withStyles(styles)(withTranslation('shared')(App))
