import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'

const useStyles = makeStyles(theme => ({
  root: {
    width: '8px',
    height: '8px',
    marginLeft: theme.spacing(1),
    background: theme.palette.tertiary.main,
    borderRadius: '4px'
  }
}))

export default () => {
  const classes = useStyles()
  return (<div className={classes.root} />)
}
