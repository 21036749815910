import React, { useContext } from 'react'
import { withTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'
import SimpleDialog from 'shared-components/common/SimpleDialog'
import { appContext } from './App'

const CovidDialog = ({ t, open, onClose }) => {
  const { event } = useContext(appContext)
  const content = (
    <Typography variant='body1'>{event.covidMeasurements}</Typography>
  )
  return (
    <SimpleDialog
      open={open}
      onClose={onClose}
      title={t('covidMeasures')}
      content={content}
    />
  )
}

export default withTranslation('shared')(CovidDialog)
