import apiClient from './../../shared-components/utils/ApiClient'
import {
  CHANGE_EDITING_USER,
  CHECK_USERNAME_AVAILABLE,
  FETCH_ME,
  RECEIVE_ME,
  RECEIVE_MY_STATS,
  UPLOAD_AVATAR_SUCCEEDED,
  UPLOAD_AVATAR,
  USERNAME_AVAILABLE,
  USERNAME_EXISTS,
  RECEIVE_FOLLOWERS,
  SEND_FEEDBACK,
  SENT_FEEDBACK,
  RECEIVE_TICKETFEES,
  START_PAYMENT_AUTH,
  RECEIVE_PAYMENT_AUTH, RECEIVE_USERS,
  RECEIVE_INVITES, SET_CURRENT_ORG, ACCEPT_INVITE, REMOVE_ORG_USER, RECEIVE_TAG, RECEIVE_TAGS, CHANGE_USER
} from './types'
import mixpanel from '../../util/mixpanel'
import { history } from '../../config/store'
import Resizer from 'react-image-file-resizer'
import { closeModal } from '../modals/actions'
import { setSnackbar } from '../notifications/actions'
import _ from 'underscore'
import { RECEIVE_MY_EVENTS } from '../events/types'
import { useIntercom } from 'react-use-intercom'

export const getMe = () => async dispatch => {
  try {
    const user = await apiClient.user.getMe()
    const organisations = await apiClient.organisationAdmin.getOrganisations()

    mixpanel.people.set({ 'Authorised On': _.map(organisations, organisation => organisation.id) })
    mixpanel.identifyUserId(user.id)

    mixpanel.people.set({
      $name: user.name,
      'Approval Status': user.state
    })
    dispatch(checkCurrentOrg(user, organisations))
  } catch (e) {
    if (e.status === 403 && !history.location.pathname.startsWith('/accept')) {
      history.replace('/signIn')
    }
  }
}

export const checkCurrentOrg = (user, organisations, goToHome = false) => dispatch => {
  let myOrg = null
  if (!user.name) {
    history.replace('/newprofile')
  } else if (!organisations.length) {
    history.replace('/createorg')
  } else if (organisations.length === 1) {
    if (organisations[0].state === 'PENDING') {
      history.replace('/selectorg')
    } else {
      myOrg = organisations[0]
      if (goToHome) history.replace('/')
    }
  } else {
    const lastLoggedInId = localStorage.getItem('lastLoggedInId')
    const lastLoggedInOrg = _.find(organisations, org => org.organisation.id === parseInt(lastLoggedInId))
    if (!lastLoggedInOrg || lastLoggedInOrg.state === 'PENDING') {
      history.replace('/selectorg')
    } else {
      myOrg = lastLoggedInOrg
      if (goToHome) history.replace('/')
    }
  }
  dispatch(receiveMe(myOrg, user, organisations))
}

export const setCurrentOrg = organisationId => (dispatch, getState) => {
  dispatch({
    type: RECEIVE_MY_EVENTS,
    events: undefined,
    pastEvents: undefined
  })
  dispatch({
    type: SET_CURRENT_ORG,
    organisationId
  })
  const user = getState().user
  window.Intercom('update', {
    company: {
      name: user.value.organisation.name,
      company_id: user.value.organisation.id,
      website: `${process.env.REACT_APP_WEBSITE}/${user.value.organisation.username}`
    }
  })

  localStorage.setItem('lastLoggedInId', organisationId)
  history.push('/')
}

export const authorizeTag = (tagCode) => async (dispatch, getState) => {
  const orgId = getState().user.value.organisation.id
  const tag = await apiClient.organisationAdmin.authorizeTag(orgId, {
    accessCode: tagCode
  })
  dispatch({
    type: RECEIVE_TAG,
    tag
  })
}

export const getTags = () => async (dispatch, getState) => {
  const orgId = getState().user.value.organisation.id
  const tags = await apiClient.organisationAdmin.event.getTags(orgId)
  dispatch({
    type: RECEIVE_TAGS,
    tags
  })
}

export const getMyFollowers = offset => async (dispatch, getState) => {
  const orgId = getState().user.value.organisation.id
  const followers = await apiClient.organisationAdmin.getMyFollowers(offset, orgId)
  dispatch({
    type: RECEIVE_FOLLOWERS,
    followers
  })
}

export const getUsers = () => async (dispatch, getState) => {
  const orgId = getState().user.value.organisation.id
  const users = await apiClient.organisationAdmin.getUsers(orgId)
  dispatch({
    type: RECEIVE_USERS,
    users
  })
}

export const changeUserRole = (orgUserId, role) => async (dispatch, getState) => {
  const orgId = getState().user.value.organisation.id
  const user = await apiClient.organisationAdmin.changeRole(orgId, orgUserId, role)
  dispatch({
    type: CHANGE_USER,
    user
  })
}

export const acceptInviteNoToken = (orgUserId) => async (dispatch, getState) => {
  const org = await apiClient.organisationAdmin.acceptInviteNoToken(orgUserId)
  dispatch({
    type: ACCEPT_INVITE,
    org
  })
}

export const inviteUsers = users => async (dispatch, getState) => {
  const orgId = getState().user.value.organisation.id
  const newUsers = await apiClient.organisationAdmin.inviteUsers(orgId, users)
  return dispatch({
    type: RECEIVE_INVITES,
    users: newUsers
  })
}

export const deleteSelf = () => async (dispatch, getState) => {
  const orgUserId = getState().user.value.id
  await apiClient.organisationAdmin.rejectInvite(orgUserId)
  dispatch({
    type: REMOVE_ORG_USER,
    orgUserId
  })
  const user = getState().user.user
  const orgs = getState().user.organisations
  dispatch(checkCurrentOrg(user, orgs, true))
}

export const deleteUser = (orgUserId) => async (dispatch, getState) => {
  const orgId = getState().user.value.organisation.id
  await apiClient.organisationAdmin.deleteUser(orgId, orgUserId)
  dispatch(getUsers())
}

export const rejectInvite = (orgUserId) => async (dispatch) => {
  await apiClient.organisationAdmin.rejectInvite(orgUserId)
  dispatch({
    type: REMOVE_ORG_USER,
    orgUserId
  })
  // await dispatch(getMe())
}

export const getTicketFees = () => async (dispatch, getState) => {
  const orgId = getState().user.value.organisation.id
  const ticketFees = await apiClient.organisationAdmin.getTicketFees(orgId)
  dispatch({
    type: RECEIVE_TICKETFEES,
    ticketFees
  })
}

export const sendFeedback = feedback => async (dispatch, getState) => {
  dispatch({
    type: SEND_FEEDBACK
  })
  const orgId = getState().user.value.organisation.id
  await apiClient.organisationAdmin.sendFeedback(feedback, orgId)
  dispatch({
    type: SENT_FEEDBACK
  })
  dispatch(closeModal('feedback'))
  dispatch(setSnackbar('success', 'Thank you for your feedback!'))
}

export const changeEditingUser = (key, value) => ({
  type: CHANGE_EDITING_USER,
  key,
  value
})

export const receiveMe = (org, user, organisations) => async dispatch => {
  dispatch({
    type: RECEIVE_ME,
    org: org,
    user: user,
    organisations: organisations
  })
}

export const patchMe = (patchUser, returnToHome = true) => async (dispatch, getState) => {
  dispatch({
    type: FETCH_ME
  })
  const orgId = getState().user.value.organisation.id
  const user = await apiClient.organisationAdmin.patchMe(patchUser, orgId)
  dispatch({
    type: RECEIVE_ME,
    org: { organisation: user }
  })
  dispatch(getMyStats())
  mixpanel.trackOrgDashOrgProject('Confirm Activation ', { 'Org Description': user.description })
  if (returnToHome) history.push('/')
}

export const patchUser = patchUser => async (dispatch, getState) => {
  dispatch({
    type: FETCH_ME
  })
  const user = await apiClient.user.patchMe(patchUser)
  const organisations = await apiClient.organisationAdmin.getOrganisations()
  dispatch(checkCurrentOrg(user, organisations, true))
}

export const createOrg = (organisation, imgFile) => async dispatch => {
  const org = await apiClient.organisationAdmin.createOrg(organisation)
  await dispatch(uploadAvatar(imgFile, org))
  await dispatch(getMe())
  dispatch(setCurrentOrg(org.id))
  // dispatch({
  //   type: RECEIVE_ME,
  //   org: orgUser,
  //   organisations: { orgUser }
  // })
  history.push('/')
}

export const getMyStats = () => async (dispatch, getState) => {
  const orgId = getState().user.value.organisation.id
  const stats = await apiClient.organisationAdmin.getMyStats(orgId)
  mixpanel.people.set({
    'Number of Hosted Events': stats.eventCount,
    Followers: stats.followerCount,
    Attendees: stats.guestCount
  })
  dispatch({
    type: RECEIVE_MY_STATS,
    stats
  })
}

export const checkUsernameAvailability = username => async dispatch => {
  dispatch({
    type: CHECK_USERNAME_AVAILABLE
  })
  const available = await apiClient.organisationAdmin.usernameAvailable(username)
  dispatch({
    type: available ? USERNAME_AVAILABLE : USERNAME_EXISTS,
    username: username
  })
}

export const authoriseMollie = code => async (dispatch, getState) => {
  dispatch({
    type: START_PAYMENT_AUTH
  })
  const orgId = getState().user.value.organisation.id
  const paymentMethods = await apiClient.organisationAdmin.authoriseMollie(code, orgId)
  console.log(paymentMethods)
  dispatch({
    type: RECEIVE_PAYMENT_AUTH,
    paymentMethods
  })
}

export const getConnectedApps = () => async (dispatch, getState) => {
  const orgId = getState().user.value.organisation.id
  dispatch({
    type: START_PAYMENT_AUTH
  })
  try {
    const paymentMethods = await apiClient.organisationAdmin.getConnectedApps(orgId)
    dispatch({
      type: RECEIVE_PAYMENT_AUTH,
      paymentMethods
    })
  } catch (error) {
    console.log(error)
    dispatch({
      type: RECEIVE_PAYMENT_AUTH,
      paymentMethods: [{
        type: 'MOLLIE',
        status: 'NONE'
      }]
    })
  }
}

const limitFileSize = (file) => new Promise(resolve => {
  Resizer.imageFileResizer(file, 512, 512, 'PNG', 100, 0,
    uri => {
      resolve(uri)
    },
    'blob'
  )
})

export const uploadAvatar = (file, org) => async (dispatch, getState) => {
  const orgId = org ? org.id : getState().user.value.organisation.id
  const uploadUrl = await apiClient.organisationAdmin.getUploadUrl(file.type, orgId)
  mixpanel.trackOrgDash('Add Organisation Avatar')
  // const limitedImg = await limitFileSize(file)
  // const limitedFile = new File([limitedImg], file.name)
  dispatch({
    type: UPLOAD_AVATAR
  })
  // eslint-disable-next-line no-undef
  await fetch(uploadUrl, {
    method: 'PUT',
    header: {
      'Content-Type': 'image/jpeg'
    },
    body: file
  })
  dispatch({
    type: UPLOAD_AVATAR_SUCCEEDED
  })
}

export const uploadUserAvatar = file => async (dispatch) => {
  const uploadUrl = await apiClient.user.getUploadUrl(file.type)
  dispatch({
    type: UPLOAD_AVATAR
  })

  // const limitedImg = await limitFileSize(file)
  // const limitedFile = new File([limitedImg], file.name)
  // eslint-disable-next-line no-undef
  await fetch(uploadUrl, {
    method: 'PUT',
    header: {
      'Content-Type': 'image/jpeg'
    },
    body: file
  })
  dispatch({
    type: UPLOAD_AVATAR_SUCCEEDED
  })
}
