import React from 'react'
import { Typography, withStyles } from '@material-ui/core'
import classNames from 'classnames'
import Messages from './Messages'
import { withTranslation } from 'react-i18next'

const styles = theme => ({
  obscured: {
    opacity: '0.3 !important'
  },
  explanation: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    textAlign: 'center',
    height: 'auto',
    overflow: 'hidden'
  }
})

const MessageBlock = ({ classes, eventCode, messages = [], className, forwardRef, display = true, obscured, t }) => {
  if (!display || messages.length === 0) return null
  return (
    <div ref={forwardRef} className={classNames(className, { [classes.obscured]: obscured })}>
      <div className={classes.explanation}>
        <Typography variant='caption'>
          {t('messagesExplanation')}
        </Typography>
      </div>
      {messages.length > 0 && <Messages messages={messages} eventCode={eventCode} />}
    </div>
  )
}

export default withTranslation('shared')(withStyles(styles)(MessageBlock))
