import React from 'react'
import { withStyles } from '@material-ui/styles'
import { Paper, SvgIcon, Typography } from '@material-ui/core'
import OnboardingItem from './OnboardingItem'
import StarsIcon from '@material-ui/icons/Stars'
import classNames from 'classnames'
import { ReactComponent as MaybeIcon } from '@bash/shared-components/images/status/MAYBE_outline.svg'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { connect, useDispatch, useSelector } from 'react-redux'
import { history } from '../../config/store'
import LinearProgress from '@material-ui/core/LinearProgress'
import Button from '@material-ui/core/Button'
import mixpanel from '../../util/mixpanel'
import adam from '../../images/dummyprofiles/adam.png'
import maggie from '../../images/dummyprofiles/maggie.png'
import christie from '../../images/dummyprofiles/christie.png'
import eric from '../../images/dummyprofiles/eric.png'
import anna from '../../images/dummyprofiles/anna.png'
import { setDraft } from '../../model/events/actions'
import { Trans, useTranslation } from 'react-i18next'
import _ from 'underscore'

const styles = theme => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(5)
  },
  content: {
    display: 'flex',
    padding: theme.spacing(2),
    boxShadow: theme.shadows[2],
    cursor: 'pointer'
  },
  title: {
    fontWeight: '700'
  },
  icon: {
    margin: theme.spacing(2)
  },
  progress: {
    color: theme.palette.yellow.main
  },
  done: {
    color: theme.palette.green.main
  },
  optional: {
    color: theme.palette.primary.main
  },
  whiteBackground: {
    backgroundColor: 'white',
    borderRadius: '50%'
  },
  progressBar: {
    height: 2
  },
  containerPaper: {
    borderRadius: 8,
    overflow: 'hidden'
  },
  requestButton: {
    float: 'right',
    margin: theme.spacing(2)
  },
  marginTopHalf: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(2)
  }
})

const OnboardingContainer = ({ classes, user, profileCompleteCount, onCopyLink, events }) => {
  const { t } = useTranslation('common')
  const draft = useSelector(state => state.events.draft)
  const dispatch = useDispatch()
  const badges = {
    OPTIONAL: <StarsIcon className={classNames(classes.optional, classes.icon, classes.whiteBackground)} />,
    PROGRESS: <SvgIcon component={MaybeIcon} className={classNames(classes.progress, classes.icon)} />,
    DONE: <CheckCircleIcon className={classNames(classes.done, classes.whiteBackground, classes.icon)} />
  }

  const profileExpanded = !user.description
  const firstEventExpanded = !(user.eventCount > 0 || (events && events.length > 0))
  const followersExpanded = user.followerCount < 5

  const onActivateClick = (location) => {
    mixpanel.trackOrgDash('Request Activation', { Location: location })
    history.push('/activation')
  }

  const onThrowClicked = () => {
    mixpanel.trackOrgDash('Start Throw Bash', { Location: 'onboardingButton' })
    if (draft && draft.tags && _.find(draft.tags, tag => tag.id === 1)) {
      dispatch(setDraft({
        name: '',
        startDate: null,
        endDate: null,
        location: '',
        callLink: '',
        description: '',
        covidMeasurements: '',
        responseType: 'INTEREST',
        limitNumberOfGuests: false,
        expectedNumberOfGuests: null,
        guestsCanSeeGuests: true,
        visibleOnProfile: true,
        ticketProducts: [],
        type: 'PUBLIC',
        ticketLink: null,
        ticketAmount: null,
        dateToBeAnnounced: false,
        tags: [],
        guests: [
          {
            status: 'GOING',
            name: 'Adam',
            avatarUrl: adam
          },
          {
            status: 'GOING',
            name: 'Maggie',
            avatarUrl: maggie
          },
          {
            status: 'GOING',
            name: 'Christie',
            avatarUrl: christie
          },
          {
            status: 'MAYBE',
            name: 'Eric',
            avatarUrl: eric
          },
          {
            status: 'CANT',
            name: 'Anna',
            avatarUrl: anna
          }
        ],
        imageUrl: ''
      }))
    }
    history.push('/event')
  }

  const onEditProfileClicked = (location) => {
    mixpanel.trackOrgDash('Land on Edit Profile Page', { Location: location })
    history.push('/profile')
  }

  const progress = () => {
    if (!events) return 0
    let count = 0
    if (user.description) count += 33
    if (user.followerCount >= 5) count += 33
    if (user.eventCount > 0 || events.length > 0) count += 33
    return count === 99 ? 100 : count
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant='h5'>{t('onboarding.title')}</Typography>
      <Typography className={classes.marginTopHalf} variant='subtitle1' color='textSecondary'>{t('onboarding.subtitle')}</Typography>
      <Paper
        className={classes.containerPaper}
        elevation={2}
      >
        {events && <LinearProgress className={classes.progressBar} variant='determinate' color='secondary' value={progress()} />}
        <OnboardingItem
          title={t('onboarding.editProfile.title')}
          text={t('onboarding.editProfile.body')}
          icon={!user.description ? badges.PROGRESS : badges.DONE}
          link={t('onboarding.editProfile.link')}
          onClick={() => onEditProfileClicked('onboardingButton')}
          expanded={profileExpanded}
        />
        {events && (
          <OnboardingItem
            title={t('onboarding.createEvent.title')}
            text={t('onboarding.createEvent.body')}
            icon={(user.eventCount > 0 || events.length > 0) ? badges.DONE : badges.OPTIONAL}
            link={t('onboarding.createEvent.link')}
            onClick={onThrowClicked}
            expanded={!profileExpanded && firstEventExpanded}
          />
        )}
        <OnboardingItem
          title={(user.followerCount >= 5 || user.followerCount === undefined) ? t('onboarding.followers.title') : <Trans i18nKey='onboarding.followers.title2' t={t} followerCount={user.followerCount}>Get your first 5 followers ({{ followerCount: user.followerCount }}/5)</Trans>}
          text={t('onboarding.followers.body')}
          icon={user.followerCount >= 5 ? badges.DONE : badges.PROGRESS}
          link={t('onboarding.followers.link')}
          onClick={onCopyLink}
          expanded={false}
        />
      </Paper>
    </div>
  )
}
const mapStateToProps = ({ user, events }) => ({
  user: user.value.organisation,
  events: events.value
})

export default connect(mapStateToProps)(withStyles(styles)(OnboardingContainer))
