import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import logo from '../media/logo/icon.svg'
import { CircularProgress, Typography } from '@material-ui/core'
import classNames from 'classnames'
import { withTranslation } from 'react-i18next'

const styles = theme => ({
  loaderRoot: {
    display: 'flex',
    height: 'calc(100vh - 56px)',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  coverAll: {
    position: 'absolute',
    top: 0,
    background: theme.palette.background.default,
    zIndex: 9999,
    height: '100vh'
  },
  img: {
    marginBottom: theme.spacing(4)
  }
})

function LoadingScreen ({ classes, t, isRejected, coverAll, isOrg }) {
  return (
    <div className={classNames(classes.loaderRoot, { [classes.coverAll]: coverAll })}>
      <img src={logo} className={classes.img} alt='BASH' height={48} />
      {!isRejected ?
        <CircularProgress color='primary' /> :
        <Typography variant='subtitle2'>{isOrg ? t('orgNotFound') : t('eventNotFound')}</Typography>}
    </div>
  )
}

export default withTranslation('shared')(withStyles(styles)(LoadingScreen))
