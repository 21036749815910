import { withStyles } from '@material-ui/core'
import React, { useState } from 'react'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'
import TimelineItem from './TimelineItem'
import ReachedDialog from '../dialog/ReachedDialog'
import { getReachedAndMissed } from '../../model/events/actions'
import { connect } from 'react-redux'
import mixpanel from '../../util/mixpanel'
import { useTranslation } from 'react-i18next'

const styles = theme => ({
  root: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(7)
  }
})

const sortTimelineItem = () => (a, b) => {
  return moment(a.createdAt).diff(moment(b.createdAt))
}

const mergeTimelineItems = (eventUpdates, reminders, event) => {
  const mappedReminders = []
  // Map reminders to updateEvent format
  if (reminders) {
    reminders.map((reminder, index) => {
      const mappedReminder = {
        type: 'REMINDER',
        notified: false,
        createdAt: moment(event.startDate).subtract(reminder.timeDifference, 'ms').format()
      }
      mappedReminders.push(mappedReminder)
    })
  }

  // Sort updates
  const merged = eventUpdates ? eventUpdates.concat(mappedReminders).sort(sortTimelineItem()) : mappedReminders.sort(sortTimelineItem())
  // Add reminderId to determine the order of reminders (helps with determining if it's the first, second etc.. or final reminder)
  let reminderCount = 0
  for (let i = 0; i < merged.length; i++) {
    if (merged[i].type === 'REMINDER') {
      if (i === merged.length - 1) {
        merged[i].reminderId = 'FINAL'
      } else {
        merged[i].reminderId = reminderCount
        reminderCount++
      }
    }
  }
  return merged
}

const Timeline = ({ classes, event, getReachedAndMissed }) => {
  const { t } = useTranslation('common')
  const [reachedAndMissedId, setReachedAndMissedId] = useState(undefined)
  const [reachedOpen, setReachedOpen] = useState(false)

  const publishedUpdate = event && event.eventUpdates && event.eventUpdates.find(update => {
    return update.type === 'PUBLISHED'
  })

  const onTimelineClick = (eventUpdateId) => {
    mixpanel.trackOrgEvent('Check Reached Guests', event)
    setReachedAndMissedId(eventUpdateId)
    setReachedOpen(true)
    getReachedAndMissed(event.id, eventUpdateId)
  }

  const showCreatedTimeline = publishedUpdate ? event && moment(publishedUpdate.createdAt).diff(moment(event.createdAt), 'minutes') > 60 : true

  return (
    <div className={classes.root}>
      <Typography variant='subtitle1'>{t('event.view.timeline.title')}</Typography>
      {showCreatedTimeline && <TimelineItem type='CREATED_EVENT' notified top createdAt={event.createdAt} />}

      {mergeTimelineItems(event.eventUpdates, event.reminders, event).map((update, index) => {
        return (
          <TimelineItem key={index} onClick={() => onTimelineClick(update.id)} eventStartDate={event.startDate} type={update.type} reminderId={update.reminderId} reachedCount={update.reachedCount} notified={update.notified} missedCount={update.missedCount} top={showCreatedTimeline ? false : index === 0} createdAt={update.createdAt} />
        )
      })}

      <TimelineItem type='EVENT_START' notified createdAt={event.startDate} upcoming />
      <ReachedDialog reachedAndMissedId={reachedAndMissedId} open={reachedOpen} reachedAndMissed={event.reachedAndMissed} onClose={() => setReachedOpen(false)} eventCode={event.code} />
    </div>)
}

export default connect(null, { getReachedAndMissed })(withStyles(styles)(Timeline))
