import { withStyles } from '@material-ui/core'
import InputBase from '@material-ui/core/InputBase/InputBase'
import InputLabel from '@material-ui/core/InputLabel/InputLabel'
import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import classNames from 'classnames'

const styles = theme => ({
  inputLabel: {
    color: 'black',
    marginBottom: theme.spacing(1),
    fontSize: '0.875rem',
    fontWeight: '500',
    marginTop: theme.spacing(2)
  },
  fullWidth: {
    width: '100%'
  }
})

const TextInputBase = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey.main,
    border: 'none',
    borderRadius: 8,
    width: '100%',
    padding: theme.spacing(2),
    fontSize: '1rem',
    '& input': {
      padding: 0
    }
  }
}))(InputBase)

const InputHelperText = withStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    display: 'none'
  },
  error: {
    display: 'block'
  }
}))(FormHelperText)

const TextInput = ({ label, doubleHeight, customClass, error, errorText, classes, ...other }) => {
  return (
    <FormControl error={error} className={classNames(classes.fullWidth, customClass)}>
      {label && <InputLabel className={classes.inputLabel}>{label}</InputLabel>}
      <TextInputBase {...other} inputProps={{ ...other.inputProps }} />
      <InputHelperText>{errorText}</InputHelperText>
    </FormControl>
  )
}

export default (withStyles(styles)(TextInput))
