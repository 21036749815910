import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import { Typography } from '@material-ui/core'
import { HelpOutline } from '@material-ui/icons'
import Tooltip from '@material-ui/core/Tooltip'

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.grey.dark,
    margin: theme.spacing(2, -3, 1, -3),
    padding: theme.spacing(2, 3, 2, 3),
    position: 'relative'
  },
  title: {
    marginTop: theme.spacing(0.5)
  },
  caption: {
    color: theme.palette.tertiary.main
  },
  helpIcon: {
    position: 'absolute',
    right: theme.spacing(3),
    top: theme.spacing(3),
    opacity: 0.6
  }
}))

export default () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Typography className={classes.caption} variant='overline'>JOINING EVENT</Typography>
      <Typography className={classes.title} variant='body1'>Freedom Day</Typography>
      {/* <Tooltip title='Freedom Day'> */}
      {/*  <HelpOutline className={classes.helpIcon} /> */}
      {/* </Tooltip> */}
    </div>)
}
