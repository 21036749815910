export const countStatus = guests => {
  var counts = {
    GOING: 0,
    MAYBE: 0,
    CANT: 0,
    NONE: 0
  }
  if (guests) {
    guests.forEach(guest => counts[guest.status]++)
  }
  return counts
}
