import typeToReducer from 'type-to-reducer'
import { OPEN_MODAL, CLOSE_MODAL, SET_MODAL_INFO } from './types'

const initialState = {
  open: {
    feedback: false,
    invite: false,
    acceptInvite: false
  },
  info: {
    acceptInvite: {
      orgId: undefined
    }
  }
}

export default typeToReducer(
  {
    [OPEN_MODAL]: (state, action) => ({
      ...state,
      open: {
        ...state.open,
        [action.payload]: true
      }
    }),
    [CLOSE_MODAL]: (state, action) => ({
      ...state,
      open: {
        ...state.open,
        [action.payload]: false
      }
    }),
    [SET_MODAL_INFO]: (state, { modal, info }) => ({
      ...state,
      info: {
        [modal]: {
          ...state.info[modal],
          ...info
        }
      }
    })
  },
  initialState
)
