import { ADD_BREADCRUMB, REPLACE_BREADCRUMBS } from './types'

export const addBreadcrumb = (text, link) => ({
  type: ADD_BREADCRUMB,
  text,
  link
})

export const replaceBreadcrumbs = (breadcrumbs) => ({
  type: REPLACE_BREADCRUMBS,
  breadcrumbs
})
