import React from 'react'
import { withStyles } from '@material-ui/styles'
import classNames from 'classnames'

const styles = theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexGrow: 1,
    marginRight: 'auto',
    marginLeft: 'auto',
    maxWidth: theme.breakpoints.values.visual,
    flexDirection: 'column',
    transition: `max-width 0.3s ${theme.transitions.easing.easeInOut}`
  },
  small: {
    maxWidth: theme.breakpoints.values.container
  },
  fullHeight: {
    height: '100vh'
  },
  fullHeightWithHeader: {
    height: 'calc(100vh - 56px)'
  },
  padding: {
    padding: theme.spacing(2)
  }
})

const PageContentWrapper = ({ classes, className, children, small, fullHeight, fullHeightWithHeader, padding, ...other }) => {
  return (
    <div
      className={
        classNames(
          classes.root, {
            [classes.small]: small,
            [classes.fullHeight]: fullHeight,
            [classes.fullHeightWithHeader]: fullHeightWithHeader,
            [classes.padding]: padding
          },
          className)
      }
      id='page-content-wrapper'
      is-small={small && 'true'}
      {...other}
    >
      {children}
    </div>
  )
}

export default withStyles(styles)(PageContentWrapper)
