export const RECEIVE_ME = 'RECEIVE_ME'
export const RECEIVE_MY_STATS = 'RECEIVE_MY_STATS'
export const FETCH_ME = 'FETCH_ME'
export const CHECK_USERNAME_AVAILABLE = 'CHECK_USERNAME_AVAILABLE'
export const USERNAME_EXISTS = 'CHECK_USERNAME_EXISTS'
export const USERNAME_AVAILABLE = 'USERNAME_AVAILABLE'
export const UPLOAD_AVATAR_SUCCEEDED = 'UPLOAD_AVATAR_SUCCEEDED'
export const CHANGE_EDITING_USER = 'CHANGE_EDITING_USER'
export const UPLOAD_AVATAR = 'UPLOAD_AVATAR'
export const RECEIVE_FOLLOWERS = 'RECEIVE_FOLLOWERS'
export const SEND_FEEDBACK = 'SEND_FEEDBACK'
export const SENT_FEEDBACK = 'SENT_FEEDBACK'
export const RECEIVE_TICKETFEES = 'RECEIVE_TICKETFEES'
export const START_PAYMENT_AUTH = 'START_PAYMENT_AUTH'
export const RECEIVE_PAYMENT_AUTH = 'RECEIVE_PAYMENT_AUTH'
export const RECEIVE_USERS = 'RECEIVE_USERS'
export const RECEIVE_INVITES = 'RECEIVE_INVITES'
export const SET_CURRENT_ORG = 'SET_CURRENT_ORG'
export const ACCEPT_INVITE = 'ACCEPT_INVITE'
export const REMOVE_ORG_USER = 'REMOVE_ORG_USER'
export const RECEIVE_TAG = 'RECEIVE_TAG'
export const RECEIVE_TAGS = 'RECEIVE_TAGS'
export const CHANGE_USER = 'CHANGE_USER'
