import { withStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import TextInput from '../../shared-components/common/TextInput'
import Typography from '@material-ui/core/Typography'
import FlatButton from '@bash/shared-components/buttons/FlatButton'
import EuroIcon from '@material-ui/icons/EuroSymbol'
import HelpIcon from '@material-ui/icons/HelpOutline'
import { history } from '../../config/store'
import { useDispatch, useSelector } from 'react-redux'
import { getConnectedApps } from '../../model/user/actions'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tooltip from '@material-ui/core/Tooltip'
import StateBadge from '../StateBadge'
import { getMollieMethod } from '../../selectors/payments'
import { useTranslation } from 'react-i18next'

const styles = theme => ({
  root: {

  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3)
  },
  backButton: {
    backgroundColor: theme.palette.grey.main,
    color: 'black',
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    },
    marginRight: theme.spacing(2)
  },
  subItem: {
    display: 'flex',
    marginTop: theme.spacing(2),
    alignItems: 'center'
  },
  helpIcon: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2),
    flexShrink: 0
  },
  subItemPrice: {
    marginLeft: 'auto',
    flexShrink: 0
  },
  priceDivider: {
    height: '2px',
    width: '57px',
    marginLeft: 'auto',
    background: 'black',
    marginTop: theme.spacing(2)
  },
  costSwitch: {
    marginTop: theme.spacing(2)
  },
  disabledSubText: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: 'center'
  },
  disabledTitle: {
    marginTop: theme.spacing(5),
    textAlign: 'center'
  },
  disabledLink: {
    textAlign: 'center',
    cursor: 'pointer',
    marginBottom: theme.spacing(5)
  },
  marginTopHalf: {
    marginTop: theme.spacing(0.5)
  },
  loadingProgress: {
    display: 'flex',
    marginTop: theme.spacing(3),
    justifyContent: 'center'
  },
  deleteButton: {
    marginRight: 'auto'
  },
  titleContainer: {
    display: 'flex',
    marginBottom: theme.spacing(2)
  },
  defaultBadge: {
    marginLeft: 'auto',
    alignSelf: 'flex-start'
  },
  nameInput: {
    marginBottom: theme.spacing(2)
  }
})

const PriceScreen = ({ classes, title, subtitle, currentValue, nameEditEnabled, onSave, onBack, onDelete }) => {
  const { t } = useTranslation('common')
  const [value, setValue] = useState(currentValue)
  const mollieMethod = useSelector(getMollieMethod)
  const dispatch = useDispatch()

  const enabled = mollieMethod && mollieMethod.status === 'CONNECTED'
  const serviceFixed = enabled && parseFloat(mollieMethod.serviceFee.fixed)
  const serviceVariable = enabled && parseFloat(mollieMethod.serviceFee.variable)
  const transFixed = enabled && parseFloat(mollieMethod.transactionFee[0].fee.fixed)
  const transVariable = enabled && parseFloat(mollieMethod.transactionFee[0].fee.variable)

  // const ticketFees = useSelector((state) => state.user.ticketFees)
  // const ticketFees = 1
  // const serviceFixed = ticketFees && parseFloat(ticketFees.serviceFee.fixed)
  // const serviceVariable = ticketFees && parseFloat(ticketFees.serviceFee.variable)
  // const transFixed = ticketFees && parseFloat(ticketFees.transactionFee[0].fee.fixed)
  // const transVariable = ticketFees && parseFloat(ticketFees.transactionFee[0].fee.variable)
  // const serviceFixed = 0.20
  // const serviceVariable = 0.01
  // const transFixed = 0.25
  // const transVariable = 0.02
  const parsedValue = value ? parseFloat(value.price) : 0

  // const amount = (parsedValue - serviceFixed - transFixed) /
  //     (1 + serviceVariable + transVariable)

  let amount
  let bashFee
  let transFee
  if (parsedValue) {
    amount = ((parsedValue - serviceFixed - transFixed) /
      (1 + transVariable)) - serviceVariable * parsedValue
    // const bashFee = (serviceFixed + serviceVariable * amount)
    bashFee = (serviceFixed + serviceVariable * parsedValue)
    transFee = (transFixed + transVariable * amount)
  } else {
    amount = 0
    bashFee = 0
    transFee = 0
  }

  useEffect(() => {
    !mollieMethod && dispatch(getConnectedApps())
  }, [dispatch, mollieMethod])

  const onInputBlur = () => {
    if (!value) {
      setValue(state => ({
        ...state,
        price: 0.00
      }
      ))
      return
    }
    const parsedValue = parseFloat(value.price)
    if (parsedValue < 0) {
      setValue(state => ({
        ...state,
        price: 0.00
      }))
    } else {
      setValue(state => ({
        ...state,
        price: parsedValue.toFixed(2)
      }))
    }
  }

  const onPriceChange = (event) => {
    const val = event.target.value
    setValue(prevState => ({
      ...prevState,
      price: val
    }))
  }

  const onNameChange = (event) => {
    const val = event.target.value
    setValue(prevState => ({
      ...prevState,
      name: val
    }))
  }

  const notEnabled = (
    <div>
      <Typography className={classes.disabledTitle} variant='subtitle2'>{t('event.edit.tickets.notEnabled.title')}</Typography>
      <Typography className={classes.disabledSubText} variant='body2'>{t('event.edit.tickets.notEnabled.body')}</Typography>
      <Typography className={classes.disabledLink} variantMapping={{ button: 'p' }} onClick={() => history.push('/settings/payments/mollie')} color='primary' variant='button'>{t('event.edit.tickets.notEnabled.button')}</Typography>
    </div>
  )

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <div>
          <Typography variant='subtitle1'>{t('event.edit.tickets.title')}</Typography>
          <Typography className={classes.marginTopHalf} variant='caption'>{t('event.edit.tickets.subtitle')}</Typography>
        </div>
        {value.weight === 0 && nameEditEnabled && (
          <Tooltip title={t('event.edit.tickets.defaultTooltip')}>
            <StateBadge className={classes.defaultBadge} text='DEFAULT' color='primary' />
          </Tooltip>
        )}
      </div>
      {!enabled && mollieMethod && notEnabled}
      {!mollieMethod && <div className={classes.loadingProgress}><CircularProgress /></div>}
      {enabled && mollieMethod && (
        <>
          {(nameEditEnabled || value.weight !== 0) && <TextInput className={classes.nameInput} label={t('event.edit.tickets.name')} onKeyUp={(e) => { if (e.key === 'Enter') { onSave(value); onBack() } }} value={value.name} onBlur={onInputBlur} onChange={onNameChange} />}
          <TextInput type='number' inputProps={{ style: { paddingLeft: '8px' }, min: 0 }} startAdornment={<EuroIcon color='primary' />} autoFocus onKeyUp={(e) => { if (e.key === 'Enter') { onSave(value); onBack() } }} value={value.price} onBlur={onInputBlur} onChange={onPriceChange} />
          <div className={classes.subItem}>
            <Typography color='textSecondary' variant='body2'>{t('event.edit.tickets.transFee')}</Typography>
            <HelpIcon className={classes.helpIcon} />
            <Typography className={classes.subItemPrice} color='textSecondary' variant='body2'>€{parsedValue === 0 ? '0,00' : transFee.toFixed(2).replace('.', ',')}</Typography>
          </div>
          <div className={classes.subItem}>
            <Typography color='textSecondary' variant='body2'>{t('event.edit.tickets.bashFee')}</Typography>
            <Tooltip title={`€${serviceFixed.toFixed(2)} + ${serviceVariable * 100}%`}>
              <HelpIcon className={classes.helpIcon} />
            </Tooltip>
            <Typography className={classes.subItemPrice} color='textSecondary' variant='body2'>€{parsedValue === 0 ? '0,00' : bashFee.toFixed(2).replace('.', ',')}</Typography>
          </div>
          <div className={classes.priceDivider} />
          <div className={classes.subItem}>
            <Typography variant='subtitle1'>{t('event.edit.tickets.youReceive')}</Typography>
            <Typography className={classes.subItemPrice} variant='h5'>€{parsedValue === 0 ? '0,00' : parseFloat(amount).toFixed(2).replace('.', ',')}</Typography>
          </div>
        </>
      )}
      <div className={classes.buttons}>
        <FlatButton disabled={(value.weight === 0 && nameEditEnabled) || value.id} color='tertiary' className={classes.deleteButton} onClick={() => onDelete(value)}>{t('event.edit.tickets.delete')}</FlatButton>
        <FlatButton className={classes.backButton} onClick={() => { setValue(currentValue); onBack() }}>{t('cancel')}</FlatButton>
        <Tooltip title={(parsedValue < 0.5 && parsedValue !== 0 ? 'Price can not be below €0,50' : '') || (value.weight === 0 && parsedValue === 0 ? 'The default price can not be 0' : '')}>
          <span>
            <FlatButton disabled={(parsedValue < 0.5 && parsedValue !== 0) || (value.weight === 0 && !parsedValue)} onClick={() => { onSave(value) }}>{t('save')}</FlatButton>
          </span>
        </Tooltip>
      </div>
    </div>)
}

export default (withStyles(styles)(PriceScreen))
