import React from 'react'

import classNames from 'classnames'

import { Typography, withStyles } from '@material-ui/core'

const styles = theme => ({
  root: {
    boxShadow: '0 10px 8px 1px rgba(0, 0, 0, 0.04), 0 3px 14px 2px rgba(0, 0, 0, 0.04), 0 5px 5px -3px rgba(0, 0, 0, 0.06)',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderRadius: 50,
    height: 32,
    alignItems: 'center',
    fontSize: 12,
    justifyContent: 'center'
  },
  count: {
    color: theme.palette.primary.lighter,
    marginLeft: 4,
    fontWeight: 700
  },
  emoji: {
  },
  emojiWithCount: {
  }
})

const EmojiBadge = ({ classes, emoji, count }) => {
  return (
    <div className={classes.root}>
      <span
        className={classNames(classes.emoji, { [classes.emojiWithCount]: count })}
        role='img'
      >
        {emoji}
      </span>
      {count && <Typography className={classes.count} variant='caption'>{count}</Typography>}
    </div>
  )
}

EmojiBadge.propTypes = {

}

export default withStyles(styles)(EmojiBadge)
