import React from 'react'
import { withStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'

const styles = theme => ({
  root: {
    width: 'auto',
    height: '32px',
    borderRadius: '8px',
    padding: theme.spacing(1),
    // marginBottom: theme.spacing(1),
    margin: theme.spacing(0, 2, 0, 2),
    cursor: 'pointer',
    marginBottom: theme.spacing(1)
  },
  active: {
    backgroundColor: '#F0F0F0'
  },
  text: {
    fontWeight: '600'
  }
})

const SideNavItem = ({ classes, name, className, onClick, active, ...other }) => {
  return (
    <div
      className={classNames([
        classes.root,
        active && classes.active,
        className
      ])} onClick={onClick}
    >
      <Typography {...other} className={classes.text} variant='button'>{name}</Typography>
    </div>
  )
}

export default withStyles(styles)(SideNavItem)
