import { withStyles } from '@material-ui/core'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import CheckIcon from '@material-ui/icons/Check'
import { Block } from '@material-ui/icons'
import classNames from 'classnames'

const styles = theme => ({
  root: {
    background: theme.palette.grey.main,
    borderRadius: 8,
    width: '100%',
    marginTop: theme.spacing(2),
    '&:before': {
      display: 'none'
    },
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.5)
  },
  cursorDefault: {
    cursor: 'default'
  },
  summaryContent: {
    alignItems: 'center'
  },
  summaryExpanded: {
    minHeight: 'unset !important',
    marginTop: '0 !important',
    marginBottom: '0 !important',
    color: theme.palette.primary.main,
    transform: 'none !important'
  },
  icon: {
    marginRight: theme.spacing(2)
  },
  check: {
    marginLeft: 'auto'
  },
  block: {
    marginLeft: 'auto',
    opacity: 0.6
  }
})

const EventField = React.forwardRef(({ classes, checked, name, onClick, description, icon, restricted, ...other }, ref) => {
  // const [isExpanded, setIsExpanded] = useState(false)

  // const toggleExpanded = event => {
  //   event.stopPropagation()
  //   setIsExpanded(!isExpanded)
  // }

  return (
    // <Accordion onClick={onClick} expanded={isExpanded} elevation={0} className={classes.root}>
    //   <AccordionSummary
    //     expandIcon={description && <HelpOutline onClick={toggleExpanded} />}
    //     aria-controls="panel1a-content"
    //     id="panel1a-header"
    //     classes={{
    //       content: classes.summaryContent,
    //       expanded: classes.summaryExpanded,
    //     }}
    //   >
    //     {React.cloneElement(icon, { className: classes.icon })}
    //     <Typography variant='subtitle1'>{name}</Typography>
    //   </AccordionSummary>
    //   {description && <AccordionDetails>
    //     <Typography variant='body2' color='textSecondary'>
    //       {description}
    //     </Typography>
    //   </AccordionDetails>}
    // </Accordion>
    <div {...other} ref={ref} onClick={restricted ? undefined : onClick} className={classNames(classes.root, restricted && classes.cursorDefault)}>
      {React.cloneElement(icon, { className: classes.icon })}
      <Typography variant='button'>{name}</Typography>
      {(checked && !restricted) ? <CheckIcon className={classes.check} color='secondary' /> : null}
      {restricted ? <Block className={classes.block} /> : null}
    </div>
  )
})

export default (withStyles(styles)(EventField))
