import React, { useState, useContext } from 'react'
import { mdiCalendarPlus } from '@mdi/js'

import AddToCalendarHOC from 'react-add-to-calendar-hoc'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
import mixpanel from 'shared-components/utils/mixpanel'
import classNames from 'classnames'

import { Fab, Menu, MenuItem, SvgIcon, withStyles, withWidth } from '@material-ui/core'
import { rbContext } from './index'
import { appContext } from '../App'

const styles = theme => ({
  root: {
    float: 'left',
    position: 'relative',
    '&:not(:last-child)': {
      marginRight: theme.spacing(2)
    }
  },
  button: {
    backgroundColor: theme.palette.grey.main,
    color: theme.palette.text.secondary,
    textTransform: 'none'
  },
  fabIcon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1)
  },
  fab: {
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#CEDDE1 !important'
    }
  },
  fabRoot: {
    boxShadow: theme.shadows[0],
    '&:active': {
      boxShadow: theme.shadows[0]
    }
  }
})

const TextButton = ({ classes, onClick, t }) => (
  <Fab
    variant='extended'
    color='default'
    classes={{
      root: classes.fabRoot
    }}
    className={classes.button}
    size='large'
    onClick={onClick}
  >
    <SvgIcon className={classes.fabIcon}>
      <path d={mdiCalendarPlus} />
    </SvgIcon>
    {t('addToCalendar')}
  </Fab>
)

const Button = ({ width, ...other }) => (<TextButton {...other} />)

const wrappedOnClick = (onClick, calendar, activeResponse, closeMenu) => (e) => {
  mixpanel.trackEvent('Add to Calendar', { 'Calender Type': calendar, 'RSVP Status': activeResponse })
  onClick(e)
  closeMenu(e)
}

const Dropdown = ({ children, isOpen, onRequestClose, menuAnchorEl, classes }) => {
  const { activeResponse } = useContext(rbContext)
  return (
    <Menu
      style={{ zIndex: 1600 }}
      id='simple-menu'
      className={classes.menuRoot}
      anchorEl={isOpen ? menuAnchorEl : null}
      open={isOpen}
      onClose={onRequestClose}
    >
      {children.map((child, i) => (
        <MenuItem key={i} href={child.props.href} onClick={wrappedOnClick(child.props.onClick, child.props.children, activeResponse, onRequestClose)}>{child.props.children}</MenuItem>
      ))}
    </Menu>
  )
}

const AddToCalendarDropdown = AddToCalendarHOC(withWidth()(withStyles(styles)(withTranslation('shared')(Button))), withStyles(styles)(Dropdown))

const transformEvent = (event) => {
  const startDatetime = moment(event.startDate).utc()
  const endDatetime = event.endDate === '' ? startDatetime.clone().add(2, 'hours') : moment(event.endDate).utc()
  const duration = endDatetime.diff(startDatetime, 'hours') + ''
  const eventDescription = (process.env ? `${process.env.REACT_APP_RSVP}/${event.code}\n\n` : '') +
    (event.description ? event.description : '')
  return {
    description: eventDescription,
    duration,
    startDatetime: startDatetime.format('YYYYMMDDTHHmmssZ'),
    endDatetime: endDatetime.format('YYYYMMDDTHHmmssZ'),
    location: event.location,
    title: event.name
  }
}

const AddToCalendarButtonBlue = ({ classes, className }) => {
  const { event } = useContext(appContext)
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)

  const setAnchorEl = (event) => {
    setMenuAnchorEl(event.currentTarget)
  }

  return (
    <div className={classNames(className, classes.root)} onClick={setAnchorEl}>
      <AddToCalendarDropdown
        dropdownProps={{ menuAnchorEl }}
        event={transformEvent(event)}
      />
    </div>
  )
}

export default withStyles(styles)(AddToCalendarButtonBlue)
