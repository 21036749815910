import React from 'react'
import Linkify from 'react-linkify'

const MixpanelLinkWrapper = (href, text, key) => {
  return (
    <a target='_blank' rel='noopener noreferrer' href={href} key={key}>
      {text}
    </a>
  )
}

const MixpanelLinkify = ({ children }) => (
  <Linkify componentDecorator={MixpanelLinkWrapper}>
    {children}
  </Linkify>
)

export default MixpanelLinkify
