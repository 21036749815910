import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import { Typography } from '@material-ui/core'
import { history } from '../config/store'
import { useDispatch } from 'react-redux'
import { setDraft } from '../model/events/actions'
import adam from '../images/dummyprofiles/adam.png'
import maggie from '../images/dummyprofiles/maggie.png'
import christie from '../images/dummyprofiles/christie.png'
import eric from '../images/dummyprofiles/eric.png'
import anna from '../images/dummyprofiles/anna.png'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(5),
    background: theme.palette.grey.dark,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2)
  },
  marginTop1: {
    marginTop: theme.spacing(1)
  },
  marginTop2: {
    marginTop: theme.spacing(2)
  }
}))

export default () => {
  const { t } = useTranslation('common')
  const classes = useStyles()
  const dispatch = useDispatch()

  const createGigEvent = () => {
    dispatch(setDraft({
      name: 'Freedom Day',
      startDate: null,
      endDate: null,
      location: '',
      callLink: '',
      description: 'Het eerste weekend waarop grootschalige evenementen weer zijn toegestaan zullen we met heel Nederland Freedom Day vieren – op straat, op feesten, maar vooral in de Nederlandse horeca!',
      covidMeasurements: '',
      responseType: 'INTEREST',
      limitNumberOfGuests: false,
      expectedNumberOfGuests: null,
      guestsCanSeeGuests: true,
      visibleOnProfile: true,
      themeImageKey: 'suggestedEvents/freedomday.jpeg',
      ticketProducts: [
        {
          localId: 0,
          price: '16.99',
          currency: 'EUR',
          name: 'Shirt',
          weight: 0
        },
        {
          localId: 1,
          price: '17.99',
          currency: 'EUR',
          name: 'Longsleeve',
          weight: 1
        },
        {
          localId: 2,
          price: '27.99',
          currency: 'EUR',
          name: 'Trui',
          weight: 1
        }
      ],
      type: 'PUBLIC',
      dateToBeAnnounced: true,
      isGigEvent: true,
      tags: [
        {
          id: 1,
          name: 'FreedomDay',
          protected: true,
          url: 'https://freedom-day.nl'
        }
      ],
      guests: [
        {
          status: 'GOING',
          name: 'Adam',
          avatarUrl: adam
        },
        {
          status: 'GOING',
          name: 'Maggie',
          avatarUrl: maggie
        },
        {
          status: 'GOING',
          name: 'Christie',
          avatarUrl: christie
        },
        {
          status: 'MAYBE',
          name: 'Eric',
          avatarUrl: eric
        },
        {
          status: 'CANT',
          name: 'Anna',
          avatarUrl: anna
        }
      ],
      imageUrl: '/events/themeImages/suggestedEvents/freedomday.jpeg'
    }))
    history.push('/event')
  }

  return (
    <div className={classes.root}>
      <Typography variant='subtitle1'>{t('freedomGigCard.title')}</Typography>
      <Typography className={classes.marginTop1} variant='body1'>{t('freedomGigCard.body')}</Typography>
      <Typography style={{ cursor: 'pointer' }} onClick={createGigEvent} variant='button' variantMapping={{ button: 'p' }} color='primary' className={classes.marginTop2}>{t('freedomGigCard.create')}</Typography>
    </div>
  )
}
