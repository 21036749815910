import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { Avatar, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentOrg } from '../../model/user/actions'
import AddIcon from '@material-ui/icons/Add'
import { history } from '../../config/store'
import { openModal, setModalInfo } from '../../model/modals/actions'
import classNames from 'classnames'
import RedDot from '../RedDot'

const useStyles = makeStyles(theme => ({
  avatar: {
    width: '24px',
    height: '24px',
    marginRight: theme.spacing(2),
    border: '0.5px solid',
    borderColor: theme.palette.grey.dark
  },
  plusIcon: {
    width: '24px',
    height: '24px',
    opacity: 0.6,
    marginRight: theme.spacing(2)
  },
  halfOpacity: {
    opacity: 0.5
  }
}))

const OrgSwitchItem = React.forwardRef(({ org, onClose, isCreate }, ref) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const orgUser = useSelector(state => state.user.value.organisation)
  const active = orgUser && orgUser.id === org.organisation.id
  const pending = org.state === 'PENDING'

  const onClick = () => {
    dispatch(setCurrentOrg(org.organisation.id))
    onClose()
  }

  const onPendingClicked = () => {
    dispatch(setModalInfo('acceptInvite', { orgId: org.id }))
    dispatch(openModal('acceptInvite'))
    onClose()
  }

  const onCreate = () => {
    history.push('/createorg')
    onClose()
  }

  return (
    <MenuItem onClick={isCreate ? onCreate : (pending ? onPendingClicked : onClick)}>
      {!isCreate && <Avatar className={classNames(classes.avatar, pending && classes.halfOpacity)} src={`${org.organisation.avatarUrl}`} />}
      {isCreate && <AddIcon className={classes.plusIcon} />}
      <Typography color={isCreate || pending ? 'textSecondary' : 'textPrimary'} variant='body2'>{active ? <b>{org.organisation.name}</b> : org.organisation.name}</Typography>
      {pending && <RedDot />}
    </MenuItem>
  )
})

export default OrgSwitchItem
