import RestClient from './RestClient'

class ApiClient extends RestClient {
  constructor (baseUrl) {
    super(baseUrl, {})
  }

  event = {
    byCode: (eventCode, inviterCode) => {
      return this.GET('/code/events', { eventCode, inviterCode })
    },
    getMine: () => this.GET('/events'),
    getMinePast: () => this.GET('/events/past'),
    get: eventId => this.POST(`/events/${eventId}`),
    getFollowing: () => this.GET('/events/following'),
    findByQuery: (query) => this.GET('/events/find', query),
    getTags: () => this.GET('/events/tags'),
    getByTagName: (name, page, size) => this.GET('/events/tags/byName', { name, page, size })
  }

  rsvp = {
    user: {
      normal: (eventId, status, eventCode) => this.POST(`/events/${eventId}/rsvp`, { status }, { eventCode }),
      dateOptions: (eventId, dateOptions, eventCode) => this.POST(`/events/${eventId}/rsvpDateOptions`, { dateOptions }, { eventCode }),
      ticket: (eventId, ticketProductId, eventCode) => this.POST(`/events/${eventId}/rsvpTicket`, { ticketProductId }, { eventCode })
    },
    guest: {
      normal: (eventId, guestObj, eventCode, guestId, inviterCode) => this.POST(`/code/events/${eventId}/rsvp`, guestObj, { eventCode, guestId, inviterCode }),
      dateOptions: (eventId, guestObj, eventCode, guestId, inviterCode) => this.POST(`/code/events/${eventId}/rsvpDateOptions`, guestObj, { eventCode, guestId, inviterCode }),
      ticket: (eventId, guestObj, eventCode) => this.POST(`/code/events/${eventId}/rsvpTicket`, guestObj, { eventCode })
    }
  }

  ticket = {
    checkStatus: (eventId, ticketOrderId, eventCode) => this.GET(`/code/events/${eventId}/tickets/orders/${ticketOrderId}`, { eventCode }),
    cancel: (eventId, ticketOrderId, eventCode) => this.PATCH(`/code/events/${eventId}/tickets/orders/${ticketOrderId}/cancel`, null, { eventCode })
  }

  guest = {
    patchByEventCode: (eventCode, body) => {
      return this.PATCH('/code/events/guests', body, { eventCode })
    },
    addByEventCode: (eventCode, body) => {
      return this.POST('/code/events/guests', body, { eventCode })
    }
  }

  images = {
    guestAvatar: (id, eventCode) => {
      return `${this.baseUrl}/code/events/guests/${id}/avatar?eventCode=${eventCode}`
    },
    userAvatar: (id, eventCode) => {
      return `${this.baseUrl}/code/events/users/${id}/avatar?eventCode=${eventCode}`
    }
  }

  organisation = {
    find: (username) => this.GET('/organisations/find', { username }),
    getEvents: (id) => this.GET(`/organisations/${id}/events`),
    getPastEvents: (id, page, size) => this.GET(`/organisations/${id}/events/past`, { page, size }),
    follow: (id) => this.GET(`/organisations/${id}/follow`),
    unfollow: (id) => this.GET(`/organisations/${id}/unfollow`)
  }

  organisationAdmin = {
    getMe: orgId => this.GET(`/organisationsAdmin/${orgId}`),
    getMyFollowers: (offset, orgId) => this.GET(`/organisationsAdmin/${orgId}/followers`, { offset }),
    patchMe: (user, orgId) => this.PATCH(`/organisationsAdmin/${orgId}`, user),
    getMyStats: orgId => this.GET(`/organisationsAdmin/${orgId}/stats`),
    getUploadUrl: (format, orgId) => this.GET(`/organisationsAdmin/${orgId}/avatarUploadUrl`, { format }),
    usernameAvailable: username => this.GET('/organisationsAdmin/usernameAvailable', { username }),
    requestApproval: (content, orgId) => this.POST(`/organisationsAdmin/${orgId}/requestApproval`, content),
    sendFeedback: (feedback, orgId) => this.POST(`/organisationsAdmin/${orgId}/feedback`, feedback),
    createOrg: (organisation) => this.POST('/organisationsAdmin/create', organisation),
    getTicketFees: (orgId) => this.GET(`/organisationsAdmin/${orgId}/ticketFees`),
    authoriseMollie: (code, orgId) => this.GET(`/organisationsAdmin/${orgId}/authoriseMollie?code=${code}`),
    getConnectedApps: (orgId) => this.GET(`/organisationsAdmin/${orgId}/connectedApps`),
    getUsers: (orgId) => this.GET(`/organisationsAdmin/${orgId}/users`),
    inviteUsers: (orgId, users) => this.POST(`/organisationsAdmin/${orgId}/invite`, users),
    acceptInvite: (email, token) => this.GET('/organisationsAdmin/acceptInvite', { email, token }),
    acceptInviteNoToken: (orgUserId) => this.POST(`/organisationsAdmin/users/${orgUserId}/accept`),
    rejectInvite: (orgUserId) => this.DELETE(`/organisationsAdmin/users/${orgUserId}`),
    getOrganisations: () => this.GET('/organisationsAdmin/users'),
    authorizeTag: (orgId, tagCodes) => this.POST(`/organisationsAdmin/${orgId}/events/tags/authorize`, tagCodes),
    changeRole: (orgId, orgUserId, role) => this.GET(`/organisationsAdmin/${orgId}/changeRole/${orgUserId}`, { role }),
    deleteUser: (orgId, orgUserId) => this.GET(`/organisationsAdmin/${orgId}/deleteUser/${orgUserId}`),
    event: {
      getMine: orgId => this.GET(`/organisationsAdmin/${orgId}/events`),
      getMinePast: orgId => this.GET(`/organisationsAdmin/${orgId}/events/past`),
      getTags: orgId => this.GET(`/organisationsAdmin/${orgId}/events/tags`),
      saveNew: (event, orgId) => this.POST(`/organisationsAdmin/${orgId}/events`, event),
      save: (event, orgId) => this.PATCH(`/organisationsAdmin/${orgId}/events/${event.id}`, event),
      getUploadUrl: (eventId, format, orgId) => this.GET(`/organisationsAdmin/${orgId}/events/${eventId}/imageUploadUrl`, { format }),
      getVideoUploadUrl: (eventId, format, orgId) => this.GET(`/organisationsAdmin/${orgId}/events/${eventId}/videoUploadUrl`, { format }),
      get: (eventId, orgId) => this.GET(`/organisationsAdmin/${orgId}/events/${eventId}`),
      delete: (eventId, orgId) => this.DELETE(`/organisationsAdmin/${orgId}/events/${eventId}`),
      getReachedAndMissed: (eventId, eventUpdateId, orgId) => this.GET(`/organisationsAdmin/${orgId}/events/${eventId}/eventUpdates/${eventUpdateId}/reachedAndMissed`),
      announceDate: (orgId, eventId, startDate, endDate) => this.POST(`/organisationsAdmin/${orgId}/events/${eventId}/announceDate`, { startDate, endDate })
    }
  }

  user = {
    getMe: () => this.GET('/users'),
    patchMe: (user) => this.PATCH('/users', user),
    signMeOut: () => this.GET('/users/signOut'),
    register: (email, platform, name, followProfileId, redirectTo) => {
      if (typeof name === 'string') {
        return this.POST('/users', { newEmailAddress: email, name }, { platform, followProfileId, redirectTo })
      } else {
        return this.POST('/users', { newEmailAddress: email, ...name }, { platform, followProfileId, redirectTo })
      }
    },
    verifyEmail: (email, token) => this.GET('/users/verifyEmail', { email, token }),
    getFollowing: () => this.GET('/users/following'),
    getUploadUrl: format => this.GET('/users/avatarUploadUrlJSON', { format }),
    findByName: (name) => this.GET('/users/findByName', { name }),
    requestToken: (emailAddress, platform, name, followProfileId, redirectTo) => this.POST('/users/signIn/requestToken', { emailAddress, platform, name, followProfileId }, { redirectTo }),
    verifyToken: (emailAddress, token) => this.POST('/users/signIn/verifyToken', { emailAddress, token }),
    verifyToken3rdParty: (token, type, timeZone, platform, followProfileId) => this.POST('/users', { timeZone }, { token, type, platform, followProfileId })
  }
}

const client = new ApiClient(process.env.REACT_APP_API_HOST)
Object.freeze(client)

export default client
