import React, { useEffect, useRef, useState } from 'react'
import { withStyles } from '@material-ui/core'
import { connect, useDispatch, useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { setSnackbar } from '../model/notifications/actions'
import { copyText } from '../shared-components/utils/copy'
import { replaceBreadcrumbs } from '../model/general/actions'
import mixpanel from '../util/mixpanel'
import OnboardingContainer from '../components/onboarding/OnboardingContainer'
import LoadingScreen from '../components/LoadingScreen'
import ProfileCard from '../components/ProfileCard'
import FreedomGigCard from '../components/FreedomGigCard'
import { useTranslation } from 'react-i18next'
import EventList from '../components/events/EventList'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import { history } from '../config/store'
import { getMyPastEvents } from '../model/events/actions'
import { useHistory, useLocation } from 'react-router'
import _ from 'underscore'

const styles = theme => ({
  root: {
    maxWidth: theme.breakpoints.values.visual,
    width: '100%',
    height: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  smallerContainer: {
    maxWidth: theme.breakpoints.values.container,
    alignSelf: 'center',
    width: '100%'
  },
  profileTitle: {
    fontWeight: '700',
    marginBottom: theme.spacing(2)
  },
  banner: {
    marginTop: theme.spacing(4),
    width: '100%'
  },
  profilePaper: {
    padding: theme.spacing(0, 2, 2, 2)
  },
  eventList: {
    maxWidth: theme.breakpoints.values.container,
    alignSelf: 'center'
  },
  eventsLoading: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(5),
    display: 'flex',
    alignItems: 'center'
  },
  eventsLoadingProgress: {
    margin: theme.spacing(1)
  }
})

const Profile2 = ({ classes, user, setSnackbar, replaceBreadcrumbs }) => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  // const showPast = location.pathname.endsWith('past')
  const [showPast, setShowPast] = useState(false)

  const copyTextAreaRef = useRef()
  // const tags = useSelector(state => state.user.value.organisation.authorizedTags)
  const tags = user && user.authorizedTags
  const gigInvited = tags && _.find(tags, tag => tag.id === 1)
  const profileLink = user && `${process.env.REACT_APP_WEBSITE}/${user.username}`

  const events = useSelector(state => state.events.value)
  const pastEvents = useSelector(state => state.events.pastEvents)

  const showEvents = showPast ? pastEvents : events

  useEffect(() => {
    showPast && !pastEvents && dispatch(getMyPastEvents())
  }, [dispatch, pastEvents, showPast])

  const onClickPastEvents = async () => {
    // history.push('/events/past')
    setShowPast(true)
    mixpanel.trackOrgDash('Check Past Events')
  }

  const onClickUpcomingEvents = () => {
    // history.push('/events')
    setShowPast(false)
    mixpanel.trackOrgDash('Check Future Events')
  }

  const onEventClick = event => {
    mixpanel.trackOrgEvent('Open Bash', event)
    history.push(`/event/${event.id}/view`)
  }

  const profileCompleteCount = () => {
    let count = 0
    if (user.name) count++
    if (user.username) count++
    if (user.avatarUrl) count++
    if (user.location) count++
    if (user.description) count++

    return count
  }

  const onboardingCompleted =
    user &&
    profileCompleteCount() >= 4 &&
    user.followerCount >= 5 &&
    user.eventCount > 0

  useEffect(() => {
    mixpanel.trackOrgDash('Land on Organisation Home Page')
    mixpanel.trackOrgDash('Check Profile')
    const breadCrumb = t('nav.sidebar.profile')
    replaceBreadcrumbs({ [breadCrumb]: { href: '/' } })
  }, [replaceBreadcrumbs, t])

  const onCopyProfileLinkClicked = async () => {
    mixpanel.trackOrgDash('Copy Organisation Link')
    await copyText(profileLink, copyTextAreaRef, setSnackbar, t)
  }

  if (!user) return (<LoadingScreen />)

  return (
    <div className={classes.root}>

      {/* <Typography className={classes.profileTitle} variant='h5'>{t('profile.title')}</Typography> */}
      <ProfileCard />
      <div className={classes.smallerContainer}>
        {gigInvited && <FreedomGigCard />}
        {!onboardingCompleted && user && user.followerCount !== undefined && <OnboardingContainer onCopyLink={onCopyProfileLinkClicked} profileCompleteCount={profileCompleteCount()} />}
        {showEvents ?
          <EventList
            organisation={user} onEventClick={onEventClick}
            onClickPastEvents={onClickPastEvents} onClickUpcomingEvents={onClickUpcomingEvents} showPast={showPast}
          /> :
          <div className={classes.eventsLoading}>
            <CircularProgress className={classes.eventsLoadingProgress} size={24} color='inherit' />{t('events.loading')}
          </div>}
      </div>
      {profileLink &&
        <textarea readOnly ref={copyTextAreaRef} hidden value={profileLink} />}
    </div>
  )
}

const mapStateToProps = ({ user }) => ({
  user: user.value.organisation
})

export default connect(mapStateToProps, { setSnackbar, replaceBreadcrumbs })(withStyles(styles)(Profile2))
