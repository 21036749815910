import { applyMiddleware, combineReducers, createStore } from 'redux'
import promise from 'redux-promise-middleware'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import user from '../model/user/reducer'
import auth from '../model/auth/reducer'
import notifications from '../model/notifications/reducer'
import events from '../model/events/reducer'
import general from '../model/general/reducer'
import modals from '../model/modals/reducer'

export const history = createBrowserHistory()
const initialize = (initialState = {}) =>
  createStore(
    combineReducers({
      router: connectRouter(history),
      user,
      auth,
      events,
      notifications,
      general,
      modals
    }),
    initialState,
    composeWithDevTools(
      applyMiddleware(
        routerMiddleware(history),
        promise,
        thunk
      )
    )
  )

export default initialize()
