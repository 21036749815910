import React, { useEffect, useRef } from 'react'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { copyText } from '@bash/shared-components/utils/copy'
import { setSnackbar } from '../model/notifications/actions'
import Paper from '@material-ui/core/Paper'
import UserListItem from '../components/UserListItem'
import CircularProgress from '@material-ui/core/CircularProgress'
import { replaceBreadcrumbs } from '../model/general/actions'
import { getMyFollowers } from '../model/user/actions'
import mixpanel from '../util/mixpanel'
import Tooltip from '@material-ui/core/Tooltip'
import LoadingScreen from '../components/LoadingScreen'
import { Trans, useTranslation } from 'react-i18next'

const styles = theme => ({
  root: {
    maxWidth: theme.breakpoints.values.events,
    width: '100%',
    height: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(0, 2, 2, 2),
    marginTop: theme.spacing(7),
    display: 'flex',
    flexDirection: 'column'
  },
  profileTitle: {
    fontWeight: '700',
    marginBottom: theme.spacing(0.5)
  },
  progressDialog: {
    backgroundColor: theme.palette.grey.main,
    borderRadius: 8,
    padding: theme.spacing(2),
    width: '100%',
    marginTop: theme.spacing(2)
  },
  margintop: {
    marginTop: theme.spacing(1)
  },
  progressLinks: {
    alignSelf: 'flex-end',
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
    '& span': {
      marginLeft: theme.spacing(3),
      cursor: 'pointer',
      fontWeight: '700'
    }
  },
  followersContainer: {
    padding: theme.spacing(2, 2, 0, 2),
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  },
  segmentTitle: {
    marginTop: theme.spacing(5),
    fontWeight: '700'
  },
  endTitle: {
    marginTop: theme.spacing(3),
    textAlign: 'center'
  },
  endCaption: {
    marginTop: theme.spacing(1),
    textAlign: 'center'
  },
  endLink: {
    marginTop: theme.spacing(1),
    cursor: 'pointer',
    fontWeight: '700',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  recentlyLoading: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(4)
  }
})

const Followers = ({ classes, user, followers, setSnackbar, replaceBreadcrumbs, getMyFollowers }) => {
  const { t } = useTranslation('common')
  const copyTextAreaRef = useRef()
  const profileLink = user && `${process.env.REACT_APP_WEBSITE}/${user.username}`
  const onCopyProfileLinkClicked = async () => {
    await copyText(profileLink, copyTextAreaRef, setSnackbar, t)
  }

  useEffect(() => {
    mixpanel.trackOrgDash('Check Followers')
    replaceBreadcrumbs({ [t('nav.sidebar.followers')]: { href: '/followers' } })
    !followers && user && getMyFollowers(0)
  }, [followers, getMyFollowers, replaceBreadcrumbs, t, user])

  if (!user) return (<LoadingScreen />)

  return (
    <div className={classes.root}>
      <Typography className={classes.profileTitle} variant='h5'>{t('followersPage.title')}</Typography>
      <Typography color='textSecondary' variant='subtitle2'>{t('followersPage.subtitle')}</Typography>
      {user.followerCount !== null && user.followerCount < 5 &&
        <div className={classes.progressDialog}>
          <Typography variant='subtitle1'><Trans t={t} i18nKey='followersPage.progress.getFollowers' followerCount={user.followerCount}>Get followers ({{ followerCount: user.followerCount }}/5)</Trans></Typography>
          <Typography className={classes.margintop} color='textSecondary' variant='body2'>{t('followersPage.progress.caption')}</Typography>
          <div className={classes.progressLinks}>
            {/* <Typography color='textSecondary' variant='button'>Learn more</Typography> */}
            <Typography onClick={onCopyProfileLinkClicked} color='primary' variant='button'>{t('copyLink')}</Typography>
          </div>
        </div>}
      <Typography className={classes.segmentTitle} variant='h6'>{t('followersPage.recentlyFollowed')}</Typography>
      {followers ? (
        <>
          {followers.length > 0 && (
            <Paper className={classes.followersContainer} elevation={2}>
              {followers.map(follower => <UserListItem key={follower.user.name} follower={follower} />)}
            </Paper>)}
          <Typography variant='h6' className={classes.endTitle}>{t('thatsIt')}</Typography>
          <Typography variant='body1' color='textSecondary' className={classes.endCaption}>{t('followersPage.sendToAccount')}</Typography>
          <Typography color='primary' onClick={onCopyProfileLinkClicked} className={classes.endLink} variant='button'>{t('copyProfileLink')}</Typography>
        </>) : <CircularProgress className={classes.recentlyLoading} size={24} />}
      <textarea readOnly ref={copyTextAreaRef} hidden value={profileLink} />
    </div>
  )
}

const mapStateToProps = ({ user }) => ({
  user: user.value.organisation,
  followers: user.followers
})

export default connect(mapStateToProps, { setSnackbar, replaceBreadcrumbs, getMyFollowers })(withStyles(styles)(Followers))
