import { createMuiTheme } from '@material-ui/core'

const headerFontFamily = '"Helvetica Neue", "Fira Sans", "Helvetica", sans-serif'

const containedOutlineButtonStyle = {
  textTransform: 'none',
  letterSpacing: '0.04em',
  height: 48,
  borderRadius: 1000,
  backgroundColor: '#fafafa',
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.06), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.04)',
  '&:hover': {
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.06), 0px 4px 5px 0px rgba(0,0,0,0.04), 0px 1px 10px 0px rgba(0,0,0,0.04)'
  }
}

export default createMuiTheme({
  typography: {
    h3: {
      fontFamily: headerFontFamily,
      fontSize: '2.313rem',
      lineHeight: '2.688rem',
      fontWeight: 500
    },
    h4: {
      fontFamily: headerFontFamily,
      fontSize: '2.125rem',
      lineHeight: '2.5rem',
      fontWeight: 500
    },
    h5: {
      fontFamily: headerFontFamily,
      fontSize: '1.5rem',
      lineHeight: '1.75rem',
      fontWeight: 500
    },
    h6: {
      fontFamily: headerFontFamily,
      fontSize: '1.25rem',
      lineHeight: '1.438rem',
      fontWeight: 500
    },
    subtitle1: {
      fontFamily: headerFontFamily,
      fontSize: '1rem',
      lineHeight: '1.188rem',
      fontWeight: 500,
      letterSpacing: '0.0009rem'
    },
    subtitle2: {
      fontFamily: headerFontFamily,
      fontSize: '0.875rem',
      lineHeight: '1.063rem',
      letterSpacing: '0.007em',
      fontWeight: 500
    },
    body1: {
      fontSize: '1rem',
      lineHeight: '1.25rem',
      letterSpacing: '0.031rem'
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: '1rem',
      letterSpacing: '0.018em'
    },
    caption: {
      fontSize: '0.75rem',
      letterSpacing: '0.029em',
      lineHeight: '0.875rem'
    },
    button: {
      textTransform: 'none',
      fontWeight: 500,
      letterSpacing: '0.089em',
      fontSize: '0.875rem',
      lineHeight: '1rem'
    },
    overline: {
      letterSpacing: '0.150em',
      fontSize: '0.625rem',
      lineHeight: '0.75rem',
      textTransform: 'uppercase'
    },
    fontFamily: '-apple-system, "BlinkMacSystemFont", "Roboto", "Arial", sans-serif',
    useNextVariants: true
  },
  props: {
    MuiTypography: {
      variantMapping: {
        caption: 'p'
        // overline: 'p'
      }
    }
  },
  palette: {
    text: {
      secondary: 'rgba(0,0,0,0.6)'
    },
    background: {
      default: '#f5f5f5',
      wizard: '#E5E5E5',
      paper: '#fafafa'
    },
    primary: {
      main: '#197BBD',
      lighter: '#7EC0FA',
      light: '#E1EDF5',
      lightDark: '#d2dee6',
      dark: '#0A588C',
      halfTransparent: 'rgba(25,123,189,0.5)'
    },
    secondary: {
      main: '#00A693',
      light: '#D2EDE9',
      dark: '#00232C',
      lighter: '#00beeb',
      lightDark: '#CDEDF6'
    },
    tertiary: {
      main: '#F25F5C',
      light: '#FFE2E2',
      lighter: '#FDECEC',
      dark: '#D13E3B',
      contrastText: '#fff'
    },
    green: {
      dark: '#002C0E',
      main: '#00A693',
      light: '#E8FCF3'
    },
    red: {
      darker: '#2C0000',
      dark: '#D13E3B',
      main: '#F25F5C',
      light: '#FDECEC',
      lighter: '#FF8784',
      lightest: '#FFEFEF'
    },
    yellow: {
      dark: '#332602',
      main: '#E6CE57',
      light: '#FFF9E9'
    },
    grey: {
      light: '#F5F5F5',
      main: '#F0F0F0',
      dark: '#EBEBEB'
    }
  },
  breakpoints: {
    values: {
      container: 480,
      visual: 640,
      header: 800,
      xs: 0,
      sm: 400,
      md: 640,
      lg: 960,
      xl: 1280,
      xxl: 1920,
      events: 568
    }
  },
  sidebar: {
    width: 200
  },
  header: {
    height: 56
  },
  drawer: {
    width: 400,
    showFrom: 'xxxl' // 'xl'
  },
  shadows: [
    'none',
    '0px 2px 1px -1px rgba(0,0,0,0.06),0px 1px 1px 0px rgba(0,0,0,0.04),0px 1px 3px 0px rgba(0,0,0,0.04)',
    '0px 3px 1px -2px rgba(0,0,0,0.06),0px 2px 2px 0px rgba(0,0,0,0.04),0px 1px 5px 0px rgba(0,0,0,0.04)',
    '0px 3px 3px -2px rgba(0,0,0,0.06),0px 3px 4px 0px rgba(0,0,0,0.04),0px 1px 8px 0px rgba(0,0,0,0.04)',
    '0px 2px 4px -1px rgba(0,0,0,0.06),0px 4px 5px 0px rgba(0,0,0,0.04),0px 1px 10px 0px rgba(0,0,0,0.04)',
    '0px 3px 5px -1px rgba(0,0,0,0.06),0px 5px 8px 0px rgba(0,0,0,0.04),0px 1px 14px 0px rgba(0,0,0,0.04)',
    '0px 3px 5px -1px rgba(0,0,0,0.06),0px 6px 10px 0px rgba(0,0,0,0.04),0px 1px 18px 0px rgba(0,0,0,0.04)',
    '0px 4px 5px -2px rgba(0,0,0,0.06),0px 7px 10px 1px rgba(0,0,0,0.04),0px 2px 16px 1px rgba(0,0,0,0.04)',
    '0px 5px 5px -3px rgba(0,0,0,0.06),0px 8px 10px 1px rgba(0,0,0,0.04),0px 3px 14px 2px rgba(0,0,0,0.04)',
    '0px 5px 6px -3px rgba(0,0,0,0.06),0px 9px 12px 1px rgba(0,0,0,0.04),0px 3px 16px 2px rgba(0,0,0,0.04)',
    '0px 6px 6px -3px rgba(0,0,0,0.06),0px 10px 14px 1px rgba(0,0,0,0.04),0px 4px 18px 3px rgba(0,0,0,0.04)',
    '0px 6px 7px -4px rgba(0,0,0,0.06),0px 11px 15px 1px rgba(0,0,0,0.04),0px 4px 20px 3px rgba(0,0,0,0.04)',
    '0px 7px 8px -4px rgba(0,0,0,0.06),0px 12px 17px 2px rgba(0,0,0,0.04),0px 5px 22px 4px rgba(0,0,0,0.04)',
    '0px 7px 8px -4px rgba(0,0,0,0.06),0px 13px 19px 2px rgba(0,0,0,0.04),0px 5px 24px 4px rgba(0,0,0,0.04)',
    '0px 7px 9px -4px rgba(0,0,0,0.06),0px 14px 21px 2px rgba(0,0,0,0.04),0px 5px 26px 4px rgba(0,0,0,0.04)',
    '0px 8px 9px -5px rgba(0,0,0,0.06),0px 15px 22px 2px rgba(0,0,0,0.04),0px 6px 28px 5px rgba(0,0,0,0.04)',
    '0px 8px 10px -5px rgba(0,0,0,0.06),0px 16px 24px 2px rgba(0,0,0,0.04),0px 6px 30px 5px rgba(0,0,0,0.04)',
    '0px 8px 11px -5px rgba(0,0,0,0.06),0px 17px 26px 2px rgba(0,0,0,0.04),0px 6px 32px 5px rgba(0,0,0,0.04)',
    '0px 9px 11px -5px rgba(0,0,0,0.06),0px 18px 28px 2px rgba(0,0,0,0.04),0px 7px 34px 6px rgba(0,0,0,0.04)',
    '0px 9px 12px -6px rgba(0,0,0,0.06),0px 19px 29px 2px rgba(0,0,0,0.04),0px 7px 36px 6px rgba(0,0,0,0.04)',
    '0px 10px 13px -6px rgba(0,0,0,0.06),0px 20px 31px 3px rgba(0,0,0,0.04),0px 8px 38px 7px rgba(0,0,0,0.04)',
    '0px 10px 13px -6px rgba(0,0,0,0.06),0px 21px 33px 3px rgba(0,0,0,0.04),0px 8px 40px 7px rgba(0,0,0,0.04)',
    '0px 10px 14px -6px rgba(0,0,0,0.06),0px 22px 35px 3px rgba(0,0,0,0.04),0px 8px 42px 7px rgba(0,0,0,0.04)',
    '0px 11px 14px -7px rgba(0,0,0,0.06),0px 23px 36px 3px rgba(0,0,0,0.04),0px 9px 44px 8px rgba(0,0,0,0.04)',
    '0px 11px 15px -7px rgba(0,0,0,0.06),0px 24px 38px 3px rgba(0,0,0,0.04),0px 9px 46px 8px rgba(0,0,0,0.04)'
  ],
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: 8
      },
      elevation4: {
        boxShadow: '0 4px 5px 0 rgba(0, 0, 0, 0.04), 0 1px 10px 0 rgba(0, 0, 0, 0.04), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
      }
    },
    MuiButton: {
      containedSizeSmall: {
        height: 32
      },
      containedSecondary: {
        color: 'white !important'
      },
      contained: containedOutlineButtonStyle,
      outlined: containedOutlineButtonStyle
    },
    MuiInputLabel: {
      shrink: {
        fontWeight: 'bold',
        color: '#197BBD'
      }
    }
  }
})
