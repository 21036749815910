import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import DropZone from '../DropZone'
import HalfTransparentButton from '../HalfTransparentButton'
import CameraIcon from '@material-ui/icons/CameraAlt'
import TextInput from '@bash/shared-components/common/TextInput'
import DateTimeInput from '../DateTimeInput'
import moment from 'moment'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { editEventContext } from '../../routes/EditEvent'
import mixpanel from '../../util/mixpanel'
import Resizer from 'react-image-file-resizer'
import MoreIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { Block, Close, MoreVert } from '@material-ui/icons'
import GigBanner from './GigBanner'
import { useTranslation } from 'react-i18next'
import TagButtonInput from './TagButtonInput'
import { setSnackbar } from '../../model/notifications/actions'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      paddingTop: `calc(${theme.mixins.toolbar.minHeight}px + ${theme.spacing(7)}px)`
    },
    paddingTop: `calc(${theme.mixins.toolbar.minHeight}px + ${theme.spacing(4)}px)`,
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    overflowY: 'scroll',
    height: 'calc(100% - 80px)',
    borderRadius: 0
  },
  imageContainer: {
    backgroundColor: theme.palette.grey.main,
    marginTop: theme.spacing(2),
    borderRadius: 8,
    position: 'relative',
    width: '100%',
    height: 176,
    '& button': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    },
    overflow: 'hidden',
    flexShrink: 0
  },
  dropZone: {
    opacity: '1'
  },
  eventImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  addEndDate: {
    marginTop: theme.spacing(1),
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  dateMenuButton: {
    marginRight: theme.spacing(-1),
    padding: 0,
    height: '19px',
    width: '19px'
  },
  announceButton: {
    cursor: 'pointer'
  }
}))

const WizardPage1 = () => {
  const classes = useStyles()
  const { editingEvent, onChange, newEvent, imageVersion, imgFile, setImgFile, isGig } = useContext(editEventContext)
  const fileInputRef = useRef(null)
  const [endDateVisible, setEndDateVisible] = useState(false)
  const [dateMenuOpen, setDateMenuOpen] = useState(false)
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const [imageMenu, setImageMenu] = useState(null)
  const [videoActive, setVideoActive] = useState(Boolean(editingEvent.videoUrl))

  const limitFileSize = (file) => new Promise(resolve => {
    Resizer.imageFileResizer(file, 1000, 1000, 'PNG', 100, 0,
      uri => {
        resolve(uri)
      },
      'blob'
    )
  })

  const uploadImage = async file => {
    if (!file) return
    let limitedFile
    if (file.type === 'video/mp4') {
      limitedFile = file
      if (limitedFile.size > 20971520) {
        dispatch(setSnackbar('error', t('fileTooBig')))
        return
      }
    } else {
      const limitedImg = await limitFileSize(file)
      limitedFile = new File([limitedImg], 'newimg.png')
    }
    onChange('themeImageKey', null)
    if (newEvent) {
      mixpanel.trackOrgDash('Add Bash Image')
    }
    setImgFile(limitedFile)
    if (limitedFile.type === 'video/mp4') {
      onChange('videoUrl', URL.createObjectURL(limitedFile))
      onChange('imageUrl', null)
    } else {
      onChange('imageUrl', URL.createObjectURL(limitedFile))
      onChange('videoUrl', null)
    }
  }

  useEffect(() => {
    if (newEvent && !imgFile && editingEvent.imageUrl) {
      const getImage = async () => {
        const file = await fetch(editingEvent.imageUrl).then(r => r.blob()).then(blobFile => new File([blobFile], 'imageFile', { type: 'image/png' }))
        setImgFile(file)
      }
      getImage()
    }
  }, [])

  const handleEndDateClick = () => {
    if (endDateVisible || editingEvent.endDate) {
      setEndDateVisible(false)
      onChange('endDate', null)
    } else {
      setEndDateVisible(!endDateVisible)
      onChange('endDate', null)
    }
  }

  const onSetImageClicked = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const onDateMenuClick = (e) => {
    e.stopPropagation()
    setDateMenuOpen(e.currentTarget)
  }

  const setDateToBeAnnounced = (value) => {
    onChange('dateToBeAnnounced', value)
    if (value) {
      onChange('startDate', null)
      onChange('endDate', null)
      setEndDateVisible(false)
    }
    setDateMenuOpen(false)
  }

  return (
    <div className={classes.root}>
      <Typography variant='subtitle1'>{t('event.edit.title')}</Typography>
      {!isGig && <TagButtonInput />}
      {isGig && <GigBanner />}
      <div className={classes.imageContainer}>
        <DropZone videoActive={videoActive} fileInputRef={fileInputRef} onNewFile={uploadImage} className={classes.dropZone}>
          {((imgFile && imgFile.type === 'video/mp4') || (editingEvent.videoUrl && !imgFile)) && (
            <video
              autoPlay
              muted
              loop
              className={classes.eventImage}
              src={(imgFile ? URL.createObjectURL(imgFile) : `${editingEvent.videoUrl}?version=${imageVersion}`)}
            />
          )}
          {((imgFile && imgFile.type !== 'video/mp4') || !editingEvent.videoUrl) && (imgFile || editingEvent.imageUrl || isGig) &&
            <img
              alt='' className={classes.eventImage} src={(editingEvent.imageUrl && !imgFile) ?
          `${editingEvent.imageUrl}?version=${imageVersion}` :
                (isGig && !imgFile ? `/events/themeImages/suggestedEvents/freedomday.jpeg?version=${imageVersion}` : (imgFile && URL.createObjectURL(imgFile)))}
            />}
        </DropZone>
        <HalfTransparentButton onClick={onSetImageClicked} startIcon={<CameraIcon />} endIcon={<MoreVert onClick={(e) => { e.stopPropagation(); setImageMenu(e.currentTarget) }} />}>
          {videoActive ? t('event.edit.chooseVideo') : t('event.edit.chooseImage')}
        </HalfTransparentButton>
        <Menu
          id='image-menu'
          anchorEl={imageMenu}
          keepMounted
          open={Boolean(imageMenu)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          onClose={() => setImageMenu(null)}
        >
          {!videoActive && <MenuItem onClick={() => { setImageMenu(null); setVideoActive(true) }}>{t('event.edit.chooseVideo')}</MenuItem>}
          {videoActive && <MenuItem onClick={() => { setImageMenu(null); setVideoActive(false) }}>{t('event.edit.chooseImage')}</MenuItem>}
        </Menu>
      </div>
      <TextInput defaultValue={editingEvent.name} onChange={e => onChange('name', e.target.value)} label={t('event.edit.titleLabel')} />
      {!editingEvent.dateToBeAnnounced && (
        <DateTimeInput
          endAdornment={<IconButton onClick={onDateMenuClick} className={classes.dateMenuButton}><MoreIcon color='primary' /></IconButton>}
          error={moment(editingEvent.startDate).isBefore(moment()) && !editingEvent.dateToBeAnnounced}
          errorText={t('event.edit.dateError')}
          label={t('event.edit.dateLabel')}
          value={editingEvent.startDate}
          onChange={(date) => { onChange('startDate', date); onChange('dateToBeAnnounced', false) }}
        />
      )}
      {editingEvent.dateToBeAnnounced && (
        <TextInput
          label={t('event.edit.dateLabel')}
          value={t('event.edit.dateTba')}
          inputProps={{ readOnly: true }}
          endAdornment={isGig ? <Block style={{ opacity: 0.6 }} /> :
            (editingEvent.state === 'ACTIVE' ?
              undefined :
              <Close style={{ cursor: 'pointer' }} onClick={() => setDateToBeAnnounced(false)} />)}
        />
      )}

      <Menu
        id='long-menu'
        keepMounted
        anchorEl={dateMenuOpen}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={Boolean(dateMenuOpen)}
        onClose={() => setDateMenuOpen(null)}
      >
        <MenuItem key='date-announce' onClick={() => setDateToBeAnnounced(true)}>
          {t('event.edit.dateTba')}
        </MenuItem>
      </Menu>
      {(endDateVisible || editingEvent.endDate) && <DateTimeInput startDate={editingEvent.startDate} error={moment(editingEvent.endDate).isBefore(moment(editingEvent.startDate))} errorText={t('event.edit.endDateError')} label={t('event.edit.endDate')} value={editingEvent.endDate} onChange={(date) => onChange('endDate', date)} />}
      {!editingEvent.dateToBeAnnounced && <Typography variantMapping={{ button: 'p' }} className={classes.addEndDate} color='primary' variant='button' onClick={handleEndDateClick}>{(endDateVisible || editingEvent.endDate) ? t('event.edit.removeEndDate') : t('event.edit.addEndDate')}</Typography>}
      <TextInput label={t('event.edit.description')} value={editingEvent.description} onChange={e => onChange('description', e.target.value)} multiline rows={4} rowsMax={14} />
    </div>)
}

export default WizardPage1
