import apiClient from './../../shared-components/utils/ApiClient'
import { history } from '../../config/store'
import { CHECK_EMAIL, SIGN_IN, SIGN_OUT, SUBMIT_TOKEN, NO_EMAIL } from './types'
import { checkCurrentOrg, getMe, receiveMe } from '../user/actions'
import mixpanel from '../../util/mixpanel'
import _ from 'underscore'

export const signIn = email => async dispatch => {
  dispatch({
    type: SIGN_IN
  })
  await apiClient.user.requestToken(email, 'WEB_APP')
  mixpanel.track('Land on Magic Link Page')
  dispatch({
    type: CHECK_EMAIL
  })
}

export const signIn3rdParty = (token, type, timeZone) => async dispatch => {
  try {
    dispatch({
      type: SUBMIT_TOKEN
    })
    const user = await apiClient.user.verifyToken3rdParty(token, type, timeZone, 'orgDash')
    const organisations = await apiClient.organisationAdmin.getOrganisations()
    dispatch(checkCurrentOrg(user, organisations, true))
  } catch (e) {
    console.warn('Google token submit not succeeded', e)
  }
}

export const backToForm = () => async dispatch => {
  dispatch({
    type: NO_EMAIL
  })
}

export const verifyToken = (email, token) => async dispatch => {
  try {
    dispatch({
      type: SUBMIT_TOKEN
    })
    const user = await apiClient.user.verifyToken(email, token)
    const organisations = await apiClient.organisationAdmin.getOrganisations()
    dispatch(checkCurrentOrg(user, organisations, true))
    // let myOrg
    // if (!organisations.length) {
    //   myOrg = undefined
    // } else {
    //   const lastLoggedInId = localStorage.getItem('lastLoggedInId')
    //   const lastLoggedInOrg = _.find(organisations, org => org.id === parseInt(lastLoggedInId))
    //
    //   myOrg = (lastLoggedInId && lastLoggedInOrg) ? lastLoggedInOrg : undefined
    // }
    //
    // if (!user.name) {
    //   history.push('/newprofile')
    // } else if (!organisations.length) {
    //   history.push('/createorg')
    // } else if (organisations.length > 1 && !myOrg) {
    //   history.push('/selectorg')
    // } else {
    //   history.push('/')
    // }
    //
    // dispatch(receiveMe(myOrg, user, organisations))
  } catch (e) {
    console.warn('Email token submit not succeeded', e)
  }
}

export const acceptInvite = (email, token) => async dispatch => {
  try {
    dispatch({
      type: SUBMIT_TOKEN
    })
    const user = await apiClient.organisationAdmin.acceptInvite(email, token)
    const organisations = await apiClient.organisationAdmin.getOrganisations()

    if (!user.name) {
      history.push('/newprofile')
    } else if (!organisations.length) {
      history.push('/createorg')
    } else if (organisations.length === 1) {
      history.push('/')
    } else if (organisations.length > 1) {
      history.push('/selectorg')
    }

    dispatch(receiveMe(user.organisation, null, organisations))
  } catch (e) {
    console.warn('Email token submit not succeeded', e)
  }
}

export const signOut = () => async dispatch => {
  await apiClient.user.signMeOut()
  dispatch({
    type: SIGN_OUT
  })
  dispatch(getMe())
}
