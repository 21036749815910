import React, { useState } from 'react'
import { withStyles } from '@material-ui/styles'
import { SvgIcon, Typography } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import StarsIcon from '@material-ui/icons/Stars'
import { ReactComponent as CantIcon } from '../images/status/CANT_outline.svg'
import { ReactComponent as MaybeIcon } from '../images/status/MAYBE_outline.svg'
import classNames from 'classnames'

const styles = theme => ({
  root: {
    display: 'flex'
  },
  badge: {
    width: 20,
    height: 20
  },
  cant: {
    color: theme.palette.red.main
  },
  maybe: {
    color: theme.palette.yellow.main
  },
  going: {
    color: theme.palette.green.main
  },
  interested: {
    color: theme.palette.primary.main
  },
  whiteBackground: {
    backgroundColor: 'white',
    borderRadius: '50%'
  },
  count: {
    lineHeight: '20px',
    color: 'rgba(0, 0, 0, .6)',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
})

const ResponseCounter = ({ classes, count, type }) => {
  const badges = {
    INTERESTED: <StarsIcon className={classNames(classes.badge, classes.interested, classes.whiteBackground)} />,
    MAYBE: <SvgIcon component={MaybeIcon} className={classNames(classes.badge, classes.maybe)} />,
    GOING: <CheckCircleIcon className={classNames(classes.badge, classes.going, classes.whiteBackground)} />,
    CANT: <SvgIcon component={CantIcon} className={classNames(classes.badge, classes.cant)} />,
    NONE: null
  }
  return (
    <div className={classes.root}>
      {badges[type]}
      <Typography className={classes.count} variant='caption'>{count}</Typography>
    </div>
  )
}

export default withStyles(styles)(ResponseCounter)
