import React, { useCallback, useState } from 'react'
import {
  Dialog,
  Typography,
  Button, Avatar
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { closeModal } from '../../model/modals/actions'
import TextInput from '@bash/shared-components/common/TextInput'
import * as _ from 'underscore'
import FlatButton from '@bash/shared-components/buttons/FlatButton'
import SearchIcon from '@material-ui/icons/Search'
import apiClient from '@bash/shared-components/utils/ApiClient'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import RestingButton from '../RestingButton'
import CloseIcon from '@material-ui/icons/Close'
import CircularProgress from '@material-ui/core/CircularProgress'
import { inviteUsers } from '../../model/user/actions'
import { Trans, useTranslation } from 'react-i18next'
import { history } from '../../config/store'

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    padding: theme.spacing(4)
  },
  dialogPaper: {
    width: '100%',
    maxWidth: theme.breakpoints.values.visual
  },
  backdropRoot: {
    backgroundColor: 'rgba(255, 255, 255, 0.7)'
  },
  moreEmailButton: {
    marginTop: theme.spacing(1.75),
    cursor: 'pointer',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(3)
  },
  marginTopHalf: {
    marginTop: theme.spacing(0.5)
  },
  marginTop1: {
    marginTop: theme.spacing(1)
  },
  marginTop2: {
    marginTop: theme.spacing(2)
  },
  marginTop3: {
    marginTop: theme.spacing(3)
  },
  marginTop4: {
    marginTop: theme.spacing(4)
  },
  divider: {
    width: '100%',
    height: '1px',
    backgroundColor: '#DDDDDD',
    marginBottom: theme.spacing(3)
  },
  sendButton: {
    width: '100%',
    borderRadius: theme.spacing(1)
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    fontSize: '19px'
  },
  emailsContainer: {
    '& > div:first-child': {
      marginTop: theme.spacing(1)
    }
  },
  searchResultsContainer: {
    marginTop: theme.spacing(3),
    minHeight: '203px',
    overflow: 'scroll'
  },
  addedUserList: {
    paddingTop: theme.spacing(2)
  },
  searchLoading: {
    display: 'flex',
    justifyContent: 'center'
  },
  emptyResultsContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  marginTop8half: {
    marginTop: theme.spacing(8.5)
  },
  inviteByEmail: {
    marginTop: theme.spacing(1),
    cursor: 'pointer'
  }
}))

const useItemStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    height: '40px',
    width: '40px',
    marginRight: theme.spacing(2)
  },
  addButton: {
    marginLeft: 'auto',
    height: '40px'
  },
  addIcon: {
    marginRight: theme.spacing(1)
  },
  removeIcon: {
    marginLeft: 'auto',
    opacity: 0.6
  }
}))

const UserItem = ({ user, onAdd, onRemove }) => {
  const classes = useItemStyles()
  return (
    <div className={classes.root}>
      <Avatar className={classes.avatar} src={`${user.avatarUrl}`} />
      <Typography variant='subtitle1'>{user.name}</Typography>
      {onAdd && <RestingButton onClick={() => onAdd(user)} className={classes.addButton}><PersonAddIcon className={classes.addIcon} color='primary' />Invite</RestingButton>}
      {onRemove && <CloseIcon className={classes.removeIcon} onClick={() => onRemove(user)} />}
    </div>
  )
}

const InviteMemberDialog = ({ TransitionComponent }) => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const classes = useStyles()
  const open = useSelector(state => state.modals.open.invite)

  const [inviting, setInviting] = useState(false)
  const [searchResults, setSearchResults] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [searching, setSearching] = useState(false)
  const searchActive = searchValue !== ''
  const [emails, setEmails] = useState([
    {
      emailAddress: ''
    }
  ])
  const filteredEmails = _.filter(emails, email => email.emailAddress !== '')
  const [users, setUsers] = useState([])

  const debouncedSearch = useCallback(_.debounce(async (name) => {
    setSearching(true)
    const results = await apiClient.user.findByName(name)
    setSearchResults(results)
    setSearching(false)
  }, 1500), [])

  const onEmailChange = (newEmail, id) => {
    setEmails(_.map(emails, (email, index) => index === id ? { emailAddress: newEmail } : email))
  }

  const onMoreClick = () => {
    setEmails([
      ...emails,
      {
        emailAddress: ''
      }
    ])
  }

  const addToUserList = (bashUser) => {
    if (users.findIndex(obj => obj.id === bashUser.id) === -1) {
      setUsers([
        ...users,
        bashUser
      ])
    }
    setSearchValue('')
    setSearchResults([])
  }

  const removeFromUserList = (bashUser) => {
    setUsers(_.filter(users, oldUser => oldUser.id !== bashUser.id))
  }

  const onSearchChange = (searchValue) => {
    setSearchResults([])
    setSearchValue(searchValue)
    if (searchValue.length > 2) {
      setSearching(true)
      debouncedSearch(searchValue)
    }
  }

  const onInviteClicked = async () => {
    const toInviteList = users.concat(filteredEmails)
    setInviting(true)
    await dispatch(inviteUsers(toInviteList))
    setInviting(false)
    history.push('/settings/members')
    dispatch(closeModal('invite'))
  }

  return (
    <Dialog
      aria-labelledby='invite-member-dialog'
      BackdropProps={{
        classes: {
          root: classes.backdropRoot
        }
      }}
      classes={{
        paper: classes.dialogPaper
      }}
      open={open}
      onClose={() => dispatch(closeModal('invite'))}
      TransitionComponent={TransitionComponent}
    >
      <div className={classes.dialogContainer}>
        <Typography variant='h6'>{t('dialogs.inviteTeam.title')}</Typography>
        <Typography
          color='textSecondary'
          className={classes.marginTopHalf}
          variant='subtitle2'
        >{t('dialogs.inviteTeam.subtitle')}
        </Typography>
        {!searchActive && <Typography variant='subtitle1' className={classes.marginTop4}>{t('dialogs.inviteTeam.search')}</Typography>}
        <TextInput
          placeholder={t('dialogs.inviteTeam.searchPlaceholder')}
          startAdornment={<SearchIcon className={classes.searchIcon} />}
          customClass={searchActive ? classes.marginTop3 : classes.marginTop1}
          value={searchValue}
          onChange={(e) => onSearchChange(e.target.value)}
        />
        {searchActive && (
          <div className={classes.searchResultsContainer}>
            {_.map(searchResults, result => {
              if (result.hasEmailAddress) {
                return (
                  <UserItem key={result.id} user={result} onAdd={addToUserList} />
                )
              }
            })}
            {searching && <div className={classes.searchLoading}> <CircularProgress /></div>}
            {!searching && searchValue.length > 2 && searchResults.length === 0 && (
              <div className={classes.emptyResultsContainer}>
                <Typography className={classes.marginTop8half} variant='subtitle1'>{t('dialogs.inviteTeam.empty.title')}</Typography>
                <Typography className={classes.marginTop1} variant='body2'><Trans i18nKey='dialogs.inviteTeam.empty.subtitle' t={t} name={searchValue}>No users found with the name {{ name: searchValue }}.</Trans></Typography>
                <Typography color='primary' variant='button' onClick={() => onSearchChange('')} className={classes.inviteByEmail}>{t('dialogs.inviteTeam.empty.invite')}</Typography>
              </div>
            )}
          </div>
        )}

        {!searchActive && (
          <div className={classes.addedUserList}>
            {_.map(users, addedUser => {
              return (
                <UserItem key={addedUser.id} user={addedUser} onRemove={removeFromUserList} />
              )
            })}
          </div>
        )}

        {!searchActive && (
          <>
            <Typography variant='subtitle1' className={classes.marginTop4}>{t('dialogs.inviteTeam.mail')}</Typography>
            <div className={classes.emailsContainer}>
              {_.map(emails, (object, key) => {
                return (
                  <TextInput
                    placeholder={t('dialogs.inviteTeam.mailPlaceholder')}
                    type='email'
                    customClass={classes.marginTop2}
                    key={key}
                    value={object.emailAddress}
                    onChange={(e) => onEmailChange(e.target.value, key)}
                  />
                )
              })}
            </div>
            <Button disabled={!emails[emails.length - 1].emailAddress} className={classes.moreEmailButton} onClick={onMoreClick}>{t('dialogs.inviteTeam.more')}</Button>
          </>
        )}
        <div className={classes.divider} />
        <FlatButton disabled={!filteredEmails.length && !users.length} loading={inviting} onClick={onInviteClicked} color='primary' className={classes.sendButton}>{t('dialogs.inviteTeam.invite')}</FlatButton>
      </div>
    </Dialog>
  )
}

export default InviteMemberDialog
