import React, { useContext } from 'react'
import { connect } from 'react-redux'

import GuestAvatarBadge from 'shared-components/avatar/GuestAvatarBadge'
import GuestAvatar from 'shared-components/avatar/GuestAvatar'
import { Trans, withTranslation } from 'react-i18next'

import {
  Dialog,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  withStyles
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import moment from 'moment'
import PinResponseBar from 'shared-components/event/pinning/PinResponseBar'
import { appContext } from './App'

const restrictedTo = 7

const styles = theme => ({
  dialogContainer: {
    [theme.breakpoints.up(theme.drawer.showFrom)]: {
      marginLeft: theme.drawer.width
    }
  },
  closeButton: {
    cursor: 'pointer'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2, 1, 1)
  },
  itemText: {
    marginLeft: theme.spacing(2)
  },
  fadeAway: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(0deg, rgba(250, 250, 250, 1) 0%, rgba(250, 250, 250, 0) 100%)'
  },
  restrictedFooter: {
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    margin: theme.spacing(0, -1, -1, -1),
    padding: theme.spacing(2)
  },
  subheader: {
    ...theme.typography.h4,
    fontWeight: 700,
    color: theme.palette.text.primary,
    marginTop: theme.spacing(2)
  },
  pinResponseBarContainer: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  ul: {
    paddingLeft: 0
  },
  listAndBarContainer: {
    padding: theme.spacing(1),
    paddingTop: 0
  }
})

const normalGuestList = (guests, classes, t) => {
  const restricted = guests.length > restrictedTo
  if (restricted) guests = guests.slice(0, restrictedTo)
  const list = (
    <List>
      {guests.map((guest, index) => (
        <ListItem key={guest.id || index}>
          <ListItemAvatar>
            <GuestAvatarBadge guest={guest} size={60} />
          </ListItemAvatar>
          <ListItemText primary={guest.user ? guest.user.name : guest.name} secondary={guest.user && t('bashUser')} className={classes.itemText} />
          {restricted && index === guests.length - 1 &&
            <div className={classes.fadeAway} />}
        </ListItem>
      ))}
    </List>
  )
  return [restricted, list]
}

const listSection = (guests, classes, fadeGuestId, t, subheaderTranslationKey) => {
  if (guests.length === 0) return null
  return (
    <li key={`section-${subheaderTranslationKey}`} className={classes.listSection}>
      <ul className={classes.ul}>
        <ListSubheader className={classes.subheader}>{`${t(subheaderTranslationKey)} (${guests.length})`}</ListSubheader>
        {guests.map((guest) => (
          <ListItem key={guest.id}>
            <ListItemAvatar>
              <GuestAvatar guest={guest} size={40} />
            </ListItemAvatar>
            <ListItemText primary={guest.user ? guest.user.name : guest.name} secondary={guest.user && t('bashUser')} className={classes.itemText} />
            {guest.id === fadeGuestId && <div className={classes.fadeAway} />}
          </ListItem>
        ))}
      </ul>
    </li>
  )
}

const dateOptionResponses = (dateOption, classes, t) => {
  const availableGuests = []
  const maybeGuests = []
  const unavailableGuests = []
  let restricted = false
  let fadeGuestId = -1
  if (dateOption.guestStatuses) {
    for (let i = 0; i < dateOption.guestStatuses.length; i++) {
      const gs = dateOption.guestStatuses[i]
      if (gs.status === 'GOING') availableGuests.push(gs.guest)
      if (gs.status === 'MAYBE') maybeGuests.push(gs.guest)
      if (gs.status === 'CANT') unavailableGuests.push(gs.guest)
      if (i === restrictedTo - 1) {
        restricted = true
        fadeGuestId = gs.guest.id
        break
      }
    }
  }
  const list = (
    <List subheader={<li />}>
      {listSection(availableGuests, classes, fadeGuestId, t, 'available')}
      {listSection(maybeGuests, classes, fadeGuestId, t, 'maybe')}
      {listSection(unavailableGuests, classes, fadeGuestId, t, 'unavailable')}
    </List>
  )
  return [restricted, list]
}

const GuestDialog = ({ classes, guests, open, onClose, t, activeDateOptionId }) => {
  const { event, StoreButtons } = useContext(appContext)
  let restricted = false
  let list = null
  let activeDateOption = null
  if (activeDateOptionId === -1) {
    [restricted, list] = normalGuestList(guests, classes, t)
  } else {
    activeDateOption = event.dateOptions.find(dateoption => dateoption.id === activeDateOptionId)
    const args = dateOptionResponses(activeDateOption, classes, t)
    restricted = args[0]
    list = args[1]
  }
  return (
    <Dialog aria-labelledby='guest-dialog' classes={{ container: classes.dialogContainer }} fullWidth {...{ open, onClose }}>
      <DialogTitle id='guest-dialog-title' className={classes.title} disableTypography>
        <IconButton
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant='h5'>
          {activeDateOption ?
            moment(activeDateOption.date).format('ddd D MMMM · HH:mm') :
            t('guestDialogTitle')}
        </Typography>

      </DialogTitle>

      <div className={classes.listAndBarContainer}>
        {activeDateOption && (
          <div className={classes.pinResponseBarContainer}>
            <PinResponseBar dateOption={activeDateOption} />
          </div>
        )}
        {list}
        {restricted &&
          <div className={classes.restrictedFooter}>
            <Typography variant='subtitle1'>
              <Trans t={t} i18nKey='viewAllRestricted' count={guests.length - restrictedTo} key='viewAllRestricted'>
                <b>{{ count: guests.length - restrictedTo }}</b>
              </Trans>
            </Typography>
            {StoreButtons && <StoreButtons action='RSVP_guest_list' />}
          </div>}
      </div>
    </Dialog>
  )
}

const mapStateToProps = state => ({
  activeDateOptionId: isNaN(state && state.modals && state.modals.activeDateOption) ? -1 : state.modals.activeDateOption
})

export default connect(mapStateToProps)(withStyles(styles)(withTranslation('shared')(GuestDialog)))
