import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { Avatar } from '@material-ui/core'
import { history } from '../../config/store'
import { useDispatch, useSelector } from 'react-redux'
import withStyles from '@material-ui/core/styles/withStyles'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu/Menu'
import { signOut } from '../../model/auth/actions'
import _ from 'underscore'
import OrgSwitchItem from './OrgSwitchItem'
import UserMenu from './UserMenu'
import { useTranslation } from 'react-i18next'

const styles = theme => ({
  root: {
    height: '24px',
    margin: theme.spacing(0, 2, 0, 2),
    cursor: 'pointer',
    display: 'flex'
  },
  avatar: {
    height: '24px',
    width: '24px'
  },
  text: {
    lineHeight: '24px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontWeight: '400',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '100%',
    whiteSpace: 'nowrap'
  },
  personalAvatar: {
    marginLeft: 'auto'
  },
  menuDivider: {
    width: '100%',
    height: '1px',
    backgroundColor: theme.palette.grey.dark,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  menuList: {
    minWidth: '240px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '& li': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    }
  }
})

const SideProfileItem = ({ classes, name, onClick, active, ...other }) => {
  const { t } = useTranslation('common')
  const [menuOpen, setMenuOpen] = useState(null)
  const [userMenuOpen, setUserMenuOpen] = useState(null)
  const user = useSelector(state => state.user.user)
  const avatarVersion = useSelector(state => state.user.avatarVersion)
  const orgUser = useSelector(state => state.user.value.organisation)
  const orgs = useSelector(state => state.user.organisations)
  const dispatch = useDispatch()

  const onSignOutClicked = () => {
    dispatch(signOut())
  }

  const onSettingsClicked = () => {
    history.push('/settings/members')
  }

  const orgAvatarUrl = orgUser && orgUser.avatarUrl
  const userAvatarUrl = user && user.avatarUrl

  return (
    <div className={classes.root}>
      <Avatar onClick={e => setMenuOpen(e.target)} className={classes.avatar} src={`${orgAvatarUrl}?avatarVersion=${avatarVersion}`} />
      {orgUser && <Typography onClick={e => setMenuOpen(e.target)} variant='caption' className={classes.text}>{orgUser.name}</Typography>}
      <UserMenu className={classes.personalAvatar} />
      <Menu
        id='org-menu'
        keepMounted
        anchorEl={menuOpen}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={Boolean(menuOpen)}
        onClose={() => setMenuOpen(null)}
        classes={{
          list: classes.menuList
        }}
      >
        {orgs && _.map(orgs, organisation => {
          return (
            <OrgSwitchItem key={organisation.id} onClose={() => setMenuOpen(false)} org={organisation} />
          )
        })}
        <OrgSwitchItem isCreate onClose={() => setMenuOpen(false)} org={{ organisation: { name: t('nav.sidebar.createOrg') } }} />
        <div className={classes.menuDivider} />
        <MenuItem key='settings' onClick={onSettingsClicked}>
          <Typography variant='body2'>{t('nav.sidebar.orgSettings')}</Typography>
        </MenuItem>
      </Menu>
    </div>
  )
}

export default (withStyles(styles)(SideProfileItem))
