import React from 'react'
import {
  Dialog,
  Typography,
  IconButton,
  withStyles
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

const styles = theme => ({
  dialogContainer: {
    padding: theme.spacing(3)
  },
  backdropRoot: {
    backgroundColor: 'rgba(255, 255, 255, 0.7)'
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: theme.spacing(-1.5),
    marginTop: theme.spacing(-1.5)
  },
  description: {
    marginBottom: theme.spacing(1)
  },
  closeButton: {

  },
  dialogPaper: {
    margin: theme.spacing(2)
  }
})

const SimpleDialog = ({ classes, open, onClose, title, content, onBack, TransitionComponent }) => {
  return (
    <Dialog
      aria-labelledby='simple-dialog'
      BackdropProps={{
        classes: {
          root: classes.backdropRoot
        }
      }}
      classes={{
        paper: classes.dialogPaper
      }}
      onClose={onClose}
      open={open}
      TransitionComponent={TransitionComponent}
    >
      <div className={classes.dialogContainer}>
        <div className={classes.titleContainer}>
          <IconButton aria-label='close' className={classes.closeButton} onClick={onBack || onClose}>
            {onBack ? <ArrowBackIcon /> : <CloseIcon />}
          </IconButton>
          <Typography variant='h5'>
            {title}
          </Typography>
        </div>
        <div className={classes.contentContainer}>
          {content}
        </div>
      </div>
    </Dialog>
  )
}

export default withStyles(styles)(SimpleDialog)
