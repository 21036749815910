import React, { useContext } from 'react'
import { withStyles } from '@material-ui/styles'
import { appContext } from './App'

import EventDetails from 'shared-components/event/EventDetails'
import EventDescription from 'shared-components/event/EventDescription'
import ContentBlock from 'shared-components/event/ContentBlock'

const styles = theme => ({
  frontContainer: {
    width: '100%',
    marginTop: theme.spacing(-4),
    display: 'flex',
    marginBottom: theme.spacing(2)
  },
  blockRoot: {
    marginTop: theme.spacing(-2)
  }
})

const DetailsDescription = ({ event, classes }) => {
  const useEvent = useContext(appContext).event || event
  const noDescription = useEvent.description === undefined || useEvent.description === ''
  const details = (
    <EventDetails
      className={classes.frontContainer}
    />
  )
  if (noDescription) return details
  return (
    <ContentBlock
      key='description'
      className={classes.blockRoot}
    >
      {details}
      <EventDescription description={useEvent.description} />
    </ContentBlock>
  )
}

export default withStyles(styles)(DetailsDescription)
