import React from 'react'
import { connect } from 'react-redux'

import GuestAvatar from 'shared-components/avatar/GuestAvatar'
import { withTranslation } from 'react-i18next'

import {
  Dialog,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  withStyles
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'

const styles = theme => ({
  dialogContainer: {
    [theme.breakpoints.up(theme.drawer.showFrom)]: {
      marginLeft: theme.drawer.width
    }
  },
  closeButton: {
    cursor: 'pointer'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2, 1, 1)
  },
  itemText: {
    marginLeft: theme.spacing(2)
  },
  fadeAway: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(0deg, rgba(250, 250, 250, 1) 0%, rgba(250, 250, 250, 0) 100%)'
  },
  restrictedFooter: {
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    margin: theme.spacing(0, -1, -1, -1),
    padding: theme.spacing(2)
  },
  subheader: {
    ...theme.typography.h6,
    fontWeight: 700,
    color: theme.palette.text.primary,
    marginTop: theme.spacing(2)
  },
  pinResponseBarContainer: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  ul: {
    paddingLeft: 0
  },
  listAndBarContainer: {
    padding: theme.spacing(1),
    paddingTop: 0
  },
  guestsLoading: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  guestsLoadingProgress: {
    marginLeft: 'auto',
    marginRight: 'auto'
  }
})

const listSection = (guests, classes, subheaderTranslationKey, eventCode, t) => {
  if (guests.length === 0) return null
  return (
    <li key={`section-${subheaderTranslationKey}`} className={classes.listSection}>
      <ul className={classes.ul}>
        <ListSubheader disableSticky className={classes.subheader}>{`${t(subheaderTranslationKey)} (${guests.length})`}</ListSubheader>
        {guests.map((guest) => (
          <ListItem key={guest.id}>
            <ListItemAvatar>
              <GuestAvatar guest={guest} src={`/code/events${guest.user ? guest.user.avatarUrl : guest.avatarUrl}?eventCode=${eventCode}`} size={40} />
            </ListItemAvatar>
            <ListItemText primary={guest.user ? guest.user.name : guest.name} secondary={guest.user && t('bashUser')} className={classes.itemText} />
          </ListItem>
        ))}
      </ul>
    </li>
  )
}

const ReachedDialog = ({ classes, reachedAndMissedId, reachedAndMissed, open, onClose, eventCode, t }) => {
  // const list = normalGuestList(guests, eventType, classes, t, eventCode)
  return (
    <Dialog aria-labelledby='guest-dialog' classes={{ container: classes.dialogContainer }} fullWidth open={open} onClose={onClose}>
      <DialogTitle id='guest-dialog-title' className={classes.title} disableTypography>
        <IconButton
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant='h5'>
          Reached guests
        </Typography>

      </DialogTitle>

      <div className={classes.listAndBarContainer}>
        {(!reachedAndMissedId || !reachedAndMissed || !reachedAndMissed[reachedAndMissedId]) && <div className={classes.guestsLoading}><CircularProgress className={classes.guestsLoadingProgress} size={24} color='primary' /></div>}
        {reachedAndMissedId && reachedAndMissed && reachedAndMissed[reachedAndMissedId] && (
          <List subheader={<li />}>
            {listSection(reachedAndMissed[reachedAndMissedId].reached, classes, 'reached', eventCode, t)}
            {listSection(reachedAndMissed[reachedAndMissedId].missed, classes, 'missed', eventCode, t)}
          </List>)}
      </div>
    </Dialog>
  )
}

const mapDispatchToProps = {
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(withTranslation('common')(ReachedDialog)))
