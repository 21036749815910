import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import React, { useContext, useRef } from 'react'
import { eventContext } from '../../routes/Event2'
import StateBadge from '../StateBadge'
import Tooltip from '@material-ui/core/Tooltip'
import LinkIcon from '@material-ui/icons/Link'
import Paper from '@material-ui/core/Paper'
import mixpanel from '../../util/mixpanel'
import { copyText } from '@bash/shared-components/utils/copy'
import { useDispatch } from 'react-redux'
import { setSnackbar } from '../../model/notifications/actions'
import { useTranslation } from 'react-i18next'

const styles = theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  },
  flexHorizontal: {
    display: 'flex'
  },
  unpublished: {
    marginLeft: 'auto'
  },
  copyLinkContainer: {
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  copyLink: {
    marginLeft: theme.spacing(1),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  mTop1: {
    marginTop: theme.spacing(1)
  },
  mTopHalf: {
    marginTop: theme.spacing(0.5)
  },
  mTop2: {
    marginTop: theme.spacing(2)
  },
  cursorDefault: {
    cursor: 'default'
  },
  linkInactiveText: {
    marginTop: theme.spacing(1),
    color: theme.palette.tertiary.main
  }
})

const EventInviteBlock = ({ classes }) => {
  const { t } = useTranslation('common')
  const { event, user } = useContext(eventContext)
  const copyTextAreaRef = useRef()
  const dispatch = useDispatch()
  const eventLink = event && `${process.env.REACT_APP_WEBSITE}/e/${event.code}`
  const eventString = event.state === 'NEW' ? t('event.view.invite.unpublished') : (event.state === 'CANCELED' ? t('event.view.invite.cancelled') : eventLink)

  const onCopyClicked = () => {
    mixpanel.trackOrgEvent('event.Copy Bash Link', event)
    copyText(eventLink, copyTextAreaRef, (...callProps) => dispatch(setSnackbar(...callProps)), t)
  }

  return (
    <Paper className={classes.root} elevation={2}>
      <div className={classes.flexHorizontal}>
        <Typography variant='subtitle1'>{t('event.view.invite.title')}</Typography>
        {event.state !== 'ACTIVE' && <StateBadge className={classes.unpublished} text={event.state === 'NEW' ? t('event.view.invite.unpublished') : t('event.view.invite.cancelled')} />}
      </div>
      <div className={classNames(classes.copyLinkContainer, event.state !== 'ACTIVE' && classes.cursorDefault, event.state === 'ACTIVE' && classes.mTop1)} onClick={(event.state === 'ACTIVE') ? onCopyClicked : undefined}>
        <LinkIcon color={(event.state === 'ACTIVE') ? 'primary' : 'disabled'} />
        <Typography className={classes.copyLink} color={(event.state === 'ACTIVE') ? 'inherit' : 'textSecondary'} variant='caption'>{eventString}</Typography>
      </div>
      {event.state !== 'CANCELED' && <Typography className={classes.linkInactiveText} variant='caption'>{event.state === 'ACTIVE' ? '' : t('event.view.invite.notPublishedTooltip')}</Typography>}
      <Typography color={event.state === 'ACTIVE' ? 'inherit' : 'textSecondary'} className={classNames(classes.mTop1)} variant='caption'>{t('event.view.invite.subtitle')}</Typography>
      <textarea readOnly ref={copyTextAreaRef} hidden value={eventLink} />
    </Paper>
  )
}

export default (withStyles(styles)(EventInviteBlock))
