import React, { useEffect, useState, useCallback, useContext } from 'react'
import { backToForm, signIn, signIn3rdParty, signInGoogle, verifyToken } from '../model/auth/actions'
import { makeStyles, SvgIcon, withStyles } from '@material-ui/core'
import { connect, useDispatch } from 'react-redux'
import { ReactComponent as Logo } from '../media/logo/icon.svg'
import Typography from '@material-ui/core/Typography'
import FlatButton from 'shared-components/buttons/FlatButton'
import mixpanel from '../util/mixpanel'
import moment from 'moment'
import bg2x from '../images/2x_Collage.png'
import bg1x from '../images/1x_Collage.png'
import TextInput from '../components/TextInput'
import Info from '@material-ui/icons/Info'
import { setSnackbar } from '../model/notifications/actions'
import classNames from 'classnames'
import Hidden from '@material-ui/core/Hidden'
import { Trans, useTranslation } from 'react-i18next'
import GoogleLogin from 'react-google-login'
import { mdiGoogle } from '@mdi/js'
import { Apple, Mail } from '@material-ui/icons'
import AppleSignin from 'react-apple-signin-auth'
import { NavContext } from '../components/navigation/MainContainer'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  sideContainer: {
    [theme.breakpoints.up('480')]: {
      position: 'absolute',
      width: '480px'
    },
    background: 'white',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    right: 0,
    flexDirection: 'column',
    padding: theme.spacing(6),
    justifyContent: 'center'
  },
  background: {
    height: '100%',
    position: 'absolute',
    right: '480px'
  },
  logo: {
    marginBottom: theme.spacing(2),
    height: 40,
    width: 40,
    flexShrink: 0
  },
  mainItems: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  submit: {
    marginTop: theme.spacing(2),
    borderRadius: '8px',
    width: '100%',
    fontWeight: '600'
  },
  noEmail: {
    backgroundColor: '#F0F0F0'
  },
  startIcon: {
    position: 'absolute',
    left: theme.spacing(1.5)
  },
  caption: {
    color: 'rgba(0, 0, 0, 0.6)',
    marginTop: theme.spacing(2)
  },
  checkEmailText: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    '& span': {
      color: theme.palette.primary.main
    }
  },
  loader: {
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  googleButton: {
    width: '100%',
    borderRadius: theme.spacing(1),
    fontWeight: '600',
    marginTop: theme.spacing(3)
  },
  googleIcon: {
    position: 'absolute',
    left: '14px'
  },
  appleButton: {
    width: '100%',
    borderRadius: theme.spacing(1),
    fontWeight: '600',
    marginTop: theme.spacing(2),
    background: '#000',
    '&:hover': {
      background: 'rgba(0, 0, 0, .6)'
    }
  },
  appleIcon: {
    color: '#fff',
    position: 'absolute',
    left: '14px'
  },
  orDivContainer: {
    display: 'flex',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    alignItems: 'center',
    width: '100%'
  },
  orDiv: {
    height: '1px',
    width: '100%',
    marginLeft: theme.spacing(2),
    background: '#DEDEDE'
  },
  chooseEmailButton: {
    width: '100%',
    borderRadius: theme.spacing(1),
    background: theme.palette.grey.main,
    color: theme.palette.text.primary,
    '&:hover': {
      background: theme.palette.grey.dark
    }
  },
  selectText: {
    textDecoration: 'underline',
    marginTop: theme.spacing(0.5),
    cursor: 'pointer'
  }
}))

const SignIn = ({ location, signIn, verifyToken, requesting, submitting, backToForm, setSnackbar, inEmail }) => {
  const classes = useStyles()
  const { t } = useTranslation('common')
  const { setShowHeader, setShowSidebar, setDefault } = useContext(NavContext)
  const query = new URLSearchParams(location.search)
  const [email, setEmail] = useState('')
  const dispatch = useDispatch()
  const emailParam = query.get('emailAddress')
  const tokenParam = query.get('token')
  const [invalidEmail, setInvalidEmail] = useState(false)
  const [page, setPage] = useState((emailParam && tokenParam) ? 'email' : 'choose')
  const [submittingGoogle, setSubmittingGoogle] = useState(false)
  const [submittingApple, setSubmittingApple] = useState(false)

  // useEffect(() => {
  //   setEmail(emailParam)
  // }, [emailParam])

  useEffect(() => {
    mixpanel.track('Land on Sign up Page')
    setShowSidebar(false)
    setShowHeader(false)
    return setDefault
  }, [setDefault, setShowHeader, setShowSidebar])

  useEffect(() => {
    if (emailParam && tokenParam) {
      verifyToken(emailParam, tokenParam)
    }
    const emailStorage = localStorage.getItem('emailAddress')
    if (emailStorage) {
      setEmail(emailStorage)
      setInvalidEmail(false)
      localStorage.removeItem('emailAddress')
      setPage('email')
    }
  }, [emailParam, verifyToken, tokenParam])

  const onSubmit = (event) => {
    event.preventDefault()
    if (!email) {
      setInvalidEmail(true)
      return
    }
    setInvalidEmail(false)
    mixpanel.identify(mixpanel.get_distinct_id())
    mixpanel.people.set('$email', email)
    mixpanel.people.set('Sign Up Date', moment().toString())
    mixpanel.track('Sign Up', {
      Email: email
    })
    signIn(email).catch(error => {
      console.log(error)
      setSnackbar('error', 'Invalid email address')
      setInvalidEmail(true)
    })
    mixpanel.track('Land on Magic Link Page')
  }

  const onChange = useCallback((event) => {
    if (invalidEmail) setInvalidEmail(false)
    setEmail(event.target.value)
  }, [invalidEmail])

  const onNoEmailClicked = () => {
    backToForm()
  }

  const handleGoogleResponse = (response) => {
    setSubmittingGoogle(true)
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    dispatch(signIn3rdParty(response.tokenId, 'Google', timeZone))
  }

  const handleAppleResponse = (response) => {
    setSubmittingApple(true)
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    dispatch(signIn3rdParty(response.authorization.id_token, 'Apple', timeZone))
  }

  const handleFailure = (response) => {
    console.error(response)
    if (response.error !== 'idpiframe_initialization_failed') { dispatch(setSnackbar('error', t('signIn.error'))) }
  }

  return (
    <div className={classes.root}>
      <Hidden xsDown implementation='css'>
        <img alt='Background' srcSet={`${bg1x} 1x, ${bg2x} 2x`} className={classes.background} />
      </Hidden>
      <div className={classes.sideContainer}>
        <Logo className={classes.logo} />
        <Typography variant='h6' className={classes.halfMarginBottom}>{inEmail ? t('signIn.checkEmail') : t('signIn.title')}</Typography>
        {page === 'choose' && (
          <>
            <GoogleLogin
              clientId='477034137380-75omcujajv63gpnlmggg74ti1bqjrpsh.apps.googleusercontent.com'
              buttonText='Login'
              onSuccess={handleGoogleResponse}
              onFailure={handleFailure}
              cookiePolicy='single_host_origin'
              render={renderProps => (
                <FlatButton
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  className={classes.googleButton}
                  loading={submittingGoogle}
                >
                  <SvgIcon className={classes.googleIcon}><path d={mdiGoogle} /></SvgIcon>
                  {t('signIn.googleSignIn')}
                </FlatButton>
              )}
            />

            <AppleSignin
              authOptions={{
                clientId: 'social.bash.app',
                scope: 'email name',
                redirectURI: process.env.REACT_APP_APPLE_REDIRECT,
                usePopup: true
              }}
              uiType='dark'
              onSuccess={handleAppleResponse}
              onError={handleFailure}
              iconProp={{ style: { marginTop: '10px' } }}
              render={renderProps => (
                <FlatButton
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  className={classes.appleButton}
                  loading={submittingApple}
                >
                  <Apple className={classes.appleIcon} />
                  {t('signIn.appleSignIn')}
                </FlatButton>
              )}
            />

            <div className={classes.orDivContainer}><Typography variant='overline'>{t('signIn.or')}</Typography><div className={classes.orDiv} /> </div>
            <FlatButton onClick={() => setPage('email')} className={classes.chooseEmailButton}><Mail className={classes.googleIcon} color='primary' />{t('signIn.emailSignIn')}</FlatButton>
            <Typography className={classes.caption} variant='caption'>{t('signIn.subText')}</Typography>
          </>
        )}
        {page === 'email' && (
          <>
            {inEmail &&
              <Typography className={classes.checkEmailText} variant='body2'><Trans t={t} i18nKey='signIn.clickLinkEmail' email={email}>Click the link we sent you on <span>{{ email: email }}</span> to compete your sign in.</Trans></Typography>}
            {!inEmail && !submitting && (
              <form noValidate className={classes.mainItems} onSubmit={onSubmit}>
                <TextInput
                  classes={classes}
                  onChange={onChange}
                  value={email}
                  label={t('signIn.emailAddress')}
                  type='email'
                  placeholder='john.doe@gmail.com'
                />

                <FlatButton className={classes.submit} loading={requesting && !invalidEmail} color='primary' type='submit'>{t('signIn.signIn')}</FlatButton>
                <Typography className={classes.caption} variant='caption'>{t('signIn.subText')}</Typography>
                <Typography className={classes.selectText} color='textSecondary' onClick={() => setPage('choose')} variant='caption'>{t('signIn.selectMethod')}</Typography>
              </form>
            )}
            {inEmail && <FlatButton onClick={onNoEmailClicked} className={classNames(classes.submit, classes.noEmail)} color='action'><Info color='primary' className={classes.startIcon} />{t('signIn.noEmail')}</FlatButton>}
            {submitting && (
              <form className={classes.mainItems}>
                <TextInput
                  value={email}
                  label={t('signIn.emailAddress')}
                  type='email'
                  placeholder='john.doe@gmail.com'
                />
                <FlatButton className={classes.submit} loading={submitting} color='primary'>{t('signIn.signingIn')}</FlatButton>
              </form>
            )}
          </>
        )}
      </div>
    </div>

  )
}

const mapStateToProps = ({ auth }) => ({
  requesting: auth.requesting,
  submitting: auth.submitting,
  inEmail: auth.inEmail
})

export default connect(mapStateToProps, { signIn, setSnackbar, verifyToken, backToForm })(SignIn)
