import React from 'react'
import PropTypes from 'prop-types'
import GuestAvatarBadge from './GuestAvatarBadge'
import AccountCheckIcon from '@material-ui/icons/HowToReg'

import { Tooltip, Typography, withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

const styles = theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(0.25),
    width: 60,
    overflow: 'hidden',
    '&:first-of-type': {
      marginLeft: 0
    }
  },
  name: {
    textAlign: 'center'
  },
  subtitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  }
})

const iconStyle = { fontSize: 15, display: 'flex' }

const GuestAvatarName = ({ classes, t, guest, src, userCheck }) => {
  let fullName = ''
  if (guest.user && guest.user.name) {
    fullName = guest.user.name
  } else if (guest.name) {
    fullName = guest.name
  }
  let displayName = ''
  if (guest.user && guest.user.name) {
    const splits = guest.user.name.split(' ')
    if (splits) {
      displayName = splits[0]
    }
  } else if (guest.name) {
    const splits = guest.name.split(' ')
    if (splits) {
      displayName = splits[0]
    }
  }
  let i = 0
  return (
    <div className={classes.root}>
      <GuestAvatarBadge src={src} size={60} {...{ guest }} />
      <div className={classes.subtitleContainer}>
        {userCheck && guest.user && (
          <Tooltip
            enterTouchDelay={0}
            classes={{ tooltip: classes.tooltip }}
            placement='bottom'
            key={++i}
            title={t('bashUser')}
            aria-label={t('bashUser')}
          >
            <div>
              <AccountCheckIcon color='secondary' style={iconStyle} />
            </div>
          </Tooltip>
        )}
        <Tooltip
          enterTouchDelay={0}
          classes={{ tooltip: classes.tooltip }}
          placement='bottom'
          key={guest.id}
          title={fullName}
          aria-label={fullName}
        >
          <div>
            <Typography className={classes.name} variant='caption'>
              {displayName}
            </Typography>
          </div>
        </Tooltip>
      </div>
    </div>
  )
}

GuestAvatarName.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withTranslation('common')(withStyles(styles)(GuestAvatarName))
