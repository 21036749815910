import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import * as serviceWorker from './serviceWorker'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import store, { history } from './config/store'
import { CssBaseline, MuiThemeProvider } from '@material-ui/core'
import theme from './shared-components/theme'
import Global from './components/Global'
import { getMe } from './model/user/actions'
import mixpanel from './util/mixpanel'
import { SplitFactory } from '@splitsoftware/splitio-react'

import 'config/i18n'
import MainContainer from './components/navigation/MainContainer'
import { Route, Switch } from 'react-router'
import { ErrorBoundary } from 'shared-components/bugsnag'
import { IntercomProvider } from 'react-use-intercom'

store.dispatch(getMe())

const splitSdkConfig = {
  core: {
    authorizationKey: process.env.REACT_APP_SPLIT_KEY,
    key: mixpanel.get_distinct_id()
  }
  // impressionListener: {
  //   logImpression: data => {
  //     console.log(data)
  //     if (!data || !data.impression) return
  //     mixpanel.track('$experiment_started', { 'Experiment name': data.impression.feature, 'Variant name': data.impression.treatment })
  //   }
  // }
}

const rules = [
  // if it says it's a webview, let's go with that
  'WebView',
  // iOS webview will be the same as safari but missing "Safari"
  '(iPhone|iPod|iPad)(?!.*Safari)',
  // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
  // Android KitKat to lollipop webview will put {version}.0.0.0
  'Android.*(wv|.0.0.0)',
  // old chrome android webview agent
  'Linux; U; Android'
]

const webviewRegExp = new RegExp('(' + rules.join('|') + ')', 'ig')

const isWebview = (ua) => {
  return !!ua.match(webviewRegExp)
}

const userAgent = window.navigator.userAgent.toLowerCase()
const isBot = (userAgent.includes('bot') || userAgent.includes('web preview') || userAgent.includes('weblight') || userAgent.includes('webpreview'))
const isUiWebview = isWebview(window.navigator.userAgent)

!isBot && !isUiWebview && ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <Global />
          <SplitFactory config={splitSdkConfig}>
            <Switch>
              <Route path='/signIn' />
              <Route path='/profile' />
            </Switch>
            <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID}>
              <MainContainer />
            </IntercomProvider>
          </SplitFactory>
        </MuiThemeProvider>
      </ConnectedRouter>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
