import React, { useState } from 'react'
import {
  Dialog,
  Typography,
  withStyles, Button
} from '@material-ui/core'
import { connect } from 'react-redux'
import TextInput from '../TextInput'
import FlatButton from '@bash/shared-components/buttons/FlatButton'
import classNames from 'classnames'
import { sendFeedback } from '../../model/user/actions'
import { closeModal } from '../../model/modals/actions'
import { useTranslation, withTranslation } from 'react-i18next'

const styles = theme => ({
  dialogContainer: {
    padding: theme.spacing(4)
  },
  dialogPaper: {
    width: '100%',
    maxWidth: theme.breakpoints.values.visual
  },
  backdropRoot: {
    backgroundColor: 'rgba(255, 255, 255, 0.7)'
  },
  marginTopHalf: {
    marginTop: theme.spacing(0.5)
  },
  marginTop1: {
    marginTop: theme.spacing(1)
  },
  marginTop2: {
    marginTop: theme.spacing(2)
  },
  marginTop3: {
    marginTop: theme.spacing(3)
  },
  optionButtons: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridColumnGap: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  optionButton: {
    background: theme.palette.grey.main,
    height: theme.spacing(6),
    borderRadius: theme.spacing(1),
    '&:hover': {
      background: theme.palette.grey.dark
    }
  },
  sendButton: {
    width: '100%',
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(3)
  },
  activeOption: {
    background: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      background: theme.palette.primary.dark
    }
  }
})

const FeedbackDialog = ({ classes, open, closeModal, sending, sendFeedback, TransitionComponent }) => {
  const { t } = useTranslation('common')
  const [message, setMessage] = useState('')
  const [activeOption, setActiveOption] = useState(0)

  const onSendClicked = () => {
    sendFeedback({
      rating: activeOption,
      freeText: message
    })
  }

  return (
    <Dialog
      aria-labelledby='feedback-dialog'
      BackdropProps={{
        classes: {
          root: classes.backdropRoot
        }
      }}
      classes={{
        paper: classes.dialogPaper
      }}
      open={open}
      onClose={() => closeModal('feedback')}
      TransitionComponent={TransitionComponent}
    >
      <div className={classes.dialogContainer}>
        <Typography variant='h6'>{t('dialogs.feedback.title')}</Typography>
        <Typography color='textSecondary' className={classes.marginTopHalf} variant='subtitle2'>{t('dialogs.feedback.subtitle')}</Typography>
        <TextInput className={classes.marginTop2} placeholder={t('dialogs.feedback.placeholder')} multiline value={message} onChange={(e) => setMessage(e.target.value)} rows={6} />
        <Typography className={classes.marginTop3} variant='subtitle1'>{t('dialogs.feedback.disappointed')}</Typography>
        <div className={classes.optionButtons}>
          <Button onClick={() => setActiveOption(3)} className={classNames(classes.optionButton, activeOption === 3 && classes.activeOption)}>{t('dialogs.feedback.very')}</Button>
          <Button onClick={() => setActiveOption(2)} className={classNames(classes.optionButton, activeOption === 2 && classes.activeOption)}>{t('dialogs.feedback.somewhat')}</Button>
          <Button onClick={() => setActiveOption(1)} className={classNames(classes.optionButton, activeOption === 1 && classes.activeOption)}>{t('dialogs.feedback.not')}</Button>
        </div>
        <FlatButton onClick={onSendClicked} loading={sending} disabled={activeOption === 0} color='primary' className={classes.sendButton}>{t('dialogs.feedback.send')}</FlatButton>
      </div>
    </Dialog>
  )
}

const mapStateToProps = ({ modals, user }) => {
  return ({
    open: modals.open.feedback,
    sending: user.sendingFeedback
  })
}

export default connect(mapStateToProps, { sendFeedback, closeModal })(withStyles(styles)(FeedbackDialog))
