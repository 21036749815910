import { withStyles } from '@material-ui/core'
import React, { useState } from 'react'
import TextInput from '../TextInput'
import Typography from '@material-ui/core/Typography'
import FlatButton from '@bash/shared-components/buttons/FlatButton'
import Switch from '@material-ui/core/Switch/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import { useTranslation } from 'react-i18next'

const styles = theme => ({
  root: {

  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3)
  },
  backButton: {
    backgroundColor: theme.palette.grey.main,
    color: 'black',
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    },
    marginRight: theme.spacing(2)
  },
  switch: {
    marginTop: theme.spacing(1)
  },
  subtitle: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(3)
  }
})

// const SwitchScreen = ({ classes, title, switchLabel, subtitle, defaultValue, switchDefaultValue, onSave, onBack, ...other }) => {
//   const { t } = useTranslation('common')
//   const [value, setValue] = useState(defaultValue)
//   const [switchValue, setSwitchValue] = useState(switchDefaultValue)
//
//   return (
//     <div className={classes.root}>
//       <Typography variant='subtitle1'>{title}</Typography>
//       <Typography className={classes.subtitle} variant='caption'>{subtitle}</Typography>
//       <TextInput autoFocus onKeyUp={(e) => { if (e.key === 'Enter') { onSave(value, switchValue); onBack() } }} value={value} onChange={e => setValue(e.target.value)} {...other} />
//       <FormControlLabel disabled={!value} className={classes.switch} control={<Switch checked={switchValue} onChange={(e) => setSwitchValue(e.target.checked)} name='limitNumberOfGuests' color='primary' />} label={<Typography variant='caption'>{switchLabel}</Typography>} />
//       <div className={classes.buttons}>
//         <FlatButton className={classes.backButton} onClick={() => { setValue(''); onBack() }}>{t('cancel')}</FlatButton>
//         <FlatButton onClick={() => { onSave(value, switchValue); onBack() }}>{t('save')}</FlatButton>
//       </div>
//     </div>)
// }

const SwitchScreen = ({ classes, title, switchLabel, subtitle, value, switchValue, onSave, onBack, ...other }) => {
  const { t } = useTranslation('common')
  const [originalValue, setOgValue] = useState(value)
  const [originalSwitchValue, setOgSwitchValue] = useState(switchValue)

  return (
    <div className={classes.root}>
      <Typography variant='subtitle1'>{title}</Typography>
      <Typography className={classes.subtitle} variant='caption'>{subtitle}</Typography>
      <TextInput autoFocus onKeyUp={(e) => { if (e.key === 'Enter') { onSave(value, switchValue); onBack() } }} value={value} onChange={e => onSave(e.target.value, switchValue)} {...other} />
      <FormControlLabel disabled={!value} className={classes.switch} control={<Switch checked={switchValue} onChange={(e) => onSave(value, e.target.checked)} name='limitNumberOfGuests' color='primary' />} label={<Typography variant='caption'>{switchLabel}</Typography>} />
      <div className={classes.buttons}>
        <FlatButton className={classes.backButton} onClick={() => { onSave(originalValue, originalSwitchValue); onBack() }}>{t('cancel')}</FlatButton>
        <FlatButton onClick={() => { onBack() }}>{t('save')}</FlatButton>
      </div>
    </div>)
}

export default (withStyles(styles)(SwitchScreen))
