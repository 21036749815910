import mixpanel from 'mixpanel-browser'
import store from '../config/store'

const countStatus = (guests) => {
  const counts = {
    GOING: 0,
    MAYBE: 0,
    CANT: 0,
    NONE: 0
  }
  if (guests) {
    guests.forEach(guest => counts[guest.status]++)
  }
  return counts
}

const eventProperties = (event) => {
  const counts = countStatus(event.guests)
  let properties = {
    'Bash Name': event.name,
    'Bash Location': event.location || null,
    'Bash Start Date': event.startDate,
    'Bash End Date': event.endDate || null,
    'Bash Status #None': counts.NONE,
    'Bash Status #Going': counts.GOING,
    'Bash Status #Cant': counts.CANT,
    'Bash Status #Maybe': counts.MAYBE,
    'Bash ID': event.id,
    'Bash Event Type': event.type,
    'Bash Covid Measures': event.covidMeasurements,
    'Bash Organiser': event.organisation.name,
    'Bash Expected Attendees': event.expectedNumberOfGuests || null,
    'Bash Limited Number of Guests': event.limitNumberOfGuests,
    'Bash Organisation ID': event.organisation.id,
    'Bash Organisation Name': event.organisation.name,
    'Bash Response Type': event.responseType,
    'Bash Call Link': event.callLink,
    'Bash Visible on Profile': event.visibleOnProfile
  }

  if (event.organisation) {
    properties = {
      ...properties,
      'Bash Organisation ID': event.organisation.id
    }
  } else {
    properties = {
      ...properties,
      'Bash Organiser ID': event.organisers[0].id
    }
  }
  return properties
}

const organisationProperties = (organisation) => ({
  'Org Name': organisation.name,
  'Org Location': organisation.location || null,
  'Org ID': organisation.id,
  'Org Username': organisation.username,
  'Org Following': organisation.following,
  'Org Event Count': organisation.eventCount,
  'Org Follower Count': organisation.followerCount,
  'Org Guest Count': organisation.guestCount,
  'Org Approval Status': organisation.state,
  Platform: 'OrgDash'
})

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: true })
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN_ORG, { debug: true }, 'org_project')

mixpanel.identifyUserId = userId => mixpanel.identify(userId)

mixpanel.addGuestToList = (guestId) => mixpanel.people.union('guests', guestId)

mixpanel.trackOrgEvent = (name, event, properties = {}) => {
  let defaultEventProperties = {}
  try {
    defaultEventProperties = eventProperties(event)
  } catch (e) {}
  mixpanel.track(name, { ...defaultEventProperties, ...properties })
}

mixpanel.trackOrgEventOrgProject = (name, event, properties = {}) => {
  let defaultEventProperties = {}
  try {
    defaultEventProperties = eventProperties(event)
  } catch (e) {}
  mixpanel.org_project.track(name, { ...defaultEventProperties, ...properties })
}

mixpanel.trackOrgDash = (name, properties = {}) => {
  const organisation = store.getState().user.value
  let defaultOrgProperties = {}
  try {
    defaultOrgProperties = organisationProperties(organisation)
  } catch (e) {}
  mixpanel.track(name, { ...defaultOrgProperties, ...properties })
}

mixpanel.trackOrgDashOrgProject = (name, properties = {}) => {
  const organisation = store.getState().user.value
  let defaultOrgProperties = {}
  try {
    defaultOrgProperties = organisationProperties(organisation)
  } catch (e) {}
  mixpanel.org_project.track(name, { ...defaultOrgProperties, ...properties })
}

export default mixpanel
