import { Avatar, useMediaQuery, useTheme } from '@material-ui/core'
import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useDispatch, useSelector } from 'react-redux'
import { signOut } from '../../model/auth/actions'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu/Menu'
import { useTranslation } from 'react-i18next'
import { useIntercom } from 'react-use-intercom'

const useStyles = makeStyles(theme => ({
  personalAvatar: {
    height: '24px',
    width: '24px',
    cursor: 'pointer'
  },
  menuList: {
    minWidth: '240px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '& li': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    }
  },
  userMenuAvatar: {
    width: '24px',
    height: '24px',
    marginRight: theme.spacing(2)
  },
  userMenuItem: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2, 1, 2)
  },
  userEmail: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  menuDivider: {
    width: '100%',
    height: '1px',
    backgroundColor: theme.palette.grey.dark,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}))

const UserMenu = ({ className }) => {
  const { t } = useTranslation('common')
  const { shutdown, boot } = useIntercom()
  const theme = useTheme()
  const notMobile = useMediaQuery(theme.breakpoints.up('md'))
  const classes = useStyles()
  const dispatch = useDispatch()
  const [userMenuOpen, setUserMenuOpen] = useState(null)
  const user = useSelector(state => state.user.user)
  const userAvatarUrl = user && user.avatarUrl

  const onSignOutClicked = () => {
    shutdown()
    dispatch(signOut())
    boot({
      hideDefaultLauncher: !notMobile
    })
  }

  return (
    <div className={className}>
      <Avatar onClick={e => setUserMenuOpen(e.target)} className={classes.personalAvatar} src={`${userAvatarUrl}`} />
      <Menu
        id='user-menu'
        keepMounted
        anchorEl={userMenuOpen}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={Boolean(userMenuOpen)}
        onClose={() => setUserMenuOpen(null)}
        classes={{
          list: classes.menuList
        }}
      >

        <div className={classes.userMenuItem}>
          <Avatar className={classes.userMenuAvatar} src={`${userAvatarUrl}`} />
          <Typography variant='body2'>{user && user.name}</Typography>
        </div>
        <Typography className={classes.userEmail} variant='caption' color='textSecondary'>{user && user.emailAddress}</Typography>
        <div className={classes.menuDivider} />
        <MenuItem key='sign-out' onClick={onSignOutClicked}>
          <Typography variant='body2'>{t('nav.sidebar.signOut')}</Typography>
        </MenuItem>
      </Menu>
    </div>
  )
}

export default UserMenu
