import React from 'react'
import { withStyles, Paper } from '@material-ui/core'
import classNames from 'classnames'

import { DefaultErrorBoundary } from 'shared-components/bugsnag'

const styles = theme => ({
  root: {
    borderRadius: theme.spacing(1),
    position: 'relative',
    transition: 'all 100ms',
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2)
  }
})

const ContentBlock = ({ classes, children, className, forwardRef, style, display = true }) => {
  if (!display) return null
  return (
    <Paper ref={forwardRef} className={classNames(classes.root, className)} style={style} elevation={2}>
      <DefaultErrorBoundary>
        {children}
      </DefaultErrorBoundary>
    </Paper>
  )
}

export default withStyles(styles)(ContentBlock)
