import React from 'react'
import { withStyles } from '@material-ui/styles'
import { Trans } from 'react-i18next'
import { Typography } from '@material-ui/core'

const styles = theme => ({
  organiserName: {
    fontWeight: 700,
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
})

const OrganiserInviteCopy = ({ classes, organiser, LinkComponent = 'span' }) => {
  return (
    <Typography variant='subtitle1'>
      <Trans i18nKey='shared:isOrganiser' organiser={organiser.name}>
        {React.createElement(LinkComponent, { to: `/${organiser.username}`, className: classes.organiserName }, { organiser: organiser.name })} is the organiser
      </Trans>
    </Typography>
  )
}

export default withStyles(styles)(OrganiserInviteCopy)
