import typeToReducer from 'type-to-reducer'
import _ from 'underscore'
import {
  CHECK_USERNAME_AVAILABLE,
  FETCH_ME,
  RECEIVE_ME,
  RECEIVE_MY_STATS,
  UPLOAD_AVATAR_SUCCEEDED,
  UPLOAD_AVATAR,
  USERNAME_AVAILABLE,
  USERNAME_EXISTS,
  CHANGE_EDITING_USER,
  RECEIVE_FOLLOWERS,
  SEND_FEEDBACK,
  SENT_FEEDBACK,
  RECEIVE_TICKETFEES,
  START_PAYMENT_AUTH,
  RECEIVE_PAYMENT_AUTH,
  RECEIVE_USERS,
  RECEIVE_INVITES,
  SET_CURRENT_ORG,
  ACCEPT_INVITE,
  REMOVE_ORG_USER,
  RECEIVE_TAG,
  RECEIVE_TAGS,
  CHANGE_USER
} from './types'

const initialState = {
  // value = org info
  // user = user info
  value: { organisation: undefined },
  user: undefined,
  organisations: undefined,
  ticketFees: undefined,
  loading: true,
  avatarVersion: 0,
  uploadingAvatar: false,
  sendingFeedback: false,
  authorising: false,
  paymentMethods: [],
  users: undefined,
  username: {
    checkingAvailability: false,
    available: ''
  },
  followers: undefined,
  editingUser: {
    name: '',
    username: '',
    location: '',
    description: ''
  }
}

export default typeToReducer({
  [RECEIVE_ME]: (state, { org, user, organisations }) => ({
    ...state,
    value: {
      ...state.value,
      ...org
    },
    user: {
      ...state.user,
      ...user
    },
    organisations: {
      ...state.organisations,
      ...organisations
    },
    loading: false,
    username: {
      ...state.username,
      available: ''
    },
    editingUser: org ? {
      ...org.organisation
    } : {
      name: '',
      username: '',
      location: '',
      description: ''
    }
  }),
  [REMOVE_ORG_USER]: (state, { orgUserId }) => ({
    ...state,
    organisations: _.filter(state.organisations, orgUser => orgUser.id !== orgUserId)
  }),
  [SET_CURRENT_ORG]: (state, { organisationId }) => {
    const organisation = _.find(state.organisations, org => org.organisation.id === organisationId)
    return {
      ...state,
      value: organisation,
      paymentMethods: [],
      users: undefined,
      followers: undefined,
      editingUser: {
        name: organisation.organisation.name,
        username: organisation.organisation.username,
        location: organisation.organisation.location,
        description: organisation.organisation.description
      },
      username: {
        checkingAvailability: false,
        available: ''
      }
    }
  },
  [CHANGE_EDITING_USER]: (state, { key, value }) => ({
    ...state,
    editingUser: {
      ...state.editingUser,
      [key]: value
    }
  }),
  [FETCH_ME]: state => ({
    ...state,
    loading: true
  }),
  [SEND_FEEDBACK]: state => ({
    ...state,
    sendingFeedback: true
  }),
  [SENT_FEEDBACK]: state => ({
    ...state,
    sendingFeedback: false
  }),
  [RECEIVE_MY_STATS]: (state, { stats }) => ({
    ...state,
    value: {
      ...state.value,
      organisation: {
        ...state.value.organisation,
        ...stats
      }
    }
  }),
  [RECEIVE_FOLLOWERS]: (state, { followers }) => ({
    ...state,
    followers: followers
  }),
  [RECEIVE_USERS]: (state, { users }) => ({
    ...state,
    users: users
  }),
  [CHANGE_USER]: (state, { user }) => ({
    ...state,
    users: _.map(state.users, stateUser => stateUser.id === user.id ? {
      ...stateUser,
      ...user
    } : stateUser)
  }),
  [RECEIVE_TAG]: (state, { tag }) => {
    const authorizedTags = state.value.organisation.authorizedTags ? [...state.value.organisation.authorizedTags, tag] : [tag]
    return {
      ...state,
      value: {
        ...state.value,
        organisation: {
          ...state.value.organisation,
          authorizedTags: authorizedTags
        }
      }
    }
  },
  [RECEIVE_TAGS]: (state, { tags }) => ({
    ...state,
    value: {
      ...state.value,
      organisation: {
        ...state.value.organisation,
        tags: tags
      }
    }
  }),
  [RECEIVE_INVITES]: (state, { users }) => ({
    ...state,
    users: state.users ? state.users.concat(users) : users
  }),
  [RECEIVE_TICKETFEES]: (state, { ticketFees }) => ({
    ...state,
    ticketFees: ticketFees
  }),
  [START_PAYMENT_AUTH]: state => ({
    ...state,
    authorising: true
  }),
  [RECEIVE_PAYMENT_AUTH]: (state, { paymentMethods }) => ({
    ...state,
    authorising: false,
    paymentMethods: paymentMethods
  }),
  [UPLOAD_AVATAR]: state => ({
    ...state,
    uploadingAvatar: true
  }),
  [UPLOAD_AVATAR_SUCCEEDED]: state => ({
    ...state,
    avatarVersion: ++state.avatarVersion,
    uploadingAvatar: false
  }),
  [ACCEPT_INVITE]: (state, { org }) => ({
    ...state,
    value: org,
    organisations: _.map(state.organisations, organisation => organisation.id === org.id ? org : organisation)
  }),
  [CHECK_USERNAME_AVAILABLE]: state => ({
    ...state,
    username: {
      ...state.username,
      checkingAvailability: true,
      available: ''
    }
  }),
  [USERNAME_AVAILABLE]: (state, username) => ({
    ...state,
    username: {
      ...state.username,
      checkingAvailability: false,
      available: username
    }
  }),
  [USERNAME_EXISTS]: (state, username) => ({
    ...state,
    username: {
      ...state.username,
      checkingAvailability: false,
      available: ''
    }
  })
}, initialState)
