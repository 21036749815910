import React from 'react'
import { withStyles } from '@material-ui/styles'
import { withTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    // '& > :not(:last-child)': {
    //   marginRight: theme.spacing(3)
    // },
    width: '100%',
    minWidth: 170,
    maxWidth: 280
  },
  statContainer: {
    display: 'flex',
    flexDirection: 'column'
  }
})

const kmFormatter = (num) => {
  if (num < 0 || isNaN(num)) return 0
  if (num < 1000) return num
  if (num < 1000000) return (num / 1000).toFixed(1) + 'k'
  return (num / 1000000).toFixed(1) + 'M'
}

const StatComponent = ({ number, subtitle, classes }) => (
  <div className={classes.statContainer}>
    <Typography variant='h5'><b>{kmFormatter(number)}</b></Typography>
    <Typography variant='caption' color='textSecondary'>{subtitle}</Typography>
  </div>
)

const stats = [
  {
    statkey: 'eventCount',
    translationKey: 'hosted'
  },
  {
    statkey: 'followerCount',
    translationKey: 'followers'
  },
  {
    statkey: 'guestCount',
    translationKey: 'attendees'
  }
]

const StatsRow = ({ classes, t, organisation }) => {
  return (
    <div className={classes.root}>
      {stats.map(stat => (
        <StatComponent
          key={stat.statkey}
          number={organisation[stat.statkey]}
          subtitle={t(stat.translationKey)}
          classes={classes}
        />
      ))}
    </div>
  )
}

export default withTranslation('common')(withStyles(styles)(StatsRow))
