import React, { useEffect, useState, useCallback, useRef, useContext } from 'react'
import { signIn } from '../model/auth/actions'
import { IconButton, withStyles } from '@material-ui/core'
import { connect, useDispatch } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import FlatButton from 'shared-components/buttons/FlatButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import mixpanel from '../util/mixpanel'
import bg2x from '../images/2x_Collage.png'
import bg1x from '../images/1x_Collage.png'
import TextInput from '../shared-components/common/TextInput'
import {
  changeEditingUser,
  checkUsernameAvailability,
  createOrg,
  getMe,
  patchMe,
  uploadAvatar
} from '../model/user/actions'
import DropZone from '../components/DropZone'
import Avatar from '@material-ui/core/Avatar'
import RestingButton from '../components/RestingButton'
import * as _ from 'underscore'
import Camera from '@material-ui/icons/CameraAlt'
import { slugify } from 'underscore.string'
import Check from '@material-ui/icons/Check'
import Tooltip from '@material-ui/core/Tooltip/Tooltip'
import Cross from '@material-ui/icons/Close'
import Paper from '@material-ui/core/Paper'
import UserMenu from '../components/navigation/UserMenu'
import { useTranslation } from 'react-i18next'
import { ArrowBack } from '@material-ui/icons'
import { history } from '../config/store'
import InputAdornment from '@material-ui/core/InputAdornment'
import { limitFileSize } from '../util/imageResizer'
import Hidden from '@material-ui/core/Hidden'
import { NavContext } from '../components/navigation/MainContainer'

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: '100vh',
    background: '#fff'
  },
  formContainer: {
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: theme.breakpoints.values.container,
    background: 'white',
    right: 0,
    left: 0,
    padding: theme.spacing(4),
    width: '100%',
    [theme.breakpoints.down('480')]: {
      boxShadow: 'none !important'
    }
  },
  background: {
    [theme.breakpoints.down('480')]: {
      display: 'none'
    },
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    objectFit: 'cover'
  },
  logo: {
    marginBottom: theme.spacing(2),
    height: 40,
    width: 40,
    flexShrink: 0
  },
  mainItems: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  submit: {
    marginTop: theme.spacing(4),
    borderRadius: '8px',
    width: '100%',
    fontWeight: '600'
  },
  startIcon: {
    position: 'absolute',
    left: theme.spacing(1.5)
  },
  caption: {
    color: 'rgba(0, 0, 0, 0.6)',
    marginTop: theme.spacing(2)
  },
  avatarTitle: {
    marginTop: theme.spacing(2)
  },
  avatarContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    position: 'relative',
    width: '72px'
  },
  avatar: {
    height: '72px',
    width: '72px'
  },
  dropZone: {
    borderRadius: 1000,
    overflow: 'hidden'
  },
  editAvatar: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: 32,
    height: 32,
    minWidth: 32
  },
  cross: {
    color: theme.palette.red.main
  },
  invisible: {
    opacity: 0
  },
  profileLink: {
    marginTop: theme.spacing(2)
  },
  userMenu: {
    marginLeft: 'auto'
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  backButton: {
    marginLeft: theme.spacing(-1.5)
  },
  usernameProgress: {
    width: '43px !important'
  }
})

const CreateOrg = ({ classes, user, patchMe, loading, checkUsernameAvailability, avatarVersion, uploadAvatar, changeEditingUser, username, editingUser }) => {
  const { t } = useTranslation('common')
  const fileInputRef = useRef(null)
  const [imgFile, setImgFile] = useState(null)
  const [checkedCurrentUsername, setCheckedCurrentUsername] = useState(true)
  const [saving, setSaving] = useState(false)
  const dispatch = useDispatch()
  const { setShowHeader, setShowSidebar, setDefault } = useContext(NavContext)
  const [newOrg, setNewOrg] = useState({
    name: '',
    username: ''
  })

  const onCameraClicked = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const onChange = (key, value) => {
    // changeEditingUser(key, value)
    setNewOrg(prevState => {
      return {
        ...prevState,
        [key]: value
      }
    })
  }

  const uploadImage = async (file) => {
    mixpanel.trackOrgDash('Add Organisation Avatar')
    if (file) {
      const limitedImg = await limitFileSize(file)
      const limitedFile = new File([limitedImg], file.name)
      setImgFile(limitedFile)
    }
  }

  const debouncedCheckUsername = useCallback(_.debounce(username => {
    const slugged = slugify(username)
    checkUsernameAvailability(slugged)
    onChange('username', slugged)
    setCheckedCurrentUsername(true)
  }, 1500), [])

  const onUsernameChange = newUsername => {
    setCheckedCurrentUsername(false)
    if (newOrg.username && newOrg.username.length <= 2 && newUsername.length >= 3) {
      mixpanel.trackOrgDash('Add Organisation Username')
    }
    onChange('username', newUsername)
    debouncedCheckUsername(newUsername)
  }

  const onNameChange = useCallback(newName => {
    if (!newOrg.username || slugify(newOrg.name) === newOrg.username) { onUsernameChange(slugify(newName)) }

    if (newOrg.name && newOrg.name.length <= 2 && newName.length >= 3) {
      mixpanel.trackOrgDash('Add Organisation Name')
    }
    onChange('name', newName)
  })

  useEffect(() => {
    mixpanel.trackOrgDash('Land on Make Profile Page')
    setShowSidebar(false)
    setShowHeader(false)
    return setDefault
  }, [setDefault, setShowHeader, setShowSidebar])

  useEffect(() => {
    const orgName = localStorage.getItem('organisationName')
    if (orgName && onNameChange) {
      onNameChange(orgName)
      localStorage.removeItem('organisationName')
    }
  }, [onNameChange])

  const onBackClick = () => {
    history.goBack()
  }

  // const usernameAvailable = () => {
  //   return (username.available.username === newOrg.username) && newOrg.username
  //   else return true
  // }

  const usernameAvailable = (username.available.username === newOrg.username) && newOrg.username

  const onSaveClicked = async () => {
    mixpanel.trackOrgDash('Confirm Activation')
    setSaving(true)
    await dispatch(createOrg(newOrg, imgFile))
    setSaving(false)
  }

  // const continueEnabled = newOrg.name && newOrg.username && usernameAvailable
  const continueEnabled = newOrg.name && newOrg.username && imgFile && usernameAvailable

  return (
    <div className={classes.root}>
      <img alt='Background' srcSet={`${bg1x} 1x, ${bg2x} 2x`} className={classes.background} />
      <Paper elevation={24} className={classes.formContainer}>
        <div className={classes.titleContainer}>
          {user && <IconButton onClick={onBackClick} color='inherit' className={classes.backButton}><ArrowBack /></IconButton>}
          <Typography variant='h6'>{t('createOrg.title')}</Typography>
          <UserMenu className={classes.userMenu} />
        </div>
        <div className={classes.mainItems}>
          <Typography className={classes.avatarTitle} variant='subtitle1'>{t('profile.logo')}</Typography>
          <div className={classes.avatarContainer}>
            <DropZone fileInputRef={fileInputRef} onNewFile={uploadImage} overlayClassName={classes.dropZone}>
              <Avatar className={classes.avatar} alt='Profile logo' src={imgFile && URL.createObjectURL(imgFile)} />
            </DropZone>
            <RestingButton onClick={onCameraClicked} className={classes.editAvatar}>
              <Camera color='primary' />
            </RestingButton>
          </div>
          <Typography variant='caption' color='textSecondary'>{t('profile.logoSubtitle')}</Typography>
          <div className={classes.divider} />
          <TextInput label={t('profile.name')} value={newOrg.name} onChange={e => onNameChange(e.target.value)} />
          <TextInput
            endAdornment={
              username.checkingAvailability ? <CircularProgress className={classes.usernameProgress} color='primary' size={24} /> : usernameAvailable ? <Check color='secondary' /> : checkedCurrentUsername ? <Tooltip title={t('profile.usernameTaken')}><Cross className={classes.cross} /></Tooltip> : <CircularProgress className={classes.usernameProgress} color='primary' size={24} />
            }
            startAdornment={<InputAdornment disablePointerEvents style={{ opacity: 0.6 }}>https://bash.social/</InputAdornment>}
            label={t('profile.username')} value={newOrg.username} onChange={e => onUsernameChange(e.target.value)}
            inputProps={{ style: { height: '100%' } }}
          />
          <Tooltip title={imgFile ? '' : t('createOrg.avatarRequired')}>
            <span>
              <FlatButton loading={saving} disabled={!continueEnabled} className={classes.submit} color='primary' onClick={onSaveClicked}>{t('createOrg.createButton')}</FlatButton>
            </span>
          </Tooltip>
        </div>

      </Paper>
    </div>

  )
}

const mapStateToProps = ({ user }) => ({
  loading: user.loading,
  editingUser: user.editingUser,
  user: user.value.organisation,
  avatarVersion: user.avatarVersion,
  username: user.username
})

export default connect(mapStateToProps,
  { signIn, patchMe, getMe, uploadAvatar, changeEditingUser, checkUsernameAvailability })(withStyles(styles)(CreateOrg))
