import React from 'react'
import { withStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Link from '@material-ui/core/Link'
import Tooltip from '@material-ui/core/Tooltip'

const styles = theme => ({
  root: {
    padding: 0,
    margin: 0,
    borderBottom: '1px solid #EBEBEB',
    '&:before': {
      display: 'none'
    }
  },
  expandedSummary: {
    margin: '0 !important',
    minHeight: '56px !important'
  },
  noMargin: {
    margin: '0 !important'
  },
  noPadding: {
    padding: 0
  },
  title: {
    lineHeight: '56px'
  },
  text: {
    padding: theme.spacing(0, 5, 0, 5),
    color: 'rgba(0, 0, 0, .6)'
  },
  details: {
    paddingTop: 0,
    paddingBottom: theme.spacing(2)
  },
  link: {
    cursor: 'pointer',
    fontWeight: '700',
    padding: theme.spacing(0, 7, 0, 7)
  },
  bottomMargin: {
    marginBottom: theme.spacing(2)
  }
})

const OnboardingItem = ({ classes, title, text, link, onClick, icon, disabled, expanded }) => {
  return (
    <Accordion
      className={classes.root}
      square
      classes={{
        expanded: classes.noMargin
      }}
      elevation={0}
      defaultExpanded={expanded}
    >
      <AccordionSummary
        classes={{
          content: classes.noMargin,
          expanded: classes.expandedSummary,
          expandIcon: classes.noMargin
        }}
        className={classes.noPadding}
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        {icon}
        <Typography variant='subtitle2' className={classes.title}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <Typography variant='body2' className={classes.text}>
          {text}
        </Typography>

      </AccordionDetails>
      {link &&
        <div className={classes.bottomMargin}>
          {disabled ?
            <Tooltip title={disabled}>
              <Typography color='textSecondary' variant='button' className={classes.link}>
                {link}
              </Typography>
            </Tooltip> :

            <Link variant='button' className={classes.link} onClick={onClick}>
              {link}
            </Link>}
        </div>}
    </Accordion>
  )
}

export default withStyles(styles)(OnboardingItem)
