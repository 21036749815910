import mixpanel from 'mixpanel-browser'
import store from 'config/store'
import { countStatus } from './guests'

export const unregisterAllOldProperties = () => {
  mixpanel.unregister('Bash Name')
  mixpanel.unregister('Bash Name')
  mixpanel.unregister('Bash Location')
  mixpanel.unregister('Bash Start Date')
  mixpanel.unregister('Bash End Date')
  mixpanel.unregister('Bash Status #None')
  mixpanel.unregister('Bash Status #Going')
  mixpanel.unregister('Bash Status #Cant')
  mixpanel.unregister('Bash Status #Maybe')
  mixpanel.unregister('Bash ID')
  mixpanel.unregister('Bash Event Type')
  mixpanel.unregister('Bash Organisation ID')
  mixpanel.unregister('Bash Organiser ID')
}

const eventProperties = (event) => {
  const counts = countStatus(event.guests)
  let properties = {
    'Bash Name': event.name,
    'Bash Location': event.location || null,
    'Bash Start Date': event.startDate,
    'Bash End Date': event.endDate || null,
    'Bash Status #None': counts.NONE,
    'Bash Status #Going': counts.GOING,
    'Bash Status #Cant': counts.CANT,
    'Bash Status #Maybe': counts.MAYBE,
    'Bash ID': event.id,
    'Bash Event Type': event.type,
    'Bash Response Type': event.responseType
  }
  if (event.organisation) {
    properties = {
      ...properties,
      'Bash Organisation ID': event.organisation.id,
      'Bash Organisation Name': event.organisation.name
    }
  } else {
    properties = {
      ...properties,
      'Bash Organiser ID': event.organisers[0].id
    }
  }
  return properties
}

const organisationProperties = (organisation) => ({
  'Bash Organisation Name': organisation.name,
  'Bash Organisation Location': organisation.location || null,
  'Bash Organisation ID': organisation.id,
  'Bash Organisation Username': organisation.username,
  'Bash Organisation Following': organisation.following,
  'Bash Organisation Event Count': organisation.eventCount,
  'Bash Organisation Follower Count': organisation.followerCount,
  'Bash Organisation Guest Count': organisation.guestCount
})

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: false, api_host: 'https://api-eu.mixpanel.com' })

mixpanel.identifyUserId = userId => mixpanel.identify(userId)

mixpanel.addGuestToList = (guestId) => mixpanel.people.union('guests', guestId)

mixpanel.trackEvent = (name, properties = {}) => {
  const event = store.getState().event.event
  let defaultEventProperties = {}
  try {
    defaultEventProperties = eventProperties(event)
  } catch (e) {}
  mixpanel.track(name, { ...defaultEventProperties, ...properties })
}

mixpanel.trackOrg = (name, properties = {}) => {
  const organisation = store.getState().organisation.organisation
  let defaultOrgProperties = {}
  try {
    defaultOrgProperties = organisationProperties(organisation)
  } catch (e) {}
  mixpanel.track(name, { ...defaultOrgProperties, ...properties })
}

export default mixpanel
