import React, { useContext } from 'react'
import { withStyles } from '@material-ui/styles'
import { withTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'
import GuestCountIcon from '@material-ui/icons/People'
import { appContext } from './App'

const styles = theme => ({
  root: {
    display: 'flex'
  },
  guestCountContainer: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.secondary
  },
  guestCountIcon: {
    marginLeft: theme.spacing(0.5),
    fontSize: '1.3rem'
  },
  guestCountText: {
    marginLeft: theme.spacing(0.5),
    fontWeight: 700
  }
})

const guestCountNumber = (event, t) => {
  if (!event.guestsCanSeeGuests) return t('private')
  if (event.expectedNumberOfGuests) {
    if (event.limitNumberOfGuests) return event.expectedNumberOfGuests
    return `~${event.expectedNumberOfGuests}`
  }
  if (!event.guests) return 0
  if (event.organisation) return event.guests ? event.guests.length : 0
  return event.guests.length + event.organisers.length
}

const GuestCount = (props) => {
  const { classes, t, dontShowBelowTen } = props
  const eventFromContext = useContext(appContext).event
  const event = props.event || eventFromContext
  const count = guestCountNumber(event, t)
  if (dontShowBelowTen && count < 10) return null
  return (
    <div className={classes.guestCountContainer}>
      <Typography variant='caption' className={classes.guestCountText}>
        {count}
      </Typography>
      <GuestCountIcon className={classes.guestCountIcon} />
    </div>
  )
}

export default withTranslation('shared')(withStyles(styles)(GuestCount))
