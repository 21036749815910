import { withStyles } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import TextInput from '../TextInput'
import { connect, useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import FlatButton from '@bash/shared-components/buttons/FlatButton'
import LocationAutoComplete from '../LocationAutoComplete'
import { useTranslation } from 'react-i18next'
import { editEventContext } from '../../routes/EditEvent'

const styles = theme => ({
  root: {

  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3)
  },
  backButton: {
    backgroundColor: theme.palette.grey.main,
    color: 'black',
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    },
    marginRight: theme.spacing(2)
  },
  marginTopHalf: {
    marginTop: theme.spacing(0.5)
  }
})

// const LocationScreen = ({ classes, title, subtitle, defaultValue, onSave, onBack, ...other }) => {
//   const { t } = useTranslation('common')
//   const location = useSelector(state => state.user.value.organisation.location)
//   const firstValue = defaultValue || location
//   const [value, setValue] = useState(firstValue)
//
//   return (
//     <div className={classes.root}>
//       <Typography variant='subtitle1'>{title}</Typography>
//       <Typography className={classes.marginTopHalf} variant='caption'>{subtitle}</Typography>
//       <LocationAutoComplete autoFocus inputValue={value} onKeyUp={(e) => { if (e.key === 'Enter') { onSave(value); onBack() } }} onChange={setValue} {...other} />
//       <div className={classes.buttons}>
//         <FlatButton className={classes.backButton} onClick={() => { setValue(''); onBack() }}>{t('cancel')}</FlatButton>
//         <FlatButton onClick={() => { onSave(value); onBack() }}>{t('save')}</FlatButton>
//       </div>
//     </div>
//   )
// }

const LocationScreen = ({ classes, title, subtitle, onBack }) => {
  const { t } = useTranslation('common')
  const { editingEvent, onChange } = useContext(editEventContext)
  const [originalValue, setOgValue] = useState(editingEvent && editingEvent.location)

  return (
    <div className={classes.root}>
      <Typography variant='subtitle1'>{title}</Typography>
      <Typography className={classes.marginTopHalf} variant='caption'>{subtitle}</Typography>
      <LocationAutoComplete autoFocus inputValue={editingEvent.location} onKeyUp={(e) => { if (e.key === 'Enter') { onBack() } }} onChange={newValue => onChange('location', newValue)} placeholder={editingEvent.location} />
      <div className={classes.buttons}>
        <FlatButton className={classes.backButton} onClick={() => { onChange('location', originalValue); onBack() }}>{t('cancel')}</FlatButton>
        <FlatButton onClick={() => { onBack() }}>{t('save')}</FlatButton>
      </div>
    </div>
  )
}

export default (withStyles(styles)(LocationScreen))
