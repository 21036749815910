import React from 'react'
import { withStyles } from '@material-ui/core'
import ConditionalWrapper from '../common/ConditionalWrapper'
import classNames from 'classnames'

const styles = theme => ({
  root: {
    position: 'relative',
    height: 192
  },
  image: {
    width: '100%',
    height: '100%',
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    backgroundColor: theme.palette.grey.dark,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    objectFit: 'cover'
  },
  roundTopCorners: {
    borderRadius: 8
  }
})

const TitleImage = ({ classes, className, imageUrl, videoUrl, height, roundTopCorners, linkToFullImage, children }) => {
  const imageClasses = classNames(classes.image, { [classes.roundTopCorners]: roundTopCorners })
  return (
    <div
      className={classNames(className, classes.root)}
      style={{ height: height }}
    >
      <ConditionalWrapper
        condition={linkToFullImage}
        wrapper={children => (<a href={imageUrl} target='_blank' rel='noopener noreferrer'>{children}</a>)}
      >
        {!videoUrl && <div className={imageClasses} style={{ backgroundImage: imageUrl ? `url("${imageUrl}")` : 'none' }} />}
        {videoUrl && <video className={imageClasses} src={videoUrl} autoPlay muted loop />}
      </ConditionalWrapper>
      {children}
    </div>
  )
}

export default withStyles(styles)(TitleImage)
