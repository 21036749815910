import {
  CHANGE_DRAFT,
  CHANGE_EVENT,
  RECEIVE_EVENT,
  RECEIVE_REACHED_MISSED,
  RECEIVE_MY_EVENTS,
  RECEIVE_MY_PAST_EVENTS,
  SAVED_EVENT,
  STOP_SAVING,
  UPLOAD_EVENT_IMAGE,
  START_SAVING,
  DELETE_EVENT,
  SET_CURRENT_EVENT,
  TOGGLE_PREVIEW,
  SET_DRAFT
} from './types'
import apiClient from '../../shared-components/utils/ApiClient'
import { history } from '../../config/store'
import _ from 'underscore'
import mixpanel from '../../util/mixpanel'
import { setSnackbar } from '../notifications/actions'
import adam from '../../images/dummyprofiles/adam.png'
import maggie from '../../images/dummyprofiles/maggie.png'
import christie from '../../images/dummyprofiles/christie.png'
import eric from '../../images/dummyprofiles/eric.png'
import anna from '../../images/dummyprofiles/anna.png'
import Resizer from 'react-image-file-resizer'

export const getMyEvents = () => async (dispatch, getState) => {
  const orgId = getState().user.value.organisation.id
  const events = await apiClient.organisationAdmin.event.getMine(orgId)
  dispatch({
    type: RECEIVE_MY_EVENTS,
    events
  })
}

export const getMyPastEvents = () => async (dispatch, getState) => {
  const orgId = getState().user.value.organisation.id
  const events = await apiClient.organisationAdmin.event.getMinePast(orgId)
  dispatch({
    type: RECEIVE_MY_PAST_EVENTS,
    events
  })
}

export const getEvent = eventId => async (dispatch, getState) => {
  const orgId = getState().user.value.organisation.id
  const event = await apiClient.organisationAdmin.event.get(eventId, orgId)
  dispatch({
    type: RECEIVE_EVENT,
    event
  })
}

export const getReachedAndMissed = (eventId, eventUpdateId) => async (dispatch, getState) => {
  const orgId = getState().user.value.organisation.id
  const reachedAndMissed = await apiClient.organisationAdmin.event.getReachedAndMissed(eventId, eventUpdateId, orgId)
  dispatch({
    type: RECEIVE_REACHED_MISSED,
    reachedAndMissed: reachedAndMissed,
    updateId: eventUpdateId,
    eventId: eventId
  })
}

export const deleteEvent = eventId => async (dispatch, getState) => {
  dispatch({
    type: START_SAVING
  })
  try {
    const orgId = getState().user.value.organisation.id
    mixpanel.trackOrgEvent('Delete Bash', { id: eventId })
    await apiClient.organisationAdmin.event.delete(eventId, orgId)
    dispatch({
      type: DELETE_EVENT
    })
    history.push('/')
    dispatch(getMyEvents())
  } catch (error) {
    dispatch(setSnackbar('error', 'Something went wrong: ' + error.message))
    // console.log(error)
    dispatch({
      type: STOP_SAVING
    })
  }
}

export const changeDraft = (key, value) => ({
  type: CHANGE_DRAFT,
  key,
  value
})

export const setDraft = (newDraft) => ({
  type: SET_DRAFT,
  newDraft
})

export const save30Drafts = () => async (dispatch, getState) => {
  const draft = getState().events.draft
  const orgId = getState().user.value.organisation.id
  const links = []
  for (let i = 0; i < 30; i++) {
    const clone = JSON.parse(JSON.stringify(draft))
    clone.state = 'ACTIVE'
    clone.name = 'Event ' + i + ' of 30 with #GetExcited'
    const createdEvent = await apiClient.organisationAdmin.event.saveNew(clone, orgId)
    createdEvent.state = 'ACTIVE'
    const savedEvent = await apiClient.organisationAdmin.event.save(createdEvent, orgId)
    links.push('https://beta.bash.social/e/' + savedEvent.code)
  }
  console.log(links)
}

export const saveDraft = imgFile => async (dispatch, getState) => {
  const draft = getState().events.draft
  dispatch({
    type: START_SAVING
  })
  try {
    const orgId = getState().user.value.organisation.id
    const createdEvent = await apiClient.organisationAdmin.event.saveNew(draft, orgId)
    mixpanel.trackOrgEvent('Save Bash', createdEvent)

    imgFile && await dispatch(uploadEventImage(createdEvent.id, imgFile))
    dispatch({
      type: SAVED_EVENT,
      event: createdEvent
    })
    dispatch({
      type: SET_DRAFT,
      newDraft: {
        name: '',
        startDate: null,
        endDate: null,
        location: '',
        callLink: '',
        description: '',
        covidMeasurements: '',
        responseType: 'INTEREST',
        limitNumberOfGuests: false,
        expectedNumberOfGuests: null,
        guestsCanSeeGuests: true,
        visibleOnProfile: true,
        ticketProducts: [],
        type: 'PUBLIC',
        guests: [
          {
            status: 'GOING',
            name: 'Adam',
            avatarUrl: adam
          },
          {
            status: 'GOING',
            name: 'Maggie',
            avatarUrl: maggie
          },
          {
            status: 'GOING',
            name: 'Christie',
            avatarUrl: christie
          },
          {
            status: 'MAYBE',
            name: 'Eric',
            avatarUrl: eric
          },
          {
            status: 'CANT',
            name: 'Anna',
            avatarUrl: anna
          }
        ],
        imageUrl: ''
      }
    })
    history.replace(`/event/${createdEvent.id}/view`)
  } catch (error) {
    dispatch(setSnackbar('error', 'Something went wrong: ' + error.message))
    // console.log(error)
    dispatch({
      type: STOP_SAVING
    })
  }
}

export const changeEvent = (eventId, key, value) => ({
  type: CHANGE_EVENT,
  eventId,
  key,
  value
})

export const saveEvent = (eventId, imgFile) => async (dispatch, getState) => {
  const event = _.find(getState().events.value, event => event.id === parseInt(eventId))
  dispatch({
    type: START_SAVING
  })
  try {
    const orgId = getState().user.value.organisation.id
    mixpanel.trackOrgEvent('Save Bash', event)
    const savedEvent = await apiClient.organisationAdmin.event.save(event, orgId)
    imgFile && await dispatch(uploadEventImage(eventId, imgFile))
    history.replace(`/event/${savedEvent.id}/view`)
    dispatch({
      type: SAVED_EVENT,
      event: savedEvent
    })
  } catch (error) {
    dispatch(setSnackbar('error', 'Something went wrong: ' + error.message))
    console.log(error)
    console.log(event)
    dispatch({
      type: STOP_SAVING
    })
  }
}

export const setCurrentEvent = (event) => ({
  type: SET_CURRENT_EVENT,
  event
})

export const cancelEvent = (eventId) => async (dispatch, getState) => {
  dispatch(changeEvent(eventId, 'state', 'CANCELED'))
  const orgId = getState().user.value.organisation.id
  const event = _.find(getState().events.value, event => event.id === eventId)
  dispatch({
    type: START_SAVING
  })
  try {
    mixpanel.trackOrgEvent('Cancel Bash', event)
    const savedEvent = await apiClient.organisationAdmin.event.save(event, orgId)
    dispatch({
      type: SAVED_EVENT,
      event: savedEvent
    })
  } catch (error) {
    dispatch(setSnackbar('error', 'Something went wrong: ' + error.message))
    dispatch({
      type: STOP_SAVING
    })
  }
}

export const publishEvent = (eventId) => async (dispatch, getState) => {
  const orgId = getState().user.value.organisation.id
  const event = Object.assign({}, _.find(getState().events.value, event => event.id === eventId))
  event.state = 'ACTIVE'
  dispatch({
    type: START_SAVING
  })
  try {
    mixpanel.trackOrgEventOrgProject('Publish Bash', event, { 'Visible on profile': event.visibleOnProfile })
    const savedEvent = await apiClient.organisationAdmin.event.save(event, orgId)
    dispatch({
      type: SAVED_EVENT,
      event: savedEvent
    })
  } catch (error) {
    dispatch(setSnackbar('error', 'Something went wrong: ' + error.message))
    dispatch({
      type: STOP_SAVING
    })
  }
}

export const announceEventDate = (eventId, startDate, endDate) => async (dispatch, getState) => {
  const orgId = getState().user.value.organisation.id

  dispatch({
    type: START_SAVING
  })
  try {
    mixpanel.trackOrgEventOrgProject('Announce Date Bash')
    const savedEvent = await apiClient.organisationAdmin.event.announceDate(orgId, eventId, startDate, endDate)
    dispatch({
      type: SAVED_EVENT,
      event: savedEvent
    })
  } catch (error) {
    dispatch(setSnackbar('error', 'Something went wrong: ' + error.message))
    dispatch({
      type: STOP_SAVING
    })
  }
}

export const uploadEventImage = (eventId, file) => async (dispatch, getState) => {
  const orgId = getState().user.value.organisation.id
  let uploadUrl
  if (file.type === 'video/mp4') {
    uploadUrl = await apiClient.organisationAdmin.event.getVideoUploadUrl(eventId, file.type, orgId)
  } else {
    uploadUrl = await apiClient.organisationAdmin.event.getUploadUrl(eventId, file.type, orgId)
  }

  // eslint-disable-next-line no-undef
  await fetch(uploadUrl, {
    method: 'PUT',
    header: {
      'Content-Type': file.type
    },
    body: file
  })
  dispatch({
    type: UPLOAD_EVENT_IMAGE,
    eventId
  })
  dispatch(getEvent(eventId))
}

export const togglePreviewType = () => ({
  type: TOGGLE_PREVIEW
})
