import React, { useEffect, useState, useRef, useContext } from 'react'
import { withStyles } from '@material-ui/core'
import { connect, useDispatch, useSelector } from 'react-redux'
import { ReactComponent as Logo } from '../media/logo/icon.svg'
import Typography from '@material-ui/core/Typography'
import FlatButton from 'shared-components/buttons/FlatButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import mixpanel from '../util/mixpanel'
import bg2x from '../images/2x_Collage.png'
import bg1x from '../images/1x_Collage.png'
import TextInput from '../components/TextInput'
import HelpIcon from '@material-ui/icons/HelpOutline'
import {
  patchUser,
  uploadUserAvatar
} from '../model/user/actions'
import DropZone from '../components/DropZone'
import Avatar from '@material-ui/core/Avatar'
import RestingButton from '../components/RestingButton'
import Camera from '@material-ui/icons/CameraAlt'
import Hidden from '@material-ui/core/Hidden'
import classNames from 'classnames'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Resizer from 'react-image-file-resizer'
import { limitFileSize } from '../util/imageResizer'
import { NavContext } from '../components/navigation/MainContainer'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  sideContainer: {
    [theme.breakpoints.up('480')]: {
      position: 'absolute',
      width: '480px'
    },
    background: 'white',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    right: 0,
    flexDirection: 'column',
    padding: theme.spacing(6),
    justifyContent: 'center'
  },
  background: {
    height: '100%',
    position: 'absolute',
    right: '480px'
  },
  logo: {
    marginBottom: theme.spacing(2),
    height: 40,
    width: 40,
    flexShrink: 0
  },
  mainItems: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1)
  },
  submit: {
    marginTop: theme.spacing(2),
    borderRadius: '8px',
    width: '100%',
    fontWeight: '600'
  },
  startIcon: {
    position: 'absolute',
    left: theme.spacing(1.5)
  },
  caption: {
    color: 'rgba(0, 0, 0, 0.6)',
    marginTop: theme.spacing(2)
  },
  avatarTitle: {
    marginTop: theme.spacing(2)
  },
  avatarContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    position: 'relative',
    width: '96px'
  },
  avatar: {
    height: 96,
    width: 96
  },
  dropZone: {
    borderRadius: 1000,
    overflow: 'hidden'
  },
  editAvatar: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: 40,
    height: 40,
    minWidth: 40
  },
  cross: {
    color: theme.palette.red.main
  },
  invisible: {
    opacity: 0
  },
  profileLink: {
    marginTop: theme.spacing(2)
  },
  avatarProgress: {
    position: 'absolute',
    top: 24,
    left: 24,
    zIndex: '10'
  },
  helpIcon: {
    opacity: 0.6,
    marginLeft: theme.spacing(1),
    width: '20px',
    height: '20px'
  },
  doubleInput: {
    display: 'grid',
    gridColumnGap: theme.spacing(2),
    gridTemplateColumns: 'auto auto',
    width: '100%'
  }
}))

const AddProfileInfo = () => {
  const fileInputRef = useRef(null)
  const { t } = useTranslation('common')
  const { setShowHeader, setShowSidebar, setDefault } = useContext(NavContext)

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const fullName = (!firstName || !lastName) ? (firstName || lastName) : firstName + ' ' + lastName

  const dispatch = useDispatch()
  const classes = useStyles()
  const uploadingAvatar = useSelector(state => state.user.uploadingAvatar)
  const avatarVersion = useSelector(state => state.user.avatarVersion)
  const loading = useSelector(state => state.user.loading)
  const avatarUrl = useSelector(state => state.user.user.avatarUrl)

  const onCameraClicked = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  useEffect(() => {
    mixpanel.trackOrgDash('Land on Make Profile Page')
    const storageName = localStorage.getItem('name')
    if (storageName) {
      const [first, last] = storageName.split(' ', 2)
      setFirstName(first)
      setLastName(last)
    }
    setShowSidebar(false)
    setShowHeader(false)
    return setDefault
  }, [setDefault, setShowHeader, setShowSidebar])

  const uploadImage = async (file) => {
    const limitedImg = await limitFileSize(file)
    const limitedFile = new File([limitedImg], file.name)
    dispatch(uploadUserAvatar(limitedFile))
  }

  const onSaveClicked = () => {
    dispatch(patchUser({ name: fullName }))
  }

  return (
    <div className={classes.root}>
      <Hidden xsDown implementation='css'>
        <img alt='Background' srcSet={`${bg1x} 1x, ${bg2x} 2x`} className={classes.background} />
      </Hidden>
      <div className={classes.sideContainer}>
        <Logo className={classes.logo} />
        <Typography variant='h6' style={{ alignItems: 'center', display: 'flex' }}>{t('addProfileInfo.title')}
          <Tooltip title={t('addProfileInfo.titleTooltip')}><HelpIcon className={classes.helpIcon} />
          </Tooltip>
        </Typography>

        <div className={classes.mainItems}>
          <Typography className={classes.avatarTitle} variant='subtitle1'>{t('addProfileInfo.inputs.picture')}</Typography>
          <div className={classes.avatarContainer}>
            <DropZone fileInputRef={fileInputRef} onNewFile={uploadImage} overlayClassName={classes.dropZone}>
              <CircularProgress size={48} className={classNames(!uploadingAvatar && classes.invisible, classes.avatarProgress)} />
              <Avatar className={classes.avatar} src={!uploadingAvatar && `${avatarUrl}?v=${avatarVersion}`} />
            </DropZone>
            <RestingButton onClick={onCameraClicked} className={classes.editAvatar}>
              <Camera color='primary' />
            </RestingButton>
          </div>
          <Typography variant='caption' color='textSecondary'>{t('addProfileInfo.inputs.pictureCaption')}</Typography>
          <div className={classes.divider} />
          <div className={classes.doubleInput}>
            <TextInput label={t('addProfileInfo.inputs.firstName')} value={firstName} onChange={e => setFirstName(e.target.value)} placeholder='John' />
            <TextInput label={t('addProfileInfo.inputs.lastName')} value={lastName} onChange={e => setLastName(e.target.value)} placeholder='Doe' />
          </div>
          <FlatButton loading={loading} disabled={!fullName} className={classes.submit} color='primary' onClick={onSaveClicked}>{t('continue')}</FlatButton>
        </div>

      </div>
    </div>

  )
}

export default (AddProfileInfo)
