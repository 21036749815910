import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import FlatButton from '@bash/shared-components/buttons/FlatButton'
import TeamMemberListItem from '../../components/TeamMemberListItem'
import { openModal } from '../../model/modals/actions'
import LoadingScreen from '../../components/LoadingScreen'
import { getUsers } from '../../model/user/actions'
import _ from 'underscore'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { NavContext } from '../../components/navigation/MainContainer'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: theme.breakpoints.values.events,
    width: '100%',
    height: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(0, 2, 2, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: theme.spacing(7)
  },
  profileTitle: {
    fontWeight: '700',
    marginBottom: theme.spacing(0.5)
  },
  confirmText: {
    marginTop: theme.spacing(3)
  },
  fieldLabel: {
    marginTop: theme.spacing(5)
  },
  fieldSubLabel: {
    marginTop: theme.spacing(0.5)
  },
  marginTop1: {
    marginTop: theme.spacing(1)
  },
  divider: {
    width: '100%',
    height: '1px',
    backgroundColor: '#DDDDDD',
    marginTop: theme.spacing(5)
  },
  addButton: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  }
}))

const Members = () => {
  const classes = useStyles()
  const { t } = useTranslation('common')
  const orgUsers = useSelector(state => state.user.users)
  const dispatch = useDispatch()
  const { setShowHeader, setDefault } = useContext(NavContext)

  useEffect(() => {
    dispatch(getUsers())
    setShowHeader(false)
    return setDefault
  }, [dispatch, setDefault, setShowHeader])

  if (!orgUsers) {
    return (<LoadingScreen />)
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.profileTitle} variant='h5'>{t('settings.members.title')}</Typography>
      <Typography color='textSecondary' variant='subtitle1'>{t('settings.members.subtitle')}</Typography>
      <div className={classes.divider} />
      <FlatButton color='primary' className={classes.addButton} onClick={() => dispatch(openModal('invite'))}>{t('settings.members.add')}</FlatButton>
      {_.map(_.filter(orgUsers, orgUser => orgUser.state !== 'PENDING'), orgUser => {
        return (
          <TeamMemberListItem key={orgUser.id} member={orgUser} />
        )
      })}
      {_.map(_.filter(orgUsers, orgUser => orgUser.state === 'PENDING'), orgUser => {
        return (
          <TeamMemberListItem key={orgUser.id} member={orgUser} />
        )
      })}
    </div>
  )
}

export default (Members)
