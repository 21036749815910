import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import React, { useContext } from 'react'
import { editEventContext } from '../../routes/EditEvent'
import EventField from '../EventField'
import LocationIcon from '@material-ui/icons/LocationOn'
import VideoCam from '@material-ui/icons/Videocam'
import FavoriteIcon from '@material-ui/icons/Favorite'
import PeopleIcon from '@material-ui/icons/People'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import Switch from '@material-ui/core/Switch/Switch'
import ResponseTypeFields from '../ResponseTypeFields'
import DollarIcon from '@material-ui/icons/AttachMoney'
import useTreatment from '../../util/useTreatment'
import GigBanner from './GigBanner'
import { useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'
import { LocalActivity } from '@material-ui/icons'
import Tooltip from '@material-ui/core/Tooltip'
import { useSelector } from 'react-redux'
import { hasGigTag } from '../../selectors/user'

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      paddingTop: `calc(${theme.mixins.toolbar.minHeight}px + ${theme.spacing(7)}px)`
    },
    paddingTop: `calc(${theme.mixins.toolbar.minHeight}px + ${theme.spacing(4)}px)`,
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    overflowY: 'scroll',
    height: 'calc(100% - 80px)',
    borderRadius: 0
  },
  margintop: {
    marginTop: theme.spacing(2)
  },
  margintopHalf: {
    marginTop: theme.spacing(0.5)
  },
  divider: {
    width: '100%',
    height: '1px',
    backgroundColor: '#DDDDDD',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    flexShrink: 0
  }
}))

const WizardPage2 = () => {
  const { t } = useTranslation('common')
  const classes = useStyles()
  const authorizedGig = useSelector(hasGigTag)
  const { editingEvent, onChange, setPage, isGig } = useContext(editEventContext)

  const showTicketingSplit = useTreatment('ticketing') === 'on'

  return (
    <div className={classes.root}>
      <Typography variant='subtitle1'>{t('event.edit.title2')}</Typography>
      <Typography className={classes.margintopHalf} variant='caption'>{t('event.edit.subtitle')}</Typography>
      {isGig && <GigBanner />}
      <EventField checked={editingEvent.location} onClick={() => setPage('location')} name={t('event.edit.location.title')} icon={<LocationIcon color='primary' />} />
      <EventField checked={editingEvent.callLink} onClick={() => setPage('call_link')} name={t('event.edit.callLink.title')} description={t('event.edit.callLink.subtitle')} icon={<VideoCam color='primary' />} />
      <div className={classes.divider} />
      <EventField checked={editingEvent.covidMeasurements} onClick={() => setPage('covid')} name={t('event.edit.covid.title')} description={t('event.edit.covid.subtitle')} icon={<FavoriteIcon color='primary' />} />
      <EventField checked={editingEvent.expectedNumberOfGuests} onClick={() => setPage('expected_number')} name={t('event.edit.numberOfGuests.title')} description={t('event.edit.numberOfGuests.subtitle')} icon={<PeopleIcon color='primary' />} />
      <FormControlLabel disabled={!editingEvent.expectedNumberOfGuests} className={classes.margintop} control={<Switch checked={editingEvent.limitNumberOfGuests} onChange={(e) => onChange('limitNumberOfGuests', e.target.checked)} name='limitNumberOfGuests' color='primary' />} label={<Typography variant='caption'>{t('event.edit.numberOfGuests.switch')}</Typography>} />
      <div className={classes.divider} />
      <Typography variant='subtitle2'>{t('event.edit.responseType.title')}</Typography>
      <ResponseTypeFields value={editingEvent.responseType} onChange={(e) => onChange('responseType', e.target.value)} />

      <div className={classes.divider} />
      <Typography variant='subtitle2'>{t('event.edit.monetise.title')}</Typography>
      {(showTicketingSplit || authorizedGig) &&
        <Tooltip title={editingEvent.ticketLink ? t('event.edit.externalTickets.tooltip') : ''}>
          <EventField restricted={editingEvent.ticketLink} checked={editingEvent.ticketProducts && editingEvent.ticketProducts[0]} onClick={() => setPage('price')} name={t('event.edit.monetise.subtitle')} icon={<DollarIcon color='primary' />} />
        </Tooltip>}
      <Tooltip title={editingEvent.ticketProducts && editingEvent.ticketProducts[0] ? t('event.edit.externalTickets.tooltip') : ''}>
        <EventField restricted={editingEvent.ticketProducts && editingEvent.ticketProducts[0]} checked={editingEvent.ticketLink && editingEvent.ticketPrice} onClick={() => setPage('extTickets')} name={t('event.edit.externalTickets.title')} icon={<LocalActivity color='primary' />} />
      </Tooltip>
      <div className={classes.divider} />
      <Typography variant='subtitle2'>{t('event.edit.hideOnProfile.title')}</Typography>
      <FormControlLabel className={classes.margintop} control={<Switch checked={!editingEvent.visibleOnProfile} onChange={(e) => onChange('visibleOnProfile', !e.target.checked)} name='showOnProfile' color='primary' />} label={<Typography variant='caption'>{t('event.edit.hideOnProfile.subtitle')}</Typography>} />
    </div>)
}

export default WizardPage2
