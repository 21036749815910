import React, { useContext } from 'react'
import { appContext } from '../event/App'

import { withStyles } from '@material-ui/styles'
import { withTranslation } from 'react-i18next'
import OrganisationInfo from './OrganisationInfo'
import { Typography } from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    marginBottom: theme.spacing(1.5)
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2)
  },
  chevronLink: {
    color: 'inherit'
  }
})

const OrganisationCard = ({ t, classes, organisation, location }) => {
  const { LinkComponent } = useContext(appContext)
  return (
    <div className={classes.root}>
      <div className={classes.headerRow}>
        <Typography variant='subtitle1' className={classes.header}>{t('hostedBy')}</Typography>
        {LinkComponent ?
          <LinkComponent to={`/${organisation.username}`} className={classes.chevronLink}>
            <ChevronRightIcon />
          </LinkComponent> : <ChevronRightIcon />}
      </div>
      <OrganisationInfo organisation={organisation} location={location} small hideButtons clickable LinkComponent={LinkComponent} clampDescription />
    </div>
  )
}

export default withTranslation('shared')(withStyles(styles)(OrganisationCard))
