import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation, Trans } from 'react-i18next'
import { Typography } from '@material-ui/core'
import GuestAvatar from 'shared-components/avatar/GuestAvatar'

import { rbContext } from './index'
import { appContext } from '../App'

const useAvatarStyles = makeStyles(theme => ({
  organiserAvatar: {
    marginRight: theme.spacing(2)
  }
}))

const LinkedAvatar = ({ guest, linkOptions }) => {
  const classes = useAvatarStyles()
  const avatar = (
    <GuestAvatar
      guest={guest}
      size={40}
      className={classes.organiserAvatar}
    />
  )
  if (linkOptions && linkOptions.shouldLink) return <linkOptions.component to={linkOptions.linkTo}>{avatar}</linkOptions.component>
  return avatar
}

const useOrgRowStyles = makeStyles(theme => ({
  organiserAndUnderTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2)
  },
  organiserRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: 24
  },
  maxPeopleText: {
    marginTop: theme.spacing(1),
    color: 'rgba(0,0,0,0.6)'
  }
}))

const OrganiserRow = () => {
  const classes = useOrgRowStyles()
  const { t } = useTranslation('shared')
  const { event, spotsLeft } = useContext(appContext)
  const { useAvatarOf, linkOptions, inviteText } = useContext(rbContext)

  return (
    <div className={classes.organiserAndUnderTextContainer}>
      <div className={classes.organiserRow}>
        <LinkedAvatar guest={useAvatarOf} linkOptions={linkOptions} />
        {inviteText}
      </div>
      {event.limitNumberOfGuests &&
      (spotsLeft < 1 ?
        <Typography className={classes.maxPeopleText} variant='caption' color='textSecondary'>
          {t('maxNumberOfPeople')}
        </Typography> :
        <Typography className={classes.maxPeopleText} variant='body2'>
          <Trans t={t} i18nKey='spotsLeft' count={spotsLeft}>
            <b>{{ count: spotsLeft }}</b> spots left
          </Trans>
        </Typography>)}
    </div>
  )
}

export default OrganiserRow
