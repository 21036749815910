import React, { useContext } from 'react'
import { createSelector } from 'reselect'
import classNames from 'classnames'

import ResponseButton from './ResponseButton'

import { withStyles, makeStyles, CircularProgress, Typography } from '@material-ui/core'
import OrganiserRow from './OrganiserRow'
import AddToCalendarButtonBlue from './AddToCalendarButtonBlue'
import { appContext } from '../App'
import { EventShareButton } from 'shared-components/common/ShareButton'

export const rbContext = React.createContext({})

const InterestButtons = () => {
  const { activeResponse, onClickResponse, loading, shouldPulse } = useContext(rbContext)
  const { limitReached } = useContext(appContext)
  return (
    <>
      <ResponseButton
        type='INTEREST' active={activeResponse === 'MAYBE'} activate={() => onClickResponse('MAYBE')}
        disabled={loading}
        unresponsive
      />
      <ResponseButton
        type='GOING' active={activeResponse === 'GOING'} activate={() => onClickResponse('GOING')}
        disabled={loading || limitReached}
        unresponsive
        shouldPulse={shouldPulse}
      />
    </>
  )
}

const DefaultButtons = () => {
  const { activeResponse, onClickResponse, loading, shouldPulse } = useContext(rbContext)
  const { limitReached } = useContext(appContext)
  return (
    <>
      <ResponseButton
        type='CANT'
        active={activeResponse === 'CANT'}
        activate={() => onClickResponse('CANT')}
        disabled={loading}
      />
      <ResponseButton
        type='MAYBE'
        active={activeResponse === 'MAYBE'}
        activate={() => onClickResponse('MAYBE')}
        disabled={loading}
      />
      <ResponseButton
        type='GOING'
        active={activeResponse === 'GOING'}
        activate={() => onClickResponse('GOING')}
        disabled={loading || limitReached}
        shouldPulse={shouldPulse}
      />
    </>
  )
}

const PostResponse = ({ children }) => {
  const { activeResponse, onClickResponse } = useContext(rbContext)
  const { event } = useContext(appContext)

  const isPublicEvent = event.type === 'PUBLIC'

  return (
    <>
      {!isPublicEvent && event.startDate != null &&
        <AddToCalendarButtonBlue />}
      {isPublicEvent && <EventShareButton />}
      <ResponseButton
        type={(activeResponse === 'MAYBE' && event.responseType === 'INTEREST') ? 'INTEREST' : activeResponse}
        active={false}
        activate={onClickResponse}
      />
    </>
  )
}

const usePriceTagStyles = makeStyles(theme => ({
  root: {
    color: '#fff',
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(0.5, 1),
    borderRadius: 4,
    position: 'absolute',
    bottom: -12,
    right: 0
  }
}))

const PriceTag = ({ price, currency }) => {
  const classes = usePriceTagStyles()
  return (
    <div className={classes.root}>
      <Typography variant='caption' color='inherit'>
        {price ? Intl.NumberFormat('nl-NL', { style: 'currency', currency: currency }).format(price) : 'TICKET'}
      </Typography>
    </div>
  )
}

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  interestContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  interestButtonsContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    '& > button:not(:last-of-type)': {
      marginRight: theme.spacing(2)
    }
  },
  spinner: {
    position: 'absolute',
    top: theme.spacing(1),
    left: 0
  },
  extraMargin: {
    marginBottom: theme.spacing(0.5)
  }
})

const getTicketProducts = event => event.ticketProducts

const getLowestWeightTicketProduct = createSelector(
  [getTicketProducts],
  (ticketProducts) => (ticketProducts != null && ticketProducts.length > 0) ? ticketProducts.reduce(
    (acc, loc) => acc.weight < loc.weight ? acc : loc
  ) : undefined
)

const ResponseBar = ({
  classes,
  showPostResponse,
  loading,
  activeResponse,
  onClickResponse,
  shouldPulse = true,
  useAvatarOf,
  linkOptions,
  inviteText,
  SelectDate
}) => {
  const rb = {
    activeResponse,
    loading,
    onClickResponse,
    shouldPulse,
    useAvatarOf,
    linkOptions,
    inviteText
  }

  const { event } = useContext(appContext)
  const ticketProduct = getLowestWeightTicketProduct(event)
  const hasTicket = ticketProduct || event.ticketLink
  const currency = event.ticketCurrency || (ticketProduct && ticketProduct.currency)
  const price = event.ticketPrice || (ticketProduct && ticketProduct.price)

  return (
    <rbContext.Provider value={rb}>
      <div className={classes.root}>
        <div className={classNames(classes.interestContainer, { [classes.extraMargin]: hasTicket })}>
          <OrganiserRow />
          {SelectDate ||
            <div className={classes.interestButtonsContainer}>
              {showPostResponse ? <PostResponse /> :
                event.responseType === 'INTEREST' ?
                  <InterestButtons /> :
                  <DefaultButtons />}
              {hasTicket && !showPostResponse && <PriceTag currency={currency} price={price} />}
              {loading && <CircularProgress className={classes.spinner} size={32} />}
            </div>}
        </div>
      </div>
    </rbContext.Provider>
  )
}

export default withStyles(styles)(ResponseBar)
