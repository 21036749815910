import React, { useContext } from 'react'
import { appContext } from './App'
import { withStyles } from '@material-ui/styles'
import { Fab } from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

const styles = theme => ({
  button: {
    color: theme.palette.red.main,
    backgroundColor: theme.palette.red.lightest,
    textTransform: 'none',
    paddingRight: '12px !important',
    '&:hover': {
      backgroundColor: '#ffcccc'
    },
    minWidth: 'fit-content !important'
  },
  extendedIcon: {
    marginRight: 8,
    width: 18,
    height: 18
  },
  fabRoot: {
    boxShadow: theme.shadows[0],
    '&:active': {
      boxShadow: theme.shadows[0]
    }
  }
})

const CovidBadge = ({ classes, t }) => {
  const { event, openCovidDialog } = useContext(appContext)
  if (!event.covidMeasurements) return null
  return (
    <Fab
      variant='extended'
      classes={{
        root: classes.fabRoot
      }}
      className={classes.button}
      size='small'
      onClick={openCovidDialog}
    >
      <HelpOutlineIcon className={classes.extendedIcon} />
      Covid
    </Fab>
  )
}

export default withStyles(styles)(CovidBadge)
