import React from 'react'
import { withStyles } from '@material-ui/styles'
import { Paper, Typography } from '@material-ui/core'
import moment from 'moment'
import TitleImage from '@bash/shared-components/event/TitleImage'
import ResponseCounter from '@bash/shared-components/event/ResponseCounter'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

const styles = theme => ({
  root: {
    display: 'flex',
    padding: theme.spacing(2),
    width: '100%',
    maxWidth: theme.breakpoints.events,
    boxShadow: theme.shadows[2],
    cursor: 'pointer'
  },
  name: {
    marginBottom: theme.spacing(1)
  },
  date: {
    color: theme.palette.red.dark,
    letterSpacing: '0.25px'
  },
  location: {
    marginTop: theme.spacing(1),
    color: 'rgba(0, 0, 0, .6)',
    letterSpacing: '0.25px'
  },
  smallTitleImage: {
    height: 96,
    width: 113,
    marginLeft: 'auto',
    flexShrink: 0
  },
  videoThumbnail: {
    height: 96,
    width: 113,
    marginLeft: 'auto',
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    background: theme.palette.grey.main
  },
  responsesContainer: {
    display: 'flex',
    marginTop: 'auto'
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  ellipsize: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    marginRight: theme.spacing(1)
  }
})

const EventCard = ({ classes, event, onClick, menuContent }) => {
  const { t } = useTranslation('common')
  const statusCounts = guests => {
    var counts = {
      GOING: 0,
      MAYBE: 0,
      CANT: 0,
      NONE: 0
    }
    if (guests) {
      guests.forEach(guest => counts[guest.status]++)
    }
    return counts
  }

  const guestCounts = statusCounts(event.guests)

  return (
    <Paper
      className={classes.root}
      onClick={onClick}
    >
      <div className={classes.infoContainer}>
        <Typography className={classNames(classes.name, classes.ellipsize)} variant='subtitle1'>{event.name}</Typography>
        <Typography className={classNames(classes.date, classes.ellipsize)} variant='caption'>{event.dateToBeAnnounced ? t('event.edit.dateTba') : moment(event.startDate).format('dddd DD MMMM HH:mm')}</Typography>
        <Typography className={classNames(classes.location, classes.ellipsize)} variant='body2'>{event.location}</Typography>
        {event.responseType === 'DEFAULT' ?
          <div className={classes.responsesContainer}>
            <ResponseCounter type='GOING' count={guestCounts.GOING} />
            <ResponseCounter type='MAYBE' count={guestCounts.MAYBE} />
            <ResponseCounter type='CANT' count={guestCounts.CANT} />
          </div> :
          <div className={classes.responsesContainer}>
            <ResponseCounter type='GOING' count={guestCounts.GOING} />
            <ResponseCounter type='INTERESTED' count={guestCounts.MAYBE} />
          </div>}
      </div>
      {!event.videoUrl && <TitleImage imageUrl={event.imageUrl} roundTopCorners className={classes.smallTitleImage} />}
      {event.videoUrl && <div className={classes.videoThumbnail}><Typography color='textSecondary' variant='caption'><b>VIDEO</b></Typography></div>}
    </Paper>
  )
}

export default withStyles(styles)(EventCard)
