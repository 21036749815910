import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  SvgIcon,
  Typography,
  withStyles
} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = theme => ({
  image: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: theme.spacing(2),
    color: theme.palette.tertiary.main,
    width: 63,
    height: 57
  },
  title: {
    marginBottom: theme.spacing(1)
  },
  destructive: {
    color: theme.palette.tertiary.main
  },
  progress: {
    marginLeft: theme.spacing(1)
  }
})

const YesNoDialog = ({ classes, open, onClose, onConfirm, title, subtitle, description, cancelText, confirmText, icon, loading, destructive }) => {
  return (
    <Dialog aria-labelledby='confirm-action-dialog' onClose={onClose} open={open}>
      <DialogTitle disableTypography>
        <Typography className={classes.title} variant='h5'>
          {title}
        </Typography>
        {subtitle && (
          <Typography color='textSecondary' variant='subtitle2'>
            {subtitle}
          </Typography>
        )}
      </DialogTitle>
      {(icon || description) &&
        <DialogContent>
          {icon &&
            <SvgIcon className={classes.image}>
              <path d={icon} />
            </SvgIcon>}
          <DialogContentText color='textPrimary' variant='body1'>
            {description}
          </DialogContentText>
        </DialogContent>}
      <DialogActions>
        <Button disabled={loading} onClick={onClose}>
          {cancelText}
        </Button>
        <Button disabled={loading} className={destructive ? classes.destructive : null} onClick={onConfirm} color='primary'>
          {confirmText}
          {loading && <CircularProgress className={classes.progress} size={20} color='inherit' />}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(YesNoDialog)
