import React from 'react'
import PropTypes from 'prop-types'

import EventIcon from '@material-ui/icons/Event'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import CreateIcon from '@material-ui/icons/Create'
import VideoCamIcon from '@material-ui/icons/Videocam'
import { mdiPound } from '@mdi/js'

import classNames from 'classnames'

import { Typography, withStyles, SvgIcon } from '@material-ui/core'

const styles = theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    whiteSpace: 'pre-wrap'
  },
  small: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: 0
  },
  verticalCenter: {
    alignItems: 'center'
  },
  main: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  icon: {
    marginRight: theme.spacing(2),
    width: 20,
    height: 20,
    fill: theme.palette.text.secondary
  },
  smallIcon: {
    fontSize: 12,
    marginRight: theme.spacing(1.5)
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  firstLine: {
    display: 'block'
  },
  smallFirstLine: {
    fontSize: '0.85rem',
    lineHeight: 1.2,
    opacity: 0.7
  },
  secondLine: {
    display: 'block',
    opacity: 0.7
  },
  smallSecondLine: {
    fontSize: '0.75rem',
    lineHeight: 1.2,
    marginTop: theme.spacing(0.5),
    opacity: 0.5
  },
  clickable: {
    cursor: 'pointer'
  }
})

const PoundIcon = props => (
  <SvgIcon {...props}>
    <path d={mdiPound} />
  </SvgIcon>
)

const icon = {
  date: EventIcon,
  location: LocationOnIcon,
  description: CreateIcon,
  link: VideoCamIcon,
  tags: PoundIcon,
  none: null
}

const InfoRow = ({ classes, className, type, text, secondLine, onClick, endAdornment, variant }) => {
  const IconComponent = icon[type]
  let href = ''
  if (type === 'link') {
    href = text
    if (!/^[a-z0-9]+:\/\//.test(href)) href = 'https://' + href
  }

  return (
    <div
      className={classNames(classes.root, {
        [classes.clickable]: onClick,
        [classes.verticalCenter]: (secondLine && variant !== 'small') || (!secondLine && variant === 'small'),
        [classes.small]: variant === 'small'
      }, className)} onClick={onClick}
    >
      {IconComponent != null &&
        <IconComponent className={classNames(classes.icon, { [classes.smallIcon]: variant === 'small' })} />}
      <div className={classes.main}>
        <div className={classes.textContainer}>
          <Typography
            variant='body2' className={classNames(classes.firstLine, {
              [classes.smallFirstLine]: variant === 'small'
            })}
          >
            {type === 'link' ? <a href={href} target='_blank' rel='noopener noreferrer'>{text}</a> : text}
          </Typography>
          {secondLine !== undefined &&
            <Typography
              variant='body2' className={classNames(classes.secondLine, {
                [classes.smallSecondLine]: variant === 'small'
              })}
            >
              {secondLine}
            </Typography>}
        </div>
        {endAdornment}
      </div>
    </div>
  )
}

InfoRow.propTypes = {
  type: PropTypes.oneOf(['date', 'location', 'description', 'link', 'tags', 'none']).isRequired,
  text: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(InfoRow)
