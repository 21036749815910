import React from 'react'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import ArrowBack from '@material-ui/icons/ArrowBack'

const styles = theme => ({
  root: {
    height: '24px',
    margin: theme.spacing(0, 2, 0, 2),
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center'
  },
  text: {
    marginLeft: theme.spacing(2)
  }
})

const BackNavItem = ({ classes, name, onClick, active, user, ...other }) => {
  return (
    <div onClick={onClick} className={classes.root}>
      <ArrowBack />
      <Typography className={classes.text} variant='subtitle1'>{name}</Typography>
    </div>
  )
}

export default (withStyles(styles)(BackNavItem))
