import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { getMe, getMyStats, getMyFollowers, authorizeTag, getTags } from '../model/user/actions'
import { getMyEvents, getMyPastEvents } from '../model/events/actions'
import { usePrevious } from '../shared-components/utils/hooks'
import { useLocation } from 'react-router'
import { history } from '../config/store'

const Global = ({ user, getMe, getMyStats, getMyEvents, getMyPastEvents, getMyFollowers }) => {
  const previousUser = usePrevious(user)
  const location = useLocation()
  const dispatch = useDispatch()
  const query = new URLSearchParams(location.search)
  const tagCode = query.get('tagCode')
  const nameParam = query.get('name')
  const orgNameParam = query.get('organisationName')
  const emailParam = query.get('emailAddress')

  useEffect(() => {
    const deleteFromQuery = (param) => {
      query.delete(param)
      history.replace({
        search: query.toString()
      })
    }

    if (emailParam) {
      localStorage.setItem('emailAddress', emailParam)
      deleteFromQuery('emailAddress')
    }

    if (nameParam) {
      localStorage.setItem('name', nameParam)
      deleteFromQuery('name')
    }

    if (orgNameParam) {
      localStorage.setItem('organisationName', orgNameParam)
      deleteFromQuery('organisationName')
    }

    if (tagCode) {
      localStorage.setItem('tagCode', tagCode)
      deleteFromQuery('tagCode')
    }
    const storageTagCode = localStorage.getItem('tagCode')
    if (storageTagCode && user && user.id) {
      dispatch(authorizeTag(storageTagCode))
      localStorage.removeItem('tagCode')
    }
    if ((!previousUser || previousUser.id !== user.id) && user) {
      getMyStats()
      getMyEvents()
      dispatch(getTags())
      // getMyPastEvents()
      // getMyFollowers(0)
    }
  }, [dispatch, getMyEvents, getMyStats, previousUser, tagCode, user, nameParam, orgNameParam, emailParam, query])
  return null
}

const mapStateToProps = ({ user }) => ({
  user: user.value.organisation
})

export default connect(mapStateToProps, { getMe, getMyStats, getMyEvents, getMyPastEvents, getMyFollowers })(Global)
