import React, { useEffect, useState, useCallback } from 'react'
import { acceptInvite, backToForm, signIn, verifyEmail } from '../model/auth/actions'
import { withStyles } from '@material-ui/core'
import { connect, useDispatch } from 'react-redux'
import { ReactComponent as Logo } from '../media/logo/icon.svg'
import Typography from '@material-ui/core/Typography'
import { useParams } from 'react-router'
import FlatButton from 'shared-components/buttons/FlatButton'
import bg2x from '../images/2x_Collage.png'
import bg1x from '../images/1x_Collage.png'
import TextInput from '../components/TextInput'
import Hidden from '@material-ui/core/Hidden'
import { history } from '../config/store'

const styles = theme => ({
  root: {
    height: '100%'
  },
  sideContainer: {
    [theme.breakpoints.up('480')]: {
      position: 'absolute',
      width: '480px'
    },
    background: 'white',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    right: 0,
    flexDirection: 'column',
    padding: theme.spacing(6),
    justifyContent: 'center'
  },
  background: {
    height: '100%',
    position: 'absolute',
    right: '480px'
  },
  logo: {
    marginBottom: theme.spacing(2),
    height: 56,
    width: 56,
    flexShrink: 0
  },
  mainItems: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  submit: {
    marginTop: theme.spacing(2),
    borderRadius: '8px',
    width: '100%',
    fontWeight: '600'
  },
  noEmail: {
    backgroundColor: '#F0F0F0'
  },
  startIcon: {
    position: 'absolute',
    left: theme.spacing(1.5)
  },
  caption: {
    color: 'rgba(0, 0, 0, 0.6)',
    marginTop: theme.spacing(2)
  },
  checkEmailText: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    '& span': {
      color: theme.palette.primary.main
    }
  },
  loader: {
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto'
  }
})

const AcceptInvite = ({ classes, inEmail }) => {
  const [email, setEmail] = useState('')
  const { email: emailParam, token: tokenParam } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    setEmail(emailParam)
  }, [emailParam])

  useEffect(() => {
    if (emailParam && tokenParam) {
      dispatch(acceptInvite(emailParam, tokenParam))
    } else {
      history.replace('/')
    }
  }, [dispatch, emailParam, tokenParam])

  return (
    <div className={classes.root}>
      <Hidden xsDown implementation='css'>
        <img alt='Background' srcSet={`${bg1x} 1x, ${bg2x} 2x`} className={classes.background} />
      </Hidden>
      <div className={classes.sideContainer}>
        <Logo className={classes.logo} />
        <Typography variant='h6' className={classes.halfMarginBottom}>{inEmail ? 'Check your email' : 'Manage your profile'}</Typography>
        <form className={classes.mainItems}>
          <TextInput
            value={email}
            label='Email address'
            type='email'
            placeholder='john.doe@gmail.com'
          />
          <FlatButton className={classes.submit} loading color='primary'>Accepting invite</FlatButton>
        </form>
      </div>
    </div>
  )
}

export default (withStyles(styles)(AcceptInvite))
