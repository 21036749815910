import Resizer from 'react-image-file-resizer'

export const limitFileSize = (file) => new Promise(resolve => {
  Resizer.imageFileResizer(file, 512, 512, 'PNG', 100, 0,
    uri => {
      resolve(uri)
    },
    'blob'
  )
})
