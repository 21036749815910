import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import React, { useContext } from 'react'
import { eventContext } from '../../routes/Event2'
import Paper from '@material-ui/core/Paper'
import UserListItem from '../UserListItem'
import RestingButton from '../RestingButton'
import ArrowIcon from '@material-ui/icons/ArrowForwardIos'
import { history } from '../../config/store'
import { useTranslation } from 'react-i18next'

const styles = theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  },
  viewAllButton: {
    marginLeft: 'auto',
    paddingLeft: theme.spacing(2.5)
  },
  showAllIcon: {
    marginLeft: theme.spacing(1),
    fontSize: 14
  },
  attendeesSubtitle: {
    marginBottom: theme.spacing(2)
  }
})

const EventInviteBlock = ({ classes }) => {
  const { t } = useTranslation('common')
  const { event } = useContext(eventContext)

  const onViewAllClicked = () => {
    history.push(`/event/${event.id}/view/attendees`)
  }

  if (!event.guests) return null

  return (
    <Paper className={classNames(classes.root)} elevation={2}>
      <Typography className={classes.attendeesSubtitle} variant='subtitle1'>{t('event.view.attendees.title')}</Typography>
      {event.guests.slice(0, 3).map(guest => <UserListItem responseType={event.responseType} key={guest.id} guest={guest} />)}
      <RestingButton onClick={onViewAllClicked} className={classes.viewAllButton}>{t('event.view.attendees.viewAll')}<ArrowIcon className={classes.showAllIcon} color='primary' /></RestingButton>
    </Paper>
  )
}

export default (withStyles(styles)(EventInviteBlock))
