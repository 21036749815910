import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import DropZone from '../DropZone'
import HalfTransparentButton from '../HalfTransparentButton'
import CameraIcon from '@material-ui/icons/CameraAlt'
import TextInput from '@bash/shared-components/common/TextInput'
import DateTimeInput from '../DateTimeInput'
import moment from 'moment'
import React, { useContext, useRef, useState } from 'react'
import { editEventContext } from '../../routes/EditEvent'
import mixpanel from '../../util/mixpanel'
import Resizer from 'react-image-file-resizer'
import InputLabel from '@material-ui/core/InputLabel/InputLabel'
import Switch from '@material-ui/core/Switch/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import { useTranslation, Trans } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      paddingTop: `calc(${theme.mixins.toolbar.minHeight}px + ${theme.spacing(7)}px)`
    },
    paddingTop: `calc(${theme.mixins.toolbar.minHeight}px + ${theme.spacing(4)}px)`,
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    overflowY: 'scroll',
    height: 'calc(100% - 80px)',
    borderRadius: 0
  },
  marginTopHalf: {
    marginTop: theme.spacing(0.5)
  },
  caption: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1)
  },
  inputLabel: {
    color: 'black',
    marginBottom: theme.spacing(1),
    fontSize: '0.875rem',
    fontWeight: '500',
    marginTop: theme.spacing(3)
  }
}))

const WizardFreedomDayPage = () => {
  const classes = useStyles()
  const { editingEvent, onChange, newEvent, imageVersion, imgFile, setImgFile } = useContext(editEventContext)

  const { t } = useTranslation('common')

  return (
    <div className={classes.root}>
      <Typography variant='subtitle1'>{t('event.freedomDay.title')}</Typography>
      <Typography className={classes.caption} color='textSecondary' variant='caption'>
        <Trans i18nKey='freedomDay.subtitle' t={t}>
          These event details are set by <strong>GiG Drinks B.V.</strong> and can’t be changed by you.
        </Trans>
      </Typography>
      <TextInput inputProps={{ readOnly: true }} value={t('event.freedomDay.tba')} label={t('event.freedomDay.dateLabel')} />
      <InputLabel className={classes.inputLabel}>{t('event.freedomDay.monetise')}</InputLabel>
      <FormControlLabel disabled control={<Switch checked name='monetise' color='primary' />} label={<Typography variant='caption'>{t('event.freedomDay.revenue')}</Typography>} />
    </div>)
}

export default WizardFreedomDayPage
