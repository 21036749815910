import React from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core'
import classNames from 'classnames'
import MapIcon from '@material-ui/icons/LocationOn'
import FeedbackIcon from '@material-ui/icons/Feedback'
import ErrorIcon from '@material-ui/icons/Error'
import DescriptionIcon from '@material-ui/icons/ChatBubble'
import EventIcon from '@material-ui/icons/Event'
import ShareIcon from '@material-ui/icons/Share'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import VideoCamIcon from '@material-ui/icons/Videocam'
import { ReactComponent as LogoSvg } from '../../media/logo/icon.svg'
import moment from 'moment'
import { Trans, useTranslation } from 'react-i18next'

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  body: {
    marginTop: theme.spacing(0.5)
  },
  colorTertiary: {
    color: theme.palette.tertiary.main
  },
  topText: {
    opacity: '.6',
    marginBottom: theme.spacing(0.5)
  },
  noNotifsSent: {
    opacity: '.38',
    marginTop: theme.spacing(0.5)
  },
  reachedText: {
    textDecoration: 'underline',
    marginLeft: theme.spacing(1),
    opacity: '.6',
    cursor: 'pointer'
  },
  dateText: {
    opacity: '.38',
    marginLeft: theme.spacing(1.5)
  },
  iconDiv: {
    marginRight: theme.spacing(2)
  },
  blueLine: {
    width: '2px',
    height: '12px',
    backgroundColor: '#000',
    marginLeft: 'auto',
    marginRight: 'auto',
    opacity: '.6'
  },
  disabledBlueLine: {
    width: '2px',
    height: '12px',
    backgroundColor: '#000',
    marginLeft: 'auto',
    marginRight: 'auto',
    opacity: '.3'
  },
  icon: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: '#000',
    opacity: '.6'
  },
  disabledIcon: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    opacity: '.3'
  },
  partyEmoji: {
    fontSize: '24',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  invisible: {
    opacity: '0'
  }
})

const TimelineItem = ({ classes, key, className, type, top, createdAt, reachedCount, missedCount, notified, eventStartDate, reminderId, onClick }) => {
  const { t } = useTranslation('common')
  const upcoming = moment().diff(createdAt) < 0 || (type === 'EVENT_START' && moment().diff(moment(eventStartDate) < 0))
  const showNotifsSent = type !== 'PUBLISHED' && type !== 'CREATED_EVENT' && !upcoming

  // const reminderTimeStamp = eventStartDate && moment.duration(moment(eventStartDate).diff(moment(createdAt))).asHours()
  const reminderTimeStamp = eventStartDate && moment(eventStartDate).from(moment(createdAt)).substring(3)

  const topText = () => {
    switch (type) {
      case 'CREATED_EVENT':
        return t('event.view.timeline.created')
      case 'PUBLISHED':
        return t('event.view.timeline.published')
      case 'LOCATION':
        return t('event.view.timeline.location')
      case 'DESCRIPTION':
        return t('event.view.timeline.description')
      case 'START_DATE':
        return t('event.view.timeline.startDate')
      case 'CALL_LINK':
        return t('event.view.timeline.callLink')
      case 'DATE_ANNOUNCED':
        return t('event.view.timeline.dateAnnounced')
      case 'REMINDER':
        switch (reminderId) {
          case 0:
            return t('event.view.timeline.firstReminder')
          case 'FINAL':
            return t('event.view.timeline.finalReminder')
          default:
            return t('event.view.timeline.reminder') + (reminderId + 1)
        }
      case 'EVENT_START':
        return t('event.view.timeline.start')
      default:
        return ''
    }
  }
  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.iconDiv}>
        <div className={classNames([(upcoming ? classes.disabledBlueLine : classes.blueLine), (top && classes.invisible)])} />
        {type === 'CREATED_EVENT' && <AddCircleIcon className={upcoming ? classes.disabledIcon : classes.icon} style={{ fontSize: 20 }} />}
        {type === 'PUBLISHED' && <ShareIcon className={upcoming ? classes.disabledIcon : classes.icon} style={{ fontSize: 20 }} />}
        {type === 'LOCATION' && <MapIcon className={upcoming ? classes.disabledIcon : classes.icon} style={{ fontSize: 20 }} />}
        {type === 'DESCRIPTION' && <DescriptionIcon className={upcoming ? classes.disabledIcon : classes.icon} style={{ fontSize: 20 }} />}
        {(type === 'START_DATE' || type === 'DATE_ANNOUNCED') && <EventIcon className={upcoming ? classes.disabledIcon : classes.icon} style={{ fontSize: 20 }} />}
        {type === 'REMINDER' && <FeedbackIcon className={upcoming ? classes.disabledIcon : classes.icon} style={{ fontSize: 20 }} />}
        {type === 'CALL_LINK' && <VideoCamIcon className={upcoming ? classes.disabledIcon : classes.icon} style={{ fontSize: 20 }} />}
        {type === 'EVENT_START' && <LogoSvg className={classes.icon} style={{ width: '20px', height: '20px' }} />}
        <div className={classNames([(upcoming ? classes.disabledBlueLine : classes.blueLine), (type === 'EVENT_START' && classes.invisible)])} />
      </div>
      <div>
        <Typography className={classes.topText} variant='body2'>{topText()}</Typography>
        {(!notified && showNotifsSent) && <Typography className={classes.noNotifsSent} variant='caption'>{t('event.view.timeline.noNotifsSent')}</Typography>}
        {(missedCount > 0 && showNotifsSent) &&
          <div className={classes.root}>
            <ErrorIcon style={{ fontSize: 20 }} color='secondary' />
            <Typography onClick={onClick} className={classes.reachedText} variant='caption'><Trans i18nKey='view.timeline.reached' t={t} reached={reachedCount} total={reachedCount + missedCount}>Reached {{ reached: reachedCount }}/{{ total: reachedCount + missedCount }} automatically</Trans></Typography>
          </div>}
      </div>
      <Typography className={classes.dateText} variant='caption'>{type === 'REMINDER' ? <Trans i18nKey='view.timeline.timeBeforeEvent' t={t} rawDate={moment(createdAt).fromNow()} time={reminderTimeStamp}>{{ rawDate: moment(createdAt).fromNow() }} ({{ time: reminderTimeStamp }} before the event)}</Trans> : moment(createdAt).fromNow()}</Typography>
    </div>
  )
}

export default withStyles(styles)(TimelineItem)
