import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import UserListItem from '../components/UserListItem'
import { replaceBreadcrumbs } from '../model/general/actions'
import { setCurrentEvent } from '../model/events/actions'
import _ from 'underscore'
import LoadingScreen from '../components/LoadingScreen'
import classNames from 'classnames'
import mixpanel from '../util/mixpanel'
import { Trans, useTranslation } from 'react-i18next'

const styles = theme => ({
  root: {
    maxWidth: theme.breakpoints.values.events,
    width: '100%',
    height: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(0, 2, 2, 2),
    marginTop: theme.spacing(7),
    display: 'flex',
    flexDirection: 'column'
  },
  profileTitle: {
    fontWeight: '700',
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(5)
  },
  mainSubtitle: {
    marginBottom: theme.spacing(5)
  },
  margintop: {
    marginTop: theme.spacing(1)
  },
  segment: {
    width: '100%',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(5),
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  mTop1: {
    marginTop: theme.spacing(1)
  },
  mTopHalf: {
    marginTop: theme.spacing(0.5)
  },
  mTop2: {
    marginTop: theme.spacing(2)
  },
  attendeesSubtitle: {
    marginBottom: theme.spacing(2)
  }
})

const Attendees = ({ classes, user, event, notFound, loading, replaceBreadcrumbs, setCurrentEvent }) => {
  const { t } = useTranslation('common')

  useEffect(() => {
    event && replaceBreadcrumbs({
      [t('event.breadcrumb')]: { href: '/events' },
      [event.name]: { href: '/event/' + event.id + '/view' },
      [t('event.view.attendees.pageTitle')]: { href: '/event/' + event.id + '/view/attendees' }
    })
    event && mixpanel.trackOrgEvent('event.Check Guestlist', event)

    event && setCurrentEvent(event)
  }, [event, replaceBreadcrumbs, setCurrentEvent, t])

  const sortAttendees = (a, b) => {
    if (!a || !b) return 0
    const nameA = a.name || a.user.name
    const nameB = b.name || b.user.name
    return nameA.localeCompare(nameB)
  }

  if (loading || notFound) return <LoadingScreen />

  return (
    <div className={classes.root}>
      <Typography className={classes.profileTitle} variant='h5'>{t('event.view.attendees.pageTitle')}</Typography>
      <Typography className={classes.mainSubtitle} color='textSecondary' variant='subtitle2'><Trans i18nKey='view.attendees.subtitle' t={t} eventName={event.name}>Who have responded to {{ eventName: event.name }}</Trans></Typography>
      <Typography className={classes.attendeesSubtitle} variant='h6'><b><Trans i18nKey='view.attendees.subSubtitle' t={t} amount={event.guests.length}>All attendees ({{ amount: event.guests.length }})</Trans></b></Typography>
      <Paper className={classNames(classes.segment)} elevation={2}>
        {event.guests.sort(sortAttendees).map(guest => <UserListItem responseType={event.responseType} key={guest.id} guest={guest} />)}
      </Paper>
    </div>
  )
}

const mapStateToProps = ({ user, events, auth }, props) => {
  const eventId = +props.match.params.eventId
  const temp = eventId && _.find(events.value, event => event.id === eventId)
  const event = temp || eventId && _.find(events.pastEvents, event => event.id === eventId)
  const notFound = eventId && !event
  return ({
    user: user.value.organisation,
    notFound,
    event,
    loading: events.loading,
    attendees: event && event.guests
  })
}

export default connect(mapStateToProps, { replaceBreadcrumbs, setCurrentEvent })(withStyles(styles)(Attendees))
