import React, { useEffect, useState } from 'react'
import { CircularProgress, withStyles } from '@material-ui/core'
import { connect, useDispatch } from 'react-redux'
import { setSnackbar } from '../model/notifications/actions'
import { replaceBreadcrumbs } from '../model/general/actions'
import { setCurrentEvent, getReachedAndMissed, getMyPastEvents, publishEvent } from '../model/events/actions'
import _ from 'underscore'
import LoadingScreen from '../components/LoadingScreen'
import mixpanel from '../util/mixpanel'
import Timeline from '../components/timeline/Timeline'
import EventDetails from '../components/event/EventDetails'
import EventInviteBlock from '../components/event/EventInviteBlock'
import EventAttendeesBlock from '../components/event/EventAttendeesBlock'
import FlatButton from '@bash/shared-components/buttons/FlatButton'
import Hidden from '@material-ui/core/Hidden'
import PublishIcon from '@material-ui/icons/Publish'
import { useTranslation } from 'react-i18next'
import AnnounceDateDialog from '../components/dialog/AnnounceDateDialog'

const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  eventImage: {
    maxWidth: theme.breakpoints.values.header,
    height: '200px',
    width: '100%',
    objectFit: 'cover',
    borderRadius: '0 0 8px 8px',
    backgroundColor: theme.palette.grey.main
  },
  mainContainer: {
    maxWidth: theme.breakpoints.values.events,
    width: '100%',
    marginTop: theme.spacing(-1)
  },
  mobilePublishButton: {
    position: 'fixed',
    bottom: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    width: `calc(100vw - ${theme.spacing(4)}px)`
  },
  startIcon: {
    position: 'absolute',
    left: theme.spacing(2)
  }
})

export const eventContext = React.createContext({})

const Event = ({ classes, notFound, event, user, loading, saving, imageVersion, setSnackbar, setCurrentEvent, replaceBreadcrumbs, getMyPastEvents, getReachedAndMissed }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  useEffect(() => {
    event && replaceBreadcrumbs({
      [t('nav.sidebar.profile')]: { href: '/' },
      [event.name]: { href: '/event/' + event.id + '/view' }
    })

    event && setCurrentEvent(event)
    event && mixpanel.trackOrgEvent('Land On Bash Page', event, { 'Is host': true })
    !event && getMyPastEvents()

    window.scrollTo(0, 0)
  }, [event, setCurrentEvent, replaceBreadcrumbs, getMyPastEvents, t])

  const onPublishClicked = () => {
    dispatch(publishEvent(event.id))
  }

  const contextVal = {
    event,
    user
  }

  if (loading || notFound) return <LoadingScreen />

  return (
    <eventContext.Provider value={contextVal}>
      <div className={classes.root}>
        {event.videoUrl && <video muted autoPlay loop className={classes.eventImage} src={`${event.videoUrl}?v=${imageVersion}`} />}
        {!event.videoUrl && <img className={classes.eventImage} alt='Event header' src={`${event.imageUrl}?v=${imageVersion}`} />}
        <div className={classes.mainContainer}>
          <EventDetails />
          <EventInviteBlock />
          <EventAttendeesBlock />
          {(event && ((event.eventUpdates && event.eventUpdates.length) || (event.reminders && event.reminders.length))) ? <Timeline event={event} /> : null}
        </div>
        <Hidden mdUp>
          {event.state === 'NEW' && <FlatButton onClick={onPublishClicked} color='secondary' className={classes.mobilePublishButton}>{saving ? <CircularProgress size={24} className={classes.startIcon} /> : <PublishIcon className={classes.startIcon} />}{t('event.view.publish')}</FlatButton>}
        </Hidden>
      </div>
    </eventContext.Provider>
  )
}

const mapStateToProps = ({ user, events, auth }, props) => {
  const eventId = +props.match.params.eventId
  const temp = eventId && _.find(events.value, event => event.id === eventId)
  const event = temp || eventId && _.find(events.pastEvents, event => event.id === eventId)
  const notFound = eventId && !event
  return ({
    user: user.value.organisation,
    notFound,
    event,
    loading: events.loading,
    saving: events.saving,
    imageVersion: events.imageVersion
  })
}

export default connect(mapStateToProps, { setSnackbar, setCurrentEvent, replaceBreadcrumbs, getReachedAndMissed, getMyPastEvents })(withStyles(styles)(Event))
