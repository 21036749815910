import { withStyles } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import moment from 'moment'

import React from 'react'
import TextInput from '../shared-components/common/TextInput'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import { useTranslation } from 'react-i18next'

const styles = theme => ({
  root: {
    display: 'flex',
    width: '100%'
  },
  paddingLeft: {
    paddingLeft: theme.spacing(2)
  },
  noPadding: {
    padding: 0,
    background: 'inherit !important'
  },
  selectMenu: {
    maxHeight: '300px'
  }
})

const renderInput = ({ InputProps, ...props }) => (
  <TextInput
    {...props}
  />
)

const getTimeOptions = () => {
  const options = []
  for (let i = 0; i < 24; i++) {
    if (i < 10) {
      options.push('0' + i + ':00')
      options.push('0' + i + ':15')
      options.push('0' + i + ':30')
      options.push('0' + i + ':45')
    } else {
      options.push(i + ':00')
      options.push(i + ':15')
      options.push(i + ':30')
      options.push(i + ':45')
    }
  }
  return options
}

const timeOptions = getTimeOptions()

const DateTimeInput = ({ classes, value, label, onChange, startDate, ...other }) => {
  const { t } = useTranslation('common')

  let focusDate
  if (!startDate) {
    focusDate = new Date()
    focusDate.setHours(24, 0, 0, 0) // next midnight
  } else {
    focusDate = moment(startDate)
  }

  const onDateChange = date => {
    const newValue = moment(date, 'YYYY-MM-DD')
    if (!newValue.isValid()) return
    let oldValue
    if (value) {
      oldValue = moment(value)
    } else {
      oldValue = moment()
      oldValue.hours(19)
      oldValue.minutes(0)
    }
    oldValue.year(newValue.year())
    oldValue.month(newValue.month())
    oldValue.date(newValue.date())
    onChange(oldValue.toDate())
  }
  const onTimeChange = e => {
    if (!e.target.value) return
    const splits = e.target.value.split(':')
    let oldValue
    if (value) {
      oldValue = moment(value)
    } else if (startDate) {
      oldValue = moment(startDate)
    } else {
      oldValue = moment()
      oldValue.hours(19)
      oldValue.minutes(0)
    }
    oldValue.hours(splits[0])
    oldValue.minutes(splits[1])
    onChange(oldValue.toDate())
  }

  return (
    <div className={classes.root}>
      <DatePicker format='MMMM DD' value={value} onChange={onDateChange} autoOk placeholder='' initialFocusedDate={focusDate} disablePast label={label} TextFieldComponent={renderInput} variant='inline' {...other} />
      <Select MenuProps={{ classes: { paper: classes.selectMenu } }} autoWidth classes={{ select: classes.noPadding }} input={<TextInput customClass={classes.paddingLeft} label={t('event.edit.time')} />} onChange={onTimeChange} value={value ? moment(value).format('HH:mm') : ''}>
        {timeOptions.map(option => <MenuItem key={option} value={option}>{option}</MenuItem>)}
      </Select>
    </div>
  )
}

export default (withStyles(styles)(DateTimeInput))
