import { createSelector } from 'reselect'
import _ from 'underscore'

const getOrganisations = (state) => state.user.organisations
const getCurrentInviteOrgId = (state) => state.modals.info.acceptInvite.orgId
const getAuthorizedTags = (state) => state.user.value.organisation.authorizedTags

export const getAcceptInviteOrgInfo = createSelector(
  [getOrganisations, getCurrentInviteOrgId],
  (organisations, currentInviteOrgId) => {
    if (!organisations || !currentInviteOrgId) return false
    return _.find(organisations, organisation => organisation.id === currentInviteOrgId)
  }
)

export const hasGigTag = createSelector(
  [getAuthorizedTags],
  (tags) => Boolean(_.find(tags, tag => tag.name === 'FreedomDay'))
)
