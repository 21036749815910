import React from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core'
import classNames from 'classnames'

const styles = theme => ({
  root: {
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.tertiary.light,
    color: theme.palette.tertiary.main,
    padding: theme.spacing(1.25),
    fontWeight: '700',
    fontSize: '0.625rem',
    letterSpacing: '1.5px',
    lineHeight: '12px'
  },
  primaryColor: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main
  },
  greyColor: {
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.grey.main
  }
})

const StateBadge = React.forwardRef(({
  classes,
  className,
  text,
  color,
  ...other
}, ref) => {
  return <Typography {...other} ref={ref} variant='button' className={classNames(classes.root, className, color === 'primary' ? classes.primaryColor : undefined, color === 'grey' ? classes.greyColor : undefined)}>{text}</Typography>
})

export default withStyles(styles)(StateBadge)
