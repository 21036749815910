import React, { useCallback, useEffect, useRef, useState } from 'react'
import { withStyles } from '@material-ui/core'
import { connect, useDispatch } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import DropZone from '../components/DropZone'
import Avatar from '@material-ui/core/Avatar'
import RestingButton from '../components/RestingButton'
import Camera from '@material-ui/icons/CameraAlt'
import { signIn } from '../model/auth/actions'
import { checkUsernameAvailability, getMe, patchMe, uploadAvatar, changeEditingUser } from '../model/user/actions'
import InputLabel from '@material-ui/core/InputLabel'
import * as _ from 'underscore'
import { slugify } from 'underscore.string'
import mixpanel from '../util/mixpanel'
import CircularProgress from '@material-ui/core/CircularProgress'
import Check from '@material-ui/icons/Check'
import Tooltip from '@material-ui/core/Tooltip/Tooltip'
import Cross from '@material-ui/icons/Close'
import TextInput from '../shared-components/common/TextInput'
import classNames from 'classnames'
import Switch from '@material-ui/core/Switch/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import useTreatment from '../util/useTreatment'
import { replaceBreadcrumbs } from '../model/general/actions'
import LoadingScreen from '../components/LoadingScreen'
import { Trans, useTranslation } from 'react-i18next'
import FlatButton from '@bash/shared-components/buttons/FlatButton'
import { history } from '../config/store'
import Hidden from '@material-ui/core/Hidden'
import { limitFileSize } from '../util/imageResizer'

const styles = theme => ({
  root: {
    maxWidth: theme.breakpoints.values.events,
    width: '100%',
    height: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(0, 2, 2, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: theme.spacing(7)
  },
  profileTitle: {
    fontWeight: '700'
  },
  avatarTitle: {
    marginTop: theme.spacing(7)
  },
  avatarContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    position: 'relative'
  },
  avatar: {
    height: 96,
    width: 96
  },
  avatarProgress: {
    position: 'absolute',
    top: 24,
    left: 24,
    zIndex: '10'
  },
  dropZone: {
    borderRadius: 1000,
    overflow: 'hidden'
  },
  editAvatar: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: 40,
    height: 40,
    minWidth: 40
  },
  divider: {
    width: '100%',
    height: '1px',
    backgroundColor: '#DDDDDD',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3)
  },
  doubleInput: {
    display: 'grid',
    gridColumnGap: theme.spacing(3),
    gridTemplateColumns: 'auto auto',
    width: '100%'
  },
  linkCaption: {
    marginTop: theme.spacing(2)
  },
  cross: {
    color: theme.palette.red.main,
    fontSize: '19px'
  },
  invisible: {
    opacity: 0,
    fontSize: '19px'
  },
  inputLabel: {
    color: 'black',
    marginBottom: theme.spacing(1),
    fontSize: '0.875rem',
    fontWeight: '500',
    marginTop: theme.spacing(2)
  },
  accessContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  accessField: {
    margin: 0,
    backgroundColor: theme.palette.primary.light,
    borderRadius: '8px',
    padding: theme.spacing(1.25, 1.5),
    fontSize: '0.625rem',
    fontWeight: '700',
    letterSpacing: '1.5px',
    color: theme.palette.primary.main
  },
  redField: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    marginLeft: theme.spacing(2)
  },
  marginTopHalf: {
    marginTop: theme.spacing(0.5)
  },
  activationText: {
    marginTop: theme.spacing(3)
  },
  activationButton: {
    marginTop: theme.spacing(1)
  },
  check: {
    fontSize: '19px'
  },
  comingSoonBadges: {
    display: 'flex'
  }
})

const EditProfile = ({ classes, user, uploadingAvatar, checkUsernameAvailability, avatarVersion, uploadAvatar, changeEditingUser, username, editingUser, replaceBreadcrumbs }) => {
  const [t, i18] = useTranslation('common')
  const dispatch = useDispatch()
  const fileInputRef = useRef(null)
  const [checkedCurrentUsername, setCheckedCurrentUsername] = useState(true)
  const requestButtonDisabled = !editingUser.description || !editingUser.username || !editingUser.name

  const showAccessFieldSplit = useTreatment('member_plan') === 'on'

  useEffect(() => {
    replaceBreadcrumbs({
      Profile: { href: '/' },
      Edit: { href: '/profile' }
    })
    mixpanel.trackOrgDash('Land on Edit Profile Page')
  }, [replaceBreadcrumbs])

  const onCameraClicked = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const uploadImage = async (file) => {
    const limitedImg = await limitFileSize(file)
    const limitedFile = new File([limitedImg], file.name)
    uploadAvatar(limitedFile)
  }

  const onChange = (key, value) => {
    if (key === 'location') {
      if (editingUser.location && editingUser.location.length <= 2 && value.length >= 3) {
        mixpanel.trackOrgDash('Add Organisation Location')
      }
    } else if (key === 'description') {
      if (editingUser.description && editingUser.description.length <= 2 && value.length >= 3) {
        mixpanel.trackOrgDash('Add Organisation Description')
      }
    }
    changeEditingUser(key, value)
  }

  const debouncedCheckUsername = useCallback(_.debounce(username => {
    const slugged = slugify(username)
    checkUsernameAvailability(slugged)
    onChange('username', slugged)
    setCheckedCurrentUsername(true)
  }, 1500), [])

  const onUsernameChange = username => {
    setCheckedCurrentUsername(false)
    if (!user.username && editingUser.username.length <= 2 && username.length >= 3) {
      mixpanel.trackOrgDash('Add Organisation Username')
    }
    onChange('username', username)
    debouncedCheckUsername(username)
  }

  // const usernameAvailable = user ? ((username.available.username === editingUser.username || user.username === editingUser.username) && editingUser.username) : true
  const usernameAvailable = () => {
    if (user) return (username.available.username === editingUser.username || user.username === editingUser.username) && editingUser.username
    else return true
  }

  const saveUser = () => {
    dispatch(patchMe(editingUser, false))
  }

  // if (!user) return (<LoadingScreen />)

  return (
    <div className={classes.root}>
      <Typography className={classes.profileTitle} variant='h5'>{t('profile.edit')}</Typography>
      <Typography className={classes.marginTopHalf} color='textSecondary' variant='subtitle1'>{t('profile.editSubtitle')}</Typography>
      <Typography className={classes.avatarTitle} variant='subtitle2'>{t('profile.logo')}</Typography>
      {user &&
        <div className={classes.avatarContainer}>
          <DropZone fileInputRef={fileInputRef} onNewFile={uploadImage} overlayClassName={classes.dropZone}>
            <CircularProgress size={48} className={classNames(!uploadingAvatar && classes.invisible, classes.avatarProgress)} />
            <Avatar className={classes.avatar} src={!uploadingAvatar && `${user.avatarUrl}?v=${avatarVersion}`} />
          </DropZone>
          <RestingButton onClick={onCameraClicked} className={classes.editAvatar}>
            <Camera color='primary' />
          </RestingButton>
        </div>}
      <Typography variant='caption' color='textSecondary'>{t('profile.logoSubtitle')}</Typography>

      {showAccessFieldSplit && (
        <>
          <div className={classes.divider} />
          <InputLabel className={classes.inputLabel}>{t('profile.access')}</InputLabel>
          <div className={classes.accessContainer}>
            <FormControlLabel disabled className={classes.margintop} control={<Switch checked={false} name='limitNumberOfGuests' color='primary' />} label={<Typography variant='caption'>{t('profile.accessCaption')}</Typography>} />
            <div className={classes.comingSoonBadges}>
              <div className={classNames(classes.accessField)}>{t('comingSoon')}</div>
              <div className={classNames(classes.accessField, classes.redField)}>{t('member')}</div>
            </div>
          </div>
        </>
      )}
      <div className={classes.divider} />
      <Hidden smDown>
        <div className={classes.doubleInput}>
          <TextInput label={t('profile.name')} value={editingUser.name} onChange={e => onChange('name', e.target.value)} placeholder={user.name} />
          <TextInput
            endAdornment={
              username.checkingAvailability ? <CircularProgress color='primary' size={19} /> : usernameAvailable() ? <Check className={classes.check} color='secondary' /> : checkedCurrentUsername ? <Tooltip title={t('profile.usernameTaken')}><Cross className={classes.cross} /></Tooltip> : <Cross className={classes.invisible} />
            }
            startAdornment={<span style={{ opacity: 0.6 }}>https://bash.social/</span>}
            label={t('profile.username')} placeholder={user.username} value={editingUser.username} onChange={e => onUsernameChange(e.target.value)}
          />
        </div>
      </Hidden>

      <Hidden mdUp>
        <TextInput label={t('profile.name')} value={editingUser.name} onChange={e => onChange('name', e.target.value)} placeholder={user.name} />
        <TextInput
          endAdornment={
            username.checkingAvailability ? <CircularProgress color='primary' size={24} /> : usernameAvailable() ? <Check color='secondary' /> : checkedCurrentUsername ? <Tooltip title={t('profile.usernameTaken')}><Cross className={classes.cross} /></Tooltip> : <Cross className={classes.invisible} />
          }
          startAdornment={<span style={{ opacity: 0.6 }}>https://bash.social/</span>}
          label={t('profile.username')} placeholder={user.username} value={editingUser.username} onChange={e => onUsernameChange(e.target.value)}
        />
      </Hidden>
      <Typography className={classes.linkCaption} variant='caption' color='textSecondary'><Trans t={t} i18nKey='profile.profileLink' username={editingUser.username}>your profile link: https://bash.social/{{ username: editingUser.username }}</Trans></Typography>
      <div className={classes.divider} />
      <TextInput label={t('profile.location')} placeholder={user.location} value={editingUser.location} onChange={e => onChange('location', e.target.value)} />
      <TextInput label={t('profile.description')} multiline rows='4' placeholder={user.description} value={editingUser.description} onChange={e => onChange('description', e.target.value)} />
    </div>
  )
}

const mapStateToProps = ({ user }) => ({
  loading: user.loading,
  editingUser: user.editingUser,
  user: user.value.organisation,
  avatarVersion: user.avatarVersion,
  username: user.username,
  uploadingAvatar: user.uploadingAvatar
})

export default connect(mapStateToProps,
  { signIn, patchMe, getMe, uploadAvatar, replaceBreadcrumbs, changeEditingUser, checkUsernameAvailability })(withStyles(styles)(EditProfile))
