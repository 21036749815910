import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useContext, useState } from 'react'
import { Typography } from '@material-ui/core'
import { Add, Check, HelpOutline } from '@material-ui/icons'
import Tooltip from '@material-ui/core/Tooltip'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'underscore'
import RestingButton from '../RestingButton'
import YesNoDialog from '../dialog/YesNoDialog'
import { changeDraft, setDraft } from '../../model/events/actions'
import adam from '../../images/dummyprofiles/adam.png'
import maggie from '../../images/dummyprofiles/maggie.png'
import christie from '../../images/dummyprofiles/christie.png'
import eric from '../../images/dummyprofiles/eric.png'
import anna from '../../images/dummyprofiles/anna.png'
import { editEventContext } from '../../routes/EditEvent'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
    position: 'relative'
  },
  tagsContainer: {
    display: 'flex',
    overflow: 'scroll',
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  tagButton: {
    color: theme.palette.text.primary,
    paddingTop: 0,
    paddingBottom: 0,
    height: '41px',
    flexShrink: 0,
    marginRight: theme.spacing(1)
  },
  helpIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    fill: theme.palette.text.secondary
  },
  tagButtonEnabled: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  }
}))

export default () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const { setPage, newEvent, onChange, editingEvent } = useContext(editEventContext)
  const [freedomOpen, setFreedomOpen] = useState(false)
  const tags = useSelector(state => state.user.value.organisation.tags)

  const onFreedomConfirm = () => {
    setFreedomOpen(false)
    const freedomEvent = {
      name: 'Freedom Day',
      startDate: null,
      endDate: null,
      location: '',
      callLink: '',
      description: 'Het eerste weekend waarop grootschalige evenementen weer zijn toegestaan zullen we met heel Nederland Freedom Day vieren – op straat, op feesten, maar vooral in de Nederlandse horeca!',
      covidMeasurements: '',
      responseType: 'INTEREST',
      limitNumberOfGuests: false,
      expectedNumberOfGuests: null,
      guestsCanSeeGuests: true,
      visibleOnProfile: true,
      themeImageKey: 'suggestedEvents/freedomday.jpeg',
      ticketProducts: [
        {
          localId: 0,
          price: '16.99',
          currency: 'EUR',
          name: 'Shirt',
          weight: 0
        },
        {
          localId: 1,
          price: '17.99',
          currency: 'EUR',
          name: 'Longsleeve',
          weight: 1
        },
        {
          localId: 2,
          price: '27.99',
          currency: 'EUR',
          name: 'Trui',
          weight: 1
        }
      ],
      type: 'PUBLIC',
      dateToBeAnnounced: true,
      isGigEvent: true,
      tags: [
        {
          id: 1,
          name: 'FreedomDay',
          protected: true,
          url: 'https://freedom-day.nl'
        }
      ],
      guests: [
        {
          status: 'GOING',
          name: 'Adam',
          avatarUrl: adam
        },
        {
          status: 'GOING',
          name: 'Maggie',
          avatarUrl: maggie
        },
        {
          status: 'GOING',
          name: 'Christie',
          avatarUrl: christie
        },
        {
          status: 'MAYBE',
          name: 'Eric',
          avatarUrl: eric
        },
        {
          status: 'CANT',
          name: 'Anna',
          avatarUrl: anna
        }
      ],
      imageUrl: '/events/themeImages/suggestedEvents/freedomday.jpeg'
    }

    if (newEvent) {
      dispatch(setDraft(freedomEvent))
    } else {
      for (const [key, value] of Object.entries(freedomEvent)) {
        onChange(key, value)
      }
    }
    setPage('-1')
  }

  const onFreedomClicked = () => {
    setFreedomOpen(true)
  }

  const onTagClicked = (tag, enabled) => {
    if (enabled) {
      onChange('tags',
        _.filter(editingEvent.tags, editingTag => editingTag.id !== tag.id)
      )
    } else {
      if (editingEvent.tags) {
        onChange('tags', [
          ...editingEvent.tags,
          tag
        ])
      } else {
        onChange('tags', [tag])
      }
    }
  }

  if (!tags) return null

  return (
    <div className={classes.root}>
      <Typography className={classes.caption} variant='subtitle2'>{t('event.edit.addTag')}</Typography>
      <div className={classes.tagsContainer}>
        {_.map(tags, tag => {
          const enabled = _.find(editingEvent.tags, editingTag => editingTag.id === tag.id)
          return (
            <RestingButton key={tag.name} onClick={tag.id === 1 ? onFreedomClicked : () => onTagClicked(tag, enabled)} className={classNames(classes.tagButton, enabled && classes.tagButtonEnabled)} startIcon={enabled ? <Check /> : <Add color='primary' />}>#{tag.name}</RestingButton>
          )
        })}
      </div>
      {/* <Tooltip title='Tooltip'> */}
      {/*  <HelpOutline className={classes.helpIcon} /> */}
      {/* </Tooltip> */}
      <YesNoDialog
        open={freedomOpen}
        onClose={() => setFreedomOpen(false)}
        onConfirm={onFreedomConfirm}
        title={t('event.edit.freedomTagDialog.title')}
        subtitle={t('event.edit.freedomTagDialog.subtitle')}
        confirmText={t('event.edit.freedomTagDialog.confirm')}
        cancelText={t('event.edit.freedomTagDialog.cancel')}
      />
    </div>)
}
