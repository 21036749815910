import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useRef, useState } from 'react'
import RestingButton from './RestingButton'
import LinkIcon from '@material-ui/icons/Link'
import Tooltip from '@material-ui/core/Tooltip'
import FlatButton from '@bash/shared-components/buttons/FlatButton'
import OpenIcon from '@material-ui/icons/OpenInNew'
import Paper from '@material-ui/core/Paper'
import mixpanel from '../util/mixpanel'
import { copyText } from '@bash/shared-components/utils/copy'
import { history } from '../config/store'
import { useDispatch, useSelector } from 'react-redux'
import { setSnackbar } from '../model/notifications/actions'
import { useTranslation } from 'react-i18next'
import OrganisationInfo from '@bash/shared-components/organisation/OrganisationInfo'

const useStyles = makeStyles(theme => ({
  banner: {
    marginTop: theme.spacing(4),
    width: '100%'
  },
  profilePaper: {
    padding: theme.spacing(3),
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    marginBottom: theme.spacing(5)
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  avatar: {
    width: 96,
    height: 96,
    marginRight: theme.spacing(2)
  },
  stat: {
    marginLeft: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column'
  },
  statNumber: {
    fontWeight: 'bold'
  },
  name: {
    marginTop: theme.spacing(2)
  },
  locationIcon: {
    height: 15,
    width: 15,
    marginRight: theme.spacing(0.5)
  },
  infoItems: {
    marginTop: theme.spacing(2),
    display: 'flex',
    color: theme.palette.text.secondary + ' !important',
    '& span': {
      color: theme.palette.text.secondary + ' !important',
      fontWeight: 'normal !important'
    }
  },
  infoItemIcon: {
    marginRight: theme.spacing(2)
  },
  infoItemText: {
    color: theme.palette.text.secondary,
    whiteSpace: 'pre-wrap',
    letterSpacing: '0.5px',
    paddingTop: theme.spacing(0.5)
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex'
  },
  icon: {
    color: 'white',
    marginRight: theme.spacing(),
    height: 24,
    width: 24,
    '& *': {
      fill: 'currentColor'
    }
  },
  profileButton: {
    marginRight: theme.spacing(1)
  },
  profileButtons: {
    marginTop: theme.spacing(3),
    display: 'flex'
  },
  editIcon: {
    marginRight: theme.spacing()
  },
  linkContainer: {
    display: 'flex',
    color: theme.palette.primary.main,
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: theme.spacing()
  },
  linkIcon: {
    marginRight: theme.spacing()
  },
  divider: {
    marginTop: theme.spacing(2),
    width: '100%',
    height: '1px',
    backgroundColor: '#DDDDDD'
  },
  moreButton: {
    borderRadius: 1000,
    backgroundColor: theme.palette.grey['200'],
    color: theme.palette.grey['700'],
    '&:hover': {
      backgroundColor: theme.palette.primary.lightDark
    }
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(3),
    '& > :first-child': {
      marginRight: theme.spacing(2)
    }
  }
}))

export default () => {
  const [t, i18] = useTranslation('common')
  const classes = useStyles()
  const dispatch = useDispatch()
  const user = useSelector(state => state.user.value.organisation)
  const avatarVersion = useSelector(state => state.user.avatarVersion)

  const copyTextAreaRef = useRef()
  const profileLink = user && `${process.env.REACT_APP_WEBSITE}/${user.username}`
  const [menuOpen, setMenuOpen] = useState(false)

  const onOpenProfileClicked = (location) => {
    mixpanel.trackOrgDash('Profile Preview', { Location: location })
    window.open(profileLink)
  }

  const onCopyProfileLinkClicked = async () => {
    mixpanel.trackOrgDash('Copy Organisation Link')
    await copyText(profileLink, copyTextAreaRef, (...callProps) => dispatch(setSnackbar(...callProps)), t)
  }

  const onEditProfileClicked = (location) => {
    mixpanel.trackOrgDash('Land on Edit Profile Page', { Location: location })
    history.push('/profile')
  }

  const customButtons = (
    <div className={classes.buttonsContainer}>
      {profileLink ?
        <RestingButton className={classes.profileButton} onClick={onCopyProfileLinkClicked}><LinkIcon color='primary' className={classes.editIcon} />{t('copyLink')}</RestingButton> :
        <Tooltip title={t('copyLinkTooltip')}>
          <div>
            <FlatButton disabled className={classes.profileButton} color='primary'><LinkIcon className={classes.editIcon} />{t('copyLink')}</FlatButton>
          </div>
        </Tooltip>}
      {profileLink ?
        <RestingButton className={classes.profileButton} onClick={() => onOpenProfileClicked('profileButton')}><OpenIcon color='primary' className={classes.editIcon} />{t('viewAsVisitor')}</RestingButton> :
        <Tooltip title={t('viewAsVisitorTooltip')}>
          <div>
            <FlatButton disabled className={classes.profileButton} color='primary'><OpenIcon className={classes.editIcon} />{t('viewAsVisitor')}</FlatButton>
          </div>
        </Tooltip>}
    </div>
  )

  return (
    <Paper elevation={2} className={classes.profilePaper}>
      <OrganisationInfo customButtons={customButtons} organisation={user} />
      {profileLink &&
        <textarea readOnly ref={copyTextAreaRef} hidden value={profileLink} />}
    </Paper>
  )
}
