import React, { useContext, useState } from 'react'
import TicketsScreen from './TicketsScreen'
import PriceScreen from './PriceScreen'
import _ from 'underscore'
import { editEventContext } from '../../routes/EditEvent'

const TicketsWrapper = ({ onSave, onBack }) => {
  const { isGig, editingEvent } = useContext(editEventContext)
  // const [newTickets, setNewTickets] = useState(currentTicketProducts || [])
  const ticketProducts = editingEvent.ticketProducts || []
  const [originalTickets, setOgTickets] = useState(editingEvent.ticketProducts)
  const [page, setPage] = useState((ticketProducts.length === 0 && !isGig) ? 'price' : 'tickets')
  const [currentProduct, setCurrentProduct] = useState(ticketProducts ? { localId: 1, price: 0, currency: 'EUR', name: 'Attendance', weight: 0 } : ticketProducts[0])

  const saveProduct = (product) => {
    const exists = ticketProducts.some((ticketProduct) => (product.id && ticketProduct.id === product.id) || (ticketProduct.localId && ticketProduct.localId === product.localId))
    if (exists) {
      onSave(
        _.map(ticketProducts, ticketProduct => ((product.id && ticketProduct.id === product.id) || (ticketProduct.localId && ticketProduct.localId === product.localId)) ? product : ticketProduct)
      )
    } else {
      onSave([
        ...ticketProducts,
        product
      ]
      )
    }
    setPage('tickets')
  }

  const deleteProduct = (product) => {
    const exists = ticketProducts.some((ticketProduct) => (product.id && ticketProduct.id === product.id) || (ticketProduct.localId && ticketProduct.localId === product.localId))
    if (exists) {
      const length = ticketProducts.length
      onSave(
        _.filter(ticketProducts, ticketProduct => ((ticketProduct.id && ticketProduct.id !== product.id) || (ticketProduct.localId && ticketProduct.localId !== product.localId)))
        // _.map(state, ticketProduct => (ticketProduct.id === product.id || (ticketProduct.localId && ticketProduct.localId === product.localId)) ? null : ticketProduct)
      )
      if (length <= 1) {
        onSave([])
        onBack()
      } else {
        setPage('tickets')
      }
    } else {
      if (ticketProducts.length === 0) {
        onSave([])
        onBack()
      } else {
        setPage('tickets')
      }
    }
  }

  const openProduct = (product) => {
    setCurrentProduct(product)
    setPage('price')
  }

  const onPriceBack = () => {
    if (ticketProducts.length === 0) {
      onBack()
    } else {
      setPage('tickets')
    }
  }

  return (page === 'price' ?
    <PriceScreen nameEditEnabled={ticketProducts.length > 1} onBack={onPriceBack} enabled currentValue={currentProduct} onSave={saveProduct} onDelete={deleteProduct} /> :
    <TicketsScreen onBack={() => { onSave(originalTickets); onBack() }} onSave={() => { onBack() }} openProduct={openProduct} ticketProducts={ticketProducts} />)
}

export default (TicketsWrapper)
