import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import StarsIcon from '@material-ui/icons/Stars'
import { ReactComponent as CantIcon } from 'images/status/CANT.svg'
import { ReactComponent as MaybeIcon } from 'images/status/MAYBE.svg'

import { withTranslation } from 'react-i18next'

import { Fab, withStyles, SvgIcon } from '@material-ui/core'
import { usePrevious } from 'shared-components/utils/hooks'

const styles = theme => ({
  button: {
    color: 'rgba(0, 0, 0, 0.6)',
    backgroundColor: theme.palette.grey.main,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'inherit !important'
    }
  },
  cancelButton: {
    '&:hover': {
      backgroundColor: 'rgba(242,95,92, 0.1) !important'
    }
  },
  cancelActive: {
    backgroundColor: theme.palette.red.main,
    '&:hover': {
      backgroundColor: `${theme.palette.red.main} !important`
    }
  },
  maybeButton: {
    '&:hover': {
      backgroundColor: 'rgba(230,206,87,0.15) !important'
    }
  },
  maybeActive: {
    backgroundColor: theme.palette.yellow.main,
    '&:hover': {
      backgroundColor: `${theme.palette.yellow.main} !important`
    }
  },
  goButton: {
    '&:hover': {
      backgroundColor: 'rgba(0,166,147,0.1) !important'
    }
  },
  goActive: {
    backgroundColor: theme.palette.green.main,
    '&:hover': {
      backgroundColor: `${theme.palette.green.main} !important`
    }
  },
  interestButton: {
    '&:hover': {
      backgroundColor: 'rgba(25,123,189,0.15) !important'
    }
  },
  interestActive: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main} !important`
    }
  },
  secondaryActive: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  active: {
    color: '#fff !important'
  },
  cantColor: {
    color: theme.palette.red.main
  },
  maybeColor: {
    color: theme.palette.yellow.main
  },
  goColor: {
    color: theme.palette.green.main
  },
  interestColor: {
    color: theme.palette.primary.main
  },
  disabled: {
    border: 'inherit !important',
    color: 'rgba(0, 0, 0, 0.38) !important',
    backgroundColor: `${theme.palette.grey.main} !important`
  },
  extendedIcon: {
    marginLeft: -4,
    marginRight: 8
  },
  removeIconResponsive: {
    [theme.breakpoints.down(373)]: {
      display: 'none'
    }
  },
  fabRoot: {
    boxShadow: theme.shadows[0],
    '&:active': {
      boxShadow: theme.shadows[0]
    }
  },
  pulseGreen: {
    boxShadow: '0 0 0 0 rgba(0, 166, 147, 1)',
    animation: 'pulse-green 2s 3',
    animationDelay: '750ms'
  }
})

var timeout = null
const activatePulseAnimationOnce = (setRunPulse) => {
  setRunPulse(true)
  timeout = setTimeout(() => setRunPulse(false), 7000)
}

const ResponseButton = ({ classes, type, active, activate, disabled, t, text, flat = false, secondary = false, unresponsive = false, shouldPulse = false }) => {
  const [runPulse, setRunPulse] = useState(false)
  const prevShouldPulse = usePrevious(shouldPulse)
  useEffect(() => {
    if (shouldPulse && !prevShouldPulse) activatePulseAnimationOnce(setRunPulse)
    return () => clearTimeout(timeout)
  }, [shouldPulse, prevShouldPulse])

  const iconClasses = classNames(classes.extendedIcon, {
    [classes.removeIconResponsive]: !unresponsive,
    [classes.active]: active && !disabled,
    [classes.disabled]: disabled
  })
  const buttonProperties = {
    CANT: {
      classes: classNames({ [classes.cancelActive]: active, [classes.cancelButton]: !active }),
      icon: <SvgIcon component={CantIcon} className={classNames(iconClasses, classes.cantColor)} />,
      text: text || t('rsvp.cant')
    },
    MAYBE: {
      classes: classNames({ [classes.maybeActive]: active, [classes.maybeButton]: !active }),
      icon: <SvgIcon component={MaybeIcon} className={classNames(iconClasses, classes.maybeColor)} />,
      text: text || t('rsvp.maybe')
    },
    GOING: {
      classes: classNames({ [classes.goActive]: active, [classes.goButton]: !active, [classes.pulseGreen]: runPulse }),
      icon: <CheckCircleIcon className={classNames(iconClasses, classes.goColor)} />,
      text: text || t('rsvp.going')
    },
    INTEREST: {
      classes: classNames({ [classes.interestActive]: active, [classes.interestButton]: !active }),
      icon: <StarsIcon className={classNames(iconClasses, classes.interestColor)} />,
      text: text || t('rsvp.interested')
    }
  }
  return (
    <Fab
      variant='extended'
      disabled={disabled}
      classes={{
        root: classes.fabRoot,
        disabled: classes.disabled
      }}
      className={classNames(classes.button, { [classes.active]: active && !disabled }, buttonProperties[type].classes, { [classes.secondaryActive]: secondary })}
      size='large'
      onClick={activate}
    >
      {buttonProperties[type].icon}
      {buttonProperties[type].text}
    </Fab>
  )
}

ResponseButton.propTypes = {
  type: PropTypes.oneOf(['CANT', 'MAYBE', 'GOING', 'INTEREST']).isRequired,
  active: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(withTranslation('shared')(ResponseButton))
