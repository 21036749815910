import React from 'react'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import { Avatar, SvgIcon } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import StarsIcon from '@material-ui/icons/Stars'
import { ReactComponent as CantIcon } from '../images/status/CANT.svg'
import { ReactComponent as MaybeIcon } from '../images/status/MAYBE.svg'
import Mail from '@material-ui/icons/Mail'
import MobileFriendly from '@material-ui/icons/MobileFriendly'
import Notifications from '@material-ui/icons/Notifications'
import Tooltip from '@material-ui/core/Tooltip'

const styles = theme => ({
  root: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    height: '40px',
    width: '40px',
    marginRight: theme.spacing(2)
  },
  cant: {
    color: theme.palette.red.main
  },
  maybe: {
    color: theme.palette.yellow.main
  },
  going: {
    color: theme.palette.green.main
  },
  interested: {
    color: theme.palette.primary.main
  },
  contactType: {
    color: theme.palette.text.secondary,
    marginLeft: 'auto'
  },
  badge: {
    // marginLeft: 'auto'
    flexShrink: '0'
  },
  ticketType: {
    marginRight: theme.spacing(2)
  },
  rightSection: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center'
  },
  pricePaid: {
    marginRight: theme.spacing(2),
    lineHeight: '0.875rem'
  }
})

const UserListItem = ({ classes, follower, guest, responseType, status, ...other }) => {
  const badges = {
    INTERESTED: <StarsIcon className={classNames(classes.badge, classes.interested)} />,
    MAYBE: responseType === 'DEFAULT' ? <SvgIcon component={MaybeIcon} className={classNames(classes.badge, classes.maybe)} /> : <StarsIcon className={classNames(classes.badge, classes.interested)} />,
    GOING: <CheckCircleIcon className={classNames(classes.badge, classes.going)} />,
    CANT: <SvgIcon component={CantIcon} className={classNames(classes.badge, classes.cant)} />,
    NONE: null
  }

  const contactTypes = {
    EMAIL: <Tooltip title='Updated on new events on their email address.'><Mail className={classes.contactType} /></Tooltip>,
    APP: <Tooltip title='Updated on new events in the BASH app.'><MobileFriendly className={classes.contactType} /></Tooltip>,
    NOTIFS: <Tooltip title='Updated on new events via web notifications (only on Android).'><Notifications className={classes.contactType} /></Tooltip>
  }
  return (
    <div className={classes.root} {...other}>
      {follower &&
        <>
          <Avatar className={classes.avatar} src={`${follower.avatarUrl}`} />
          <Typography variant='subtitle1'>{follower.user.name}</Typography>
          {follower.hasApp ? contactTypes.APP : (follower.hasEmail ? contactTypes.EMAIL : (follower.hasNotifications && contactTypes.NOTIFS))}
        </>}

      {guest &&
        <>
          <Avatar className={classes.avatar} src={`${guest.avatarUrl}`} />
          <Typography variant='subtitle1'>{guest.name ? guest.name : guest.user.name}</Typography>
          <div className={classes.rightSection}>
            {guest.tickets && guest.tickets[0].ticketProduct && <Typography className={classes.ticketType} variant='caption' color='textSecondary'>{guest.tickets[0].ticketProduct.name}</Typography>}
            {guest.tickets && guest.tickets[0].ticketProduct && <Typography className={classes.pricePaid} variant='caption' color='secondary'><b>€{parseFloat(guest.tickets[0].ticketProduct.price).toFixed(2).replace('.', ',')}</b></Typography>}
            {badges[guest.status]}
          </div>
        </>}
    </div>
  )
}
export default (withStyles(styles)(UserListItem))
