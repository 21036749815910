import React, { useState } from 'react'
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  SvgIcon,
  Typography,
  withStyles
} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../../model/modals/actions'
import { getAcceptInviteOrgInfo } from '../../selectors/user'
import FlatButton from '@bash/shared-components/buttons/FlatButton'
import { acceptInviteNoToken, rejectInvite } from '../../model/user/actions'
import { history } from '../../config/store'
import { Trans, useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    width: '100%',
    maxWidth: theme.breakpoints.values.visual,
    padding: theme.spacing(2)
  },
  dialogContentPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  backdropRoot: {
    backgroundColor: 'rgba(255, 255, 255, 0.7)'
  },
  title: {
    marginBottom: theme.spacing(1)
  },
  orgContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  orgAvatar: {
    marginRight: theme.spacing(2)
  },
  description: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  removeButton: {
    color: theme.palette.tertiary.main,
    marginRight: theme.spacing(1)
  },
  marginTop1: {
    marginTop: theme.spacing(1)
  },
  loadingProgress: {
    width: '24px !important',
    height: '24px !important',
    marginLeft: theme.spacing(2)
  }
}))

const AcceptInviteDialog = ({}) => {
  const { t } = useTranslation('common')
  const classes = useStyles()
  const dispatch = useDispatch()
  const open = useSelector(state => state.modals.open.acceptInvite)
  const orgInfo = useSelector(getAcceptInviteOrgInfo)
  const [loading, setLoading] = useState(false)
  const [rejecting, setRejecting] = useState(false)

  const onAcceptClicked = async () => {
    setLoading(true)
    await dispatch(acceptInviteNoToken(orgInfo.id))
    setLoading(false)
    dispatch(closeModal('acceptInvite'))
    history.push('/')
  }

  const onRejectClicked = async () => {
    setRejecting(true)
    await dispatch(rejectInvite(orgInfo.id))
    setRejecting(false)
    dispatch(closeModal('acceptInvite'))
  }

  if (!orgInfo) return null

  return (
    <Dialog
      aria-labelledby='accept-invite-dialog'
      onClose={() => dispatch(closeModal('acceptInvite'))}
      open={open}
      BackdropProps={{
        classes: {
          root: classes.backdropRoot
        }
      }}
      classes={{
        paper: classes.dialogPaper
      }}
    >
      <DialogTitle className={classes.dialogContentPadding} disableTypography>
        <Typography className={classes.title} variant='h6'>
          {t('dialogs.acceptInvite.title')}
        </Typography>
        <Typography color='textSecondary' variant='subtitle2'>
          <Trans t={t} i18nKey='dialogs.acceptInvite.subtitle' organiser={orgInfo.organisation.name}>
            <b>{{ organiser: orgInfo.organisation.name }}</b> invites you to manage their organisation profile.
          </Trans>
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.orgContainer}>
        <Avatar className={classes.orgAvatar} src={`${orgInfo.organisation.avatarUrl}`} />
        <div style={{ minWidth: 0 }}>
          <Typography variant='subtitle1'><b>{orgInfo.organisation.name}</b></Typography>
          <Typography className={classes.description} variant='body2' color='textSecondary'>{orgInfo.organisation.description}</Typography>
        </div>
      </DialogContent>
      <DialogActions className={classes.marginTop1}>
        <Button disabled={rejecting} onClick={onRejectClicked} className={classes.removeButton}>
          {t('dialogs.acceptInvite.remove')} {rejecting && <CircularProgress className={classes.loadingProgress} />}
        </Button>
        <FlatButton disabled={loading} onClick={onAcceptClicked} color='secondary'>
          {t('dialogs.acceptInvite.accept')} {loading && <CircularProgress className={classes.loadingProgress} />}
        </FlatButton>
      </DialogActions>
    </Dialog>
  )
}

export default (AcceptInviteDialog)
