import { withStyles } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import TextInput from '../TextInput'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import FlatButton from '@bash/shared-components/buttons/FlatButton'
import { useTranslation } from 'react-i18next'
import validator from 'validator'
import cc from 'currency-codes'
import moment from 'moment'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { editEventContext } from '../../routes/EditEvent'
import { HelpOutline } from '@material-ui/icons'
import Tooltip from '@material-ui/core/Tooltip'

const styles = theme => ({
  root: {

  },

  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3)
  },
  backButton: {
    backgroundColor: theme.palette.grey.main,
    color: 'black',
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    },
    marginRight: theme.spacing(2)
  },
  marginTopHalf: {
    marginTop: theme.spacing(0.5)
  },
  marginTop1: {
    marginTop: theme.spacing(1)
  },
  marginTop3: {
    marginTop: theme.spacing(3)
  },
  linkInput: {
    marginTop: theme.spacing(1)
  },
  noPadding: {
    padding: 0,
    background: 'inherit !important'
  },
  selectMenu: {
    maxHeight: '300px'
  },
  ticketPageContainer: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center'
  },
  questionMark: {
    opacity: 0.6,
    marginLeft: 'auto'
  }
})

const ExternalTicketScreen = ({ classes, onBack }) => {
  const { t } = useTranslation('common')
  const { editingEvent, onChange } = useContext(editEventContext)
  const value = editingEvent.ticketLink
  const label = editingEvent.ticketPrice
  const currency = editingEvent.ticketCurrency
  // const [value, setValue] = useState(defaultValue)
  // const [label, setLabel] = useState(defaultLabel)
  // const [currency, setCurrency] = useState(defaultCurrency || 'EUR')
  const [originalValue, setOgValue] = useState({
    value,
    label,
    currency
  })

  const error = value && !validator.isURL(value)

  const currencies = cc.codes()

  return (
    <div className={classes.root}>
      <Typography variant='subtitle1'>{t('event.edit.externalTickets.title')}</Typography>
      <Typography className={classes.marginTopHalf} variant='caption'>{t('event.edit.externalTickets.subtitle')}</Typography>

      <div className={classes.ticketPageContainer}>
        <Typography variant='subtitle2'>{t('event.edit.externalTickets.link.title')}</Typography>
        <Tooltip title={t('event.edit.externalTickets.link.caption')}>
          <HelpOutline className={classes.questionMark} />
        </Tooltip>
      </div>

      {/* <Typography className={classes.marginTop1} variant='caption' color='textSecondary'>{t('event.edit.externalTickets.link.caption')}</Typography> */}
      <TextInput
        error={error} errorText={t('invalidUrl')} type='url' className={classes.linkInput} autoFocus value={value} onChange={e => {
          if (!e.target.value) {
            onChange('ticketLink', null)
          } else {
            if (!currency) onChange('ticketCurrency', 'EUR')
            onChange('ticketLink', e.target.value)
          }
        }}
      />

      <Typography className={classes.marginTop3} variant='subtitle2'>{t('event.edit.externalTickets.label.title')}</Typography>
      <Typography className={classes.marginTop1} variant='caption' color='textSecondary'>{t('event.edit.externalTickets.label.caption')}</Typography>
      <TextInput
        className={classes.linkInput} type='number' inputProps={{ min: 0 }} value={label} onChange={e => {
          if (!e.target.value) {
            onChange('ticketPrice', null)
          } else {
            if (!currency) onChange('ticketCurrency', 'EUR')
            onChange('ticketPrice', e.target.value)
          }
        }}
      />

      <Select MenuProps={{ classes: { paper: classes.selectMenu } }} autoWidth classes={{ select: classes.noPadding }} input={<TextInput customClass={classes.paddingLeft} label={t('event.edit.externalTickets.currency.title')} />} onChange={(e) => onChange('ticketCurrency', e.target.value)} value={currency}>
        {currencies.map(option => <MenuItem key={option} value={option}>{option}</MenuItem>)}
      </Select>

      <div className={classes.buttons}>
        <FlatButton className={classes.backButton} onClick={() => { onChange('ticketLink', originalValue.value); onChange('ticketPrice', originalValue.label); onChange('ticketCurrency', 'EUR'); onBack() }}>{t('cancel')}</FlatButton>
        <FlatButton disabled={error} onClick={() => { onBack() }}>{t('save')}</FlatButton>
      </div>
    </div>
  )
}

export default (withStyles(styles)(ExternalTicketScreen))
