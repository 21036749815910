import { withStyles, SvgIcon } from '@material-ui/core'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import StarsIcon from '@material-ui/icons/Stars'
import { ReactComponent as CantIcon } from '../images/status/CANT.svg'
import { ReactComponent as MaybeIcon } from '../images/status/MAYBE.svg'
import classNames from 'classnames'

const styles = theme => ({
  root: {
    width: '100%',
    display: 'block'
  },
  field: {
    background: theme.palette.grey.main,
    borderRadius: 8,
    width: '100%',
    marginTop: theme.spacing(2),
    marginLeft: 0,
    padding: theme.spacing(0.5)
  },
  fieldLabel: {
    display: 'flex',
    alignItems: 'center'
  },
  cant: {
    color: theme.palette.red.main,
    marginLeft: 'auto'
  },
  maybe: {
    color: theme.palette.yellow.main,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  going: {
    color: theme.palette.green.main,
    marginRight: theme.spacing(1.5)
  },
  interested: {
    color: theme.palette.primary.main,
    marginLeft: 'auto',
    marginRight: theme.spacing(1)
  },
  whiteBackground: {
    backgroundColor: 'white',
    borderRadius: '50%'
  },
  fullWidth: {
    width: '100%'
  }
})

const radioLabel = (classes, type) => {
  if (type === 'INTEREST') {
    return (
      <div className={classes.fieldLabel}>
        <Typography variant='button'>Interested / Going</Typography>
        <StarsIcon className={classNames(classes.badge, classes.interested)} />
        <CheckCircleIcon className={classNames(classes.badge, classes.going)} />
      </div>
    )
  } else if (type === 'DEFAULT') {
    return (
      <div className={classes.fieldLabel}>
        <Typography variant='button'>Can't / Maybe / Going</Typography>
        <SvgIcon component={CantIcon} className={classNames(classes.badge, classes.cant)} />
        <SvgIcon component={MaybeIcon} className={classNames(classes.badge, classes.maybe)} />
        <CheckCircleIcon className={classNames(classes.badge, classes.going)} />
      </div>
    )
  }
}

const ResponseTypeFields = ({ classes, onClick, ...other }) => {
  return (
    <RadioGroup className={classes.root} {...other}>
      <FormControlLabel classes={{ label: classes.fullWidth }} className={classes.field} value='INTEREST' control={<Radio color='primary' />} label={radioLabel(classes, 'INTEREST')} />
      <FormControlLabel classes={{ label: classes.fullWidth }} className={classes.field} value='DEFAULT' control={<Radio color='primary' />} label={radioLabel(classes, 'DEFAULT')} />
    </RadioGroup>
  )
}

export default (withStyles(styles)(ResponseTypeFields))
