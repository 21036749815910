export const CHANGE_DRAFT = 'CHANGE_DRAFT'
export const RECEIVE_MY_EVENTS = 'RECEIVE_MY_EVENTS'
export const RECEIVE_MY_PAST_EVENTS = 'RECEIVE_MY_PAST_EVENTS'
export const RECEIVE_EVENT = 'RECEIVE_EVENT'
export const RECEIVE_REACHED_MISSED = 'RECEIVE_REACHED_MISSED'
export const DELETE_EVENT = 'DELETE_EVENT'
export const SAVED_EVENT = 'SAVED_EVENT'
export const START_SAVING = 'START_SAVING'
export const CHANGE_EVENT = 'CHANGE_EVENT'
export const UPLOAD_EVENT_IMAGE = 'UPLOAD_EVENT_IMAGE'
export const STOP_SAVING = 'STOP_SAVING'
export const SET_CURRENT_EVENT = 'SET_CURRENT_EVENT'
export const TOGGLE_PREVIEW = 'TOGGLE_PREVIEW'
export const SET_DRAFT = 'SET_DRAFT'
