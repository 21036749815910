import React, { useState } from 'react'
import AddIcon from '@material-ui/icons/AddOutlined'
import makeStyles from '@material-ui/core/styles/makeStyles'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    height: '100%',
    width: '100%'
  },
  fileInput: {
    display: 'none'
  },
  noPointerEvents: {
    pointerEvents: 'none'
  },
  overlay: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.halfTransparent
  },
  addIcon: {
    color: 'white'
  }
}))

export default function DropZone ({ className, fileInputRef, overlayClassName, onNewFile, children, videoActive }) {
  const classes = useStyles()
  const onDrop = evt => {
    evt.preventDefault()
    if (evt.dataTransfer && evt.dataTransfer.files) {
      onNewFile(evt.dataTransfer.files[0])
    }
    setHighlighted(false)
  }
  const onFileSelected = evt => {
    if (evt.target && evt.target.files) {
      onNewFile(evt.target.files[0])
    }
  }

  const [highlighted, setHighlighted] = useState(false)

  return (
    <div
      className={classNames(className, classes.root)}
      onDragOver={evt => { setHighlighted(true); evt.preventDefault() }}
      onDragLeave={() => setHighlighted(false)}
      onDrop={onDrop}
    >
      {/* {React.Children.map(children, child => React.cloneElement(child, { className: classNames(child.props && child.props.className, classes.noPointerEvents) }))} */}
      {children}
      {highlighted > 0 &&
        <div className={classNames(overlayClassName, classes.overlay, classes.noPointerEvents)}>
          <AddIcon className={classes.addIcon} color='inherit' />
        </div>}
      <input
        ref={fileInputRef}
        className={classes.fileInput}
        type='file'
        accept={videoActive ? 'video/mp4' : 'image/jpeg, image/png'}
        onChange={onFileSelected}
      />
    </div>
  )
}
