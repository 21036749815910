import React, { useState } from 'react'
import {
  Dialog,
  Typography,
  IconButton,
  makeStyles, Button
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useTranslation } from 'react-i18next'
import MoreIcon from '@material-ui/icons/MoreVert'
import moment from 'moment'
import MomentUtils from '@date-io/moment'
import DateTimeInput from '../DateTimeInput'
import { useDispatch, useSelector } from 'react-redux'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import FlatButton from '@bash/shared-components/buttons/FlatButton'
import { announceEventDate } from '../../model/events/actions'
import CircularProgress from '@material-ui/core/CircularProgress'
import { closeModal } from '../../model/modals/actions'

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    padding: theme.spacing(4)
  },
  backdropRoot: {
    backgroundColor: 'rgba(255, 255, 255, 0.7)'
  },
  subtitle: {
    marginTop: theme.spacing(0.5)
  },
  description: {
    marginBottom: theme.spacing(1)
  },
  closeButton: {

  },
  dialogPaper: {
    margin: theme.spacing(2),
    width: '100%',
    maxWidth: theme.breakpoints.values.container
  },
  contentContainer: {
    whiteSpace: 'pre-wrap'
  },
  divider: {
    margin: theme.spacing(3, -4),
    height: '1px',
    background: theme.palette.grey.dark
  },
  addEndDate: {
    marginTop: theme.spacing(1),
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  buttonContainer: {
    float: 'right',
    display: 'flex',
    alignItems: 'center'
  },
  progress: {
    marginLeft: theme.spacing(1)
  },
  cancelButton: {
    marginRight: theme.spacing(2)
  }
}))

const AnnounceDateDialog = ({ open, onClose, eventId, TransitionComponent }) => {
  const { t } = useTranslation('common')
  const classes = useStyles()
  const dispatch = useDispatch()
  const saving = useSelector(state => state.events.saving)

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [endDateVisible, setEndDateVisible] = useState(false)

  const buttonEnabled = startDate && moment().isBefore(moment(startDate)) && (!endDate || moment(startDate).isBefore(moment(endDate)))

  const handleEndDateClick = () => {
    if (endDateVisible || endDate) {
      setEndDateVisible(false)
      setEndDate(null)
    } else {
      setEndDateVisible(!endDateVisible)
      setEndDate(null)
    }
  }

  const onAnnounce = async () => {
    await dispatch(announceEventDate(eventId, startDate, endDate))
    onClose()
  }

  return (
    <Dialog
      aria-labelledby='simple-dialog'
      BackdropProps={{
        classes: {
          root: classes.backdropRoot
        }
      }}
      classes={{
        paper: classes.dialogPaper
      }}
      onClose={onClose}
      open={open}
      TransitionComponent={TransitionComponent}
    >
      <div className={classes.dialogContainer}>
        <Typography variant='h6'>
          {t('dialogs.announceDate.title')}
        </Typography>
        <Typography variant='subtitle2' color='textSecondary'>
          {t('dialogs.announceDate.subtitle')}
        </Typography>
        <div className={classes.contentContainer}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <div className={classes.divider} />
            <DateTimeInput
              error={moment(startDate).isBefore(moment())}
              errorText={t('event.edit.dateError')}
              label={t('event.edit.dateLabel')}
              value={startDate}
              onChange={setStartDate}
            />
            {(endDateVisible || endDate) && (
              <DateTimeInput
                startDate={startDate}
                error={moment(endDate).isBefore(moment(startDate))}
                errorText={t('event.edit.endDateError')}
                label={t('event.edit.endDate')}
                value={endDate}
                onChange={setEndDate}
              />
            )}
            <Typography
              variantMapping={{ button: 'p' }}
              className={classes.addEndDate}
              color='primary'
              variant='button'
              onClick={handleEndDateClick}
            >
              {(endDateVisible || endDate) ? t('event.edit.removeEndDate') : t('event.edit.addEndDate')}
            </Typography>
            <div className={classes.divider} />
            <div className={classes.buttonContainer}>
              <Button
                className={classes.cancelButton}
                onClick={onClose}
              >
                {t('cancel')}
              </Button>
              <FlatButton onClick={onAnnounce} disabled={!buttonEnabled} color='secondary'>{t('dialogs.announceDate.button')} {saving && <CircularProgress size={20} color='inherit' className={classes.progress} />}</FlatButton>
            </div>
          </MuiPickersUtilsProvider>
        </div>
      </div>
    </Dialog>
  )
}

export default (AnnounceDateDialog)
