import React, { useCallback, useContext, useEffect, useState } from 'react'
import joost from '../images/joost.png'
import { withStyles } from '@material-ui/core'
import { connect, useDispatch, useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import _ from 'underscore'
import mixpanel from '../util/mixpanel'
import { changeDraft, changeEvent, save30Drafts, saveDraft, saveEvent } from '../model/events/actions'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import FlatButton from '@bash/shared-components/buttons/FlatButton'
import Next from '@material-ui/icons/NavigateNext'
import Before from '@material-ui/icons/NavigateBefore'
import { Check } from '@material-ui/icons'
import BasicScreen from '../components/event/BasicScreen'
import CircularProgress from '@material-ui/core/CircularProgress'
import LoadingScreen from '../components/LoadingScreen'
import { history } from '../config/store'
import SwitchScreen from '../components/event/SwitchScreen'
import moment from 'moment'
import { addBreadcrumb, replaceBreadcrumbs } from '../model/general/actions'
import LocationScreen from '../components/event/LocationScreen'
import Hidden from '@material-ui/core/Hidden'
import RsvpApp from 'shared-components/event/App'
import classNames from 'classnames'
import TicketsWrapper from '../components/event/TicketsWrapper'
import WizardPage1 from '../components/event/WizardPage1'
import WizardPage2 from '../components/event/WizardPage2'
import WizardFreedomDayPage from '../components/event/WizardFreedomDayPage'
import WizardFreedomDayButtons from '../components/event/WizardFreedomDayButtons'
import { useTranslation } from 'react-i18next'
import ExternalTicketScreen from '../components/event/ExternalTicketScreen'
import { Prompt, useParams } from 'react-router'
import { getTags } from '../model/user/actions'
import { NavContext } from '../components/navigation/MainContainer'
import Tooltip from '@material-ui/core/Tooltip'
import { useBeforeunload } from 'react-beforeunload'

const styles = theme => ({
  root: {
    width: '100%',
    height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
    background: theme.palette.grey.dark
  },
  formContainer: {
    [theme.breakpoints.up('md')]: {
      paddingTop: `calc(${theme.mixins.toolbar.minHeight}px + ${theme.spacing(7)}px)`
    },
    paddingTop: `calc(${theme.mixins.toolbar.minHeight}px + ${theme.spacing(4)}px)`,
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    overflowY: 'scroll',
    height: '100%',
    borderRadius: 0
  },
  imageContainer: {
    backgroundColor: theme.palette.grey.main,
    marginTop: theme.spacing(2),
    borderRadius: 8,
    position: 'relative',
    width: '100%',
    height: 176,
    '& button': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    },
    overflow: 'hidden',
    flexShrink: 0
  },
  dropZone: {
    opacity: '1'
  },
  eventImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  lowerButtons: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    left: 0,
    boxShadow: '0px -1px 5px rgba(0, 0, 0, 0.03), 0px -1px 10px rgba(0, 0, 0, 0.03), 0px 0px 4px rgba(0, 0, 0, 0.06)',
    borderRadius: 0,
    padding: theme.spacing(2, 3),
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginLeft: theme.spacing(2)
    }
  },
  backButton: {
    backgroundColor: theme.palette.grey.main,
    color: 'black',
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    }
  },
  divider: {
    width: '100%',
    height: '1px',
    backgroundColor: '#DDDDDD',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    flexShrink: 0
  },
  margintop: {
    marginTop: theme.spacing(2)
  },
  leftBarContainer: {
    [theme.breakpoints.up('md')]: {
      width: theme.drawer.width
    },
    width: '100%',
    height: '100%',
    position: 'fixed',
    left: 0,
    top: 0,
    overflow: 'hidden'
  },
  previewContainer: {
    marginLeft: theme.drawer.width,
    background: theme.palette.grey.dark,
    paddingBottom: theme.spacing(6)
  },
  maxWidthText: {
    marginTop: theme.spacing(1),
    maxWidth: '328px',
    textAlign: 'center'
  },
  addEndDate: {
    marginTop: theme.spacing(1),
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  margintopHalf: {
    marginTop: theme.spacing(0.5)
  },
  previewTitle: {
    paddingTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
    textAlign: 'center'
  },
  deviceContainer: {
    borderRadius: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '375px',
    maxHeight: '700px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    position: 'relative',
    background: theme.palette.grey.light
  },
  desktopPreview: {
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
    height: 0,
    maxWidth: 'unset',
    maxHeight: 'unset',
    paddingBottom: '56.25%' // 16:9,
  },
  previewContent: {
    top: 0,
    left: 0,
    right: 0,
    position: 'absolute'
  }
})

const trackMixPanel = (newEvent, editingEvent, field, value) => {
  if (newEvent) {
    if (field === 'name' && editingEvent.name.length <= 2 && value.length >= 3) {
      mixpanel.trackOrgDash('Add Bash Title')
    } else if (field === 'startDate' && !editingEvent.startDate && value) {
      mixpanel.trackOrgDash('Add Bash Date')
    } else if (field === 'expectedNumberOfGuests' && !editingEvent.expectedNumberOfGuests) {
      mixpanel.trackOrgDash('Add Bash Expected Attendees')
    } else if (field === 'limitNumberOfGuests') {
      mixpanel.trackOrgDash('Add Bash Limit Number of Guests', { 'limited number of guests': value })
    } else if (field === 'endDate' && !editingEvent.endDate && value) {
      mixpanel.trackOrgDash('Add Bash End Date')
    } else if (field === 'location' && editingEvent.location.length <= 2 && value.length >= 3) {
      mixpanel.trackOrgDash('Add Bash Location')
    } else if (field === 'description' && editingEvent.description.length <= 2 && value.length >= 3) {
      mixpanel.trackOrgDash('Add Bash Description')
    } else if (field === 'covidMeasurements' && editingEvent.covidMeasurements.length <= 2 && value.length >= 3) {
      mixpanel.trackOrgDash('Add Bash Covid Measures')
    } else if (field === 'callLink' && editingEvent.callLink.length <= 2 && value.length >= 3) {
      mixpanel.trackOrgDash('Add Bash Call Link')
    } else if (field === 'responseType' && editingEvent.responseType.length <= 2 && value.length >= 3) {
      mixpanel.trackOrgDash('Add Bash Response Type')
    } else if (field === 'visibleOnProfile' && editingEvent.visibleOnProfile.length <= 2 && value.length >= 3) {
      mixpanel.trackOrgDash('Add Bash Visible on Profile')
    }
  }
}

export const editEventContext = React.createContext({})

const EditEvent = ({ classes, changeDraft, changeEvent, saveDraft, saveEvent, replaceBreadcrumbs }) => {
  const { eventId } = useParams()
  const eventIdInt = parseInt(eventId)
  const events = useSelector(state => state.events)
  const temp = eventId && _.find(events.value, event => event.id === eventIdInt)
  const event = temp || (eventId && _.find(events.pastEvents, event => event.id === eventIdInt))
  const user = useSelector(state => state.user.value.organisation)
  const newEvent = !eventIdInt
  const previewType = events.previewType
  const imageVersion = event && event.imageVersion

  const editingEvent = newEvent ? events.draft : event
  const isGig = editingEvent && editingEvent.tags && _.find(editingEvent.tags, tag => tag.id === 1)

  const [imgFile, setImgFile] = useState(null)

  const { t } = useTranslation('common')
  const { setShowHeader, setShowSidebar, setDefault } = useContext(NavContext)

  const [page, setPage] = useState((isGig && newEvent) ? '-1' : '0')
  const [madeChange, setMadeChange] = useState(false)

  useBeforeunload((e) => {
    madeChange && e.preventDefault()
  })

  const nextEnabled = editingEvent && editingEvent.name && (editingEvent.startDate || editingEvent.dateToBeAnnounced) && (editingEvent.imageUrl || imgFile) &&
    (moment().isBefore(moment(editingEvent.startDate)) || editingEvent.dateToBeAnnounced) && (moment(editingEvent.startDate).isBefore(moment(editingEvent.endDate)) || !editingEvent.endDate)

  const setBreadcrumbs = useCallback(() => {
    if (newEvent) {
      replaceBreadcrumbs({ [t('events.create')]: { href: '/event' } })
    } else {
      event && replaceBreadcrumbs({
        [event.name]: {
          href: history.location.pathname + '/view'
        },
        [t('events.edit')]: {
          href: history.location.pathname
        }
      })
    }
  }, [event, newEvent, replaceBreadcrumbs])

  useEffect(() => {
    !madeChange && setBreadcrumbs()
    setShowSidebar(false)
    setShowHeader(true)
    return setDefault
  }, [madeChange, setBreadcrumbs, setDefault, setShowHeader, setShowSidebar])

  useEffect(() => {
    mixpanel.trackOrgDash('Land On Edit Bash Page')
  }, [])

  const onChange = (field, value) => {
    if (!madeChange) setMadeChange(true)
    trackMixPanel(newEvent, editingEvent, field, value)
    newEvent ? changeDraft(field, value) : changeEvent(eventIdInt, field, value)
  }

  const editContextVal = {
    editingEvent,
    user,
    newEvent,
    imgFile,
    setImgFile,
    onChange,
    imageVersion,
    setPage,
    isGig
  }

  const onSave = async () => {
    setMadeChange(false)
    if (newEvent) {
      await saveDraft(imgFile)
    } else {
      await saveEvent(eventId, imgFile)
    }
  }

  const onBack = () => {
    setPage('1')
  }

  if (events.loading) {
    return <LoadingScreen />
  } else if (eventId && !event) {
    return 'Not found'
  }

  return (
    <editEventContext.Provider value={editContextVal}>
      <div className={classes.root}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Paper elevation={1} className={classes.leftBarContainer}>
            {/* <Prompt */}
            {/*  when={madeChange} */}
            {/*  message={t('nav.header.dialog.exit.subtitle')} */}
            {/* /> */}
            {page === '-1' && <WizardFreedomDayPage />}
            {page === '0' && <WizardPage1 />}
            {page === '1' && <WizardPage2 />}
            <div className={classes.formContainer}>
              {/* {page === 'location' && <LocationScreen defaultValue={editingEvent.location} onSave={newValue => onChange('location', newValue)} onBack={onBack} title={t('event.edit.location.title')} subtitle={t('event.edit.location.subtitle')} placeholder={user.location} />} */}
              {page === 'location' && <LocationScreen onBack={onBack} title={t('event.edit.location.title')} subtitle={t('event.edit.location.subtitle')} placeholder={user.location} />}
              {page === 'call_link' && <BasicScreen value={editingEvent.callLink} onSave={newValue => onChange('callLink', newValue)} onBack={onBack} title={t('event.edit.callLink.title')} subtitle={t('event.edit.callLink.subtitle')} />}
              {page === 'covid' && <BasicScreen multiline value={editingEvent.covidMeasurements} onSave={newValue => onChange('covidMeasurements', newValue)} onBack={onBack} title={t('event.edit.covid.title')} subtitle={t('event.edit.covid.subtitle')} />}
              {page === 'expected_number' && <SwitchScreen value={editingEvent.expectedNumberOfGuests} switchValue={editingEvent.limitNumberOfGuests} onSave={(newValue, newSwitchValue) => { onChange('expectedNumberOfGuests', newValue); onChange('limitNumberOfGuests', newSwitchValue) }} type='number' inputProps={{ min: 0 }} onBack={onBack} switchLabel={t('event.edit.numberOfGuests.switch')} title={t('event.edit.numberOfGuests.title')} subtitle={t('event.edit.numberOfGuests.subtitle')} />}
              {page === 'price' && <TicketsWrapper onSave={(value) => onChange('ticketProducts', value)} onBack={onBack} currentTicketProducts={editingEvent.ticketProducts} />}
              {page === 'extTickets' && <ExternalTicketScreen onSave={(link, label, currency) => { onChange('ticketLink', link); onChange('ticketPrice', label); onChange('ticketCurrency', currency) }} onBack={onBack} value={editingEvent.ticketLink} defaultLabel={editingEvent.ticketPrice} defaultCurrency={editingEvent.currency} />}
            </div>
            {(page === '-1' || page === '0' || page === '1') && (
              <Paper className={classes.lowerButtons}>
                {page === '-1' && <WizardFreedomDayButtons />}
                {page === '0' && editingEvent.isGigEvent && <FlatButton className={classes.backButton} onClick={() => setPage('-1')} startIcon={<Before color='primary' />}>Back</FlatButton>}
                {page === '0' && (
                  <Tooltip title={imgFile ? '' : t('event.edit.imageRequired')}>
                    <div>
                      <FlatButton disabled={!nextEnabled} onClick={() => setPage('1')} endIcon={<Next />}>{t('next')}</FlatButton>
                    </div>
                  </Tooltip>
                )}
                {page === '1' && <FlatButton className={classes.backButton} onClick={() => setPage('0')} startIcon={<Before color='primary' />}>{t('back')}</FlatButton>}
                {page === '1' && <FlatButton onClick={onSave} disabled={events.saving} startIcon={events.saving ? <CircularProgress size={24} /> : <Check />}>{t('saveEvent')}</FlatButton>}
              </Paper>
            )}
          </Paper>
        </MuiPickersUtilsProvider>
        <Hidden smDown implementation='css'>
          <div className={classes.previewContainer}>
            <Typography className={classes.previewTitle} variant='h5' color='textSecondary'>{previewType === 'mobile' ? t('event.edit.preview.mobile') : t('event.edit.preview.web')}</Typography>
            <Paper elevation={4} className={classNames(classes.deviceContainer, previewType === 'desktop' && classes.desktopPreview)}>
              <div className={previewType === 'desktop' ? classes.previewContent : undefined}>
                <RsvpApp useRawAvatarUrl useRawImgUrl={!editingEvent.imageUrl || Boolean(imgFile)} event={editingEvent} organisation={user} isFulfilled />
              </div>
            </Paper>
          </div>
        </Hidden>
      </div>
    </editEventContext.Provider>
  )
}

export default connect(null, { saveDraft, saveEvent, changeDraft, addBreadcrumb, changeEvent, replaceBreadcrumbs })(withStyles(styles)(EditEvent))
