import React from 'react'

import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'

const bugsnagClient = bugsnag({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY,
  notifyReleaseStages: ['production']
})

bugsnagClient.use(bugsnagReact, React)

export const ErrorBoundary = bugsnagClient.getPlugin('react')

const DefaultFallbackComponent = () => (
  <h3>Sorry, something went wrong.</h3>
)

export const DefaultErrorBoundary = ({ FallbackComponent = DefaultFallbackComponent, children }) => (
  <ErrorBoundary FallbackComponent={FallbackComponent}>
    {children}
  </ErrorBoundary>
)
