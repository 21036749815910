import typeToReducer from 'type-to-reducer'
import { ADD_BREADCRUMB, REPLACE_BREADCRUMBS } from './types'

const initialState = {
  breadcrumbs: {
    BASH: {
      href: '/'
    }
  }
}

export default typeToReducer(
  {
    [ADD_BREADCRUMB]: (state, { text, link }) => ({
      ...state,
      breadcrumbs: {
        ...state.breadcrumbs,
        [text]: {
          href: link
        }
      }
    }),
    [REPLACE_BREADCRUMBS]: (state, { breadcrumbs }) => ({
      breadcrumbs: breadcrumbs
    })
  },
  initialState
)
