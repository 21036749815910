import typeToReducer from 'type-to-reducer'
import { CHECK_EMAIL, SIGN_IN, SIGN_OUT, SUBMIT_TOKEN, NO_EMAIL } from './types'
import { RECEIVE_ME } from '../user/types'

const initialState = {
  submitting: false,
  requesting: false,
  inEmail: false
}

export default typeToReducer({
  [SIGN_IN]: state => ({
    ...state,
    requesting: true
  }),
  [SIGN_OUT]: state => ({
    ...state,
    requesting: false,
    submitting: false,
    inEmail: false
  }),
  [CHECK_EMAIL]: state => ({
    ...state,
    requesting: false,
    inEmail: true
  }),
  [NO_EMAIL]: state => ({
    ...state,
    requesting: false,
    inEmail: false
  }),
  [SUBMIT_TOKEN]: state => ({
    ...state,
    submitting: true
  }),
  [RECEIVE_ME]: state => ({
    ...state,
    // submitting: false,
    requesting: false,
    inEmail: false
  })
}, initialState)
