import React, { useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { history } from '../../config/store'
import { changeDraft } from '../../model/events/actions'
import MenuItem from '@material-ui/core/MenuItem'
import { CircularProgress, Typography } from '@material-ui/core'
import moment from 'moment'
import EventCard from './EventCard'
import withStyles from '@material-ui/core/styles/withStyles'
import IconButton from '@material-ui/core/IconButton'
import MoreIcon from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu/Menu'
import Link from '@material-ui/core/Link'
import { useTranslation } from 'react-i18next'
import { getNonTbaEvents, getTbaEvents } from '../../selectors/events'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    width: '100%',
    '& > div:not(:last-child)': {
      marginBottom: theme.spacing(2)
    },
    '& > h6': {
      marginBottom: theme.spacing(1)
    },
    '& > h6:not(:first-child)': {
      marginTop: theme.spacing(3)
    }
  },
  topItems: {
    width: '100%',
    display: 'flex',
    marginBottom: '0 !important'
  },
  title: {
    fontWeight: '700'
  },
  monthHeader: {
    color: theme.palette.red.main,
    textTransform: 'uppercase',
    letterSpacing: '1.5px',
    fontSize: '0.625rem',
    alignSelf: 'flex-start',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2)
  },
  dateTitle: {
    color: theme.palette.red.main,
    textTransform: 'uppercase',
    letterSpacing: '1.5px',
    fontSize: '0.625rem',
    alignSelf: 'flex-start',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(0.5)
  },
  moreButton: {
    marginLeft: 'auto',
    borderRadius: 1000,
    backgroundColor: theme.palette.grey['200'],
    color: theme.palette.grey['700'],
    '&:hover': {
      backgroundColor: theme.palette.primary.lightDark
    },
    alignSelf: 'center'
  },
  endText: {
    marginTop: theme.spacing(5)
  },
  endText2: {
    opacity: '.6'
  },
  endLink: {
    marginTop: theme.spacing(1),
    cursor: 'pointer',
    fontWeight: '700'
  }
})

const EventList = ({
  classes, onEventClick, changeDraft, loading, reverseSort, userId, onClickPastEvents,
  onClickUpcomingEvents, showPast
}) => {
  const { t } = useTranslation('common')
  const [menuOpen, setMenuOpen] = useState(null)

  const nonTbaEvents = useSelector(getNonTbaEvents)
  const tbaEvents = useSelector(getTbaEvents)
  const pastEvents = useSelector(state => state.events.pastEvents)

  const onDuplicateClick = (dupeEvent) => {
    Object.keys(dupeEvent).map((field) => {
      if (field !== 'organisation' && field !== 'id' && field !== 'imageUrl' && field !== 'code' && field !== 'startDate' &&
          field !== 'guests' && field !== 'endDate' && field !== 'state') { changeDraft(field, dupeEvent[field]) }
    }
    )
    history.push('/event')
  }

  const menuItems = (menuEvent) =>
    <MenuItem key='menu-cancel' onClick={(e) => { e.stopPropagation(); onDuplicateClick(menuEvent) }}>
      Duplicate event
    </MenuItem>

  const sortEvent = (reverseSort) => (a, b) => {
    const diff = moment(a.startDate).diff(moment(b.startDate))
    return reverseSort ? -diff : diff
  }

  let currentMonth = ''
  return (
    <div className={classes.root}>
      <div className={classes.topItems}>
        <div>
          <Typography className={classes.dateTitle} variant='overline'>{moment().format('dddd DD MMMM')}</Typography>
          <Typography className={classes.title} variant='h5'>{showPast ? t('events.pastEvents') : t('events.comingUp')}</Typography>
        </div>
        <IconButton onClick={e => setMenuOpen(e.currentTarget)} className={classes.moreButton}><MoreIcon color='primary' /></IconButton>
        <Menu
          id='long-menu'
          keepMounted
          anchorEl={menuOpen}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={Boolean(menuOpen)}
          onClose={() => setMenuOpen(null)}
        >
          {!showPast &&
            <MenuItem key='show-past-events' onClick={() => { setMenuOpen(null); onClickPastEvents() }}>
              {t('events.showPast')}
            </MenuItem>}
          {showPast &&
            <MenuItem key='show-upcoming-events' onClick={() => { setMenuOpen(null); onClickUpcomingEvents() }}>
              {t('events.showUpcoming')}
            </MenuItem>}
        </Menu>
      </div>
      {loading && <CircularProgress />}
      {!showPast && tbaEvents && tbaEvents.length > 0 && (
        <>
          <Typography variant='overline' className={classes.monthHeader}>{t('event.edit.dateTba')}</Typography>
          {tbaEvents.sort(sortEvent(reverseSort)).map((event) => {
            return (
              <EventCard
                key={event.id}
                event={event}
                large
                userId={userId}
                onClick={() => onEventClick(event)}
              />)
          })}
        </>
      )}
      {!showPast && nonTbaEvents.sort(sortEvent(reverseSort)).map((event, idx) => {
        const eventMonth = moment(event.startDate).format('MMMM')
        let monthHeader = null
        if (currentMonth !== eventMonth) {
          currentMonth = eventMonth
          monthHeader = <Typography variant='overline' className={classes.monthHeader}>{currentMonth}</Typography>
        }
        return (
          <React.Fragment key={event.id}>
            {monthHeader}
            <EventCard
              event={event}
              large
              userId={userId}
              onClick={() => onEventClick(event)}
            />
          </React.Fragment>
        )
      })}
      {showPast && pastEvents.sort(sortEvent(true)).map((event, idx) => {
        const eventMonth = moment(event.startDate).format('MMMM')
        let monthHeader = null
        if (currentMonth !== eventMonth) {
          currentMonth = eventMonth
          monthHeader = <Typography variant='overline' className={classes.monthHeader}>{currentMonth}</Typography>
        }
        return (
          <React.Fragment key={event.id}>
            {monthHeader}
            <EventCard
              event={event}
              large
              userId={userId}
              onClick={() => onEventClick(event)}
            />
          </React.Fragment>
        )
      })}
      <Typography className={classes.endText} variant='h6'>{t('thatsIt')}</Typography>
      <Typography className={classes.endText2} variant='body1'>{t('events.fillList')}</Typography>
      <Link onClick={() => history.push('/event')} className={classes.endLink} variant='button'>{t('events.create')}</Link>
    </div>
  )
}

export default connect(null, { changeDraft })(withStyles(styles)(EventList))
