import React, { useContext } from 'react'
import classNames from 'classnames'

import { withStyles } from '@material-ui/core'
import { appContext } from '../App'

const styles = theme => ({
  root: {
    margin: theme.spacing(2)
  },
  barContainer: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    borderRadius: 5,
    marginTop: theme.spacing(1)
  },
  clickable: {
    cursor: 'pointer'
  },
  bar: {
    height: 6
  },
  cantColor: {
    backgroundColor: theme.palette.red.main
  },
  goingColor: {
    backgroundColor: theme.palette.green.main
  },
  maybeColor: {
    backgroundColor: theme.palette.yellow.main
  },
  noneColor: {
    backgroundColor: '#AAA'
  }
})

const calcBarRatios = (dateOption, amountNotResponded) => {
  const ratios = { GOING: 1, MAYBE: 0, CANT: 0, NONE: amountNotResponded }
  let total = 1
  if (dateOption.guestStatuses) {
    total = 1 + amountNotResponded + dateOption.guestStatuses.length
    dateOption.guestStatuses.forEach(guestStatus => { ratios[guestStatus.status] += 1 })
  }
  for (const status in ratios) { ratios[status] = ratios[status] / total * 100 + '%' }
  return ratios
}

const PinResponseBar = ({ classes, dateOption, onClick }) => {
  const { event } = useContext(appContext)
  const amountNotResponded = event.expectedNumberOfGuests ? Math.max(
    event.guests.filter(g => !g.gaveDateOptions).length,
    event.expectedNumberOfGuests - event.guests.length - 1
  ) : event.guests.filter(g => !g.gaveDateOptions).length
  const barRatios = calcBarRatios(dateOption, amountNotResponded)
  return (
    <div className={classNames(classes.barContainer, { [onClick]: classes.clickable })} onClick={onClick}>
      <div className={classNames(classes.bar, classes.goingColor)} style={{ width: barRatios.GOING }} />
      <div className={classNames(classes.bar, classes.maybeColor)} style={{ width: barRatios.MAYBE }} />
      <div className={classNames(classes.bar, classes.cantColor)} style={{ width: barRatios.CANT }} />
      <div className={classNames(classes.bar, classes.noneColor)} style={{ width: barRatios.NONE }} />
    </div>
  )
}

export default withStyles(styles)(PinResponseBar)
