import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/styles'
import { Route, Switch, useLocation } from 'react-router'
import requireAuth from '../composers/requireAuth'
import Event2 from '../../routes/Event2'
import SignIn from '../../routes/SignIn'
import EditEvent from '../../routes/EditEvent'
import EditProfile from '../../routes/EditProfile'
import Profile2 from '../../routes/Profile2'
import Followers from '../../routes/Followers'
import AddProfileInfo from '../../routes/AddProfileInfo'
import CreateOrg from '../../routes/CreateOrg'
import Attendees from '../../routes/Attendees'
import CoSnackbar from '@bash/shared-components/snackbar/Snackbar'
import store from '../../config/store'
import { closeSnackbar } from '../../model/notifications/actions'
import Events from '../../routes/Events'
import Payments from '../../routes/settings/Payments'
import Members from '../../routes/settings/Members'
import AcceptInvite from '../../routes/AcceptInvite'
import SelectOrg from '../../routes/SelectOrg'
import { useIntercom } from 'react-use-intercom'
import { useSelector } from 'react-redux'

const styles = theme => ({
  root: {
    marginTop: `calc(${theme.mixins.toolbar.minHeight}px + ${theme.spacing(7)}px)`,
    marginBottom: theme.spacing(5)
  },
  toolbarMargin: {
    marginTop: theme.mixins.toolbar.minHeight
  }
})

const MainContent = React.memo(({ classes }) => {
  const pathName = useLocation().pathname
  const addMargin = !pathName.toLowerCase().startsWith('/signin') && !pathName.toLowerCase().startsWith('/acceptinvite') && pathName !== '/newprofile' && pathName !== '/selectorg' && pathName.toLowerCase() !== '/createorg' && !pathName.toLowerCase().startsWith('/settings')
  const onEventPage = pathName.startsWith('/event/') || pathName === '/event' || pathName === '/'

  return (
    <div className={addMargin ? (!onEventPage ? classes.root : classes.toolbarMargin) : undefined}>
      <Switch>
        <Route exact path='/' component={requireAuth(Profile2)} />
        <Route exact path={['/events', '/events/past']} component={requireAuth(Events)} />
        <Route exact path='/event/:eventId?' component={requireAuth(EditEvent)} />
        <Route exact path='/event/:eventId?/view' component={requireAuth(Event2)} />
        <Route exact path='/event/:eventId?/view/attendees' component={requireAuth(Attendees)} />
        <Route exact path='/signIn/:email?/:token?/:invitedBy?' component={SignIn} />
        <Route exact path='/acceptInvite/:email?/:token?/:orgId?' component={AcceptInvite} />
        <Route exact path='/profile' component={requireAuth(EditProfile)} />
        <Route exact path='/newprofile' component={requireAuth(AddProfileInfo)} />
        <Route exact path='/createorg' component={requireAuth(CreateOrg)} />
        <Route exact path='/selectorg' component={requireAuth(SelectOrg)} />
        <Route exact path='/followers' component={requireAuth(Followers)} />
        <Route exact path='/settings/payments/mollie' component={requireAuth(Payments)} />
        <Route exact path='/settings/members' component={requireAuth(Members)} />
      </Switch>
      <CoSnackbar closeSnackbar={() => store.dispatch(closeSnackbar())} />
    </div>
  )
})

export default withStyles(styles)(MainContent)
