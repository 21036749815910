import React, { useContext } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import StarsIcon from '@material-ui/icons/Stars'
import { ReactComponent as CantIcon } from '../images/status/CANT_outline.svg'
import { ReactComponent as MaybeIcon } from '../images/status/MAYBE_outline.svg'
import GuestAvatar from './GuestAvatar'

import { withStyles, SvgIcon } from '@material-ui/core'
import { appContext } from '../event/App'

const styles = theme => ({
  root: {
    position: 'relative'
  },
  badge: {
    width: 24,
    height: 24,
    position: 'absolute',
    right: 0,
    bottom: 0
  },
  cant: {
    color: theme.palette.red.main
  },
  maybe: {
    color: theme.palette.yellow.main
  },
  going: {
    color: theme.palette.green.main
  },
  interested: {
    color: theme.palette.primary.main
  },
  whiteBackground: {
    backgroundColor: 'white',
    borderRadius: '50%'
  }
})

const StatusBadge = ({ status, gaveDateOptions, pinningDate, isPublicEvent, classes }) => {
  const badges = {
    MAYBE: isPublicEvent ?
      <StarsIcon className={classNames(classes.badge, classes.interested, classes.whiteBackground)} /> :
      <SvgIcon component={MaybeIcon} className={classNames(classes.badge, classes.maybe)} />,
    GOING: <CheckCircleIcon className={classNames(classes.badge, classes.going, classes.whiteBackground)} />,
    CANT: <SvgIcon component={CantIcon} className={classNames(classes.badge, classes.cant)} />,
    NONE: null
  }
  if (pinningDate && gaveDateOptions) return badges.GOING
  return badges[status]
}

const GuestAvatarBadge = ({ classes, guest, size, pinningDate, src }) => {
  const useEvent = useContext(appContext).event
  const isPublicEvent = useEvent.responseType === 'INTEREST'
  return (
    <div className={classes.root}>
      <GuestAvatar guest={guest} src={src} size={size} />
      <StatusBadge status={guest.status} gaveDateOptions={guest.gaveDateOptions} pinningDate={pinningDate} classes={classes} isPublicEvent={isPublicEvent} />
    </div>
  )
}

GuestAvatarBadge.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  pinningDate: state.event && state.event.event.type === 'PINNING',
  isPublicEvent: state.event && state.event.event.organisation
})

export default connect(mapStateToProps)(withStyles(styles)(GuestAvatarBadge))
