import React from 'react'
import { withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import classNames from 'classnames'

const styles = theme => ({
  root: {
    borderRadius: 1000,
    backgroundColor: theme.palette.grey['200'],
    color: theme.palette.grey['700'],
    '&:hover': {
      backgroundColor: theme.palette.primary.lightDark
    }
  }
})

const RestingButton = ({ classes, className, children, ...other }) => {
  return (
    <Button variant='contained' className={classNames(classes.root, className)} disableElevation {...other}>
      {children}
    </Button>
  )
}

export default withStyles(styles)(RestingButton)
