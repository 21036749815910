import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ShareIcon from '@material-ui/icons/Share'
import { setSnackbar } from '../redux/notifications/actions'
import FlatButton from 'shared-components/buttons/FlatButton'
import { appContext } from '../event/App'
import moment from 'moment'
import { mdiWhatsapp } from '@mdi/js'
import { SvgIcon, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import mixpanel from 'shared-components/utils/mixpanel'

const useShareButtonStyles = makeStyles(theme => ({
  greyColor: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.grey.main,
    '&:hover': {
      backgroundColor: theme.palette.grey.dark,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.grey.main
      }
    }
  }
}))

const ShareButton = ({ flat, className, shareOptions, small, whatsapp, ...other }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('shared')
  const classes = useShareButtonStyles()
  const onClick = (clickEvent) => {
    mixpanel.trackEvent('Share Event Normal')
    if (navigator.share) {
      navigator.share(shareOptions)
    } else {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(shareOptions.url)
        dispatch(setSnackbar('info', t('linkCopiedToClipboard')))
      } else {
        dispatch(setSnackbar('error', 'Failed to copy'))
      }
    }
  }
  if (small) {
    return (
      <IconButton
        className={classes.greyColor}
        onClick={onClick}
        startIcon={
          <ShareIcon color='primary' />
        }
        {...other}
      >
        <ShareIcon color='primary' />
      </IconButton>
    )
  }
  return (
    <FlatButton
      className={className}
      color='grey'
      onClick={onClick}
      startIcon={
        <ShareIcon color='primary' />
      }
      {...other}
    >
      {t('share')}
    </FlatButton>
  )
}

export const EventShareButton = ({ invite = true, event, activeGuest, whatsapp, ...other }) => {
  const app = useContext(appContext)
  const { t } = useTranslation('common')
  const useEvent = event || app.event
  const useActiveGuest = activeGuest || app.activeGuest
  const currentGuestCode = useActiveGuest ? useActiveGuest.code : undefined
  const eventUrl = `${process.env.REACT_APP_RSVP}/${useEvent.code}${invite && currentGuestCode ? `/${currentGuestCode}` : ''}`
  const transKey = (useActiveGuest && useActiveGuest.status === 'MAYBE') ? 'shareEvent.interested' : 'shareEvent.going'
  const orgName = useEvent.organisation ? useEvent.organisation.name : useEvent.organisers[0].name
  const date = moment(useEvent.startDate).format('dddd DD MMMM HH:mm')
  const shareOptions = {
    title: useEvent.name,
    url: eventUrl,
    text: t(transKey, { eventTitle: useEvent.name, orgName, date, eventUrl })
  }

  if (whatsapp) {
    return (
      <WhatsappShareButton message={shareOptions.text} {...other} />
    )
  }
  return <ShareButton {...other} shareOptions={shareOptions} />
}

export const OrgShareButton = ({ organisation, ...other }) => {
  const { t } = useTranslation('shared')
  const shareOptions = {
    title: organisation.name,
    url: `${process.env.REACT_APP_WEBSITE}/${organisation.username}`,
    text: t('followOrgOnBash', { name: organisation.name })
  }

  return <ShareButton {...other} shareOptions={shareOptions} />
}

const createWhatsappURL = (message) => {
  return encodeURI(`https://wa.me/?text=${message}`)
}

const WhatsappShareButton = ({ message, ...other }) => {
  const { t } = useTranslation('common')
  return (
    <FlatButton
      color='secondary'
      startIcon={
        <SvgIcon>
          <path d={mdiWhatsapp} />
        </SvgIcon>
      }
      onClick={() => mixpanel.trackEvent('Share Event WhatsApp')}
      href={createWhatsappURL(message)}
      target='_blank'
      rel='noreferrer'
      {...other}
    >
      {t('inviteViaWhatsapp')}
    </FlatButton>
  )
}
