import React, { Component } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import SnackbarContentWrapper from './SnackbarContentWrapper'

const styles = theme => ({
  root: {
    marginTop: theme.spacing(2)
  },
  contentRoot: {
    flexWrap: 'unset'
  }
})

class CoSnackbar extends Component {
  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.props.closeSnackbar()
  }

  render () {
    const { classes, open, variant, message } = this.props
    return (
      <Snackbar
        className={classes.root}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={open}
        autoHideDuration={5000}
        onClose={this.handleClose}
      >
        <SnackbarContentWrapper
          className={classes.contentRoot}
          onClose={this.handleClose}
          variant={variant}
          message={message}
        />
      </Snackbar>
    )
  }
}

const mapStateToProps = ({ notifications }) => ({
  open: notifications.snackbar.open,
  message: notifications.snackbar.message,
  variant: notifications.snackbar.variant
})

export default connect(
  mapStateToProps
)(withStyles(styles)(CoSnackbar))
