import React, { useCallback, useContext, useState } from 'react'
import { withStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import { connect, useDispatch, useSelector } from 'react-redux'
import mixpanel from '../../util/mixpanel'
import MoreIcon from '@material-ui/icons/MoreVert'
import Plus from '@material-ui/icons/Add'
import Check from '@material-ui/icons/Check'
import Button from '@material-ui/core/Button'
import { history } from '../../config/store'
import { matchPath, useLocation } from 'react-router-dom'
import CloseIcon from '@material-ui/icons/Close'
import MenuIcon from '@material-ui/icons/Menu'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import classNames from 'classnames'
import Hidden from '@material-ui/core/Hidden'
import { patchMe } from '../../model/user/actions'
import Menu from '@material-ui/core/Menu/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Tooltip from '@material-ui/core/Tooltip/Tooltip'
import { CircularProgress } from '@material-ui/core'
import { ReactComponent as Logo } from '../../media/logo/icon.svg'
import Link from '@material-ui/core/Link'
import YesNoDialog from '../dialog/YesNoDialog'
import {
  cancelEvent,
  changeDraft,
  deleteEvent,
  publishEvent,
  setDraft,
  togglePreviewType
} from '../../model/events/actions'
import Visibility from '@material-ui/icons/Visibility'
import PublishIcon from '@material-ui/icons/Publish'
import ComputerIcon from '@material-ui/icons/Computer'
import SmartPhoneIcon from '@material-ui/icons/Smartphone'
import FlatButton from '@bash/shared-components/buttons/FlatButton'
import moment from 'moment'
import adam from '../../images/dummyprofiles/adam.png'
import maggie from '../../images/dummyprofiles/maggie.png'
import christie from '../../images/dummyprofiles/christie.png'
import eric from '../../images/dummyprofiles/eric.png'
import anna from '../../images/dummyprofiles/anna.png'
import { useTranslation } from 'react-i18next'
import { currentHasTicketOrder } from '../../selectors/events'
import _ from 'underscore'
import { NavContext } from './MainContainer'

const styles = theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${theme.sidebar.width}px)`,
      marginLeft: theme.sidebar.width
    },
    background: theme.palette.background.paper
  },
  minheight: {
    minHeight: 56
  },
  fullWidth: {
    width: '100%'
  },
  flex: {
    display: 'flex'
  },
  locationText: {
    color: 'black',
    lineHeight: theme.header.height + 'px',
    cursor: 'pointer'
  },
  chevron: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  headerButtons: {
    marginLeft: 'auto',
    flex: 'none'
  },
  createButton: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    '&:hover': {
      color: theme.palette.primary.main
    },
    height: 40,
    width: 40
  },
  viewProfileButton: {
    height: '40px',
    margin: theme.spacing(1, 1, 1, 1),
    borderRadius: 1000,
    backgroundColor: theme.palette.grey['200'],
    color: theme.palette.grey['700'],
    '&:hover': {
      backgroundColor: theme.palette.primary.lightDark
    }
  },
  icon: {
    marginRight: theme.spacing(),
    height: 24,
    width: 24
  },
  closeIcon: {
    height: 24,
    width: 24,
    marginRight: theme.spacing(2),
    cursor: 'pointer',
    color: 'black'
  },
  menuButton: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  moreButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(-1)
  },
  logo: {
    width: '16px',
    height: '16px',
    marginRight: theme.spacing(2),
    flexShrink: 0
  },
  saveButton: {
    height: 40
  },
  publishButton: {
    height: 40,
    borderRadius: 100,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  activePreview: {
    color: theme.palette.tertiary.main
  },
  breadcrumbsContainer: {
    display: 'flex',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginRight: 'auto',
    textOverflow: 'ellipsis',
    '& :last-child': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  }
})

const Header = React.memo(({ classes, loading, previewType, user, togglePreviewType, currentEvent, setDraft, editingUser, deleteEvent, publishEvent, toggleSidebar, patchMe, cancelEvent, fullWidth, username, breadcrumbs, saving }) => {
  const [menuOpen, setMenuOpen] = useState(null)
  const [cancelOpen, setCancelOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [exitOpen, setExitOpen] = useState(false)
  const draft = useSelector(state => state.events.draft)
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const hasTicketOrder = useSelector(currentHasTicketOrder)
  const { headerContent, setHeaderContent } = useContext(NavContext)

  const pathName = useLocation().pathname
  const pathMatch = matchPath(pathName, {
    path: '/event/:eventId?/view',
    exact: false
  })
  const eventId = pathMatch && pathMatch.params.eventId

  const eventLink = currentEvent && currentEvent.state !== 'NEW' && `${process.env.REACT_APP_WEBSITE}/e/${currentEvent.code}`

  const profileLink = user && `${process.env.REACT_APP_WEBSITE}/${user.username}`
  const onThrowClicked = () => {
    // setHeaderContent(<div>ASGASGASGASG</div>)
    // return

    mixpanel.trackOrgDash('Start Throw Bash', { Location: 'headerButton' })
    if (draft && draft.tags && _.find(draft.tags, tag => tag.id === 1)) {
      dispatch(setDraft({
        name: '',
        startDate: null,
        endDate: null,
        location: '',
        callLink: '',
        description: '',
        covidMeasurements: '',
        responseType: 'INTEREST',
        limitNumberOfGuests: false,
        expectedNumberOfGuests: null,
        guestsCanSeeGuests: true,
        visibleOnProfile: true,
        ticketProducts: [],
        type: 'PUBLIC',
        dateToBeAnnounced: false,
        tags: [],
        ticketCurrency: 'EUR',
        guests: [
          {
            status: 'GOING',
            name: 'Adam',
            avatarUrl: adam
          },
          {
            status: 'GOING',
            name: 'Maggie',
            avatarUrl: maggie
          },
          {
            status: 'GOING',
            name: 'Christie',
            avatarUrl: christie
          },
          {
            status: 'MAYBE',
            name: 'Eric',
            avatarUrl: eric
          },
          {
            status: 'CANT',
            name: 'Anna',
            avatarUrl: anna
          }
        ],
        imageUrl: ''
      }))
    }
    history.push('/event')
  }

  const onNewEventPage = pathName === '/event' || (pathName.startsWith('/event/') && !pathName.endsWith('view') && !pathName.endsWith('attendees'))
  const onEventPage = pathName.startsWith('/event/') && (pathName.endsWith('view') || pathName.endsWith('attendees'))
  const onEditProfile = pathName === '/profile'

  const onOpenProfileClicked = (location) => {
    mixpanel.trackOrgDash('Profile Preview', { Location: location })
    window.open(profileLink)
  }

  const onSaveClicked = useCallback(() => {
    mixpanel.trackOrgDash('Save Profile Changes')
    patchMe(editingUser)
  }, [editingUser, patchMe])

  const onCancelEditClicked = () => {
    setMenuOpen(null)
    mixpanel.trackOrgDash('Cancel Profile Changes')
    history.push('/')
  }

  const usernameAvailable = () => {
    if (user) return (username.available.username === editingUser.username || user.username === editingUser.username) && editingUser.username
    else return true
  }

  const onCancelConfirmed = async () => {
    await cancelEvent(parseInt(eventId))
    setCancelOpen(false)
  }

  const onDeleteConfirmed = async () => {
    await deleteEvent(parseInt(eventId))
    setDeleteOpen(false)
  }

  const onPublishClicked = () => {
    publishEvent(parseInt(eventId))
  }

  const onViewAsVisitorClicked = () => {
    mixpanel.trackOrgEvent('Bash Preview', currentEvent)
    window.open(eventLink)
  }

  const onBreadcrumbClicked = (location) => {
    if (location !== pathName) history.push(location)
  }

  const onPreviewClicked = (type) => {
    if (previewType !== type) { togglePreviewType() }
  }

  const onEditProfileClicked = () => {
    setMenuOpen(null)
    history.push('/profile')
  }

  const duplicateEvent = () => {
    setDraft(currentEvent)
    history.push('/event')
  }

  const onCrossConfirm = () => {
    setExitOpen(false)
    dispatch(changeDraft('isGigEvent', false))
    history.goBack()
  }

  const continueEnabled = editingUser.name && editingUser.username && usernameAvailable()

  const eventMenuItems = () => {
    return (
      <div>
        {currentEvent.state !== 'CANCELED' &&
          <MenuItem key='edit-event' onClick={() => { history.push('/event/' + eventId); mixpanel.trackOrgEvent('Edit Bash', currentEvent); setMenuOpen(false) }}>
            {t('nav.header.menu.editEvent')}
          </MenuItem>}
        {currentEvent.state === 'ACTIVE' &&
          <Tooltip title={hasTicketOrder ? t('nav.header.menu.hasTicketOrder') : ''}>
            <div>
              <MenuItem disabled={hasTicketOrder || (!currentEvent.dateToBeAnnounced && moment(currentEvent.startDate).isBefore(moment()))} key='cancel-event' onClick={() => { setCancelOpen(true); setMenuOpen(false) }}>
                {t('nav.header.menu.cancelEvent')}
              </MenuItem>
            </div>
          </Tooltip>}
        <MenuItem key='dup-event' onClick={() => { duplicateEvent(); setMenuOpen(false) }}>
          {t('nav.header.menu.dupEvent')}
        </MenuItem>
        {currentEvent.state !== 'ACTIVE' &&
          <Tooltip title={hasTicketOrder ? t('nav.header.menu.hasTicketOrder') : ''}>
            <div>
              <MenuItem disabled={hasTicketOrder} key='delete-event' onClick={() => { setDeleteOpen(true); setMenuOpen(false) }}>
                {t('nav.header.menu.deleteEvent')}
              </MenuItem>
            </div>
          </Tooltip>}
      </div>
    )
  }

  return (
    <AppBar elevation={1} position='fixed' className={classNames([classes.root, fullWidth ? classes.fullWidth : null])}>
      <Toolbar classes={{ root: classes.minheight }}>
        {!onNewEventPage &&
          <IconButton
            color='primary'
            aria-label='open drawer'
            edge='start'
            onClick={toggleSidebar}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>}
        {onNewEventPage && <CloseIcon className={classes.closeIcon} onClick={() => onCrossConfirm()} />}
        <Hidden smDown><Logo className={classes.logo} /></Hidden>
        <div className={classes.breadcrumbsContainer}>
          {breadcrumbs && Object.keys(breadcrumbs).map((breadcrumb, i) => {
            return (
              <React.Fragment key={breadcrumb}>
                {i !== 0 && <Typography variant='subtitle1' className={classNames(classes.locationText, classes.chevron)}>›</Typography>}
                <Link onClick={() => onBreadcrumbClicked(breadcrumbs[breadcrumb].href)} variant='subtitle1' className={classes.locationText}>{breadcrumb}</Link>
              </React.Fragment>
            )
          })}
        </div>
        <Hidden className={classes.headerButtons} smDown implementation='css'>
          {!onEditProfile && !onNewEventPage && !onEventPage && <IconButton className={classes.createButton} onClick={onThrowClicked}><Plus /></IconButton>}
          {onEventPage && currentEvent.state === 'NEW' && <FlatButton disabled={saving} color='secondary' onClick={onPublishClicked} className={classes.publishButton} startIcon={saving ? <CircularProgress size={24} /> : <PublishIcon />} disableElevation>{t('nav.header.publish')}</FlatButton>}
          {onEventPage && user && currentEvent.state !== 'NEW' && <FlatButton color='secondary' onClick={onViewAsVisitorClicked} disableElevation className={classes.publishButton} startIcon={<Visibility />}>{t('nav.header.viewAsVisitor')}</FlatButton>}
          {onEditProfile &&
            <Button
              className={classes.saveButton} disabled={!continueEnabled || loading}
              variant='contained'
              disableElevation color='primary'
              onClick={onSaveClicked}
            >
              {loading ?
                <CircularProgress
                  size={24}
                  color='inherit'
                  className={classes.icon}
                /> : <Check className={classes.icon} />}
              {t('nav.header.save')}
            </Button>}
          {onNewEventPage && <IconButton onClick={() => onPreviewClicked('desktop')}><ComputerIcon className={previewType === 'desktop' ? classes.activePreview : null} /></IconButton>}
          {onNewEventPage && <IconButton onClick={() => onPreviewClicked('mobile')}><SmartPhoneIcon className={previewType === 'mobile' ? classes.activePreview : null} /></IconButton>}
          {onNewEventPage && (
            <YesNoDialog
              open={exitOpen}
              onClose={() => setExitOpen(false)}
              onConfirm={onCrossConfirm}
              destructive title={t('nav.header.dialog.exit.title')}
              subtitle={t('nav.header.dialog.exit.subtitle')}
              cancelText={t('nav.header.dialog.exit.cancel')}
              confirmText={t('nav.header.dialog.exit.confirm')}
            />
          )}
        </Hidden>

        {!onNewEventPage && (
          <>
            <IconButton onClick={e => setMenuOpen(e.currentTarget)} className={classes.moreButton}><MoreIcon /></IconButton>
            <Menu
              id='long-menu'
              keepMounted
              anchorEl={menuOpen}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={Boolean(menuOpen)}
              onClose={() => setMenuOpen(null)}
            >
              {onEventPage ?
                eventMenuItems() :
                !onEditProfile && (user &&
                  <div>
                    <MenuItem key='edit-profile' onClick={onEditProfileClicked}>
                      {t('nav.header.menu.editProfile')}
                    </MenuItem>
                    <MenuItem key='view-as-visitor' onClick={() => onOpenProfileClicked('headerButton')}>
                      {t('nav.header.menu.viewProfile')}
                    </MenuItem>
                  </div>)}
              {onEditProfile &&
                <MenuItem key='cancel-edit-profile' onClick={onCancelEditClicked}>
                  {t('nav.header.menu.cancel')}
                </MenuItem>}

            </Menu>
            <YesNoDialog
              loading={saving}
              onClose={() => setCancelOpen(false)} open={cancelOpen} title={t('nav.header.dialog.cancel.title')}
              confirmText={t('nav.header.dialog.cancel.confirm')}
              cancelText={t('nav.header.dialog.cancel.cancel')}
              destructive
              onConfirm={onCancelConfirmed}
              subtitle={t('nav.header.dialog.cancel.subtitle')}
            />
            <YesNoDialog
              loading={saving}
              onClose={() => setDeleteOpen(false)} open={deleteOpen} title={t('nav.header.dialog.delete.title')}
              confirmText={t('nav.header.dialog.delete.confirm')}
              cancelText={t('nav.header.dialog.delete.cancel')}
              destructive
              onConfirm={onDeleteConfirmed}
              subtitle={t('nav.header.dialog.delete.subtitle')}
            />
          </>
        )}

      </Toolbar>
    </AppBar>
  )
})

const mapStateToProps = ({ user, general, events }) => {
  return ({
    loading: user.loading,
    editingUser: user.editingUser,
    user: user.value.organisation,
    username: user.username,
    breadcrumbs: general.breadcrumbs,
    saving: events.saving,
    currentEvent: events.currentEvent,
    previewType: events.previewType
  })
}

export default connect(mapStateToProps, { patchMe, cancelEvent, deleteEvent, publishEvent, togglePreviewType, setDraft })(withStyles(styles)(Header))
