import { OPEN_MODAL, CLOSE_MODAL, SET_MODAL_INFO } from './types'

export const openModal = modal => ({
  type: OPEN_MODAL,
  payload: modal
})

export const closeModal = modal => ({
  type: CLOSE_MODAL,
  payload: modal
})

export const setModalInfo = (modal, info) => ({
  type: SET_MODAL_INFO,
  modal: modal,
  info: info
})
