import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import React, { useContext, useState } from 'react'
import { editEventContext } from '../../routes/EditEvent'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import { Check } from '@material-ui/icons'
import FlatButton from '@bash/shared-components/buttons/FlatButton'
import Checkbox from '@material-ui/core/Checkbox'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  root: {

  },
  checkCaption: {
    marginLeft: theme.spacing(1)
  },
  acceptButton: {
    float: 'right',
    marginTop: theme.spacing(3)
  }
}))

const WizardFreedomDayButtons = () => {
  const { t } = useTranslation('common')
  const classes = useStyles()
  const { setPage } = useContext(editEventContext)
  const [checkedDetails, setCheckedDetails] = useState(false)
  const [checkedRevenue, setCheckedRevenue] = useState(false)

  return (
    <div className={classes.root}>
      <FormControlLabel
        className={classes.checkBox}
        control={<Checkbox checked={checkedDetails} onChange={e => setCheckedDetails(e.target.checked)} name='details' color='primary' />}
        label={<Typography className={classes.checkCaption} variant='caption' color='textSecondary'>{t('event.freedomDay.checkmarkDetails')}<b>GiG Drinks B.V.</b></Typography>}
      />
      <FormControlLabel
        className={classes.checkBox}
        control={<Checkbox checked={checkedRevenue} onChange={e => setCheckedRevenue(e.target.checked)} name='revenue' color='primary' />}
        label={<Typography className={classes.checkCaption} variant='caption' color='textSecondary'>{t('event.freedomDay.checkmarkRevenue')}<b>GiG Drinks B.V.</b></Typography>}
      />
      <FlatButton disabled={!checkedDetails || !checkedRevenue} className={classes.acceptButton} onClick={() => setPage('0')} startIcon={<Check />}>{t('event.freedomDay.accept')}</FlatButton>
    </div>
  )
}

export default WizardFreedomDayButtons
