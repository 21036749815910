import React, { useContext } from 'react'
import { appContext } from './App'
import { useTranslation } from 'react-i18next'

import { Paper, Typography } from '@material-ui/core'
import InfoRow from './InfoRow'
import { linkToMaps } from '../utils/outboundLinks'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { getDateLines } from 'shared-components/utils/eventDisplay'
import GuestCount from 'shared-components/event/GuestCount'
import CovidBadge from './CovidBadge'

const useStyles = makeStyles(theme => ({
  detailCard: {
    flexGrow: 1,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2)
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  title: {
    fontWeight: 500,
    marginBottom: theme.spacing(0.5),
    marginRight: theme.spacing(2)
  },
  collapseIcon: {
    cursor: 'pointer'
  },
  locationRow: {
    marginTop: theme.spacing(1)
  },
  linkRow: {
    marginTop: theme.spacing(1),
    wordBreak: 'break-all'
  },
  infoRowAndWarningContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  },
  infoRowContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-start'
  },
  tag: {
    color: theme.palette.primary.main,
    textDecoration: 'none'
  }
}))

const EventDetails = (props, ref) => {
  const eventFromContext = useContext(appContext).event
  const event = props.event || eventFromContext
  const classes = useStyles()
  const { t } = useTranslation('shared')
  const dateLines = getDateLines(event)

  return (
    <div className={props.className} ref={ref}>
      <Paper className={classes.detailCard} paper={2}>
        <div className={classes.titleContainer}>
          <Typography variant='h6' color={!event.name ? 'textSecondary' : 'inherit'} className={classes.title}>
            <b>{event.name || 'Event title'}</b>
          </Typography>
          <GuestCount event={props.event} />
        </div>
        <div className={classes.infoRowAndWarningContainer}>
          <div className={classes.infoRowContainer}>
            {event.startDate != null &&
              <InfoRow
                key='date'
                type='date'
                text={event.dateToBeAnnounced ? t('toBeAnnounced') : event.startDate ? dateLines[0] : 'Start date'}
                secondLine={dateLines[1]}
              />}
            {!(event.location == null || event.location === '') &&
              <InfoRow
                className={classes.locationRow}
                key='location'
                type='location'
                text={event.location}
                onClick={() => linkToMaps(event.location)}
              />}
            {!(event.callLink == null || event.callLink === '') &&
              <InfoRow
                className={classes.linkRow}
                key='link'
                type='link'
                text={event.callLink}
              />}
            {event.tags != null && event.tags.length > 0 &&
              <InfoRow
                className={classes.linkRow}
                key='tags'
                type='tags'
                text={
                  <>
                    {event.tags.map(tag =>
                      <a
                        key={tag.id}
                        href={tag.url}
                        target='_blank'
                        rel='noreferrer'
                        className={classes.tag}
                      >
                        #{tag.name}{' '}
                      </a>)}
                  </>
                }
              />}
          </div>
          <CovidBadge />
        </div>
      </Paper>
    </div>
  )
}

export default React.forwardRef(EventDetails)
