import React, { useCallback, useEffect, useRef, useState } from 'react'
import { withStyles } from '@material-ui/styles'
import Sidebar from './Sidebar'
import MainContent from './MainContent'
import Header from './Header'
import { useLocation } from 'react-router'
import classNames from 'classnames'
import FeedbackDialog from '../dialog/FeedbackDialog'
import Hidden from '@material-ui/core/Hidden'
import Fab from '@material-ui/core/Fab'
import PlusIcon from '@material-ui/icons/Add'
import { history } from '../../config/store'
import mixpanel from '../../util/mixpanel'
import AcceptInviteDialog from '../dialog/AcceptInviteDialog'
import InviteMemberDialog from '../dialog/InviteMemberDialog'
import { IntercomProvider, useIntercom } from 'react-use-intercom'
import { useSelector } from 'react-redux'
import { useMediaQuery, useTheme } from '@material-ui/core'

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex'
  },
  content: {
    width: '100%',
    flexGrow: '1'
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  }
})

export const NavContext = React.createContext({})

const MainContainer = React.memo(({ classes }) => {
  const pathName = useLocation().pathname
  // const showNavUi = pathName === '/' || pathName === '/events' || pathName === '/followers' || pathName === '/profile'

  const onThrowClicked = () => {
    mixpanel.trackOrgDash('Start Throw Bash')
    history.push('/event')
  }

  // const showHeader = pathName.toLowerCase().startsWith('/event') && !pathName.toLowerCase().endsWith('view') && !pathName.toLowerCase().endsWith('attendees') && pathName.toLowerCase() !== '/events'
  // const showNavUi = !showHeader && !pathName.toLowerCase().startsWith('/signin') && pathName !== '/newprofile' && pathName !== '/createorg'

  const showFab = pathName === '/' || pathName === '/events'

  // const lowerCase = pathName.toLowerCase()
  // const hideSideBar = lowerCase.startsWith('/event') && !lowerCase.endsWith('view') && lowerCase !== '/events' && lowerCase !== '/events/past' && !lowerCase.endsWith('attendees')
  // const hideHeader = lowerCase.startsWith('/settings')
  // const hideAllNav = lowerCase.startsWith('/signin') || lowerCase === '/newprofile' || lowerCase === '/createorg' || lowerCase.startsWith('/acceptinvite') || lowerCase === '/selectorg'
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [showHeader, setShowHeader] = useState(true)
  const [showSidebar, setShowSidebar] = useState(true)
  const [headerContent, setHeaderContent] = useState(<div>aSG</div>)
  const setDefault = () => {
    setShowHeader(true)
    setShowSidebar(true)
  }

  const navContextVal = {
    showHeader,
    setShowHeader,
    showSidebar,
    setShowSidebar,
    headerContent,
    setHeaderContent,
    setDefault
  }

  const theme = useTheme()
  const notMobile = useMediaQuery(theme.breakpoints.up('md'))

  const toggleSidebar = useCallback(() => {
    setMobileOpen(!mobileOpen)
  }, [mobileOpen])

  const org = useSelector(state => state.user.value.organisation)
  const user = useSelector(state => state.user.user)

  const { boot, update } = useIntercom()

  useEffect(() => {
    if (user && org) {
      update({
        name: user.name,
        userId: user.id,
        email: user.emailAddress,
        company: {
          name: org.name,
          companyId: org.id,
          website: `${process.env.REACT_APP_WEBSITE}/${org.username}`
        },
        hideDefaultLauncher: !notMobile
      })
    } else {
      boot({
        hideDefaultLauncher: !notMobile,
        email: null,
        name: null,
        userId: null,
        company: null
      })
    }
  }, [boot, notMobile, org, update, user])

  useEffect(() => {
    update({
      hideDefaultLauncher: !notMobile
    })
  }, [notMobile, update])

  return (
    <NavContext.Provider value={navContextVal}>
      <div className={classes.root}>
        {/* {!hideHeader && !hideAllNav && <Header fullWidth={hideSideBar} mobileOpen={mobileOpen} toggleSidebar={toggleSidebar} />} */}
        {/* {!hideSideBar && !hideAllNav && <Sidebar mobileOpen={mobileOpen} toggleSidebar={toggleSidebar} />} */}
        {showHeader && <Header fullWidth={!showSidebar} mobileOpen={mobileOpen} toggleSidebar={toggleSidebar} />}
        {showSidebar && <Sidebar mobileOpen={mobileOpen} toggleSidebar={toggleSidebar} />}
        <div className={classNames([classes.content])}>
          <MainContent />
          <FeedbackDialog />
          <AcceptInviteDialog />
          <InviteMemberDialog />
        </div>
        {showFab &&
          <Hidden mdUp>
            <Fab color='secondary' onClick={onThrowClicked} className={classNames(classes.fab)}><PlusIcon /></Fab>
          </Hidden>}
      </div>
    </NavContext.Provider>
  )
})

export default withStyles(styles)(MainContainer)
