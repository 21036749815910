import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles, Avatar } from '@material-ui/core'
import { getGuestName } from 'shared-components/utils/eventDisplay'

const styles = theme => ({
  avatar: {
    color: '#fff',
    backgroundColor: '#333333' // colorpicked from party animals background
  },
  shadow: {
    boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.2)'
  }
})

const GuestAvatar = ({ classes, guest, src, size, className, style, shadow }) => {
  const guestName = getGuestName(guest)
  return (
    <Avatar
      alt={guestName}
      src={src || (guest.user ? guest.user.avatarUrl : guest.avatarUrl)}
      style={{ width: size, height: size, ...style }}
      className={classNames(className, { [classes.shadow]: shadow })}
    />
  )
}

GuestAvatar.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(GuestAvatar)
