import { withStyles } from '@material-ui/core'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import StateBadge from './StateBadge'
import classNames from 'classnames'
import Tooltip from '@material-ui/core/Tooltip'
import { Block } from '@material-ui/icons'

const styles = theme => ({
  root: {
    background: theme.palette.grey.main,
    borderRadius: 8,
    width: '100%',
    marginTop: theme.spacing(2),
    '&:before': {
      display: 'none'
    },
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  cursorDefault: {
    cursor: 'default'
  },
  icon: {
    marginRight: theme.spacing(2.5)
  },
  price: {
    marginLeft: 'auto'
  },
  defaultBadge: {
    marginLeft: 'auto',
    marginRight: theme.spacing(2)
  },
  paddingVertical1: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  block: {
    opacity: 0.6,
    marginLeft: theme.spacing(2)
  }
})

const TicketField = ({ classes, isAddButton, isDefault, ticketProduct, onClick, restricted }) => {
  return (
    <div onClick={restricted ? undefined : onClick} className={classNames(classes.root, isDefault && classes.paddingVertical1, restricted && classes.cursorDefault)}>
      {isAddButton ? <AddIcon className={classes.icon} style={{ fontSize: 16 }} color='primary' /> : null}
      <Typography variant='button'>{isAddButton ? 'Add other option' : ticketProduct.name}</Typography>
      {isDefault && (
        <Tooltip title='This price will be shown on the main page for this event'>
          <StateBadge className={classes.defaultBadge} color='primary' text='DEFAULT' />
        </Tooltip>
      )}
      {!isAddButton && <Typography className={isDefault ? undefined : classes.price} color='secondary' variant='caption'><b>€{parseFloat(ticketProduct.price).toFixed(2).replace('.', ',')}</b></Typography>}
      {restricted && <Block className={classes.block} />}
    </div>
  )
}

export default (withStyles(styles)(TicketField))
