import React, { useCallback } from 'react'
import { withStyles } from '@material-ui/styles'
import SideNavItem from './SideNavItem'
import SideProfileItem from './SideProfileItem'
import { useLocation } from 'react-router-dom'
import { history } from '../../config/store'
import Hidden from '@material-ui/core/Hidden'
import mixpanel from '../../util/mixpanel'
import Drawer from '@material-ui/core/Drawer'
import { connect, useDispatch, useSelector } from 'react-redux'
import Divider from '@material-ui/core/Divider'
import StateBadge from '../StateBadge'
import { openModal } from '../../model/modals/actions'
import BackNavItem from './BackNavItem'
import useTreatment from '../../util/useTreatment'
import { useTranslation } from 'react-i18next'
import { useIntercom } from 'react-use-intercom'

const styles = theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      width: theme.sidebar.width,
      flexShrink: 0
    },
    border: 'none'
  },
  sidebar: {
    [theme.breakpoints.up('md')]: {
      flexShrink: 0
    },
    width: theme.sidebar.width,
    padding: theme.spacing(2, 0, 2, 0),
    boxShadow: theme.shadows[2],
    border: 'none'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  sidebarButtons: {
    marginTop: 'auto'
  },
  icon: {
    marginRight: theme.spacing(),
    height: 24,
    width: 24,
    '& *': {
      fill: 'currentColor'
    }
  },
  divider: {
    marginBottom: theme.spacing(1)
  },
  inactiveBadge: {
    marginLeft: theme.spacing(2),
    alignSelf: 'flex-start',
    marginTop: theme.spacing(2)
  },
  firstItem: {
    marginTop: theme.spacing(4)
  },
  addTeamMembers: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2)
  }
})

const Sidebar = React.memo(({ classes, toggleSidebar, mobileOpen }) => {
  const { t } = useTranslation('common')
  const user = useSelector(state => state.user.value.organisation)
  const dispatch = useDispatch()
  const { show } = useIntercom()

  const toHome = () => {
    mobileOpen && toggleSidebar()
    mixpanel.trackOrgDash('Check Home')
    history.push('/')
  }

  const toEvents = () => {
    mobileOpen && toggleSidebar()
    history.push('/events')
  }

  const toFollowers = () => {
    mobileOpen && toggleSidebar()
    history.push('/followers')
  }

  const onThrowClicked = () => {
    mixpanel.trackOrgDash('Start Throw Bash')
    history.push('/event')
  }

  const onAddTeamMembersClicked = () => {
    dispatch(openModal('invite'))
  }

  const onChatWithUsClicked = useCallback(() => {
    show()
  }, [show])

  const profileLink = user && `${process.env.REACT_APP_WEBSITE}/${user.username}`
  const onOpenProfileClicked = () => {
    mixpanel.trackOrgDash('Profile Preview')
    window.open(profileLink)
  }

  const pathName = useLocation().pathname
  const container = window !== undefined ? () => window.document.body : undefined
  const showTicketingSplit = useTreatment('ticketing') === 'on'

  const showSettings = pathName.startsWith('/settings')

  const sideBarContentSettings = (
    <div className={classes.container}>
      <BackNavItem onClick={() => history.push('/')} name={t('nav.sidebar.settings.settings')} />
      {showTicketingSplit && <SideNavItem className={classes.firstItem} name={t('nav.sidebar.settings.payments')} active={pathName === '/settings/payments/mollie'} onClick={() => history.push('/settings/payments/mollie')} />}
      <SideNavItem className={showTicketingSplit ? undefined : classes.firstItem} name={t('nav.sidebar.settings.members')} active={pathName === '/settings/members'} onClick={() => history.push('/settings/members')} />
    </div>
  )

  const sideBarContent = (
    <div className={classes.container}>
      <SideProfileItem />
      <SideNavItem className={classes.firstItem} name={t('nav.sidebar.profile')} active={pathName === '/'} onClick={toHome} />
      {/* <SideNavItem name={t('nav.sidebar.events')} active={pathName === '/events'} onClick={toEvents} /> */}
      <SideNavItem name={t('nav.sidebar.followers')} active={pathName === '/followers'} onClick={toFollowers} />

      <Hidden className={classes.sidebarButtons} smDown implementation='css'>
        <Divider className={classes.divider} />
        <SideNavItem color='textSecondary' className={classes.addTeamMembers} name={t('nav.sidebar.addTeamMembers')} onClick={onAddTeamMembersClicked} />
        <SideNavItem color='textSecondary' name={t('nav.sidebar.sendFeedback')} onClick={() => dispatch(openModal('feedback'))} />
      </Hidden>
      <Hidden className={classes.sidebarButtons} mdUp implementation='css'>
        <Divider className={classes.divider} />
        <SideNavItem color='textSecondary' className={classes.addTeamMembers} name={t('nav.sidebar.chatWithUs')} onClick={onChatWithUsClicked} />
        <SideNavItem color='textSecondary' name={t('nav.sidebar.addTeamMembers')} onClick={onAddTeamMembersClicked} />
        <SideNavItem color='textSecondary' name={t('nav.sidebar.sendFeedback')} onClick={() => dispatch(openModal('feedback'))} />
        <SideNavItem color='textSecondary' name={t('nav.sidebar.viewProfile')} onClick={onOpenProfileClicked} />
      </Hidden>
    </div>
  )

  return (
    <nav className={classes.root}>
      <Hidden mdUp implementation='css'>
        <Drawer
          container={container}
          variant='temporary'
          anchor='left'
          open={mobileOpen}
          onClose={toggleSidebar}
          classes={{
            paper: classes.sidebar
          }}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {showSettings ? sideBarContentSettings : sideBarContent}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation='css'>
        <Drawer
          classes={{
            paper: classes.sidebar
          }}
          variant='permanent'
          open
        >
          {showSettings ? sideBarContentSettings : sideBarContent}
        </Drawer>
      </Hidden>
    </nav>
  )
})

export default withStyles(styles)(Sidebar)
