import { withStyles } from '@material-ui/core'
import React, { useContext } from 'react'
import Typography from '@material-ui/core/Typography'
import TicketField from '../TicketField'
import FlatButton from '@bash/shared-components/buttons/FlatButton'
import { editEventContext } from '../../routes/EditEvent'
import { useTranslation } from 'react-i18next'

const styles = theme => ({
  root: {

  },
  marginTopHalf: {
    marginTop: theme.spacing(0.5)
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3)
  },
  cancelButton: {
    backgroundColor: theme.palette.grey.main,
    color: 'black',
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    },
    marginRight: theme.spacing(2)
  },
  backButton: {
    backgroundColor: theme.palette.grey.main,
    color: 'black',
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    }
  }
})

const TicketsScreen = ({ classes, ticketProducts, openProduct, onSave, onBack }) => {
  const { t } = useTranslation('common')
  const { isGig } = useContext(editEventContext)
  return (
    <div className={classes.root}>
      <Typography variant='subtitle1'>{t('event.edit.tickets.title')}</Typography>
      <Typography className={classes.marginTopHalf} variant='caption'>{t('event.edit.tickets.subtitle')}</Typography>
      {ticketProducts && ticketProducts.map(ticketProduct => <TicketField key={ticketProduct.id || ticketProduct.localId} restricted={isGig} isDefault={ticketProduct.weight === 0} onClick={() => openProduct(ticketProduct)} ticketProduct={ticketProduct} />)}
      {!isGig && <TicketField isAddButton onClick={() => openProduct({ localId: Math.random().toString(36).substring(7), name: 'Option name', currency: 'EUR', price: 0, weight: 2 })} />}

      <div className={classes.buttons}>
        {isGig && <FlatButton className={classes.backButton} onClick={onBack}>{t('back')}</FlatButton>}
        {!isGig && <FlatButton className={classes.cancelButton} onClick={onBack}>{t('cancel')}</FlatButton>}
        {!isGig && <FlatButton onClick={onSave}>{t('save')}</FlatButton>}
      </div>

    </div>)
}

export default (withStyles(styles)(TicketsScreen))
