import typeToReducer from 'type-to-reducer'
import maggie from '../../images/dummyprofiles/maggie.png'
import adam from '../../images/dummyprofiles/adam.png'
import christie from '../../images/dummyprofiles/christie.png'
import eric from '../../images/dummyprofiles/eric.png'
import anna from '../../images/dummyprofiles/anna.png'
import {
  CHANGE_DRAFT,
  CHANGE_EVENT,
  RECEIVE_EVENT,
  RECEIVE_MY_EVENTS,
  RECEIVE_MY_PAST_EVENTS,
  SAVED_EVENT,
  UPLOAD_EVENT_IMAGE,
  START_SAVING,
  DELETE_EVENT,
  RECEIVE_REACHED_MISSED,
  STOP_SAVING,
  SET_CURRENT_EVENT, TOGGLE_PREVIEW,
  SET_DRAFT
} from './types'
import _ from 'underscore'

const initialState = {
  loading: true,
  value: null,
  saving: false,
  pastEvents: null,
  imageVersion: undefined,
  previewType: 'mobile',
  draft: {
    name: '',
    startDate: null,
    endDate: null,
    location: '',
    callLink: '',
    description: '',
    covidMeasurements: '',
    responseType: 'INTEREST',
    limitNumberOfGuests: false,
    expectedNumberOfGuests: null,
    guestsCanSeeGuests: true,
    visibleOnProfile: true,
    ticketProducts: [],
    type: 'PUBLIC',
    ticketLink: null,
    ticketPrice: null,
    ticketCurrency: 'EUR',
    dateToBeAnnounced: false,
    tags: [],
    guests: [
      {
        status: 'GOING',
        name: 'Adam',
        avatarUrl: adam
      },
      {
        status: 'GOING',
        name: 'Maggie',
        avatarUrl: maggie
      },
      {
        status: 'GOING',
        name: 'Christie',
        avatarUrl: christie
      },
      {
        status: 'MAYBE',
        name: 'Eric',
        avatarUrl: eric
      },
      {
        status: 'CANT',
        name: 'Anna',
        avatarUrl: anna
      }
    ],
    imageUrl: ''
  },
  currentEvent: {
    state: ''
  }
}

export default typeToReducer({
  [CHANGE_DRAFT]: (state, { key, value }) => ({
    ...state,
    draft: {
      ...state.draft,
      [key]: value
    }
  }),
  [SET_DRAFT]: (state, { newDraft }) => ({
    ...state,
    draft: {
      ...newDraft,
      ticketProducts: newDraft.ticketProducts ? _.map(newDraft.ticketProducts, ticketProduct => ({ ...ticketProduct, id: undefined, localId: Math.random().toString(36).substring(7) })) : [],
      imageUrl: newDraft.isGigEvent ? newDraft.imageUrl : (newDraft.imageUrl.startsWith('blob') ? newDraft.imageUrl : '')
    }
  }),
  [CHANGE_EVENT]: (state, { eventId, key, value }) => ({
    ...state,
    value: _.map(state.value, event => event.id === eventId ? { ...event, [key]: value } : event)
  }),
  [RECEIVE_MY_EVENTS]: (state, { events, pastEvents }) => ({
    ...state,
    value: events,
    pastEvents: pastEvents,
    loading: false
  }),
  [RECEIVE_MY_PAST_EVENTS]: (state, { events }) => ({
    ...state,
    pastEvents: events,
    loading: false
  }),
  [RECEIVE_EVENT]: (state, { event }) => ({
    ...state,
    value: _.uniq([event].concat(state.value), event => event.id),
    loading: false
  }),
  [SET_CURRENT_EVENT]: (state, { event }) => ({
    ...state,
    currentEvent: event
  }),
  [RECEIVE_REACHED_MISSED]: (state, { reachedAndMissed, updateId, eventId }) => ({
    ...state,
    value: _.map(state.value, event => event.id === eventId ? { ...event, reachedAndMissed: { ...event.reachedAndMissed, [updateId]: reachedAndMissed } } : event)
  }),
  [DELETE_EVENT]: (state, { event }) => ({
    ...state,
    value: null,
    loading: false,
    saving: false
  }),
  [START_SAVING]: (state) => ({
    ...state,
    saving: true
  }),
  [STOP_SAVING]: (state) => ({
    ...state,
    saving: false
  }),
  [TOGGLE_PREVIEW]: (state) => ({
    ...state,
    previewType: state.previewType === 'mobile' ? 'desktop' : 'mobile'
  }),
  [SAVED_EVENT]: (state, { event }) => ({
    ...state,
    value: _.uniq([event].concat(state.value), event => event.id),
    saving: false
  }),
  [UPLOAD_EVENT_IMAGE]: (state, { eventId }) => ({
    ...state,
    value: state.value.map(event => event.id === eventId ? { ...event } : event),
    imageVersion: state.imageVersion ? state.imageVersion++ : 1
  })
}, initialState)
