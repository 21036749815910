import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { withTranslation } from 'react-i18next'
import useDimensions from 'react-cool-dimensions'

import StatsRow from './StatsRow'
import { Typography, SvgIcon } from '@material-ui/core'
import { ReactComponent as VerifiedIcon } from '../images/check-decagram.svg'
import PlaceIcon from '@material-ui/icons/Place'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import GuestAvatar from '../avatar/GuestAvatar'
import ConditionalWrapper from '../common/ConditionalWrapper'

import mixpanel from 'shared-components/utils/mixpanel'
import FlatButton from 'shared-components/buttons/FlatButton'
import { OrgShareButton } from 'shared-components/common/ShareButton'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 560,
    display: 'grid',
    gridRowGap: theme.spacing(2),
    gridColumnGap: props => props.width > theme.breakpoints.values.container ? theme.spacing(6) : theme.spacing(2),
    gridTemplateRows: '88px 1fr 1fr',
    gridTemplateColumns: 'repeat(3, 1fr)'
  },
  logoContainer: {
    gridColumn: '1 / 2',
    gridRow: '1 / 2',
    width: props => props.width > theme.breakpoints.values.container ? '100%' : '90px',
    height: props => props.width > theme.breakpoints.values.container ? '100%' : '90px',
    paddingTop: props => props.width > theme.breakpoints.values.container ? '100%' : 0,
    position: 'relative'
  },
  statsContainer: {
    gridColumn: '2 / 4',
    gridRow: '1 / 2',
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  infoContainer: {
    gridRow: '2 / 4',
    gridColumn: props => props.width > theme.breakpoints.values.container ? '2 / 4 ' : '1 / 4'
  },
  logoAndStatsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  },
  logo: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '100%'
  },
  locationRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1.5)
  },
  organisationName: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  verifiedIcon: {
    marginLeft: theme.spacing(1),
    width: 20,
    height: 20
  },
  placeIcon: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.caption.fontSize,
    marginRight: theme.spacing(1)
  },
  followButton: {
    width: '100%',
    margin: theme.spacing(3, 0)
  },
  followingButton: {
    width: 'fit-content',
    color: '#000',
    backgroundColor: theme.palette.grey.main
  },
  doneIcon: {
    marginRight: theme.spacing(1)
  },
  iconContainer: {
    position: 'absolute',
    left: theme.spacing(2),
    '& > :first-child': {
      marginRight: theme.spacing(1)
    }
  },
  badge: {
    backgroundColor: theme.palette.red.main,
    color: 'white'
  },
  badgePlacement: {
    transform: 'scale(1) translate(10%, -50%)'
  },
  organiserName: {
    fontWeight: 700,
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(3),
    '& > :first-child': {
      marginRight: theme.spacing(2)
    }
  },
  clampDescription: {
    lineClamp: 8,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical'
  }
}))

const trackOrgLink = (username, location) => () => mixpanel.trackOrg('Check Organisation', { organisation: username, action: location })

const OrganisationInfo = ({ t, organisation, onFollow, onFollowing, isFollowing, small, hideButtons, clickable, location, LinkComponent, clampDescription = false, customButtons }) => {
  const { ref, width } = useDimensions()
  const classes = useStyles({ width })
  return (
    <div className={classes.root} ref={ref}>
      <div className={classes.logoContainer}>
        <ConditionalWrapper
          condition={clickable && LinkComponent}
          wrapper={children => (<LinkComponent to={`/${organisation.username}`} onClick={trackOrgLink(organisation.username, location)}>{children}</LinkComponent>)}
        >
          <GuestAvatar guest={organisation} className={classes.logo} shadow />
        </ConditionalWrapper>
      </div>
      <div className={classes.statsContainer}>
        <StatsRow organisation={organisation} />
      </div>
      <div className={classes.infoContainer}>
        <ConditionalWrapper
          condition={clickable && LinkComponent}
          wrapper={children =>
            <LinkComponent
              to={`/${organisation.username}`}
              className={classes.organiserName}
              onClick={trackOrgLink(organisation.username, location)}
            >
              {children}
            </LinkComponent>}
        >
          <Typography variant='subtitle1' className={classes.organisationName}>
            {organisation.name}
            {organisation.verified && <SvgIcon component={VerifiedIcon} className={classes.verifiedIcon} color='secondary' />}
          </Typography>
        </ConditionalWrapper>
        {!small && organisation.location &&
          <div className={classes.locationRow}>
            <PlaceIcon className={classes.placeIcon} fontSize='inherit' />
            <Typography variant='caption' color='textSecondary'>{organisation.location}</Typography>
          </div>}
        <Typography variant='caption' color='textSecondary' className={clampDescription ? classes.clampDescription : undefined}>{organisation.description}</Typography>
        {!hideButtons &&
          (customButtons ||
            <div className={classes.buttonsContainer}>
              {(isFollowing ?
                <FlatButton
                  color='grey'
                  startIcon={<PlaylistAddCheckIcon color='primary' />}
                  onClick={onFollowing}
                >
                  {t('following')}
                </FlatButton> :
                <FlatButton
                  color='secondary'
                  onClick={onFollow}
                  startIcon={<PlaylistAddIcon />}
                >
                  {t('follow')}
                </FlatButton>)}
              <OrgShareButton organisation={organisation} />
            </div>)}
      </div>
    </div>
  )
}

export default withTranslation('common')(OrganisationInfo)
