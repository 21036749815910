import React from 'react'
import { withStyles } from '@material-ui/styles'
import { Button, CircularProgress } from '@material-ui/core'
import classNames from 'classnames'

const styles = theme => ({
  root: {
    flex: 'none'
  },
  iconSpace: {
    '& > *:first-child': {
      justifyContent: 'space-between'
    }
  },
  fill: {
    width: 24
  },
  progress: {
    position: 'absolute',
    right: theme.spacing(2),
    color: 'white'
  },
  inactivePrimary: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.lighter
    }
  },
  inactiveTertiary: {
    backgroundColor: theme.palette.tertiary.light,
    color: theme.palette.tertiary.main,
    '&:hover': {
      backgroundColor: theme.palette.tertiary.lighter
    }
  },
  tertiaryColor: {
    color: theme.palette.tertiary.contrastText,
    backgroundColor: theme.palette.tertiary.main,
    '&:hover': {
      backgroundColor: theme.palette.tertiary.dark,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.tertiary.main
      }
    }
  },
  inactiveGrey: {
    backgroundColor: theme.palette.grey.light,
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: theme.palette.grey.dark
    }
  },
  greyColor: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.grey.main,
    '&:hover': {
      backgroundColor: theme.palette.grey.dark,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.grey.main
      }
    }
  }
})

const FlatButton = ({
  classes,
  color = 'primary',
  variant = 'contained',
  children,
  disabled,
  loading,
  disableElevation = true,
  className,
  inactive,
  startIcon,
  endIcon,
  fullWidth,
  ...otherProps
}, ref) => {
  return (
    <Button
      ref={ref}
      disabled={disabled || loading}
      disableElevation={disableElevation}
      variant={variant}
      color={color === 'tertiary' || color === 'grey' ? 'inherit' : color}
      startIcon={fullWidth && endIcon && !startIcon ? <span className={classes.fill} /> : startIcon}
      endIcon={fullWidth && startIcon && !endIcon ? <span className={classes.fill} /> : endIcon}
      fullWidth={fullWidth}
      size='large'
      className={
        classNames(
          className,
          classes.root,
          {
            [classes.iconSpace]: fullWidth && (startIcon || endIcon),
            [classes.inactivePrimary]: inactive && color === 'primary',
            [classes.tertiaryColor]: !inactive && color === 'tertiary',
            [classes.inactiveTertiary]: inactive && color === 'tertiary',
            [classes.greyColor]: !inactive && color === 'grey',
            [classes.inactiveGrey]: inactive && color === 'grey'
          })
      }
      {...otherProps}
    >
      {children}
      {loading &&
        <CircularProgress
          size={24}
          color='inherit'
          className={classes.progress}
        />}
    </Button>
  )
}

export default withStyles(styles)(React.forwardRef(FlatButton))
