import { withStyles } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import TextInput from '../TextInput'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import FlatButton from '@bash/shared-components/buttons/FlatButton'
import { useTranslation } from 'react-i18next'
import { editEventContext } from '../../routes/EditEvent'

const styles = theme => ({
  root: {

  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3)
  },
  backButton: {
    backgroundColor: theme.palette.grey.main,
    color: 'black',
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    },
    marginRight: theme.spacing(2)
  },
  subtitle: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(3)
  }
})

// const BasicScreen = ({ classes, title, subtitle, defaultValue, onSave, onBack, ...other }) => {
//   const { t } = useTranslation('common')
//   const [value, setValue] = useState(defaultValue)
//
//   return (
//     <div className={classes.root}>
//       <Typography variant='subtitle1'>{title}</Typography>
//       <Typography className={classes.subtitle} variant='caption'>{subtitle}</Typography>
//       <TextInput autoFocus onKeyUp={(e) => { if (e.key === 'Enter' && !{ ...other }.multiline) { onSave(value); onBack() } }} value={value} onChange={e => setValue(e.target.value)} {...other} />
//       <div className={classes.buttons}>
//         <FlatButton className={classes.backButton} onClick={() => { setValue(''); onBack() }}>{t('cancel')}</FlatButton>
//         <FlatButton onClick={() => { onSave(value); onBack() }}>{t('save')}</FlatButton>
//       </div>
//     </div>)
// }

const BasicScreen = ({ classes, title, subtitle, value, onSave, onBack, ...other }) => {
  const { t } = useTranslation('common')
  const [originalValue, setOgValue] = useState(value)

  return (
    <div className={classes.root}>
      <Typography variant='subtitle1'>{title}</Typography>
      <Typography className={classes.subtitle} variant='caption'>{subtitle}</Typography>
      <TextInput autoFocus onKeyUp={(e) => { if (e.key === 'Enter' && !{ ...other }.multiline) { onBack() } }} value={value} onChange={e => onSave(e.target.value)} />
      <div className={classes.buttons}>
        <FlatButton className={classes.backButton} onClick={() => { onSave(originalValue); onBack() }}>{t('cancel')}</FlatButton>
        <FlatButton onClick={() => { onBack() }}>{t('save')}</FlatButton>
      </div>
    </div>)
}

export default (withStyles(styles)(BasicScreen))
