import { Button, withStyles } from '@material-ui/core'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import moment from 'moment'
import DescriptionIcon from '@material-ui/icons/ChatBubble'
import VideoCam from '@material-ui/icons/Videocam'
import FavoriteIcon from '@material-ui/icons/Favorite'
import PeopleIcon from '@material-ui/icons/People'
import { Edit, LocalActivity, VisibilityOff } from '@material-ui/icons'
import DollarIcon from '@material-ui/icons/AttachMoney'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Accordion from '@material-ui/core/Accordion'
import React, { useContext, useState } from 'react'
import { eventContext } from '../../routes/Event2'
import { history } from '../../config/store'
import mixpanel from '../../util/mixpanel'
import { useTranslation } from 'react-i18next'
import AnnounceDateDialog from '../dialog/AnnounceDateDialog'
import EventIcon from '@material-ui/icons/Event'
import _ from 'underscore'
import { useLocation } from 'react-router'

const styles = theme => ({
  root: {
    padding: 0,
    marginBottom: theme.spacing(2),
    borderRadius: '8px',
    borderBottom: '1px solid #EBEBEB',
    '&:before': {
      display: 'none'
    }
  },
  accordionSummaryContent: {
    display: 'block',
    margin: 0,
    minWidth: 0
  },
  accordionSummary: {
    padding: theme.spacing(2),
    alignItems: 'end'
  },
  expandIcon: {
    padding: 0,
    marginRight: 0
  },
  fieldIcons: {
    display: 'flex',
    position: 'absolute',
    right: theme.spacing(2),
    bottom: theme.spacing(2),
    transition: 'opacity 0.3s ease',
    '& svg': {
      color: 'rgba(0, 0, 0, .38)',
      marginLeft: theme.spacing(2)
    }
  },
  locationText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '55%'
  },
  tags: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '55%'
  },
  detailsField: {
    whiteSpace: 'pre-wrap',
    display: 'flex',
    marginTop: theme.spacing(2),
    '& svg': {
      color: 'rgba(0, 0, 0, .6)',
      marginRight: theme.spacing(2)
    },
    '&:first-child': {
      marginTop: 0
    },
    color: theme.palette.text.secondary,
    fontSize: '0.813rem',
    fontWeight: '400'
  },
  accordionDetails: {
    flexDirection: 'column',
    borderTop: '1px solid #EDEDED',
    padding: theme.spacing(2)
  },
  mTop1: {
    marginTop: theme.spacing(1)
  },
  mTopHalf: {
    marginTop: theme.spacing(0.5)
  },
  mTop2: {
    marginTop: theme.spacing(2)
  },
  date: {
    color: theme.palette.tertiary.main
  },
  expandedSummary: {
    margin: '0 !important',
    minHeight: 'unset !important',
    '& $fieldIcons': {
      opacity: 0
    }
  },
  dateClickable: {
    cursor: 'pointer',
    display: 'inline-block',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
})

const EventDetails = ({ classes }) => {
  const { t } = useTranslation('common')
  const { event } = useContext(eventContext)
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const [announceOpen, setAnnounceOpen] = useState(Boolean(query.get('announceDate')))
  const isGig = _.find(event.tags, tag => tag.name === 'FreedomDay')

  const onEditClicked = () => {
    history.push('/event/' + event.id)
    mixpanel.trackOrgEvent('event.Edit Bash', event)
  }

  const date = event.dateToBeAnnounced ? t('event.edit.dateTba') : moment(event.startDate).format('DD MMMM HH:mm') + (event.endDate ? ' - ' + moment(event.endDate).format('DD MMMM HH:mm') : '')

  return (
    <Accordion
      className={classes.root}
      square
    >
      <AccordionSummary
        classes={{
          content: classes.accordionSummaryContent,
          expanded: classNames(classes.expandedSummary),
          expandIcon: classes.expandIcon
        }}
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography variant='subtitle1'>{event.state === 'CANCELED' ? event.name + t('event.view.cancelled') : event.name}</Typography>
        <Typography onClick={(event.dateToBeAnnounced && !isGig) ? (e) => { e.stopPropagation(); setAnnounceOpen(true) } : undefined} className={classNames(classes.mTop1, classes.date, (event.dateToBeAnnounced && !isGig) && classes.dateClickable)} variant='body2'>{date}</Typography>
        <Typography className={classNames(classes.mTopHalf, classes.locationText)} variant='body2' color='textSecondary'>{event.location}</Typography>
        <Typography className={classNames(classes.mTopHalf, classes.tags)} variant='body2' color='primary'>{_.map(event.tags, tag => <span>#{tag.name} </span>)}</Typography>
        <div className={classes.fieldIcons}>
          {event.description && <DescriptionIcon />}
          {event.callLink && <VideoCam />}
          {event.covidMeasurements && <FavoriteIcon />}
          {event.expectedNumberOfGuests && <PeopleIcon />}
          {!event.visibleOnProfile && <VisibilityOff />}
          {event.ticketProducts && event.ticketProducts.length > 0 && <DollarIcon />}
          {event.ticketLink && <LocalActivity />}
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {event.state !== 'CANCELED' && (
          <div className={classes.editButtons}>
            {event.dateToBeAnnounced && (!event.tags || !_.find(event.tags, tag => tag.id === 1)) && <Button onClick={() => setAnnounceOpen(true)} color='primary' className={classes.editButton} startIcon={<EventIcon color='primary' />}>{t('event.view.announce')}</Button>}
            {event && event.dateToBeAnnounced && <AnnounceDateDialog eventId={event.id} open={announceOpen} onClose={() => setAnnounceOpen(false)} />}
            <Button onClick={onEditClicked} color='primary' className={classes.editButton} startIcon={<Edit color='primary' />}>{t('event.view.edit')}</Button>
          </div>
        )}
        {event.description && <Typography className={classes.detailsField}><DescriptionIcon />{event.description}</Typography>}
        {event.callLink && <Typography className={classes.detailsField}><VideoCam />{event.callLink}</Typography>}
        {event.covidMeasurements && <Typography className={classes.detailsField}><FavoriteIcon />{event.covidMeasurements}</Typography>}
        {event.expectedNumberOfGuests && <Typography className={classes.detailsField}><PeopleIcon />{event.limitNumberOfGuests ? '' : '~'}{event.expectedNumberOfGuests}</Typography>}
        {!event.visibleOnProfile && <Typography className={classes.detailsField}><VisibilityOff />{t('event.view.hidden')}</Typography>}
        {event.ticketProducts && event.ticketProducts.length > 0 && <Typography className={classes.detailsField}><DollarIcon />{t('event.view.hasTickets')}</Typography>}
        {event.ticketLink && <Typography className={classes.detailsField}><LocalActivity />{t('event.view.hasExtTickets')}</Typography>}
      </AccordionDetails>
    </Accordion>)
}

export default (withStyles(styles)(EventDetails))
