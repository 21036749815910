import React, { useContext, useEffect } from 'react'
import { SvgIcon, withStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { ReactComponent as MollieIcon } from '../../media/icons/mollie.svg'
import FlatButton from '@bash/shared-components/buttons/FlatButton'
import { authoriseMollie, getConnectedApps } from '../../model/user/actions'
import { useLocation } from 'react-router'
import LoadingScreen from '../../components/LoadingScreen'
import { getMollieMethod } from '../../selectors/payments'
import { Trans, useTranslation } from 'react-i18next'
import { NavContext } from '../../components/navigation/MainContainer'
import { history } from '../../config/store'
import { Check, Close } from '@material-ui/icons'
import classNames from 'classnames'
import CircularProgress from '@material-ui/core/CircularProgress'
import { setDraft } from '../../model/events/actions'

const styles = theme => ({
  root: {
    maxWidth: theme.breakpoints.values.events,
    width: '100%',
    height: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(0, 2, 2, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: theme.spacing(7)
  },
  profileTitle: {
    fontWeight: '700',
    marginBottom: theme.spacing(0.5)
  },
  confirmText: {
    marginTop: theme.spacing(3)
  },
  fieldLabel: {
    marginTop: theme.spacing(5)
  },
  fieldSubLabel: {
    marginTop: theme.spacing(0.5)
  },
  marginTop1: {
    marginTop: theme.spacing(1)
  },
  divider: {
    width: '100%',
    height: '1px',
    backgroundColor: '#DDDDDD',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5)
  },
  mollieButton: {
    padding: theme.spacing(2),
    background: theme.palette.grey.dark,
    color: theme.palette.text.primary,
    '&:hover': {
      background: '#DDDDDD'
    }
  },
  mollieIcon: {
    marginRight: theme.spacing(1.5)
    // color: theme.palette.primary.main
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  },
  buttonStatus: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.secondary
  },
  statusGreen: {
    color: theme.palette.secondary.main
  },
  statusRed: {
    color: theme.palette.tertiary.main
  },
  statusIcon: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5)
  },
  loading: {
    marginTop: theme.spacing(3)
  },
  refreshButton: {
    marginTop: theme.spacing(2)
  }
})

const Payments = ({ classes }) => {
  const { t } = useTranslation('common')
  const { setShowHeader, setDefault } = useContext(NavContext)
  const orgId = useSelector(state => state.user.value.organisation.id)
  const authorising = useSelector(state => state.user.authorising)
  const eventDraft = useSelector(state => state.events.draft)
  const mollieMethod = useSelector(getMollieMethod)
  const dispatch = useDispatch()
  const location = useLocation()

  const onMollieClick = async () => {
    if (mollieMethod.status === 'NONE') {
      localStorage.setItem('draft', JSON.stringify(eventDraft))

      eventDraft.imageUrl && await fetch(eventDraft.imageUrl).then(r => r.blob()).then(blob => {
        const reader = new FileReader()
        reader.addEventListener('loadend', function () {
          const base64FileData = reader.result.toString()

          // save the file info to localStorage
          localStorage.setItem('draftImage', JSON.stringify(base64FileData))
        })
        reader.readAsDataURL(blob)
      })

      window.open(`/organisationsAdmin/${orgId}/connectMollie`, '_self')
    } else {
      window.open('https://mollie.com/dashboard', '_blank')
    }
  }

  const params = new URLSearchParams(location.search)
  const code = params.get('code')
  useEffect(() => {
    const deleteFromQuery = (param) => {
      params.delete(param)
      history.replace({
        search: params.toString()
      })
    }

    if (code && !authorising) {
      dispatch(authoriseMollie(code))
      deleteFromQuery('code')
      const localDraft = JSON.parse(localStorage.getItem('draft'))
      const localImage = JSON.parse(localStorage.getItem('draftImage'))
      if (localDraft) {
        if (localImage) {
          fetch(localImage)
            .then(res => res.blob())
            .then(blob => {
              const file = new File([blob], 'File name', { type: 'image/png' })
              dispatch(setDraft({
                ...localDraft,
                imageUrl: URL.createObjectURL(file)
              }))
            })
        } else {
          dispatch(setDraft(localDraft))
        }

        localStorage.removeItem('draft')
        localStorage.removeItem('draftImage')
      }
    } else {
      !mollieMethod && dispatch(getConnectedApps())
    }
    setShowHeader(false)
    return setDefault
  }, [code, dispatch, mollieMethod, setDefault, setShowHeader])

  const onRefreshClicked = () => {
    dispatch(getConnectedApps())
  }

  const helperText = mollieMethod && {
    NEEDS_DATA: <span><Trans i18nKey='settings.payments.needsData' t={t}>You cannot accept payments through Mollie yet because your Mollie account is not yet completed. Please <a target='_blank' rel='noreferrer' href='https://mollie.com/dashboard'>complete your Mollie account</a> to start accepting payments with BASH.</Trans></span>,
    IN_REVIEW: <span>{t('settings.payments.inReview')}</span>,
    UNKNOWN_ERROR: <span><Trans i18nKey='settings.payments.unknownError' t={t}>You cannot accept payments through Mollie yet. Please <a target='_blank' rel='noreferrer' href='https://mollie.com/dashboard'>complete your Mollie account</a> to start accepting payments with BASH.</Trans></span>,
    NO_WEBSITE_PROFILE: t('settings.payments.noWebsiteProfile'),
    EXPIRED: t('settings.payments.expired')
  }

  const buttonStatus = mollieMethod && {
    CONNECTED: <Typography className={classNames(classes.buttonStatus, classes.statusGreen)} variant='button'><Check className={classes.statusIcon} />{t('settings.payments.status.connected')}</Typography>,
    NEEDS_DATA: <Typography className={classes.buttonStatus} variant='button'><Close className={classNames(classes.statusIcon, classes.statusRed)} />{t('settings.payments.status.needsData')}</Typography>,
    UNKNOWN_ERROR: <Typography className={classes.buttonStatus} variant='button'><Close className={classNames(classes.statusIcon, classes.statusRed)} />{t('settings.payments.status.error')}</Typography>,
    NO_WEBSITE_PROFILE: <Typography className={classes.buttonStatus} variant='button'><Close className={classNames(classes.statusIcon, classes.statusRed)} />{t('settings.payments.status.noWebsiteProfile')}</Typography>,
    IN_REVIEW: <Typography className={classes.buttonStatus} variant='button'><Check className={classNames(classes.statusIcon)} />{t('settings.payments.status.inReview')}</Typography>,
    EXPIRED: <Typography className={classes.buttonStatus} variant='button'><Close className={classNames(classes.statusIcon, classes.statusRed)} />{t('settings.payments.status.expired')}</Typography>
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.profileTitle} variant='h5'>{t('settings.payments.title')}</Typography>
      <Typography color='textSecondary' variant='subtitle1'>{t('settings.payments.subtitle')}</Typography>
      <div className={classes.divider} />
      {(authorising || !mollieMethod) ?
        <CircularProgress className={classes.loading} size={32} /> :
        <>
          {/* {mollieMethod && mollieMethod.status === 'CONNECTED' && <Typography variant='subtitle1'>{t('settings.payments.connected')}</Typography>} */}
          {/* {mollieMethod && mollieMethod.status === 'NONE' && ( */}
          <Typography variant='subtitle1'>{t('settings.payments.subSubtitle')}</Typography>
          <Typography variant='body1' className={classes.marginTop1} color='textSecondary'>{t('settings.payments.body')}</Typography>
          <div className={classes.buttonContainer}>
            <FlatButton onClick={onMollieClick} className={classes.mollieButton}><SvgIcon color='primary' className={classes.mollieIcon} viewBox='0 0 26 17' component={MollieIcon} />{mollieMethod.status === 'NONE' ? t('settings.payments.connectMollie') : t('settings.payments.goMollie')}</FlatButton>
            {buttonStatus[mollieMethod.status]}
          </div>
          {/* )} */}
          {mollieMethod.status !== 'CONNECTED' && mollieMethod.status !== 'NONE' && (
            <>
              <Typography variant='body1' className={classes.marginTop1} color='textSecondary'>{helperText[mollieMethod.status]}</Typography>
              <FlatButton onClick={onRefreshClicked} color='primary' className={classes.refreshButton}>{t('settings.payments.refresh')}</FlatButton>
            </>
          )}
        </>}
    </div>
  )
}

export default (withStyles(styles)(Payments))
