import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { Avatar, IconButton } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import FlatButton from '@bash/shared-components/buttons/FlatButton'
import StateBadge from './StateBadge'
import classNames from 'classnames'
import { changeUserRole, deleteSelf, deleteUser } from '../model/user/actions'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@material-ui/core/styles/makeStyles'
import YesNoDialog from './dialog/YesNoDialog'
import { Trans, useTranslation } from 'react-i18next'
import { Check, ExitToApp, MoreVert } from '@material-ui/icons'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu/Menu'
import _ from 'underscore'
import apiClient from '@bash/shared-components/utils/ApiClient'
import RestingButton from './RestingButton'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    width: '100%'
  },
  avatar: {
    height: '40px',
    width: '40px',
    marginRight: theme.spacing(2)
  },
  emailAddress: {
    marginTop: theme.spacing(0.25)
  },
  leaveButton: {
    // marginLeft: 'auto'
  },
  pendingBadge: {
    // marginLeft: 'auto'
  },
  pending: {
    opacity: 0.6
  },
  menuButton: {
    padding: theme.spacing(1),
    marginLeft: theme.spacing(2)
  },
  menuDivider: {
    width: '100%',
    height: '1px',
    backgroundColor: theme.palette.grey.dark,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 2)
  },
  memberName: {
    marginRight: 'auto'
  },
  roleCheck: {
    fontSize: '20px',
    marginLeft: 'auto'
  },
  exitIcon: {
    color: theme.palette.tertiary.main,
    marginRight: theme.spacing(1)
  }
}))

const TeamMemberListItem = ({ member, ...other }) => {
  const { t } = useTranslation('common')
  const pending = member.state === 'PENDING'
  const currentOrgUser = useSelector(state => state.user.value)
  const currentIsAdmin = currentOrgUser.role === 'ADMIN'
  const orgUsers = useSelector(state => state.user.users)
  const [deleting, setDeleting] = useState(false)
  const dispatch = useDispatch()
  const classes = useStyles()
  const [leaveOpen, setLeaveOpen] = useState(false)
  const [removeOpen, setRemoveOpen] = useState(false)
  const [menuOpen, setMenuOpen] = useState(null)
  const showLeaveOrg = currentOrgUser.id === member.id && _.size(orgUsers) > 1

  const onLeaveConfirmed = async () => {
    setDeleting(true)
    await dispatch(deleteSelf())
    // setDeleting(false)
  }

  const onRemoveConfirmed = async () => {
    setDeleting(true)
    await dispatch(deleteUser(member.id))
    setDeleting(false)
    setRemoveOpen(false)
  }

  const changeRole = (role) => {
    if (member.role === role) return
    dispatch(changeUserRole(member.id, role))
  }

  return (
    <Paper elevation={2} className={classes.root} {...other}>
      {member.user && <Avatar className={classNames(classes.avatar, pending ? classes.pending : undefined)} src={`${member.user ? member.user.avatarUrl : ''}`} />}
      <div className={classes.memberName}>
        <Typography color={pending ? 'textSecondary' : undefined} variant='subtitle1'>{member.user ? member.user.name : ''}</Typography>
        <Typography className={classes.emailAddress} variant='body2' color='textSecondary'>{member.inviteEmail}</Typography>
      </div>
      {pending && <StateBadge className={classes.pendingBadge} color='grey' text='PENDING' />}
      {!showLeaveOrg && currentIsAdmin && <IconButton onClick={e => setMenuOpen(e.target)} className={classes.menuButton}><MoreVert /></IconButton>}
      {!showLeaveOrg && currentIsAdmin && (
        <Menu
          id='user-menu'
          keepMounted
          anchorEl={menuOpen}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          open={Boolean(menuOpen)}
          onClose={() => setMenuOpen(null)}
          classes={{
            list: classes.menuList
          }}
        >

          <MenuItem className={classes.menuItem} onClick={() => changeRole('MEMBER')}>
            <Typography variant='body2'>
              {member.role === 'MEMBER' ?
                <b>{t('settings.members.roles.member')}</b> :
                t('settings.members.roles.member')}
            </Typography>
            {member.role === 'MEMBER' && <Check className={classes.roleCheck} />}
          </MenuItem>
          <MenuItem className={classes.menuItem} onClick={() => changeRole('ADMIN')}>
            <Typography variant='body2'>
              {member.role === 'ADMIN' ?
                <b>{t('settings.members.roles.admin')}</b> :
                t('settings.members.roles.admin')}
            </Typography>
            {member.role === 'ADMIN' && <Check className={classes.roleCheck} />}
          </MenuItem>
          <div className={classes.menuDivider} />
          <MenuItem key='kick-member' onClick={() => { setRemoveOpen(true); setMenuOpen(false) }}>
            <Typography variant='body2'>{t('settings.members.remove')}</Typography>
          </MenuItem>
        </Menu>
      )}
      {showLeaveOrg && <RestingButton onClick={() => setLeaveOpen(true)} className={classes.leaveButton}><ExitToApp className={classes.exitIcon} />{t('settings.members.leave')}</RestingButton>}
      {showLeaveOrg && (
        <YesNoDialog
          title='Are you sure?'
          subtitle='Leaving this organisation will remove your access to it'
          confirmText='Leave organisation' cancelText='Cancel'
          destructive
          open={leaveOpen}
          onConfirm={onLeaveConfirmed}
          loading={deleting}
          onClose={() => setLeaveOpen(false)}
        />
      )}
      {!showLeaveOrg && currentIsAdmin && (
        <YesNoDialog
          title='Are you sure?'
          subtitle={<Trans t={t} i18nKey='settings.members.removeDialog.subtitle' name={member.user && member.user.name}>This will remove {{ name: member.user && member.user.name }} from this organisation.</Trans>}
          confirmText='Remove from organisation'
          cancelText='Cancel'
          destructive
          open={removeOpen}
          onConfirm={onRemoveConfirmed}
          loading={deleting}
          onClose={() => setRemoveOpen(false)}
        />
      )}
    </Paper>
  )
}
export default (TeamMemberListItem)
