import React, { Component } from 'react'

import GuestAvatarName from 'shared-components/avatar/GuestAvatarName'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { Trans, withTranslation } from 'react-i18next'
import { LinearProgress, Typography, withStyles } from '@material-ui/core'
import { countStatus } from 'shared-components/utils/guests'

const styles = theme => ({
  avatarContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(1)
  },
  viewAllContainer: {
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    cursor: 'pointer',
    float: 'right',
    marginTop: -4
  },
  amountRepliedContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing()
  },
  linearProgress: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    height: 4,
    width: '85%',
    borderRadius: 4
  },
  colorSecondary: {
    backgroundColor: theme.palette.red.light
  },
  barColorSecondary: {
    backgroundColor: theme.palette.red.main
  },
  openForUninvitedLabel: {
    marginTop: theme.spacing(1),
    display: 'flex'
  },
  shareIcon: {
    marginRight: theme.spacing(1)
  },
  chevronRight: {
    fontSize: 24,
    color: theme.palette.text.main
  },
  numberOfGuests: {
    fontWeight: 'bold',
    color: theme.palette.text.secondary
  },
  topTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
})

class ShowGuestsRow extends Component {
  constructor (props) {
    super(props)
    this.avatarContainer = React.createRef()
    this.state = {
      avatarAmount: 6
    }
  }

  buildStatusElementsArray = (counts, interested, t) => {
    const countElements = []
    if (counts.GOING > 0) {
      countElements.push(
        <Trans t={t} i18nKey='rsvpCounts.going' count={counts.GOING} key='going'>
          <b>{{ count: counts.GOING }}</b> going
        </Trans>)
    }

    if (counts.MAYBE > 0) {
      countElements.push(
        <Trans t={t} i18nKey={interested ? 'rsvpCounts.interested' : 'rsvpCounts.maybe'} count={counts.MAYBE} key='maybe'>
          <b>{{ count: counts.MAYBE }}</b> maybe
        </Trans>)
    }
    // if (counts.CANT > 0) {
    //   countElements.push(
    //     <Trans t={t} i18nKey='rsvpCounts.cant' count={counts.CANT} key='cant'>
    //       <b>{{ count: counts.CANT }}</b> can't go
    //     </Trans>)
    // }
    if (counts.NONE > 0) {
      countElements.push(
        <Trans t={t} i18nKey='rsvpCounts.none' count={counts.NONE} key='none'>
          <b>{{ count: counts.NONE }}</b> are invited
        </Trans>)
    }
    return countElements
  }

  buildStatusElementsArrayPinning = (countHaveResponded, countNotResponded, t) => {
    const countElements = []
    if (countHaveResponded > 0) {
      countElements.push(
        <Trans t={t} i18nKey='rsvpCounts.responded' count={countHaveResponded} key='going'>
          <b>{{ count: countHaveResponded }}</b> going
        </Trans>)
    }
    if (countNotResponded > 0) {
      countElements.push(
        <Trans t={t} i18nKey='rsvpCounts.none' count={countNotResponded} key='none'>
          <b>{{ count: countNotResponded }}</b> are invited
        </Trans>)
    }
    return countElements
  }

  buildStringFromElements = (countElements) => {
    if (countElements.length > 0) {
      const finalElements = [].concat(...countElements.map((e, i) => i === countElements.length - 1 ? [e] : [e, ', ']))
      finalElements.push('.')
      return finalElements
    }
    return null
  }

  buildStatusString = (statusCounts, interested = false, t) => {
    const countElements = this.buildStatusElementsArray(statusCounts, interested, t)
    return this.buildStringFromElements(countElements)
  }

  buildPinningRespondedString = (guests, t) => {
    const countHaveResponded = guests.filter(g => g.gaveDateOptions || g.organiser).length
    const countNotResponded = guests.length - countHaveResponded
    const countElements = this.buildStatusElementsArrayPinning(countHaveResponded, countNotResponded, t)
    return this.buildStringFromElements(countElements)
  }

  updateAvatarAmount = () => {
    if (this.avatarContainer.current) {
      const newAmount = Math.floor(this.avatarContainer.current.offsetWidth / 64)
      this.setState({ avatarAmount: newAmount })
    }
  }

  componentDidMount () {
    this.updateAvatarAmount()
    window.addEventListener('resize', this.updateAvatarAmount)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateAvatarAmount)
  }

  render () {
    const { classes, t, event, openGuestModal, spotsLeft, guests, useRawImgUrl } = this.props
    const limitReached = event.limitNumberOfGuests && spotsLeft < 1
    const { type, expectedNumberOfGuests, limitNumberOfGuests, responseType } = event
    const pinningDate = type === 'PINNING'
    const isPublicEvent = type === 'PUBLIC'
    const interested = responseType === 'INTEREST'

    const statusCounts = countStatus(event.guests)
    if (!isPublicEvent) statusCounts.GOING = statusCounts.GOING + 1
    const repliedGuests = pinningDate ?
      event.guests.filter(g => g.gaveDateOptions || g.organiser).length :
      statusCounts.GOING

    return (
      <div>
        <div className={classes.topTextContainer}>
          <Typography variant='subtitle1'>
            {pinningDate ?
              this.buildPinningRespondedString(event.guests, t) :
              this.buildStatusString(statusCounts, interested, t)}
          </Typography>
          {event.guests.length > this.state.avatarAmount &&
            <div className={classes.viewAllContainer} onClick={openGuestModal}>
              <Typography variant='caption'>{t('viewAll')}</Typography>
              <ChevronRightIcon className={classes.chevronRight} />
            </div>}
        </div>
        <div className={classes.avatarContainer} ref={this.avatarContainer}>
          {guests.slice(0, this.state.avatarAmount).map((guest, i) =>
            <GuestAvatarName src={useRawImgUrl ? guest.avatarUrl : null} key={i} guest={guest} userCheck />
          )}
        </div>
        {expectedNumberOfGuests && (
          <div className={classes.amountRepliedContainer}>
            <Typography variant='caption' className={classes.numberOfGuests}>{repliedGuests}</Typography>
            <LinearProgress
              variant='determinate'
              className={classes.linearProgress}
              color={limitReached ? 'secondary' : 'primary'}
              classes={{ barColorSecondary: classes.barColorSecondary, colorSecondary: classes.colorSecondary }}
              value={repliedGuests > (Math.max(expectedNumberOfGuests, repliedGuests)) ? 100 : repliedGuests / Math.max(expectedNumberOfGuests, repliedGuests) * 100}
            />
            <Typography variant='caption' className={classes.numberOfGuests}>{`${!limitNumberOfGuests ? '~' : ''}${expectedNumberOfGuests}`}</Typography>
          </div>
        )}
      </div>
    )
  }
}

export default withStyles(styles)(withTranslation('common')(ShowGuestsRow))
