import { createSelector } from 'reselect'
import _ from 'underscore'

const getEvents = (state) => state.events.value
const getCurrentEvent = (state) => state.events.currentEvent

export const getTbaEvents = createSelector(
  [getEvents],
  (events) => _.filter(events, event => event.dateToBeAnnounced)
)

export const getNonTbaEvents = createSelector(
  [getEvents],
  (events) => _.filter(events, event => !event.dateToBeAnnounced)
)

export const currentHasTicketOrder = createSelector(
  [getCurrentEvent],
  (event) => event.guests && _.find(event.guests, guest => guest.tickets && guest.tickets.length > 0)
)
