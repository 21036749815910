import React, { useEffect, useState, useCallback, useRef, useContext, useLayoutEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import mixpanel from '../util/mixpanel'
import bg2x from '../images/2x_Collage.png'
import bg1x from '../images/1x_Collage.png'
import Avatar from '@material-ui/core/Avatar'
import Paper from '@material-ui/core/Paper'
import makeStyles from '@material-ui/core/styles/makeStyles'
import classNames from 'classnames'
import _ from 'underscore'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'
import RedDot from '../components/RedDot'
import RestingButton from '../components/RestingButton'
import AddIcon from '@material-ui/icons/Add'
import { setCurrentOrg } from '../model/user/actions'
import { history } from '../config/store'
import { openModal, setModalInfo } from '../model/modals/actions'
import { useTranslation } from 'react-i18next'
import { NavContext } from '../components/navigation/MainContainer'
import LoadingScreen from '@bash/shared-components/common/LoadingScreen'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: '100vh'
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  formContainer: {
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: theme.breakpoints.values.container,
    background: 'white',
    display: 'flex',
    right: 0,
    left: 0,
    flexDirection: 'column',
    padding: theme.spacing(3),
    width: '100%'
  },
  background: {
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    objectFit: 'cover'
  },
  createButton: {
    width: '100%',
    marginTop: theme.spacing(2),
    color: theme.palette.text.primary
  },
  plusIcon: {
    position: 'absolute',
    left: theme.spacing(1.5)
  }
}))

const useOrgItemStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  avatar: {
    width: '40px',
    height: '40px',
    marginRight: theme.spacing(2),
    border: '0.5px solid',
    borderColor: theme.palette.grey.dark
  },
  halfOpacity: {
    opacity: 0.5
  },
  button: {
    marginLeft: 'auto'
  }
}))

const OrgItem = ({ org }) => {
  const { t } = useTranslation('common')
  const classes = useOrgItemStyles()
  const dispatch = useDispatch()
  const pending = org.state === 'PENDING'

  const onSelectClicked = () => {
    dispatch(setCurrentOrg(org.organisation.id))
  }

  const onRespondClicked = () => {
    dispatch(setModalInfo('acceptInvite', { orgId: org.id }))
    dispatch(openModal('acceptInvite'))
  }

  return (
    <div className={classes.root}>
      <Avatar className={classNames(classes.avatar, pending && classes.halfOpacity)} src={`${org.organisation.avatarUrl}`} />
      <Typography variant='body1' color={pending ? 'textSecondary' : 'textPrimary'}>{org.organisation.name}</Typography>
      {pending && <RedDot />}
      <Button onClick={pending ? onRespondClicked : onSelectClicked} className={classes.button} color='primary'><b>{pending ? t('selectOrg.respond') : t('selectOrg.select')}</b></Button>
    </div>
  )
}

const SelectOrg = () => {
  const { t } = useTranslation('common')
  const { setShowHeader, setShowSidebar, setDefault } = useContext(NavContext)
  const classes = useStyles()
  const orgs = useSelector(state => state.user.organisations)

  useLayoutEffect(() => {
    mixpanel.trackOrgDash('Land on Select Organisation Page')
    setShowSidebar(false)
    setShowHeader(false)
    return setDefault
  }, [setDefault, setShowHeader, setShowSidebar])

  const onCreateOrgClicked = () => {
    history.push('/createorg')
  }

  return (
    <div className={classes.root}>
      <img alt='Background' srcSet={`${bg1x} 1x, ${bg2x} 2x`} className={classes.background} />
      <Paper elevation={24} className={classes.formContainer}>
        <Typography variant='h6' className={classes.title}><b>{t('selectOrg.title')}</b></Typography>
        {_.map(orgs, org => <OrgItem key={org.id} org={org} />)}
        <RestingButton onClick={onCreateOrgClicked} className={classes.createButton}><AddIcon color='primary' className={classes.plusIcon} />{t('selectOrg.createButton')}</RestingButton>
      </Paper>
    </div>

  )
}

export default SelectOrg
