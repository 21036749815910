import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'
import MixpanelLinkify from 'shared-components/common/MixpanelLinkify'
import mixpanel from 'shared-components/utils/mixpanel'

const TEXT_MAX_HEIGHT = 100
const FADE_HEIGHT = 50

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  textContainer: {
    overflow: 'hidden',
    position: 'relative'
  },
  fadeOut: {
    marginBottom: 0,
    '&:before': {
      content: "''",
      width: '100%',
      height: FADE_HEIGHT,
      position: 'absolute',
      left: '0',
      top: TEXT_MAX_HEIGHT - FADE_HEIGHT,
      background: 'linear-gradient(rgba(250,250,250,0), rgba(250,250,250,1))',
      fallbacks: [
        { background: '-moz-linear-gradient(top, rgba(250,250,250,0) 0%, rgba(250,250,250,1) 100%)' },
        { background: '-webkit-linear-gradient(top, rgba(250,250,250,0) 0%,rgba(250,250,250,1) 100%)' }
      ]
    }
  },
  description: {
    whiteSpace: 'pre-wrap'
  },
  readMore: {
    display: 'flex',
    alignSelf: 'flex-end',
    background: 'none !important',
    border: 'none',
    padding: '0 !important',
    cursor: 'pointer',
    width: 'fit-content',
    outline: 'none'
  }
})

const EventDescription = ({ classes, t, description }) => {
  const [showReadMore, setShowReadMore] = useState(false)
  const [showAllText, setShowAllText] = useState(false)
  const checkForReadMore = () => {
    const eventDescriptionEl = document.getElementById('event-description')
    if (eventDescriptionEl) {
      const descHeight = eventDescriptionEl.clientHeight
      if (descHeight >= TEXT_MAX_HEIGHT) setShowReadMore(true)
      if (descHeight < TEXT_MAX_HEIGHT) setShowReadMore(false)
    }
  }
  useEffect(() => {
    checkForReadMore()
    window.addEventListener('resize', checkForReadMore)
  }, [description])

  useEffect(() => {
    mixpanel.track_links('#event-description a', 'Outbound Link Description', { referrer: document.referrer })
  }, [description])

  return (
    <div className={classes.root}>
      <div
        className={classNames(classes.textContainer, { [classes.fadeOut]: showReadMore && !showAllText })}
        style={{ maxHeight: showAllText ? 'none' : TEXT_MAX_HEIGHT }}
      >
        <Typography variant='body2' className={classes.description} id='event-description'>
          <MixpanelLinkify>
            {description}
          </MixpanelLinkify>
        </Typography>
      </div>
      {showReadMore && (
        <button className={classes.readMore} onClick={() => setShowAllText(!showAllText)}>
          <b>{showAllText ? t('less') : t('more')}...</b>
        </button>
      )}
    </div>
  )
}

export default withTranslation('shared')(withStyles(styles)(EventDescription))
