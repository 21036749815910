import moment from 'moment'

export const sortGuests = (a, b) => sortByIsAdded(a, b) || sortByStatus(a, b) || sortByIsUser(a, b) || sortByName(a, b) || 0
export const sortGuestsWithCurrent = (currentGuestId) => (a, b) =>
  currentGuestId ? (sortByCurrentGuest(currentGuestId, a, b) || sortGuests(a, b)) : sortGuests(a, b)

export const getDateLines = (event) => {
  const start = moment(event.startDate)
  const end = !event.endDate ? undefined : moment(event.endDate)
  if (end === undefined) {
    return [start.format('dddd DD MMMM HH:mm'), undefined]
  } else if (end.diff(start, 'hours') < 24) {
    return [start.format('dddd DD MMMM HH:mm') + ' - ' + end.format('HH:mm'), undefined]
  } else {
    return [start.format('dddd DD MMMM HH:mm'), end.format('dddd DD MMMM HH:mm')]
  }
}

export const getGuestName = (guest) => {
  if (guest.user != null && guest.user.name != null) return guest.user.name
  if (guest.name != null) return guest.name
  return 'user'
}

const sortByName = (a, b) => {
  const nameA = getGuestName(a).toUpperCase()
  const nameB = getGuestName(b).toUpperCase()
  if (nameA < nameB) return -1
  else if (nameA > nameB) return 1
  else return 0
}

const sortByStatus = (a, b) => {
  const statusOrder = {
    GOING: 0,
    MAYBE: 1,
    CANT: 2,
    NONE: 3
  }
  if (statusOrder[a.status] < statusOrder[b.status]) return -1
  else if (statusOrder[a.status] > statusOrder[b.status]) return 1
  else return 0
}

export const sortByIsUser = (a, b) => {
  if (a.user && !b.user) return -1
  if (!a.user && b.user) return 1
  return 0
}

const sortByIsAdded = (a, b) => {
  if (a.justAdded && !b.justAdded) return -1
  if (!a.justAdded && b.justAdded) return 1
  return 0
}

const sortByCurrentGuest = (currentGuestId, a, b) => {
  if (a.id === currentGuestId) return -1
  if (b.id === currentGuestId) return 1
  return 0
}
