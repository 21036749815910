import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import moment from 'moment'
import 'moment/locale/nl'
import 'moment/locale/en-gb'
// import { reactI18nextModule } from 'react-i18next'

import commonNl from 'translations/nl/common.json'
import commonEn from 'translations/en/common.json'
import sharedNl from 'shared-components/translations/nl/shared.json'
import sharedEn from 'shared-components/translations/en/shared.json'
import eventNl from 'translations/nl/event.json'
import eventEn from 'translations/en/event.json'

const getDefaultLanguage = () => {
  // return 'nl'
  let lang = 'en'
  // eslint-disable-next-line
  let rest;

  if (navigator.languages && navigator.languages.length) {
    [lang, ...rest] = navigator.languages
  } else if (navigator.userLanguage) {
    lang = navigator.userLanguage
  } else if (navigator.language) {
    lang = navigator.language
  }

  lang = lang.substr(0, 2).toLowerCase()
  if (['en', 'nl'].indexOf(lang) > -1) {
    return lang
  }

  return 'en'
}

i18n
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false }, // React already does escaping
    lng: getDefaultLanguage(), // language to use,
    resources: {
      en: {
        common: commonEn,
        shared: sharedEn,
        event: eventEn
      },
      nl: {
        common: commonNl,
        shared: sharedNl,
        event: eventNl
      }
    }
  })

moment.locale(getDefaultLanguage())

document.changeLanguage = (lan) => {
  moment.locale(lan)
  return i18n.changeLanguage(lan)
}

export default i18n
