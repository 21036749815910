import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import mixpanel from '../util/mixpanel'
import { getMyPastEvents } from '../model/events/actions'
import { replaceBreadcrumbs } from '../model/general/actions'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import { history } from '../config/store'
import EventList from '../components/events/EventList'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

const styles = theme => ({
  root: {
    maxWidth: theme.breakpoints.values.events,
    width: '100%',
    height: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(0, 2, 2, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: theme.spacing(7)
  },
  emptyEvents: {
    marginTop: theme.spacing(4),
    backgroundColor: theme.palette.grey.main,
    borderRadius: 8,
    padding: theme.spacing(2),
    width: '100%',
    textAlign: 'center'
  },
  emptyTitle: {
    marginBottom: theme.spacing(1)
  },
  eventsLoading: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(5),
    display: 'flex',
    alignItems: 'center'
  },
  eventsLoadingProgress: {
    margin: theme.spacing(1)
  },
  eventButtonsRow: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    '& button:first-child': {
      marginRight: theme.spacing(2)
    }
  }
})

const Events = ({ classes, user, events, pastEvents, getMyPastEvents, replaceBreadcrumbs }) => {
  const { location } = useHistory()
  const showPast = location.pathname.endsWith('past')
  const { t } = useTranslation('common')

  useEffect(() => {
    showPast && !pastEvents && getMyPastEvents()
  }, [getMyPastEvents, pastEvents, showPast])

  const showEvents = showPast ? pastEvents : events
  const onClickPastEvents = async () => {
    history.push('/events/past')
    mixpanel.trackOrgDash('Check Past Events')
  }

  const onClickUpcomingEvents = () => {
    history.push('/events')
    mixpanel.trackOrgDash('Check Future Events')
  }

  const onEventClick = event => {
    mixpanel.trackOrgEvent('Open Bash', event)
    history.push(`/event/${event.id}/view`)
  }

  useEffect(() => {
    mixpanel.trackOrgDash('Check Events')
    replaceBreadcrumbs({
      [t('nav.sidebar.events')]: { href: '/events' },
      ...(showPast && { [t('events.pastEvents')]: { href: '/events/past' } })
    })
  }, [replaceBreadcrumbs, showPast, t])

  return (
    <div className={classes.root}>
      {showEvents ?
        <EventList
          className={classes.eventList} events={showEvents} organisation={user} onEventClick={onEventClick}
          onClickPastEvents={onClickPastEvents} onClickUpcomingEvents={onClickUpcomingEvents} showPast={showPast}
        /> :
        <div className={classes.eventsLoading}>
          <CircularProgress className={classes.eventsLoadingProgress} size={24} color='inherit' />{t('events.loading')}
        </div>}
    </div>
  )
}

const mapStateToProps = ({ user, events, pastEvents }) => ({
  user: user.value.organisation,
  events: events.value,
  pastEvents: events.pastEvents
})

export default connect(mapStateToProps, { getMyPastEvents, replaceBreadcrumbs })(withStyles(styles)(Events))
